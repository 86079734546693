import executeBlockInSequence from './helpers/executeBlockInSequence'

const ifActionNode = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!actionNodeRunner.children.length) {
			// no children - Signal to parent to run next
			actionNodeLogger.debug('Evaluation of condition has been skipped because there is no children')
			resolve()
		} else if (appController.getDataFromDataValue(actionNode.condition, contextData)) {
			actionNodeLogger.debug('Condition is true')
			executeBlockInSequence(actionNodeRunner.children, contextData, actionNodeLogger)
				.then((result) => resolve(result))
				.catch((err) => reject(err))
		} else {
			// condition not true - Signal to parent to run next
			actionNodeLogger.debug('Condition is false')
			resolve()
		}
	})

export default ifActionNode
