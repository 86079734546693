import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import escapeHtml from 'escape-html'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'

import { e_TypographyType } from '@appfarm/common/enums/e_PropertyTypes'

const useStyles = makeStyles({
	root: {
		position: 'relative',
		whiteSpace: 'pre-wrap',
		'& > p': {
			marginTop: 0,
			marginBottom: '0.25em',
			minHeight: '1em',
			overflowWrap: 'break-word',
		},
	},
	resetStyle: {
		lineHeight: 'unset',
	},
	clickable: {
		cursor: 'pointer',
	},
})

const isText = (value) => isPlainObject(value) && typeof value.text === 'string'

const serializeNode = (node) => {
	if (isText(node)) {
		let string = escapeHtml(node.text)
		if (!string) return '</br>'
		if (node.bold) string = `<strong>${string}</strong>`
		if (node.italic) string = `<em>${string}</em>`
		if (node.underline) string = `<u>${string}</u>`
		if (node.strikethrough) string = `<s>${string}</s>`
		return string
	}

	const children = node.children.map((node) => serializeNode(node)).join('')

	switch (node.type) {
		case 'p':
			return `<p>${children}</p>`
		// case 'link':
		// 	return `<a href="${escapeHtml(node.url)}">${children}</a>`
		default:
			return children
	}
}

const serializeTextBlockContent = (nodes) => {
	return nodes.map((node) => serializeNode(node)).join('')
}

const UiTextBlock = (props) => {
	const { component, styleProp, conditionalClassNames, appController, contextData } = props

	const elementId = useMemo(() => {
		if (isNil(component.elementId)) return undefined
		return appController.getDataFromDataValue(component.elementId, contextData)
	}, [component.elementId, contextData])

	const content = useMemo(() => {
		if (!component.content) return ''
		return serializeTextBlockContent(component.content)
	}, [component.content])

	const handleClick = useCallback(
		(event) => {
			props.eventHandler(component.onClick, null, { eventType: 'onClick' }, event)
		},
		[component.onClick]
	)

	const classes = useStyles()

	return (
		<Typography
			id={elementId}
			className={classNames(classes.root, 'c' + component.id, conditionalClassNames, {
				[classes.resetStyle]: component.variant === e_TypographyType.NONE,
				[classes.clickable]: component.onClick,
			})}
			component="div"
			variant={component.variant === e_TypographyType.NONE ? undefined : component.variant || undefined}
			style={styleProp}
			onClick={component.onClick ? handleClick : undefined}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}

UiTextBlock.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
}

export default React.memo(UiTextBlock)
