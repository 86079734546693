import axios from 'axios'
import { useEffect } from 'react'

const ImageServiceTokenLoader = ({ activeAppId }) => {
	const INTERVAL_REFRESH = 55 * 60 * 1000 // 55 minutes
	const getRandomInteger = (min, max) => Math.random() * (max - min) + min

	const getToken = () => {
		return axios.get(`/api/v1/apps/${activeAppId}/token/image/external`).then((res) => {
			return res.data
		})
	}

	const updateToken = () => {
		getToken()
			.then((token) => (window.AF_PARAMS.imageServiceToken = token))
			.catch((err) => console.warn('Failed to get token: ' + err))
	}

	useEffect(() => {
		if (!activeAppId) return

		const initalOffset = getRandomInteger(0, 60 * 4) * 1000 // 4 minutes
		const refreshInterval = INTERVAL_REFRESH + initalOffset

		// perform once
		updateToken()

		// perform on interval, with offset
		const intervalID = setInterval(() => {
			updateToken()
		}, refreshInterval)

		return () => {
			clearInterval(intervalID)
		}
	}, [activeAppId])
}

export default ImageServiceTokenLoader
