import { getMutedActionsFromLocalStorage } from '../../components/DevTools/utils/localStorageGetters'
import { e_Level } from './types'

export const shouldLog = (minLevel: e_Level, level: e_Level): boolean => {
	return minLevel <= level
}

export const generateIdForLogger = (): string => {
	return (
		Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1) +
		Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	)
}

export const makeIsActionNotMuted = (actionId: string) => () => {
	const mutedActions = getMutedActionsFromLocalStorage() || {}
	return !mutedActions[actionId]
}
