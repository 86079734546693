import { combineReducers } from 'redux'

import metaData from './metaDataReducer'
import appState from './appStateReducer'
import runtimeState from './runtimeStateReducer'
import authState from './authStateReducer'
import resourceReadyState from './resourceReadyStateReducer'

export default combineReducers({
	resourceReadyState,
	metaData,
	appState,
	runtimeState,
	authState,
})
