export default {
	months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split('_'),
	monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
	weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
	weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
	weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D. MMMM YYYY',
		LLL: 'D. MMMM YYYY HH:mm',
		LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
	},
	calendar: {
		sameDay: '[i dag kl.] LT',
		nextDay: '[i morgen kl.] LT',
		nextWeek: 'på dddd [kl.] LT',
		lastDay: '[i går kl.] LT',
		lastWeek: '[i] dddd[s kl.] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'om %s',
		past: '%s siden',
		s: 'få sekunder',
		ss: '%d sekunder',
		m: 'et minut',
		mm: '%d minutter',
		h: 'en time',
		hh: '%d timer',
		d: 'en dag',
		dd: '%d dage',
		M: 'en måned',
		MM: '%d måneder',
		y: 'et år',
		yy: '%d år',
	},
	dayOfMonthOrdinalParse: /\d{1,2}\./,
	ordinal: '%d.',
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
}
