import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
	loginSection: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		textAlign: 'center',
		left: 0,
		right: 0,
		paddingTop: 16,
	},
}

const LoginSection = ({ classes, children }) => <div className={classes.loginSection}>{ children }</div>

LoginSection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginSection)
