import axios from 'axios'
import axiosErrorParser from '#modules/axiosErrorParser'
import isString from 'lodash/isString'
import getToken from './helpers/getTokenForActionNode'
import p_placeFileInDataSource from './helpers/placeFileInDataSource'

const pdfServiceHostname = window.AF_PARAMS.pdfServiceTarget
const endpointURL = `${pdfServiceHostname}/api/v1/generate`

const p_generateDocument = async ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) => {
	actionNodeLogger.info('Run ActionNode on Client', { payload: actionNode })
	if (!actionNode.dataSourceId) throw new Error('dataSourceId is not set')
	const dataSource = appController.getDataSource(actionNode.dataSourceId)
	if (!dataSource) throw new Error('Could not find dataSource for document creation')
	if (!dataSource.isFileObjectClass) throw new Error('DataSource is not a file-datasource')

	let fileName = appController.getDataFromDataValue(actionNode.fileName, contextData)
	if (!fileName) fileName = 'filename.pdf'
	if (!isString(fileName)) throw new Error('File Name must be a string')
	if (!fileName.endsWith('.pdf')) fileName += '.pdf'

	const rootActionId = actionNodeRunner.getRootAction().id
	const templateData = appController.getDataFromDataValue(actionNode.templateString, contextData)
	if (!isString(templateData)) throw new Error('Template must be a string')

	const token = await getToken(appController, actionNode, rootActionId)

	const config = {
		url: endpointURL,
		method: 'post',
		responseType: 'blob',
		timeout: 60 * 1000,
		data: {
			template: templateData,
			fileName: fileName,
			options: {
				format: actionNode.documentFormat,
				marginTop: actionNode.marginTop,
				marginRight: actionNode.marginRight,
				marginBottom: actionNode.marginBottom,
				marginLeft: actionNode.marginLeft,
				orientation: actionNode.documentOrientation,
			},
		},
		headers: { 'x-af-sol-token-v1': token },
	}

	let pdfPayload
	try {
		pdfPayload = await axios.request(config)
	} catch (err) {
		throw axiosErrorParser(err)
	}

	const file = new File([pdfPayload.data], fileName, { type: 'application/pdf' })
	return await p_placeFileInDataSource({
		dataSource,
		actionNode,
		actionNodeRunner,
		actionNodeLogger,
		file,
		fileName,
		contextData,
		mimeType: 'application/pdf',
	})
}

export default p_generateDocument
