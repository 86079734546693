/**
 * Will Execute a single block of children
 */
const executeBlockInSequence = (children, contextData, contextLogger) =>
	new Promise((resolve, reject) => {
		// Empty block is ok.
		if (!children.length) return resolve()

		const runSingleChild = (indexForExecute, error) => {
			const childForExec = children[indexForExecute]

			childForExec
				.run({ contextData, error, contextLogger })
				.then((result) => {
					// Check for result from flow actions
					if (result) {
						const { endExecution, nextIteration, exitLoop } = result

						// Done with loop or action
						if (endExecution || exitLoop || nextIteration) return resolve(result)

						// Should not end up here - unknown signal
					}

					const nextIndex = indexForExecute + 1

					// Done
					if (!children[nextIndex]) return resolve()

					// Run next
					runSingleChild(nextIndex)
				})
				.catch((error) => {
					// Look for error handler
					let nextIndex = indexForExecute + 1

					while (children[nextIndex]) {
						if (children[nextIndex].isValidExceptionHandler(error)) {
							// Found Valid Error handler among siblings - run it
							return runSingleChild(nextIndex, error)
						}

						nextIndex++
					}

					reject(error)
				})
		}

		// Initiate
		runSingleChild(0)
	})

export default executeBlockInSequence
