import moment from 'moment'
import e_FileTargetOptions from '@appfarm/common/enums/e_FileTargetOptions'
import { ONE } from '@appfarm/common/enums/e_Cardinality'

import { requestFileUpload } from '#modules/afClientApi'
import p_runFileUpload from './uploadFile/p_runFileUpload'

const vcardStringEscape = (value) => {
	/**
	 * PROTIP: The order of this chain is very important!
	 * @see http://tools.ietf.org/html/rfc6350#section-3.4
	 */
	return value.replace('\\', '\\\\').replace(',', '\\,').replace(';', '\\;').replace(/\n/g, '\\n')
}

const p_createCalendarEvent = ({
	actionNode,
	contextData,
	appController,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		const eventStart = appController.getDataFromDataValue(actionNode.eventStart, contextData)
		const eventEnd = appController.getDataFromDataValue(actionNode.eventEnd, contextData)
		const eventSummary = appController.getDataFromDataValue(actionNode.eventSummary, contextData)
		const eventDescription = appController.getDataFromDataValue(actionNode.eventDescription, contextData)
		const eventLocation = appController.getDataFromDataValue(actionNode.eventLocation, contextData)

		let fileName = appController.getDataFromDataValue(actionNode.fileName, contextData)
		fileName = fileName ? fileName + '.ics' : 'event.ics'

		const formatString = 'YYYYMMDDTHHmmss'

		if (actionNode.addToGoogleCalendar) {
			actionNodeLogger.debug('Adding event to Google Calendar')
			// https://stackoverflow.com/questions/22757908/google-calendar-render-action-template-parameter-documentation
			const googleUrlParts = [
				`text=${encodeURIComponent(eventSummary)}`,
				eventDescription ? `details=${encodeURIComponent(eventDescription)}` : null,
				eventLocation ? `location=${encodeURIComponent(eventLocation)}` : null,
				`dates=${moment(eventStart).utc().format(formatString)}Z/${moment(eventEnd)
					.utc()
					.format(formatString)}Z`,
				'trp=true',
				'sf=true',
				'output=xml',
			]

			const url =
				'https://calendar.google.com/calendar/r/eventedit?' + googleUrlParts.filter((item) => item).join('&')
			window.open(url, '_blank')
			return resolve()
		}

		const eventId = appController.getDataFromDataValue(actionNode.eventId, contextData) || moment().toJSON()

		const eventData = [
			'BEGIN:VEVENT',
			`UID:${eventId}@appfarm.io`.toUpperCase(),
			'SEQUENCE:0',
			`DTSTAMP:${moment().utc().format(formatString)}Z`,
			`DTSTART:${moment(eventStart).utc().format(formatString)}Z`,
			`DTEND:${moment(eventEnd).utc().format(formatString)}Z`,
			eventSummary && `SUMMARY:${vcardStringEscape(eventSummary)}`,
			eventLocation && `LOCATION:${vcardStringEscape(eventLocation)}`,
			eventDescription && `DESCRIPTION:${vcardStringEscape(eventDescription)}`,
			'END:VEVENT',
		].filter((item) => item)

		const calData = [
			'BEGIN:VCALENDAR',
			'VERSION:2.0',
			'PRODID:-//Appfarm AS//appfarm.io//EN',
			...eventData,
			'END:VCALENDAR',
		].join('\n')

		const blob = new Blob([calData], { type: 'text/calendar;charset=utf-8' })

		if (actionNode.fileTarget === e_FileTargetOptions.PUT_IN_DATASOURCE) {
			actionNodeLogger.debug('Adding event to Data Source')
			/**
			 * Sanity checks
			 */
			if (!actionNode.dataSourceId) return reject(new Error('Could not upload file - no target datasource'))

			const dataSource = appController.getDataSource(actionNode.dataSourceId)
			if (!dataSource) return reject(new Error('Unable to find target datasource for upload'))
			if (!dataSource.isFileObjectClass)
				return reject(new Error('Cannot upload file into non-file ObjectClass'))

			if (dataSource.cardinality === ONE && dataSource.getAllObjects().length)
				return reject(
					new Error('Cannot upload file into datasource with cardinality one - object already exist')
				)

			const rootActionId = actionNodeRunner.getRootAction().id
			if (dataSource.local) {
				let newFileObject = dataSource.generateNewObject(actionNode.defaultValues, contextData)

				newFileObject = {
					...newFileObject,
					__file: blob,
					__actionNodeId: actionNode.id, // Need this for upload
					__actionId: rootActionId, // Need this for upload
					originalFileName: fileName,
					__mimeType: 'text/calendar',
					__fileSize: blob.size,
					__uploadComplete: false,
					__uploadProgress: 0,
				}

				dataSource.p_insertFileObject(newFileObject).then(resolve).catch(reject)
			} else {
				const defaultValues = dataSource.generateShellObject(actionNode.defaultValues, contextData)

				requestFileUpload({
					actionId: rootActionId,
					actionNodeId: actionNode.id,
					data: defaultValues,
				})
					.then((uploadPermissionResponse) => {
						return p_runFileUpload({
							fileOrBlob: blob,
							uploadPermissionResponse,
							fileName,
							defaultValues,
							dataSource,
							logger: actionNodeLogger,
						})
					})
					.then(resolve)
					.catch(reject)
			}
		} else {
			/**
			 * Download directly
			 */

			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, fileName)
			} else {
				const link = document.createElement('a')
				const reader = new FileReader()
				reader.onload = function (e) {
					link.setAttribute('href', reader.result)
					link.setAttribute('download', fileName)
					link.style.visibility = 'hidden'
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					resolve()
				}
				reader.readAsDataURL(blob)
			}
		}
	})

export default p_createCalendarEvent
