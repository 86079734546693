/**
 * Provides a shortcut directly to reduxStore.
 */

let reduxStore = null

export const init = (store) => (reduxStore = store)

export const getStore = () => reduxStore
export const getState = () => reduxStore.getState()
