import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

import { motion } from 'framer-motion'

const styles = (theme) => ({
	root: {
		display: 'flex',
		zIndex: 10,
	},
	mini: {
		height: 40,
		width: 40,
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	lineBreak: { whiteSpace: 'pre-line' },
})

const UiFabButton = (props) => {
	const onClickHandler = useCallback(
		(event) => {
			props.eventHandler(props.component.onClick, null, { eventType: 'onClick' }, event)
		},
		[props.component]
	)

	const {
		component,
		tooltipTitle,
		tooltipDisableInteractive,
		tooltipLineBreak,
		disabled,
		styleProp,
		style,
		conditionalClassNames,
		classes,
	} = props

	const button = (
		<Fab
			//{...(props.forwardedRef ? { ref: props.forwardedRef} : {})}
			component={props.useMotion ? motion.button : undefined}
			className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
			style={{
				...styleProp,
				...style,
			}}
			variant={component.extended ? 'extended' : 'circular'}
			size={component.buttonSize}
			color={component.buttonColor}
			onClick={onClickHandler}
			disabled={disabled}
			{...props.motionProps}
		>
			<Icon className={classNames(component.icon, { [classes.extendedIcon]: component.extended })} />
			{ component.label }
		</Fab>
	)

	if (tooltipTitle)
		return (
			<Tooltip
				title={tooltipTitle}
				interactive={!tooltipDisableInteractive}
				classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
			>
				{ button }
			</Tooltip>
		)

	return button
}

UiFabButton.propTypes = {
	component: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	ownData: PropTypes.string,
	eventHandler: PropTypes.func.isRequired,
	style: PropTypes.object,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiFabButton)
