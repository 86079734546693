import React, { useCallback, useMemo, useRef, createContext, useContext, useEffect } from 'react'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import PropTypes from 'prop-types'

const ESTIMATED_ITEM_SIZE = 36

const DynamicListContext = createContext({})

const Row = (props) => {
	const { setSize } = useContext(DynamicListContext)
	const rowRoot = useRef(null)
	const { data, index } = props
	const itemKey = data[index].key

	useEffect(() => {
		if (rowRoot.current) setSize(itemKey, rowRoot.current.getBoundingClientRect().height)
	})

	const style = { ...props.style }
	if (style.height === ESTIMATED_ITEM_SIZE) style.height = 'fit-content'

	return React.cloneElement(data[index], {
		style,
		ref: rowRoot,
	})
}
Row.propTypes = {
	data: PropTypes.array.isRequired,
	index: PropTypes.number.isRequired,
	style: PropTypes.object.isRequired,
}

const OuterElementType = React.forwardRef((props, ref) => {
	const { outerProps } = useContext(DynamicListContext)

	return <div ref={ref} {...outerProps} {...props} />
})
OuterElementType.displayName = 'OuterElementType'

const useResetCache = (data, callback) => {
	const ref = React.useRef(null)
	React.useEffect(() => {
		callback()
		if (ref.current !== null) {
			ref.current.resetAfterIndex(0)
		}
	}, [data])
	return ref
}

// eslint-disable-next-line react/no-multi-comp
const UiVirtualizedListBox = React.forwardRef((props, ref) => {
	const sizeMap = useRef({})
	const listRef = useResetCache(props.children.length, () => (sizeMap.current = {}))

	const { children, ...outerProps } = props
	const itemData = React.Children.toArray(children)

	const setSize = useCallback(
		(key, size, skipIfSizeIsSet) => {
			if (!sizeMap.current) return
			if (skipIfSizeIsSet && sizeMap.current[key]) return

			// Only update the sizeMap and reset cache if an actual value is changed
			if (sizeMap.current[key]?.toFixed(4) !== size?.toFixed(4)) {
				//only compare until the fourth decimal to avoid rendering issues
				sizeMap.current = { ...sizeMap.current, [key]: size }
				if (listRef.current) {
					// Clear cached data and rerender
					listRef.current.resetAfterIndex(0)
				}
			}
		},
		[sizeMap, listRef]
	)

	const getSize = useCallback(
		(index) => {
			const key = itemData[index].key
			const size = sizeMap?.current[key] || ESTIMATED_ITEM_SIZE
			return size
		},
		[itemData, sizeMap]
	)

	const initialHeight = useMemo(() => {
		if (itemData.length > 15) return 15 * ESTIMATED_ITEM_SIZE
		const size = itemData.map((node, index) => getSize(index)).reduce((a, b) => a + b, 5)
		return size
	}, [itemData, getSize])

	return (
		<div ref={ref} style={{ width: '100%', height: initialHeight }}>
			<DynamicListContext.Provider
				value={{
					setSize,
					outerProps,
				}}
			>
				<AutoSizer>
					{ ({ height, width }) => (
						<List
							ref={listRef}
							width={width}
							height={height}
							itemData={itemData}
							itemCount={itemData.length}
							estimatedItemSize={ESTIMATED_ITEM_SIZE}
							itemSize={getSize}
							overscanCount={5}
							outerElementType={OuterElementType}
							innerElementType="ul"
						>
							{ Row }
						</List>
					) }
				</AutoSizer>
			</DynamicListContext.Provider>
		</div>
	)
})

UiVirtualizedListBox.propTypes = {
	children: PropTypes.array.isRequired,
}
export default UiVirtualizedListBox
