import dayjs from '../../controllers/dayjs'

const locale = {
	name: 'it',
	weekdays: 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
	weekdaysShort: 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
	weekdaysMin: 'do_lu_ma_me_gi_ve_sa'.split('_'),
	months:
		'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split(
			'_'
		),
	weekStart: 1,
	monthsShort: 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd D MMMM YYYY HH:mm',
	},
	relativeTime: {
		future: 'tra %s',
		past: '%s fa',
		s: 'qualche secondo',
		m: 'un minuto',
		mm: '%d minuti',
		h: 'un\' ora',
		hh: '%d ore',
		d: 'un giorno',
		dd: '%d giorni',
		M: 'un mese',
		MM: '%d mesi',
		y: 'un anno',
		yy: '%d anni',
	},
	ordinal: (n) => `${n}º`,
	calendar: {
		sameDay: function () {
			return dayjs(this).format(
				'[Oggi a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
			)
		},
		nextDay: function () {
			return dayjs(this).format(
				'[Domani a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
			)
		},
		nextWeek: function () {
			return dayjs(this).format(
				'dddd [a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
			)
		},
		lastDay: function () {
			return dayjs(this).format(
				'[Ieri a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
			)
		},
		lastWeek: function () {
			switch (this.$D) {
				case 0:
					return dayjs(this).format(
						'[La scorsa] dddd [a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
					)
				default:
					return dayjs(this).format(
						'[Lo scorso] dddd [a' + (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : 'll\'') + ']LT'
					)
			}
		},
		sameElse: 'L',
	},
}

export default locale
