import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Trans } from '@lingui/macro'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { isInDevelopMode } from '#selectors/metadataSelectors'

/**
 * Gammel versjon vil stort sett fungere helt fint. bare tving oppgradering
 * dersom server sier det er nødvendig, ellers blir oppgradering
 * tatt sammen med modellendring.
 */

const UpgradeDialog = ({ open }) => (
	<Dialog open={open}>
		<DialogTitle>
			<Trans>A new version of Appfarm is available</Trans>
		</DialogTitle>
		<DialogContent>
			<Typography variant="body1" gutterBottom>
				<Trans>The update will just take a couple of seconds.</Trans>
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => window.location.reload(true)} color="primary">
				<Trans>Update Now</Trans>
			</Button>
		</DialogActions>
	</Dialog>
)

UpgradeDialog.propTypes = {
	open: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
	let open = false
	if (isInDevelopMode(state)) {
		open = state.appState.clientOutdated
	} else {
		open = state.appState.clientOutdated && state.appState.forceClientUpgrade
	}

	return {
		open: open,
	}
}
export default connect(mapStateToProps)(UpgradeDialog)
