import getColorValueFromMuiTheme from '@appfarm/common/utils/getColorValueFromMuiTheme'
import { e_BackgroundType } from '@appfarm/common/enums/e_PropertyTypes'

const generateBackgroundStyle = (component, muiTheme, params = {}) => {
	const componentStyle = component['style_props']

	if (componentStyle.backgrounds?.length) {
		let background = componentStyle.backgrounds
			.map((backgroundItem) => {
				switch (backgroundItem.type) {
					case e_BackgroundType.LINEAR_GRADIENT:
						if (!backgroundItem.linearGradient) return null
						return backgroundItem.linearGradient

					case e_BackgroundType.RADIAL_GRADIENT:
						if (!backgroundItem.radialGradient) return null
						return backgroundItem.radialGradient

					case e_BackgroundType.COLOR_OVERLAY: {
						if (!backgroundItem.colorOverlay) return null
						const colorOverlay = getColorValueFromMuiTheme(backgroundItem.colorOverlay, muiTheme)
						if (!colorOverlay) return null
						return `linear-gradient(${colorOverlay}, ${colorOverlay})`
					}

					case e_BackgroundType.IMAGE:
					default:
						if (!backgroundItem.imageValue) return null
						return `${backgroundItem.backgroundRepeat || 'no-repeat'} ${
							backgroundItem.backgroundPosition || 'center'
						}/${backgroundItem.backgroundSize || 'cover'} url("${params.getImageUrl(
							backgroundItem.imageValue
						)}")`
				}
			})
			.filter((item) => !!item)
			.join(', ')

		if (componentStyle.backgroundColor) {
			const backgroundColor = getColorValueFromMuiTheme(componentStyle.backgroundColor, muiTheme)
			if (backgroundColor) {
				if (background) {
					background += `, ${backgroundColor}`
				} else {
					background = backgroundColor
				}
			}
		}

		if (background) return { background }
	}

	return undefined
}

export default generateBackgroundStyle
