import logout from './logout'

const logoutAndReload = () => {
	logout()
		.then(() => {
			window.location.reload(true)
		})
		.catch(() => {
			// Should not happen.
			window.location.reload(true)
		})
}

export default logoutAndReload
