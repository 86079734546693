const getObjectChanges = (oldObject, newObject) => {
	let hasChanges = false
	const changedNodeNames = Object.keys(newObject).reduce((result, nodeName) => {
		if (newObject[nodeName] !== oldObject[nodeName]) {
			result[nodeName] = true
			hasChanges = true
		}
		return result
	}, {})

	return {
		hasChanges,
		changedNodeNames,
	}
}

export default getObjectChanges
