import { e_Level, Transport, MetadataArgument } from '../types'
import EventEmitter from 'events'
import StorageController, { KeyValueMap, keyValue } from '../../../controllers/StorageController'

export const analysisStorageHandler = new StorageController('__AF_ANALYSIS', window.sessionStorage)
export const analysisEventHandler = new EventEmitter()

class AnalysisTransport implements Transport {
	id: string
	level: e_Level

	constructor({ level }: { level: e_Level }) {
		this.id = 'analysis'
		this.level = level
	}

	shouldLog() {
		return true
	}

	log({
		level,
		message,
		metadata,
	}: {
		level: e_Level
		message: string
		timestamp: number
		metadata: MetadataArgument
	}) {
		const timestamp = Date.now()

		//TODO allow for Error i keyValue
		const logItem: keyValue = {
			message,
			metadata: { ...(<KeyValueMap>metadata), timestamp },
		}
		const path = [timestamp.toString()]

		analysisStorageHandler.setValue(path, logItem)
		analysisEventHandler.emit('logadded')
	}
}

export default AnalysisTransport
