import { openDialog } from '../runtimeStateActions'

import { EXTRA_SMALL, SMALL } from '@appfarm/common/enums/e_ScreenSize'
import { e_DrawerType, e_ViewType } from '@appfarm/common/enums/e_PropertyTypes'

import { getMainDrawer, getViewById } from '#selectors/metadataSelectors'

const p_openDialog = ({ actionNode, dispatch, getState, contextData, appController }) => {
	const viewId = appController.getDataFromDataValue(actionNode.viewId, contextData)
	const state = getState()
	const view = getViewById(state, viewId)

	if (!view) return Promise.resolve() // nothing to do - just return

	if (view.viewType !== e_ViewType.DIALOG)
		return Promise.reject(new Error('Open Dialog: View is not a Dialog, and thus cannot be opened'))

	dispatch(openDialog(viewId))

	// Close drawer if open and temporary
	const appVariablesDataSource = appController.getAppVariablesDataSource()
	const currentDrawerState = appVariablesDataSource.getDrawerState()

	if (currentDrawerState) {
		const screenSize = appVariablesDataSource.getClientScreenSize()
		const drawer = getMainDrawer(state)
		if (
			drawer &&
			(drawer.drawerType === e_DrawerType.TEMPORARY ||
				((!drawer.drawerType || drawer.drawerType === e_DrawerType.RESPONSIVE) &&
					[EXTRA_SMALL, SMALL].includes(screenSize)))
		)
			appVariablesDataSource.setDrawerState(false)
	}

	return Promise.resolve()
}

export default p_openDialog
