/******************************************************************************
 *
 * AppState Selectors
 *
 *****************************************************************************/

export const isServerSessionReady = (state) => state.appState.serverSessionIsReady
export const isServerClientStateInSync = (state) => state.appState.serverClientStateInSync
export const isLastConnectionToSameSession = (state) => state.appState.lastConnectionToSameSession

export const getOnAppLoadExecuted = (state) => state.appState.onAppLoadExecuted
export const getOnAppLoadedExecuted = (state) => state.appState.onAppLoadedExecuted

export const getIsReadyForOnViewLoad = (state) => state.appState.currentAppHasInitialData
