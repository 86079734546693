import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'
import Divider from '@material-ui/core/Divider'

class UiDivider extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { component, contextData } = nextProps

		let visible = true

		if (!isUndefined(component.visible))
			visible = !!nextProps.appController.getDataFromDataValue(component.visible, contextData)

		if (visible === prevState.visible) return null

		return { visible }
	}

	render() {
		if (!this.state.visible) return null
		return <Divider />
	}
}

UiDivider.propTypes = {
	component: PropTypes.shape({
		visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
}

export default UiDivider
