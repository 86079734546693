/******************************************************************************
 *
 * This file will load JS-functions for use by the application
 * TODO: In preprocessing, only extract functions that will run client side.
 *
 *****************************************************************************/
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { setFunctionsChecksum } from '#actions/metadataActions'

const ScriptLoader = ({ activeAppId, appController }) => {
	const functionsChecksum = useSelector((state) => state.metaData.wantedChecksums.functions)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!activeAppId) return
		if (!functionsChecksum) return
		appController.setFunctionsPendingFlag(true)

		const element = document.createElement('script')
		element.src = `/api/v1/apps/${activeAppId}/metadata/functions?v=${functionsChecksum}`
		element.id = 'fn_' + activeAppId
		document.head.appendChild(element)

		// On Script ready
		element.onload = () => {
			appController.setFunctionsPendingFlag(false)
			dispatch(setFunctionsChecksum(functionsChecksum))
		}

		return () => {
			document.head.removeChild(element)
			dispatch(setFunctionsChecksum(null))
		}
	}, [activeAppId, functionsChecksum])

	return null
}

ScriptLoader.propTypes = {
	activeAppId: PropTypes.string,
}

export default ScriptLoader
