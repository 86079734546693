import ActionRunner from './ActionRunner'
import appController from '../controllers/appControllerInstance'
import { injectActionParamsInContextData } from '#utils/contextDataUtils'

let debuggerInstance = null

const runActionRoot =
	(eventHandler = {}, contextData = {}, eventContext = {}, callbacks) =>
		(dispatch, getState) => {
			if (!eventHandler.actionId) throw new Error('runActionRoot: No action id is provided')

			const actions = getState().metaData.actions
			const actionForRun = actions[eventHandler.actionId]

			if (!actionForRun) throw new Error('runActionRoot: Could not find action')

			if (actionForRun.actionParams?.length) {
				contextData = injectActionParamsInContextData({
					actionParams: actionForRun.actionParams,
					actionInputParams: eventHandler.actionInputParams,
					contextData,
					eventContext,
					getDataFromDataValue: appController.getDataFromDataValue,
					getEnumeratedType: appController.getEnumeratedType,
				})
			}

			const actionRunner = new ActionRunner(actionForRun, contextData, dispatch, getState, eventContext)
			if (debuggerInstance) actionRunner.attachDebugger(debuggerInstance)
			actionRunner
				.run()
				.then(() => {
					if (callbacks && callbacks.resolve) callbacks.resolve()
				})
				.catch((err) => {
					if (callbacks && callbacks.reject) callbacks.reject(err)
				})
		}

export const handleEvent =
	(eventHandler = {}, contextData = {}, eventContext = {}, callbacks) =>
		(dispatch) => {
			if (!eventHandler.actionId) {
				if (callbacks && callbacks.reject) callbacks.reject(new Error('No actionId provided'))
				return
			}

			if (appController.getIsActionRunning(eventHandler.actionId)) {
			// Force file upload cancel
				appController.cancelFileUpload(eventHandler.actionId)

				if (appController.getIsActionRunning(eventHandler.actionId)) {
					if (callbacks && callbacks.reject)
						callbacks.reject(
							new Error('Cannot run action - Another instance of this action is already running')
						)
					return console.warn('Cannot run action - Another instance of this action is already running')
				}
			}

			dispatch(runActionRoot(eventHandler, contextData, eventContext, callbacks))
		}

export const setDebugger = (actionDebugger) => {
	console.log('Action Debugger attached: ' + actionDebugger)
	debuggerInstance = actionDebugger
}
