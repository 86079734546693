import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const IntersectionObserverHook = ({ forwardedRef, component, eventHandler }) => {
	const onStartCallback = useCallback(() => {
		component.onViewportEnter &&
			eventHandler(component.onViewportEnter, null, {
				eventType: 'onViewportEnter',
			})
	}, [])

	const onEndCallback = useCallback(() => {
		component.onViewportLeave &&
			eventHandler(component.onViewportLeave, null, {
				eventType: 'onViewportLeave',
			})
	}, [])

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					onStartCallback()
				} else if (!entry.isIntersecting) {
					onEndCallback()
				}
			},
			{ threshold: component.observerThreshold }
		)

		if (forwardedRef.current) {
			observer.observe(forwardedRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [])

	return null
}

IntersectionObserverHook.propTypes = {
	forwardedRef: PropTypes.any.isRequired,
	component: PropTypes.object.isRequired,
	eventHandler: PropTypes.func.isRequired,
}

export default IntersectionObserverHook
