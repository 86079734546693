import React, { Component } from 'react'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import browserHistory from '../../browserHistory'

import InfoMessage from './InfoMessage'

const mapStateToProps = (state, ownProps) => {
	return {
		jwtAuthPublicKey: state.authState.jwtAuthPublicKey,
		serverToken: ownProps.match.params.serverToken,
	}
}

/**
 * Connector for decoding messages from server.
 */
const serverMessageDecoder = (WrappedComponent) => {
	class ServerMessageConnector extends Component {
		constructor(props) {
			super(props)

			this.state = {
				serverMessage: {},
				messageExpired: false,
				invalidMessage: false,
				ready: false,
			}
		}

		static getDerivedStateFromProps(nextProps, prevState) {
			if (prevState.ready) return null // Already decoded. No need to do it again
			if (!nextProps.serverToken) return null
			if (!nextProps.jwtAuthPublicKey) return null

			// All is good. Decode
			let serverMessage
			try {
				// decode b64
				const jwtToken = atob(nextProps.serverToken)
				serverMessage = jwt.verify(jwtToken, nextProps.jwtAuthPublicKey)
			} catch (err) {
				if (err.name === 'TokenExpiredError') {
					return {
						...prevState,
						ready: true,
						messageExpired: true,
					}
				} else {
					return {
						...prevState,
						ready: true,
						invalidMessage: true,
					}
				}
			}

			return {
				serverMessage: serverMessage,
				ready: true,
			}
		}
		render() {
			if (!this.state.ready) return null

			if (this.state.messageExpired)
				return (
					<InfoMessage
						icon="mdi mdi-alert-circle-outline"
						message="This link has expired"
						backText="Back to login"
						backFunction={() => browserHistory.replace('/')}
					/>
				)

			if (this.state.invalidMessage)
				return (
					<InfoMessage
						icon="mdi mdi-ghost"
						message="Page not found"
						backText="Back to login"
						backFunction={() => browserHistory.replace('/')}
					/>
				)

			const newProps = {
				...this.props,
				serverMessage: this.state.serverMessage,
			}

			return <WrappedComponent {...newProps} />
		}
	}

	return connect(mapStateToProps)(ServerMessageConnector)
}

export default serverMessageDecoder
