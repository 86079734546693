import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'

import { motion } from 'framer-motion'

const styles = {
	icon: {
		fontSize: '1.5em',
	},
	badge: {
		width: 'fit-content',
		display: 'block',
	},
	clickable: {
		cursor: 'pointer',
	},
	lineBreak: { whiteSpace: 'pre-line' },
}

class UiAvatar extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onClickHandler = this.onClickHandler.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		const { component, contextData, styleProp, style } = nextProps

		let avatarText
		let avatarImage
		let badgeValue

		if (component.avatarText)
			avatarText = nextProps.appController.getDataFromDataValue(component.avatarText, contextData, {
				getDisplayValue: true,
			})

		if (!isNil(component.avatarImage))
			avatarImage = nextProps.appController.getDataFromDataValue(component.avatarImage, contextData)

		if (component.badge && component.badgeValue)
			badgeValue = nextProps.appController.getDataFromDataValue(component.badgeValue, contextData, {
				getDisplayValue: true,
			})

		let iconValue
		if (component.iconValue)
			iconValue = nextProps.appController.getDataFromDataValue(component.iconValue, contextData, {
				getIcon: true,
			})

		let avatarStyle = {
			...styleProp,
			...style,
		}

		if (component.size) {
			avatarStyle = {
				...avatarStyle,
				height: component.size,
				width: component.size,
				fontSize: `${component.size / 2}px`,
			}
		}

		return {
			avatarText,
			badgeValue,
			iconValue,
			avatarImage,
			avatarStyle,
		}
	}

	onClickHandler(event) {
		this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	render() {
		const {
			component,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			classes,
			conditionalClassNames,
		} = this.props
		const { avatarText, badgeValue, iconValue, avatarImage, avatarStyle } = this.state

		let avatarContent

		if (!avatarImage) {
			if (iconValue || component.icon)
				avatarContent = <Icon className={classNames(classes.icon, iconValue || component.icon)} />

			if (component.avatarText) avatarContent = avatarText
		}

		let onClickHandler
		if (component.onClick) onClickHandler = this.onClickHandler

		let avatar = (
			<Avatar
				className={classNames('c' + component.id, conditionalClassNames, {
					[classes.clickable]: !!onClickHandler,
				})}
				src={avatarImage}
				onClick={onClickHandler}
				style={avatarStyle}
				variant={component.variant}
				component={this.props.useMotion ? motion.div : undefined}
				{...this.props.motionProps}
			>
				{ avatarContent }
			</Avatar>
		)

		if (component.badge && badgeValue)
			avatar = (
				<Badge className={classes.badge} badgeContent={badgeValue} color={component.badgeColor}>
					{ avatar }
				</Badge>
			)

		if (tooltipTitle)
			return (
				<Tooltip
					title={tooltipTitle}
					interactive={!tooltipDisableInteractive}
					classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
				>
					{ avatar }
				</Tooltip>
			)

		return avatar
	}
}

UiAvatar.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		icon: PropTypes.string,
		iconValue: PropTypes.object,
		size: PropTypes.number,
		onClick: PropTypes.shape({
			actionId: PropTypes.string.isRequired,
			actionInputParams: PropTypes.array,
		}),
		variant: PropTypes.oneOf(['circle', 'rounded', 'square']),
		avatarText: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
		avatarImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		badge: PropTypes.bool,
		badgeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
		badgeColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']).isRequired,
		backgroundColor: PropTypes.array,
		style_props: PropTypes.object,
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	style: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	classes: PropTypes.object.isRequired,
	conditionalClassNames: PropTypes.string,
}

export default withStyles(styles)(UiAvatar)
