const p_sortObjects = ({ actionNode, appController, contextData }) =>
	new Promise((resolve, reject) => {
		const dataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find dataSource'))

		if (dataSource.sorting && dataSource.sorting.length)
			return reject(new Error('Cannot apply sorting to a default-sorted data source'))

		if (actionNode.randomSort) {
			dataSource.applyRandomSort()
			resolve()
		}

		if (!actionNode.sorting || !actionNode.sorting.length) return reject(new Error('No sorting is defined'))

		const sortDescriptorWithDeepSortField = actionNode.sorting.filter(
			(sortDescriptorItem) => !!sortDescriptorItem.sortField.edgeDataBinding
		)

		let additionalSortDataDict
		if (sortDescriptorWithDeepSortField.length) {
			const objects = dataSource.getAllObjects()
			additionalSortDataDict = objects.reduce((dataDict, item) => {
				dataDict[item._id] = sortDescriptorWithDeepSortField.reduce((data, sortDescriptorItem) => {
					const dataObject = appController.getDataFromDataBinding({
						contextData: { ...contextData, [dataSource.id]: [item] },
						dataBinding: sortDescriptorItem.sortField,
					})
					data[sortDescriptorItem.sortNodeName] = appController.getPrimitiveValueFromDataBinding({
						dataBinding: sortDescriptorItem.sortField,
						dataObject,
					})
					return data
				}, {})
				return dataDict
			}, {})
		}

		dataSource.applyCustomSorting(actionNode.sorting, additionalSortDataDict)

		resolve()
	})

export default p_sortObjects
