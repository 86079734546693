import isInteger from 'lodash/isInteger'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'

import { ONE, MANY } from '@appfarm/common/enums/e_Cardinality'

import objectGenerator from './objectGenerator'
import { resolvePropertyValues } from '@appfarm/common/utils/resolvePropertyValues'
import { createObject as createObjectOnServer, getSideEffects } from '../../modules/afClientApi'
import { MissingRequiredFieldsError } from '#utils/clientErrors'

const createObject = function ({
	dataSource,
	defaultValues,
	contextData,
	setSelectedAfterCreate,
	replaceSingleObject,
	numCopies,
	logger,
}) {
	return new Promise((resolve, reject) => {
		if (isInteger(numCopies) && numCopies > 1) {
			if (!dataSource.local)
				return reject(new Error('Cannot create multiple objects in a filtered datasource'))

			if (dataSource.cardinality === ONE)
				return reject(new Error('Cannot create multiple objects in a datasource with cardinality one'))
		}

		if (dataSource.cardinality === ONE && dataSource.getAllObjects().length !== 0 && !replaceSingleObject)
			return reject(
				new Error(
					'ModelError: Create object failed - Cannot create object in non-empty dataSource with cardinality one'
				)
			)

		const getNewObject = () => {
			// TODO: Check if the user is allowed to create object
			let newObject = objectGenerator(dataSource)
			const allProperties = dataSource.propertiesMetaDict

			if (isArray(defaultValues)) {
				newObject = {
					...newObject,
					...resolvePropertyValues({
						propertyValues: defaultValues,
						propertyDict: allProperties,
						contextData: contextData,
						originalObject: newObject,
						getDataFromDataValue: dataSource.__appController.getDataFromDataValue,
						getEnumeratedType: dataSource.__appController.getEnumeratedType,
						logger: logger,
					}),
				}
			}

			// should always calculate formulas for new object
			newObject = dataSource._recalculateSingleObjectFormula(newObject)

			return newObject
		}

		/**
		 * Create multiple objects in local dataSource
		 */
		if (isInteger(numCopies) && numCopies > 1 && dataSource.local) {
			// const emptyArray = new Array(numCopies)
			const objectList = Array.apply(null, Array(numCopies)).map(getNewObject)
			let changeDescription

			if (replaceSingleObject) {
				dataSource._replaceAllObjects(objectList)
				changeDescription = { objectsReplaced: objectList }
			} else {
				dataSource._addOrMergeObjects(objectList)
				changeDescription = { objectsAdded: objectList }
			}

			dataSource._writeToRedux()

			if (dataSource.reverseDependencies.length === 0) return resolve(objectList)

			const selectionData = dataSource.getDataForSynchronization()
			const invalidatedDataSourceIdDict = dataSource.__appController.invalidateDataSourcesById(
				dataSource.reverseDependencies.map((item) => item.dataSourceId),
				{ updateGui: true }
			)
			return getSideEffects(dataSource.id, changeDescription, selectionData)
				.then((sideEffects) => {
					// Apply side effects
					dataSource.__appController.writeSideEffects(dataSource, sideEffects, {
						logger,
						invalidatedDataSourceIdDict,
					})
					resolve(objectList)
				})
				.catch((error) => {
					// Something happened reverse stuff
					// this will leave datasource empty if replace object is set.
					dataSource._removeMultipleObjects(objectList.map((newObject) => newObject._id))
					dataSource._writeToRedux()

					reject(error)
				})
		}

		const newObject = getNewObject()

		// Validate if create in persistable
		if (!dataSource.local) {
			Object.keys(dataSource.propertiesMetaDict)
				.map((propertyId) => dataSource.propertiesMetaDict[propertyId])
				.forEach((property) => {
					if (property.required && isUndefined(newObject[property.nodeName]))
						throw new MissingRequiredFieldsError(
							'Create object failed: Required property unset - ' + dataSource.name + '.' + property.name
						)
				})
		}

		let isQualified = true
		if (dataSource.local) {
			// No persitence - just insert and set active

			if (dataSource.cardinality === ONE || replaceSingleObject) {
				dataSource._replaceAllObjects([newObject])
			} else {
				dataSource._insertObject(newObject)
			}

			dataSource._writeToRedux()
		} else {
			// Check if object is qualified
			isQualified = dataSource.isObjectQualified(newObject, contextData) && !dataSource.dataConnector

			if (isQualified) {
				dataSource._insertObject(newObject)
				dataSource._writeToRedux()
			} else {
				if (!dataSource.dataConnector)
					logger.warning('The created object does not match the filter of the Data Source it was created in')
			}
		}

		const creationPromise = async () => {
			if (dataSource.local && dataSource.reverseDependencies.length === 0)
				return { sideEffects: {}, invalidatedDataSourceIdDict: {} }

			if (dataSource.local && dataSource.cardinality === MANY && replaceSingleObject) {
				const selectionData = dataSource.getDataForSynchronization()
				const invalidatedDataSourceIdDict = dataSource.__appController.invalidateDataSourcesById(
					dataSource.reverseDependencies.map((item) => item.dataSourceId),
					{ updateGui: true }
				)
				const sideEffects = await getSideEffects(
					dataSource.id,
					{ objectsReplaced: [newObject] },
					selectionData
				)

				return { sideEffects, invalidatedDataSourceIdDict }
			}

			const invalidatedDataSourceIdDict = dataSource.__appController.invalidateDataSourcesById(
				dataSource.reverseDependencies.map((item) => item.dataSourceId),
				{ updateGui: true }
			)

			const sideEffects = await createObjectOnServer(dataSource.id, newObject)
			return { sideEffects, invalidatedDataSourceIdDict }
		}

		// Now tell the server about the new object
		creationPromise()
			.then(({ sideEffects, invalidatedDataSourceIdDict }) => {
				// merge in data from server
				if (sideEffects && sideEffects[dataSource.id]) {
					const dataForMerge = sideEffects[dataSource.id]
					dataSource._addOrMergeObjects(dataForMerge)
					delete sideEffects[dataSource.id]
				}

				// Apply side effects
				dataSource.__appController.writeSideEffects(dataSource, sideEffects, {
					logger,
					invalidatedDataSourceIdDict,
				})

				if (!dataSource.local) {
					// Insert object into other dataSources that has a matching filter
					return dataSource.__appController.p_notifyObjectChange({
						sourceDataSourceId: dataSource.id,
						changedObject: newObject,
						objectClassId: dataSource.objectClassId,
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				// Set active
				if (dataSource.cardinality === MANY && setSelectedAfterCreate) {
					dataSource
						.p_setExactlyOneObjectSelected(newObject._id)
						.then(() => resolve(newObject))
						.catch((err) => {
							logger.error('Failed to set active object after creation', { err })
							reject(new Error('Failed to set active object after creation'))
						})
				} else {
					resolve(newObject)
				}
			})
			.catch((err) => {
				// Something happened reverse object creation
				// this will leave datasource empty if replace object is set.
				if (isQualified || dataSource.local) {
					dataSource._removeObject(newObject._id)
					dataSource._writeToRedux()
				}
				reject(err)
			})
	})
}

export default createObject
