import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import yellow from '@material-ui/core/colors/yellow'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 12,
	},
	sliderRoot: {
		marginBottom: 16,
		'&$sliderVertical': {
			height: 120,
		},
	},
	sliderVertical: {},
	brushPreviewContainer: {
		margin: '16px 0 12px 0',
		height: 26,
		width: 26,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: 'rgba(255, 255, 255, 0.12)',
		boxShadow: '0 0 6px 3px rgba(255, 255, 255, 0.06)',
		borderRadius: 2,
	},
	brushPreview: {
		borderRadius: '50%',
	},
	colors: {
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
		margin: 0,
		alignItems: 'center',
		'& li': {
			listStyleType: 'none',
			height: 26,
			width: 26,
			margin: 3,
			outline: 0,
			cursor: 'pointer',
			boxShadow: '0 0 6px 3px rgba(255, 255, 255, 0.06)',
			transition: theme.transitions.create('border-radius', {
				duration: theme.transitions.duration.shortest,
			}),
			'&:hover, &:focus': {
				borderRadius: '25%',
			},
			'&.selected': {
				borderRadius: '50%',
			},
		},
	},
})

const PenTool = (props) => {
	const { lineWidth, brushColor, onBrushWidthChange, setBrushColor, classes } = props

	return (
		<div className={classes.root}>
			<Slider
				value={lineWidth}
				onChange={onBrushWidthChange}
				min={1}
				max={22}
				step={1}
				classes={{
					root: classes.sliderRoot,
					vertical: classes.sliderVertical,
				}}
				orientation="vertical"
			/>
			<div className={classes.brushPreviewContainer}>
				<div
					className={classes.brushPreview}
					style={{
						backgroundColor: brushColor,
						height: lineWidth,
						width: lineWidth,
					}}
				/>
			</div>
			<ul className={classes.colors}>
				<li
					style={{ backgroundColor: red[500] }}
					onClick={() => setBrushColor(red[500])}
					className={classNames({ selected: red[500] === brushColor })}
					role="button"
					tabIndex="0"
				/>
				<li
					style={{ backgroundColor: blue[500] }}
					onClick={() => setBrushColor(blue[500])}
					className={classNames({ selected: blue[500] === brushColor })}
					role="button"
					tabIndex="0"
				/>
				<li
					style={{ backgroundColor: green[500] }}
					onClick={() => setBrushColor(green[500])}
					className={classNames({ selected: green[500] === brushColor })}
					role="button"
					tabIndex="0"
				/>
				<li
					style={{ backgroundColor: yellow[500] }}
					onClick={() => setBrushColor(yellow[500])}
					className={classNames({ selected: yellow[500] === brushColor })}
					role="button"
					tabIndex="0"
				/>
				<li
					style={{ backgroundColor: '#ffffff' }}
					onClick={() => setBrushColor('#ffffff')}
					className={classNames({ selected: brushColor === '#ffffff' })}
					role="button"
					tabIndex="0"
				/>
				<li
					style={{ backgroundColor: '#000000' }}
					onClick={() => setBrushColor('#000000')}
					className={classNames({ selected: brushColor === '#000000' })}
					role="button"
					tabIndex="0"
				/>
			</ul>
		</div>
	)
}

PenTool.propTypes = {
	lineWidth: PropTypes.number,
	brushColor: PropTypes.string,
	setBrushColor: PropTypes.func.isRequired,
	onBrushWidthChange: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PenTool)
