import isString from 'lodash/isString'

/**
 * Condition and Filter evaluator utils
 */
export const evaluateContains = (leftValue, rightValue) => {
	if (!isString(leftValue)) return false
	if (!isString(rightValue)) return false
	if (!rightValue.length) return false

	const lowerCaseLeft = leftValue.toLowerCase()
	const lowerCaseRight = rightValue.toLowerCase()
	if (lowerCaseLeft.indexOf(lowerCaseRight) > -1) return true

	return false
}

export const evaluateContainsAll = (leftValue, rightValue) => {
	if (!isString(leftValue)) return false
	if (!isString(rightValue)) return false
	if (!rightValue.length) return false

	const lowerCaseLeft = leftValue.toLowerCase()
	const lowerCaseRight = rightValue.toLowerCase()
	const lowerCaseRightElements = lowerCaseRight.split(' ')

	if (lowerCaseRightElements.every((element) => lowerCaseLeft.indexOf(element) > -1)) return true

	return false
}

export const evaluateContainsAllWords = (leftValue, rightValue) => {
	if (!isString(leftValue)) return false
	if (!isString(rightValue)) return false
	if (!rightValue.length) return false

	const lowerCaseLeft = leftValue.toLowerCase()
	const lowerCaseRight = rightValue.toLowerCase().trim()
	const lowerCaseRightElements = lowerCaseRight.split(' ')
	const lowerCaseLeftElements = lowerCaseLeft.split(' ')

	if (lowerCaseRightElements.every((element) => lowerCaseLeftElements.includes(element))) return true

	return false
}

export const evaluateContainsAny = (leftValue, rightValue) => {
	if (!isString(leftValue)) return false
	if (!isString(rightValue)) return false
	if (!rightValue.length) return false

	const lowerCaseLeft = leftValue.toLowerCase()
	const lowerCaseRight = rightValue.toLowerCase()
	const lowerCaseRightElements = lowerCaseRight.split(' ')

	if (lowerCaseRightElements.some((element) => lowerCaseLeft.indexOf(element) > -1)) return true

	return false
}

/****/
