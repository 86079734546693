import {
	SET_APP_LIST,
	SET_GLOBAL_SETTINGS,
	SET_LATEST_CHECKSUMS,
	SET_ACTIVE_APP,
	RESET_CLIENT,
	INVALIDATE_RESOURCE,
	VALIDATE_RESOURCE,
	SET_LATEST_GLOBAL_CHECKSUMS,
} from '#actions/actionTypes'
import initialState from './initialState'

const resourceReadyState = (state = initialState.resourceReadyState, action) => {
	switch (action.type) {
		case SET_APP_LIST:
			return {
				...state,
				appList: true,
			}

		case SET_GLOBAL_SETTINGS:
			return {
				...state,
				globalSettings: true,
			}

		case SET_LATEST_GLOBAL_CHECKSUMS: {
			return {
				...state,
				globalChecksums: true,
			}
		}

		case SET_LATEST_CHECKSUMS:
			return {
				...state,
				activeAppChecksums: true,
			}
		case SET_ACTIVE_APP:
		case RESET_CLIENT:
			return {
				...state,
				activeAppChecksums: false,
			}

		case INVALIDATE_RESOURCE:
			return {
				...state,
				[action.payload]: false,
			}

		case VALIDATE_RESOURCE:
			return {
				...state,
				[action.payload]: true,
			}

		default:
			return state
	}
}

export default resourceReadyState
