import * as types from '#actions/actionTypes'
import initialState from './initialState'

const setActiveApp = (state, action) => {
	if (state.activeAppReadableId === action.payload.appId) return state

	return {
		...state,
		activeAppReadableId: action.payload.appId,
		themeIdOverride: null,
	}
}

const appState = (state = initialState.appState, action) => {
	switch (action.type) {
		case types.SET_ACTIVE_APP:
			return setActiveApp(state, action)

		case types.SET_APP_STATE_VALUE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			}

		case types.SET_MULTIPLE_APP_STATE_VALUES:
			return {
				...state,
				...action.payload.values,
			}

		case types.RESET_STATE_BEFORE_APP_CHANGE:
			return {
				...state,
				serverClientStateInSync: false,
				currentAppHasInitialData: false,
			}

		case types.SET_APP_LIST:
			return {
				...state,
				initialAppListSet: true,
			}

		case types.RESET_CLIENT:
			return initialState.appState

		default:
			return state
	}
}

export default appState
