import { ONE } from '@appfarm/common/enums/e_Cardinality'

import { modifyMultipleObjects as modifyMultipleObjectsOnServer } from '../../modules/afClientApi'
import { OBJECT_MODIFIED } from '@appfarm/common/enums/e_DataSourceChangeType'
import { UPDATED_BY, UPDATED_DATE } from '@appfarm/common/enums/e_BuiltInObjectClassPropertyIds'
import getObjectChanges from './getObjectChanges'
import { resolvePropertyValues } from '@appfarm/common/utils/resolvePropertyValues'

const modifyMultipleObjects = async ({
	dataSource,
	contextData,
	propertyValues,
	selectionType,
	staticFilter,
	filterDescriptor,
	logger,
}) => {
	// No objects to modify
	if (!dataSource.getAllObjects().length) return
	if (!propertyValues?.length) throw new Error('ModifyObject: No modifications specified')

	const allProperties = dataSource.propertiesMetaDict

	let somePropertyValueIsPersistable = false
	// Sanity check
	propertyValues.forEach((propertyValue) => {
		const propertyMeta = allProperties[propertyValue.propertyId]
		if (!propertyMeta) return

		if (!somePropertyValueIsPersistable) {
			const isPersistable = dataSource.persistableNodenameList?.find(
				(nodeName) => nodeName === propertyMeta.nodeName
			)
			if (isPersistable) somePropertyValueIsPersistable = true
		}

		if (propertyMeta.readOnly) throw new Error('Cannot modify readOnly property: ' + propertyMeta.name)
	})

	let objectsForModification = []

	if (dataSource.cardinality === ONE) {
		objectsForModification = dataSource.getAllObjects()
	} else {
		objectsForModification = dataSource.getObjectsBySelectionType({
			selectionType,
			staticFilter,
			filterDescriptor,
			contextData,
		})
	}

	// No objects for modification. That is ok
	if (!objectsForModification || !objectsForModification.length) {
		logger.debug('No objects found for modification')
		return
	}

	const updatedDate = new Date().toJSON()
	const updatedBy = dataSource.getCurrentUserId()

	let changes = objectsForModification.map((item) => {
		const newContextData = {
			...contextData,
			[dataSource.id]: [item],
		}

		const newVaules = {
			_id: item._id,
			...resolvePropertyValues({
				propertyValues: propertyValues,
				propertyDict: allProperties,
				contextData: newContextData,
				originalObject: item,
				getDataFromDataValue: dataSource.__appController.getDataFromDataValue,
				getEnumeratedType: dataSource.__appController.getEnumeratedType,
				logger,
			}),
		}

		if (somePropertyValueIsPersistable) {
			// Set temporary updated date and by properties (will be overwritten on server)
			newVaules[UPDATED_DATE] = updatedDate
			if (dataSource.storeUpdatedBy) {
				newVaules[UPDATED_BY] = updatedBy
			}
		}

		const changedObject = {
			...item,
			...newVaules,
		}

		return {
			objectId: item._id,
			changes: newVaules,
			newObject: changedObject,
			oldObject: item,
		}
	})

	// Do all next calculations in memeory
	dataSource.__appController.stopRender()

	dataSource.disableSideEffectsCalculation()
	if (dataSource.local) {
		changes = changes.map((item) => {
			return {
				...item,
				newObject: dataSource._modifyObject(item.objectId, item.changes),
			}
		})
	} else {
		changes = changes.map((item) => {
			let newObject = item.newObject
			// Check if still qualified
			const isQualified = dataSource.isObjectQualified(item.newObject, contextData)
			if (isQualified) {
				newObject = dataSource._modifyObject(item.objectId, item.changes)
			} else {
				// TODO: Do not calculate single object formula on newobject for removed objects.
				dataSource._removeObject(item.newObject._id)
			}

			return {
				...item,
				newObject,
			}
		})
	}

	let changedNodeNamesDict = {}
	changes.forEach(({ newObject, oldObject }) => {
		const { changedNodeNames } = getObjectChanges(oldObject, newObject)
		changedNodeNamesDict = { ...changedNodeNamesDict, ...changedNodeNames }
	})
	const changedNodeNamesList = Object.keys(changedNodeNamesDict)

	dataSource.enableAndExecutePendingSideEffects(OBJECT_MODIFIED, changedNodeNamesDict)

	dataSource.__appController.resumeRender()

	logger.debug('Changed objects:')
	logger.table(
		changes.map((item) => item.newObject),
		null,
		{ dataSourceId: dataSource.id }
	)

	const affectedDataSources = dataSource.getAffectedDataSourcesFromChangedNodeNames(changedNodeNamesList)

	if (!dataSource.local || affectedDataSources.length) {
		const invalidatedDataSourceIdDict = dataSource.__appController.invalidateDataSourcesById(
			affectedDataSources,
			{ updateGui: true }
		)
		const selectionDataForServer = dataSource.getDataForPropertyChange(changedNodeNamesList)

		try {
			const sideEffects = await modifyMultipleObjectsOnServer(
				dataSource.id,
				changes.map((item) => item.newObject), // Server trenger hele objektet for å sjekke om den fortsatt kvalifiserer
				selectionDataForServer,
				changedNodeNamesList
			)

			let changedObjecstArray
			if (sideEffects && sideEffects[dataSource.id]) {
				const ownSideEffects = {
					[dataSource.id]: { ...sideEffects[dataSource.id] },
				}
				delete sideEffects[dataSource.id]
				logger.debug('Got return data from server ' + dataSource.name, { payload: ownSideEffects })

				dataSource.__appController.writeSideEffects(dataSource, ownSideEffects, {
					logger,
					invalidatedDataSourceIdDict,
					addOrMergeObjects: true,
				})

				const dataFromServerDict = ownSideEffects[dataSource.id].data.reduce((dict, item) => {
					dict[item._id] = item
					return dict
				}, {})

				// merge objects and serverdata
				changedObjecstArray = changes.map((change) => {
					const newObject = change.newObject
					const serverData = dataFromServerDict[newObject._id] || {}
					return { ...newObject, ...serverData }
				})
			} else {
				changedObjecstArray = changes.map((change) => change.newObject)
			}

			if (!dataSource.local)
				await dataSource.__appController.p_notifyObjectChange({
					sourceDataSourceId: dataSource.id,
					objectClassId: dataSource.objectClassId,
					changedObjecstArray: changedObjecstArray,
				})

			logger.debug('Side effects for ' + dataSource.name, { payload: sideEffects })
			dataSource.__appController.writeSideEffects(dataSource, sideEffects, {
				logger,
				invalidatedDataSourceIdDict,
			})
		} catch (err) {
			if (dataSource.local) {
				changes.forEach((item) => dataSource._modifyObject(item.objectId, item.oldObject))
			} else {
				const changedObjecstArray = []
				changes.forEach((item) => {
					// Check if new was qualified
					const isQualified = dataSource.isObjectQualified(item.newObject, contextData)
					if (isQualified) {
						dataSource._modifyObject(item.objectId, item.oldObject)
					} else {
						dataSource._insertObject(item.oldObject)
					}

					changedObjecstArray.push(item.oldObject)
				})
				await dataSource.__appController.p_notifyObjectChange({
					sourceDataSourceId: dataSource.id,
					objectClassId: dataSource.objectClassId,
					changedObjecstArray: changedObjecstArray,
				})
			}

			throw err
		}
	}
}

export default modifyMultipleObjects
