import React, { Component } from 'react'
import { getState, getStore } from '../reduxStoreUtils'
import appController from '../../controllers/appControllerInstance'

/**
 * Drop in for react-redux. Only in use for drawable canvas
 */
const connect =
	(mapStateToPropsInput, mapDispatchToPropsInput, options = {}) =>
		(WrappedComponent) => {
			return class AppfarmGenericConnector extends Component {
				constructor(props) {
					super(props)

					this.mapDispatchToProps = null
					this.mapStateToProps = null

					if (mapStateToPropsInput)
						this.mapStateToProps = mapStateToPropsInput.length ? mapStateToPropsInput : mapStateToPropsInput()

					if (mapDispatchToPropsInput)
						this.mapDispatchToProps = mapDispatchToPropsInput.length
							? mapDispatchToPropsInput
							: mapDispatchToPropsInput()

					// Subscribe to appfarm-data
					if (options.subscribeToData) {
						const updateFunction = () => {
							console.log('FORCE UPDATE')
							this.forceUpdate()
						}

						appController.subscribeComponent(updateFunction)
						this.unsubscribeToData = () => {
							appController.unsubscribeComponent(updateFunction)
						}
					}
				}

				// componentWillUnmount() {
				// 	this.unsubscribeRedux && this.unsubscribeRedux()
				// 	this.unsubscribeToData && this.unsubscribeToData()
				// }

				// shouldComponentUpdate() {
				// 	return
				// }

				// componentWillUnmount() {
				// 	appController.unsubscribeComponent(this.updateHandler)
				// }

				// updateHandler(dataSourceIds) {
				// 	const propsFromState = this.mapStateToProps(this.props, {
				// 		getDataFromDataValue: appController.getDataFromDataValue,
				// 		state: getState()
				// 	})

				// 	const newProps = {
				// 		...this.props,
				// 		...propsFromState,
				// 	}

				// 	this.setState({ newProps })
				// }

				render() {
					const propsFromState =
					(this.mapStateToProps &&
						this.mapStateToProps(getState(), this.props, { appController: appController })) ||
					{}
					const propsFromDispatch =
					(this.mapDispatchToProps && this.mapDispatchToProps(getStore().dispatch, this.props)) || {}

					const newProps = {
						...this.props,
						...propsFromState,
						...propsFromDispatch,
					}

					return <WrappedComponent {...newProps} />
				}
			}
		}

export default connect
