//! moment.js locale configuration
//! locale : Armenian [hy-am]
//! author : Armendarabyan : https://github.com/armendarabyan

export default {
	months: {
		format: 'հունվարի_փետրվարի_մարտի_ապրիլի_մայիսի_հունիսի_հուլիսի_օգոստոսի_սեպտեմբերի_հոկտեմբերի_նոյեմբերի_դեկտեմբերի'.split(
			'_'
		),
		standalone: 'հունվար_փետրվար_մարտ_ապրիլ_մայիս_հունիս_հուլիս_օգոստոս_սեպտեմբեր_հոկտեմբեր_նոյեմբեր_դեկտեմբեր'.split(
			'_'
		),
	},
	monthsShort: 'հնվ_փտր_մրտ_ապր_մյս_հնս_հլս_օգս_սպտ_հկտ_նմբ_դկտ'.split('_'),
	weekdays: 'կիրակի_երկուշաբթի_երեքշաբթի_չորեքշաբթի_հինգշաբթի_ուրբաթ_շաբաթ'.split('_'),
	weekdaysShort: 'կրկ_երկ_երք_չրք_հնգ_ուրբ_շբթ'.split('_'),
	weekdaysMin: 'կրկ_երկ_երք_չրք_հնգ_ուրբ_շբթ'.split('_'),
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D MMMM YYYY թ.',
		LLL: 'D MMMM YYYY թ., HH:mm',
		LLLL: 'dddd, D MMMM YYYY թ., HH:mm',
	},
	calendar: {
		sameDay: '[այսօր] LT',
		nextDay: '[վաղը] LT',
		lastDay: '[երեկ] LT',
		nextWeek: function () {
			return 'dddd [օրը ժամը] LT'
		},
		lastWeek: function () {
			return '[անցած] dddd [օրը ժամը] LT'
		},
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s հետո',
		past: '%s առաջ',
		s: 'մի քանի վայրկյան',
		ss: '%d վայրկյան',
		m: 'րոպե',
		mm: '%d րոպե',
		h: 'ժամ',
		hh: '%d ժամ',
		d: 'օր',
		dd: '%d օր',
		M: 'ամիս',
		MM: '%d ամիս',
		y: 'տարի',
		yy: '%d տարի',
	},
	meridiemParse: /գիշերվա|առավոտվա|ցերեկվա|երեկոյան/,
	isPM: function (input) {
		return /^(ցերեկվա|երեկոյան)$/.test(input)
	},
	meridiem: function (hour) {
		if (hour < 4) {
			return 'գիշերվա'
		} else if (hour < 12) {
			return 'առավոտվա'
		} else if (hour < 17) {
			return 'ցերեկվա'
		} else {
			return 'երեկոյան'
		}
	},
	dayOfMonthOrdinalParse: /\d{1,2}|\d{1,2}-(ին|րդ)/,
	ordinal: function (number, period) {
		switch (period) {
			case 'DDD':
			case 'w':
			case 'W':
			case 'DDDo':
				if (number === 1) {
					return number + '-ին'
				}
				return number + '-րդ'
			default:
				return number
		}
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 7, // The week that contains Jan 7th is the first week of the year.
	},
}
