import { closeDialog } from '../runtimeStateActions'
import { getViewById } from '#selectors/metadataSelectors'
import { e_ViewType } from '@appfarm/common/enums/e_PropertyTypes'

const p_closeDialog = ({ actionNode, getState, dispatch, appController, contextData, actionNodeLogger }) => {
	if (!actionNode.viewId) {
		actionNodeLogger.debug('Close current Dialog')
		dispatch(closeDialog())
		return Promise.resolve()
	}

	const viewId = appController.getDataFromDataValue(actionNode.viewId, contextData)
	const state = getState()
	const view = getViewById(state, viewId)

	if (!view) return Promise.resolve() // nothing to do - just return

	if (view.viewType !== e_ViewType.DIALOG)
		return Promise.reject(new Error('Close Dialog: View is not a Dialog, and thus cannot be closed'))

	dispatch(closeDialog(viewId))
	return Promise.resolve()
}

export default p_closeDialog
