/**
 * Will handle stuff related to Progressive Web Apps
 */

import e_BrowserPermissionState from '@appfarm/common/enums/e_BrowserPermissionState'

export const p_enableNotification = () =>
	new Promise((resolve, reject) => {
		if ('Notification' in window && navigator.serviceWorker) {
			if (Notification.permission === 'granted') {
				resolve()
			} else if (Notification.permission === 'denied') {
				/* the user has previously denied push. Can't reprompt. */
				reject(new Error('Notification is blocked'))
			} else {
				/* show a prompt to the user */
				Notification.requestPermission(function (status) {
					if (status === 'granted') {
						resolve()
					} else {
						reject(new Error('User denied notification permission'))
					}
				})
			}
		} else {
			return reject(new Error('Notification API not available'))
		}
	})

export const getCurrentNotificationPermission = () => {
	if ('Notification' in window && navigator.serviceWorker) {
		switch (Notification.permission) {
			case e_BrowserPermissionState.GRANTED:
			case e_BrowserPermissionState.DENIED:
				return Notification.permission

			case 'default':
				return e_BrowserPermissionState.PROMPT
		}
	}

	return e_BrowserPermissionState.DENIED
}

export const p_registerServiceWorker = () =>
	new Promise((resolve, reject) => {
		if (navigator.serviceWorker) {
			navigator.serviceWorker
				.register('/assets/sw.js', { scope: '/' })
				.then((reg) => {
					console.log('Updating service worker')
					reg.update()
					resolve(reg)
				})
				.catch((err) => reject(err))
		} else {
			reject(new Error('ServiceWorker is not available'))
		}
	})

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
	const rawData = window.atob(base64)
	return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

export const p_registerPushSubscription = (webPushPublicKey) =>
	new Promise((resolve, reject) => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready.then((reg) => {
				reg.pushManager
					.getSubscription()
					.then((sub) => {
						if (sub === null) {
							return reg.pushManager.subscribe({
								userVisibleOnly: true,
								applicationServerKey: urlBase64ToUint8Array(webPushPublicKey),
							})
						} else {
							// We have a subscription, update the database
							return Promise.resolve(sub)
						}
					})
					.then((sub) => {
						resolve(sub)
					})
					.catch((err) => reject(err))
			})
		} else {
			reject(new Error('ServiceWorker is not available'))
		}
	})
