import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Trans } from '@lingui/macro'

import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'

import green from '@material-ui/core/colors/green'
import Zoom from '@material-ui/core/Zoom'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import UiImageTools from './UiImageTools'

const styles = (theme) => ({
	root: {
		//position: 'relative',
		//height: '100%',
		//width: '100%',
		//position: 'fixed',
		//top: 0,
		//left: 0,
		//width: '100%',
		//height: '100%',
		//zIndex: 1350,
		//backgroundColor: 'rgba(0, 0, 0, 0.8)',
	},
	image: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		maxWidth: '100%',
		maxHeight: '100%',
		margin: 'auto',
		overflow: 'auto',

		height: 'unset',
		minHeight: 'unset',
		width: 'unset',
		minWidth: 'unset',

		//WebkitTapHighlightColor: 'transparent',
		//'-webkit-user-select': 'none',
		//'-webkit-touch-callout': 'none',
		// '&:focus-visble': {
		// 	outline: 'none',
		// },
	},
	closeButton: {
		position: 'absolute',
		top: 4,
		left: 4,
		zIndex: 1352,
		color: 'white',
		textShadow: '0 0 1px rgb(25, 25, 25)',
	},
	saveFab: {
		position: 'absolute',
		bottom: 12,
		right: 12,
		zIndex: 1352,
		color: 'white',
		backgroundColor: green[600],
		'&:hover': {
			backgroundColor: green[700],
		},
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	dialogRoot: {
		// overriding default zIndex to make dialog appear in front of fullscreen image
		zIndex: '1353 !important',
	},
})

class UiImageFullscreen extends Component {
	constructor(props) {
		super(props)

		this.state = {
			toolIsActive: undefined,
			imageIsChanged: false,
			confirmDialogOpen: false,
		}

		this.changeCanvas = null

		this.toggleToolIsActive = this.toggleToolIsActive.bind(this)
		this.setImageIsChanged = this.setImageIsChanged.bind(this)
		this.saveChanges = this.saveChanges.bind(this)
		this.closeFullscreen = this.closeFullscreen.bind(this)
		this.openConfirmDialog = this.openConfirmDialog.bind(this)
		this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
		this.setImageSize = this.setImageSize.bind(this)
	}

	componentDidMount() {
		window.addEventListener('resize', this.setImageSize)
		this.setImageSize()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setImageSize)
	}

	setImageSize() {
		this.setState({
			imageSize: {
				height: this.imageRef.height,
				width: this.imageRef.width,
			},
		})
	}

	toggleToolIsActive() {
		this.setState({ toolIsActive: !this.state.toolIsActive })
	}

	setImageIsChanged(changeCanvas) {
		this.changeCanvas = changeCanvas
		this.setState({ imageIsChanged: !!changeCanvas })
	}

	openConfirmDialog() {
		this.setState({ confirmDialogOpen: true })
	}

	closeConfirmDialog() {
		this.setState({ confirmDialogOpen: false })
	}

	saveChanges() {
		this.closeConfirmDialog()
		if (this.changeCanvas) {
			const canvasForSave = document.createElement('canvas')
			canvasForSave.style.width = this.changeCanvas.style.width
			canvasForSave.style.height = this.changeCanvas.style.height
			canvasForSave.height = this.changeCanvas.height
			canvasForSave.width = this.changeCanvas.width

			const ctx = canvasForSave.getContext('2d')

			const originalImage = new Image()
			originalImage.onload = () => {
				ctx.drawImage(originalImage, 0, 0)
				ctx.drawImage(this.changeCanvas, 0, 0)

				canvasForSave.toBlob((fileBlob) => {
					const dataUrl = canvasForSave.toDataURL()
					this.props.appController.modifyFileObject({
						dataUrl,
						fileBlob,
						dataSourceId: this.props.component.value.dataBinding.dataSourceId,
						oldObject: this.props.ownData,
					})
					this.props.toggleFullscreen()
				})
			}
			originalImage.setAttribute('crossorigin', 'anonymous')
			originalImage.src = this.props.src
		} else {
			this.props.toggleFullscreen()
		}
	}

	closeFullscreen() {
		if (this.state.imageIsChanged) {
			this.openConfirmDialog()
		} else {
			this.props.toggleFullscreen()
		}
	}

	render() {
		const { component, ownData, src, altText, toggleFullscreen, readOnly, classes } = this.props

		return (
			<div className={classes.root} tabIndex={-1}>
				<img
					className={classes.image}
					src={src}
					alt={altText}
					onLoad={this.setImageSize}
					ref={(node) => (this.imageRef = node)}
				/>
				{ !this.state.toolIsActive && (
					<IconButton className={classes.closeButton} onClick={this.closeFullscreen}>
						<Icon className="mdi mdi-close" />
					</IconButton>
				) }
				{ component.enableImageTools && !readOnly && (
					<UiImageTools
						imageSize={this.state.imageSize}
						component={component}
						ownData={ownData}
						src={src}
						toggleToolIsActive={this.toggleToolIsActive}
						setImageIsChanged={this.setImageIsChanged}
						imageRef={this.imageRef}
						readOnly={readOnly}
					/>
				) }
				{ component.enableImageTools && !readOnly && (
					<Zoom in={this.state.imageIsChanged}>
						<Fab variant="extended" className={classes.saveFab} onClick={this.saveChanges}>
							<Icon className={classNames(classes.extendedIcon, 'mdi mdi-check')} color="inherit" />
							<Trans>Save</Trans>
						</Fab>
					</Zoom>
				) }
				{ component.enableImageTools && !readOnly && (
					<Dialog
						open={this.state.confirmDialogOpen}
						onClose={this.closeConfirmDialog}
						classes={{ root: classes.dialogRoot }}
					>
						<DialogContent>
							<DialogContentText>
								<Trans>Do you want to discard the image changes?</Trans>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.closeConfirmDialog} color="primary">
								<Trans>Continue editing</Trans>
							</Button>
							<Button onClick={toggleFullscreen} color="primary">
								<Trans>Discard changes</Trans>
							</Button>
						</DialogActions>
					</Dialog>
				) }
			</div>
		)
	}
}

UiImageFullscreen.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		modifyFileObject: PropTypes.func.isRequired,
	}).isRequired,
	ownData: PropTypes.object,
	src: PropTypes.string,
	altText: PropTypes.string,
	toggleFullscreen: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiImageFullscreen)
