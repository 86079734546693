import { shouldLog, generateIdForLogger } from './utils'
import { e_Level, MetadataArgument, Transport, LogItemType } from './types'
import isFunction from 'lodash/isFunction'

const getCurrentTimestamp = () => {
	if (window.performance.now) return window.performance.now()
	return Date.now()
}
class AppfarmLogger {
	id: string
	logName: string
	root: AppfarmLogger | null
	parent: AppfarmLogger | null
	enabled?: boolean | (() => boolean)
	level: e_Level
	metadata?: MetadataArgument
	transports: Transport[]
	children: AppfarmLogger[]
	timers: { [key: string]: number }

	constructor({
		logName,
		parent,
		enabled,
		level,
		metadata,
		transports,
	}: {
		logName: string
		parent?: AppfarmLogger | null
		enabled?: boolean | (() => boolean)
		level?: e_Level
		metadata?: MetadataArgument
		transports?: Transport[]
	}) {
		this.logName = parent ? parent.logName : logName
		this.root = null
		this.parent = null
		this.enabled = enabled
		this.level = level || e_Level.DEBUG
		this.metadata = metadata || {}
		this.transports = transports || []
		this.children = []
		this.timers = {}

		if (parent) {
			if (parent.root) {
				this.root = parent.root
			} else {
				this.root = parent
			}
			this.parent = parent
			this.id = `${parent.id}.${generateIdForLogger()}`
		} else {
			this.id = generateIdForLogger()
		}
	}

	_isEnabled() {
		if (isFunction(this.enabled)) return this.enabled()

		return this.enabled
	}

	_log({
		level,
		message,
		metadata = {},
	}: {
		level: e_Level
		message: string
		metadata?: MetadataArgument
	}): void {
		if (!this._isEnabled()) return
		if (!shouldLog(this.level, level)) return

		if (metadata && metadata.err && metadata.err.metadata) {
			metadata = { ...metadata, payload: { ...metadata.payload, ...metadata.err.metadata } }
		}

		const timestamp = getCurrentTimestamp()

		let transports
		if (this.root) {
			transports = this.root.transports
		} else {
			transports = this.transports
		}

		if (!transports || !transports.length) {
			console.warn('[AppfarmLogger: No transports found for logger')
			return
		}

		transports.forEach((transport) => {
			try {
				if (shouldLog(transport.level, level)) {
					transport.log({
						type: LogItemType.MESSAGE,
						loggerId: this.id,
						logName: this.logName,
						level,
						message,
						timestamp,
						metadata: { ...this.metadata, ...metadata },
					})
				}
			} catch (error) {
				console.log('[AppfarmLogger]: Error trying to log message to transport', transport)
				console.error(error)
			}
		})
	}

	debug(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.DEBUG, message, metadata })
	}
	info(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.INFO, message, metadata })
	}
	notice(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.NOTICE, message, metadata })
	}
	warning(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.WARNING, message, metadata })
	}
	error(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.ERROR, message, metadata })
	}
	crit(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.CRIT, message, metadata })
	}
	emerg(message: string, metadata?: MetadataArgument): void {
		this._log({ level: e_Level.EMERG, message, metadata })
	}

	table(data: any, columns?: string[], metadata?: MetadataArgument): void {
		if (!this._isEnabled()) return

		if (!shouldLog(this.level, e_Level.DEBUG)) return

		const timestamp = getCurrentTimestamp()

		let transports
		if (this.root) {
			transports = this.root.transports
		} else {
			transports = this.transports
		}

		if (!transports || !transports.length) {
			console.warn('[AppfarmLogger]: No transports found for logger')
			return
		}

		transports.forEach((transport) => {
			try {
				transport.table &&
					transport.table({
						type: LogItemType.TABLE,
						logName: this.logName,
						loggerId: this.id,
						level: e_Level.DEBUG,
						message: '',
						timestamp,
						data,
						columns,
						metadata: { ...this.metadata, ...metadata },
					})
			} catch (error) {
				console.log('[AppfarmLogger]: Error trying to log table to transport', transport)
				console.error(error)
			}
		})
	}

	context(data: any, metadata?: MetadataArgument): void {
		if (!this._isEnabled()) return

		if (!shouldLog(this.level, e_Level.DEBUG)) return

		const timestamp = getCurrentTimestamp()

		let transports
		if (this.root) {
			transports = this.root.transports
		} else {
			transports = this.transports
		}

		if (!transports || !transports.length) {
			console.warn('[AppfarmLogger]: No transports found for logger')
			return
		}

		transports.forEach((transport) => {
			try {
				transport.context &&
					transport.context({
						type: LogItemType.CONTEXT,
						logName: this.logName,
						loggerId: this.id,
						level: e_Level.DEBUG,
						message: '',
						timestamp,
						data,
						metadata: { ...this.metadata, ...metadata },
					})
			} catch (error) {
				console.log('[AppfarmLogger]: Error trying to log context to transport', transport)
				console.error(error)
			}
		})
	}

	time(key: string) {
		if (!this._isEnabled()) return

		if (this.timers[key]) {
			this.warning(`Timer with key ${key} already exists`, { prefix: 'Logger' })
			return
		}

		this.timers[key] = getCurrentTimestamp()
	}

	timeEnd(key: string) {
		if (!this._isEnabled()) return

		const timeEnd = getCurrentTimestamp()
		const timeStart = this.timers[key]
		if (!timeStart) {
			this.warning(`Timer with key ${key} does not exists, not possible to log time.`, {
				prefix: 'Logger',
			})
			return
		}

		const timeDiff = Math.round((timeEnd - timeStart + Number.EPSILON) * 1000) / 1000
		this.debug(`${key} - elapsed time: ${timeDiff}ms`, { isTimer: true })
		delete this.timers[key]
	}

	createChildLogger(metadata: MetadataArgument, enabled?: boolean | (() => boolean)) {
		const childLogger = new AppfarmLogger({
			logName: this.logName,
			parent: this,
			enabled: enabled || this.enabled,
			level: this.level,
			metadata: { ...this.metadata, ...metadata },
		})

		return childLogger
	}

	addTransport(transport: Transport) {
		if (this.root) {
			throw new Error('[AppfarmLogger]: Tried to add Transport to child logger.')
		}
		this.transports.push(transport)
	}

	removeTransport(transportId: String) {
		if (this.root) {
			throw new Error('[AppfarmLogger]: Tried to remove Transport from child logger.')
		}
		this.transports = this.transports.filter((transport) => transport.id !== transportId)
	}
}

export default AppfarmLogger
