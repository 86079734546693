import React from 'react'
import PropTypes from 'prop-types'

import ContextProvider from './ContextProvider'

const renderVirtualizedRow = ({
	data,
	index,
	style,
	component,
	contextData,
	directContextDataSourceId,
	dataUpdateReference,
	disabled,
	readOnly,
}) => {
	const objectData = data[index]

	if (!objectData) return null

	return (
		<ContextProvider
			key={objectData._id}
			childComponents={component.children}
			directContextDataSourceId={directContextDataSourceId}
			directContextObject={objectData}
			parentContext={contextData}
			contextIndex={index}
			iteratorCount={data.length}
			iteratorParamIdsInUse={component.iteratorParamIdsInUse}
			componentId={component.id}
			dataUpdateReference={dataUpdateReference}
			disabled={disabled}
			readOnly={readOnly}
			virtualizationStyle={style}
		/>
	)
}

renderVirtualizedRow.propTypes = {
	data: PropTypes.array,
	index: PropTypes.number,
	style: PropTypes.any,
	component: PropTypes.object,
	contextData: PropTypes.object,
	dataUpdateReference: PropTypes.any,
	directContextDataSourceId: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
}

export default renderVirtualizedRow
