export default {
	months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split('_'),
	monthsShort: 'jan_feb_mar_apr_mai_jun_jul_aug_sep_okt_nov_des'.split('_'),
	weekdays: 'sundag_måndag_tysdag_onsdag_torsdag_fredag_laurdag'.split('_'),
	weekdaysShort: 'sun_mån_tys_ons_tor_fre_lau'.split('_'),
	weekdaysMin: 'su_må_ty_on_to_fr_lø'.split('_'),
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D. MMMM YYYY',
		LLL: 'D. MMMM YYYY [kl.] H:mm',
		LLLL: 'dddd D. MMMM YYYY [kl.] HH:mm',
	},
	calendar: {
		sameDay: '[I dag klokka] LT',
		nextDay: '[I morgon klokka] LT',
		nextWeek: 'dddd [klokka] LT',
		lastDay: '[I går klokka] LT',
		lastWeek: '[Føregåande] dddd [klokka] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'om %s',
		past: '%s sidan',
		s: 'nokre sekund',
		ss: '%d sekund',
		m: 'eit minutt',
		mm: '%d minutt',
		h: 'ein time',
		hh: '%d timar',
		d: 'ein dag',
		dd: '%d dagar',
		M: 'ein månad',
		MM: '%d månader',
		y: 'eit år',
		yy: '%d år',
	},
	dayOfMonthOrdinalParse: /\d{1,2}\./,
	ordinal: '%d.',
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
}
