import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'

const translateItemRecursive = (itemInstance, langDictionary) => {
	if (isArray(itemInstance)) {
		if (!langDictionary) return itemInstance

		return itemInstance.map((subItem) => {
			const shouldTranslateItemRecursive =
				(subItem.children && subItem.children.length) || langDictionary[subItem.id]
			if (!shouldTranslateItemRecursive) return subItem

			return translateItemRecursive(subItem, langDictionary)
		})
	} else {
		const translatedItemInstance = { ...itemInstance }

		if (langDictionary[itemInstance.id]) {
			Object.keys(langDictionary[itemInstance.id]).forEach((fieldName) => {
				if (isArray(itemInstance[fieldName])) {
					translatedItemInstance[fieldName] = translateItemRecursive(
						itemInstance[fieldName],
						langDictionary[itemInstance.id][fieldName]
					)
				} else if (isPlainObject(itemInstance[fieldName])) {
					translatedItemInstance[fieldName] = translateItemRecursive(
						itemInstance[fieldName],
						langDictionary[itemInstance.id][fieldName]
					)
				} else {
					const translatedValue = langDictionary[itemInstance.id][fieldName]
					if (translatedValue) translatedItemInstance[fieldName] = translatedValue
				}
			})
		}

		if (translatedItemInstance.children) {
			translatedItemInstance.children = translatedItemInstance.children.map((child) =>
				translateItemRecursive(child, langDictionary)
			)
		}

		const fieldsToSkip = {
			[itemInstance.id]: true,
			children: true,
		}

		Object.keys(itemInstance).forEach((fieldName) => {
			if (fieldsToSkip[fieldName]) return
			if (!langDictionary[fieldName]) return

			if (isString(langDictionary[fieldName])) {
				translatedItemInstance[fieldName] = langDictionary[fieldName]
			} else {
				translatedItemInstance[fieldName] = translateItemRecursive(
					itemInstance[fieldName],
					langDictionary[fieldName]
				)
			}
		})

		return translatedItemInstance
	}
}

export default translateItemRecursive
