/**
 * Wrapping common function with stuff from material-ui
 */
import { getContrastRatio } from '@material-ui/core/styles/colorManipulator'
import getContrastText from '@appfarm/common/utils/getContrastText'

const wrappedGetContrastText = (backgroundColor, textPrimary, type) =>
	getContrastText(backgroundColor, getContrastRatio, textPrimary, type)

export default wrappedGetContrastText
