import ObjectId from 'bson-objectid'
import axios from 'axios'
import axiosErrorParser from '../../modules/axiosErrorParser'

/* import { LOCATION } from '@appfarm/common/enums/e_SystemPermissionType' */
import { p_enableNotification, p_registerPushSubscription } from '#utils/pwaUtils'

const WEB_PUSH_PUBLIC_KEY = window.AF_PARAMS.webPushPublicKey

const requestNotifications = async (appController) => {
	const activeApp = appController.getActiveAppMetadata()
	const userId = appController.getCurrentUserId()

	if (!activeApp) throw new Error('No active app')
	if (!userId) throw new Error('No active user')
	if (!localStorage) throw new Error('LocalStorage is not available - cannot use push notifications')

	// Ask user for permissions
	await p_enableNotification()

	// Register with service worker
	const sub = await p_registerPushSubscription(WEB_PUSH_PUBLIC_KEY)

	const itemKey = `__AF_WEB_PUSH_SUB__${userId}`
	let subId = localStorage.getItem(itemKey)

	if (!subId) {
		subId = new ObjectId().toString()
		localStorage.setItem(itemKey, subId)
	}

	// Register with backend
	try {
		await axios.post(`/api/v1/apps/${activeApp.id}/webpush/${subId}`, { subscription: sub })
	} catch (err) {
		throw axiosErrorParser(err)
	}
}

const p_requestSystemPermission = async ({ actionNode, appController, actionNodeLogger }) => {
	switch (actionNode.permissionType) {
		/* case LOCATION:
			// trigger system prompt for location
			break */

		default:
			//NOTIFICATIONS
			actionNodeLogger.debug('Request system permission for Notifications')
			await requestNotifications(appController)
	}
}

export default p_requestSystemPermission
