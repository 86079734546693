import en_gb from './locale-en-gb'
import nb from './locale-nb'
import nn from './locale-nn'
import sv from './locale-sv'
import da from './locale-da'
import pl from './locale-pl'
import de from './locale-de'
import fr from './locale-fr'
import es from './locale-es'
import pt from './locale-pt'
import it from './locale-it'
import hy_am from './locale-hy-am'
import lb from './locale-lb'
import nl from './locale-nl'
import fi from './locale-fi'
import lv from './locale-lv'
import lt from './locale-lt'
import se from './locale-se'

import e_CalendarLanguage from '@appfarm/common/enums/e_CalendarLanguage'

const getLocaleFromString = (localeString) => {
	switch (localeString) {
		case e_CalendarLanguage.EN_GB:
			return en_gb
		case e_CalendarLanguage.NB:
			return nb
		case e_CalendarLanguage.NN:
			return nn
		case e_CalendarLanguage.SV:
			return sv
		case e_CalendarLanguage.DA:
			return da
		case e_CalendarLanguage.PL:
			return pl
		case e_CalendarLanguage.DE:
			return de
		case e_CalendarLanguage.FR:
			return fr
		case e_CalendarLanguage.ES:
			return es
		case e_CalendarLanguage.PT:
			return pt
		case e_CalendarLanguage.IT:
			return it
		case e_CalendarLanguage.HY_AM:
			return hy_am
		case e_CalendarLanguage.LB:
			return lb
		case e_CalendarLanguage.NL:
			return nl
		case e_CalendarLanguage.FI:
			return fi
		case e_CalendarLanguage.LV:
			return lv
		case e_CalendarLanguage.LT:
			return lt
		case e_CalendarLanguage.SE:
			return se
		default:
			return null
	}
}

export default getLocaleFromString
