import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import AppDescriptionLoader from './AppDescriptionLoader'
import ActionsLoader from './ActionsLoader'
import DataModelLoader from './DataModelLoader'
import ScriptLoader from './ScriptLoader'
import StylesheetLoader from './StylesheetLoader'
import LayoutLoader from './LayoutLoader'

import { getAppTranslation, getActiveLanguageId } from '#selectors/metadataSelectors'
import appController from '../../../../controllers/appControllerInstance'

const DEFAULT_LANG_ID = '5b71631743501ec10595a182'

const MetadataLoader = ({ activeAppId }) => {
	const appTranslation = useSelector(getAppTranslation)
	const activeLanguageId = useSelector(getActiveLanguageId)

	// TODO: To be moved to separate provider for app variable
	useEffect(() => {
		if (!activeLanguageId || activeLanguageId === DEFAULT_LANG_ID) {
			appController.resetLanguage()
		} else {
			appController.setLanguage(activeLanguageId)
		}
	}, [activeLanguageId])

	return (
		<>
			<AppDescriptionLoader activeAppId={activeAppId} appController={appController} />
			<DataModelLoader
				activeAppId={activeAppId}
				activeLanguageId={activeLanguageId}
				appTranslation={appTranslation}
				appController={appController}
			/>
			<ActionsLoader
				activeAppId={activeAppId}
				activeLanguageId={activeLanguageId}
				appTranslation={appTranslation}
			/>
			<ScriptLoader activeAppId={activeAppId} appController={appController} />
			<StylesheetLoader activeAppId={activeAppId} appController={appController} />
			<LayoutLoader
				activeAppId={activeAppId}
				activeLanguageId={activeLanguageId}
				appTranslation={appTranslation}
			/>
		</>
	)
}

MetadataLoader.propTypes = {
	activeAppId: PropTypes.string,
}

export default MetadataLoader
