import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { appbarConnector } from '#store/dataConnector'

import isUndefined from 'lodash/isUndefined'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'

import capitalize from 'lodash/capitalize'
import UiAppBarAction from './UiAppBarAction'
import Zoom from '@material-ui/core/Zoom'

import { e_AppBarColor, e_AppBarContentColor, e_AppBarOverlayType } from '@appfarm/common/enums/e_PropertyTypes'

const styles = (theme) => ({
	root: {
		transition: theme.transitions.create(['left', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		left: 0,
		width: '100%',
		'.drawerIsOpen &': {
			left: 256,
			width: 'calc(100% - 256px)',
		},
	},
	leftButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		width: 0,
		alignSelf: 'stretch',
		transition: theme.transitions.create('width'),
		'&$visibleItems': {
			width: 56,
		},
	},
	visibleItems: {},
	leftButton: {
		position: 'absolute',
	},
	titleContainer: {
		flex: 1,
		overflow: 'hidden',
	},
	title: {
		lineHeight: '1em',
		marginBottom: 4,
	},
	subtitle: {
		opacity: 0.7,
		lineHeight: '1em',
	},
	actions: {
		whiteSpace: 'nowrap',
	},
	transparent: {
		backgroundColor: 'transparent',
	},
	colorPrimary: {
		color: theme.palette.primary.main,
	},
	colorSecondary: {
		color: theme.palette.secondary.main,
	},
})

const zoomTimeout = {
	enter: 225,
	exit: 195,
}

const emptyArray = []

class UiAppBar extends Component {
	constructor(props) {
		super(props)

		this.state = {
			menuAnchorEl: undefined,
			menuOpen: false,
		}

		this.openMenu = this.openMenu.bind(this)
		this.closeMenu = this.closeMenu.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { appController, component } = nextProps

		const leftActions = component.leftActions?.map((actionItem) => {
			let badgeValue
			let visible = true
			let title
			let iconValue

			if (!isUndefined(actionItem.badgeValue))
				badgeValue = appController.getDataFromDataValue(actionItem.badgeValue)

			if (!isUndefined(actionItem.visible)) visible = !!appController.getDataFromDataValue(actionItem.visible)

			if (!isUndefined(actionItem.title))
				title = appController.getDataFromDataValue(actionItem.title, undefined, { getDisplayValue: true })

			if (!isUndefined(actionItem.iconValue))
				iconValue = appController.getDataFromDataValue(actionItem.iconValue, undefined, {
					getIcon: true,
				})

			return {
				...actionItem,
				__badgeValue: badgeValue,
				__visible: visible,
				__title: title,
				__icon: iconValue || actionItem.icon,
			}
		})

		const rightActions = component.rightActions?.map((actionItem) => {
			let badgeValue
			let visible = true
			let disabled
			let title
			let iconValue

			if (!isUndefined(actionItem.badgeValue))
				badgeValue = appController.getDataFromDataValue(actionItem.badgeValue)

			if (!isUndefined(actionItem.disabled))
				disabled = !!appController.getDataFromDataValue(actionItem.disabled)

			if (!isUndefined(actionItem.visible)) visible = !!appController.getDataFromDataValue(actionItem.visible)

			if (!isUndefined(actionItem.title))
				title = appController.getDataFromDataValue(actionItem.title, undefined, { getDisplayValue: true })

			if (!isUndefined(actionItem.iconValue))
				iconValue = appController.getDataFromDataValue(actionItem.iconValue, undefined, {
					getIcon: true,
				})

			return {
				...actionItem,
				__badgeValue: badgeValue,
				__visible: visible,
				__disabled: disabled,
				__title: title,
				__icon: iconValue || actionItem.icon,
			}
		})

		return {
			...prevState,
			leftActions: leftActions || emptyArray,
			rightActions: rightActions || emptyArray,
		}
	}

	openMenu(event) {
		this.setState({ menuOpen: true, menuAnchorEl: event.currentTarget })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	render() {
		const { component, appController, eventHandler, title, subTitle, styleProp, overlayStyleProp, classes } =
			this.props

		let leftActions
		if (this.state.leftActions.length) {
			const firstVisibleIndex = this.state.leftActions.map((item) => item.__visible).indexOf(true)
			leftActions = (
				<div
					className={classNames(classes.leftButtonContainer, {
						[classes.visibleItems]: firstVisibleIndex > -1,
					})}
				>
					{ this.state.leftActions.map((actionItem, index) => (
						<Zoom
							key={actionItem.id}
							in={firstVisibleIndex === index}
							timeout={zoomTimeout}
							style={{ transitionDelay: `${firstVisibleIndex === index ? 195 : 0}ms` }}
							mountOnEnter
							unmountOnExit
						>
							<UiAppBarAction
								className={classes.leftButton}
								component={actionItem}
								appController={appController}
								eventHandler={eventHandler}
								isLeftAction
								edge="start"
							/>
						</Zoom>
					)) }
				</div>
			)
		}

		const overflowMenuActions = this.state.rightActions
			.filter((item) => item.overflowMenu)
			.filter((item) => item.__visible)
		let overflowActions
		if (overflowMenuActions.length > 0) {
			overflowActions = overflowMenuActions.map((action) => (
				<UiAppBarAction
					key={action.id}
					component={action}
					appController={appController}
					eventHandler={eventHandler}
					closeMenu={this.closeMenu}
					overflowMenu
				/>
			))
		}

		const appbarActions = this.state.rightActions.filter((item) => !item.overflowMenu)
		let actions
		if (appbarActions?.length > 0) {
			const visibleActions = appbarActions.filter((item) => item.__visible)
			const lastVisibleAction = visibleActions[visibleActions.length - 1]

			actions = appbarActions.map((action) => (
				<UiAppBarAction
					key={action.id}
					component={action}
					appController={appController}
					eventHandler={eventHandler}
					edge={
						action.id === (lastVisibleAction && lastVisibleAction.id) && !overflowActions ? 'end' : undefined
					}
					denseAppBar={component.dense}
				/>
			))
		}

		let titleComponent
		if (title) {
			titleComponent = subTitle ? (
				<>
					<Typography variant="h6" color="inherit" noWrap className={classes.title} gutterBottom>
						{ title }
					</Typography>
					<Typography variant="subtitle2" color="inherit" noWrap className={classes.subtitle}>
						{ subTitle }
					</Typography>
				</>
			) : (
				<Typography variant="h6" color="inherit" noWrap>
					{ title }
				</Typography>
			)
		}

		const color = component.color !== e_AppBarColor.TRANSPARENT ? component.color : undefined

		return (
			<AppBar
				position="static"
				color={color}
				elevation={component.elevation}
				className={classNames(classes.root, {
					[classes.transparent]: component.color === e_AppBarColor.TRANSPARENT,
					[classes[`color${capitalize(component.contentColor)}`]]:
						component.contentColor !== e_AppBarContentColor.INHERIT,
				})}
				style={styleProp}
			>
				<Toolbar variant={component.dense ? 'dense' : undefined} style={overlayStyleProp}>
					{ leftActions }
					<div className={classes.titleContainer}>{ titleComponent }</div>
					{ (actions || overflowActions) && (
						<div className={classes.actions}>
							{ actions }
							<Zoom in={!!overflowActions} unmountOnExit timeout={zoomTimeout}>
								<span>
									<IconButton
										onClick={this.openMenu}
										color={component.contentColor || e_AppBarContentColor.INHERIT}
										edge="end"
									>
										<Icon className="mdi mdi-dots-vertical" />
									</IconButton>
									<Menu
										anchorEl={this.state.menuAnchorEl}
										open={this.state.menuOpen}
										onClose={this.closeMenu}
										anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
										transformOrigin={{ vertical: 'top', horizontal: 'right' }}
										getContentAnchorEl={null}
									>
										{ overflowActions }
									</Menu>
								</span>
							</Zoom>
						</div>
					) }
				</Toolbar>
			</AppBar>
		)
	}
}

UiAppBar.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.object.isRequired,
	contextData: PropTypes.object,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	overlayStyleProp: PropTypes.object,
	classes: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
	const mapStateToProps = (ownProps, { getDataFromDataValue }) => {
		let title
		if (ownProps.component.title)
			title = getDataFromDataValue(ownProps.component.title, ownProps.contextData, {
				getDisplayValue: true,
			})

		let subTitle
		if (ownProps.component.subTitle)
			subTitle = getDataFromDataValue(ownProps.component.subTitle, ownProps.contextData, {
				getDisplayValue: true,
			})

		let styleProp
		if (ownProps.component.imageValue) {
			const backgroundImage = getDataFromDataValue(ownProps.component.imageValue, ownProps.contextData)

			if (backgroundImage) styleProp = { backgroundImage: `url(${backgroundImage})` }
		}

		let overlayStyleProp
		if (ownProps.component.overlay) {
			const transparency = (ownProps.component.overlayTransparency || 20) / 100
			overlayStyleProp = {
				backgroundColor:
					ownProps.component.overlayType === e_AppBarOverlayType.DARK
						? `rgba(0, 0, 0, ${transparency})`
						: `rgba(255, 255, 255, ${transparency})`,
			}
		}

		return {
			title,
			subTitle,
			styleProp,
			overlayStyleProp,
		}
	}

	return mapStateToProps
}

export default withStyles(styles)(appbarConnector(makeMapStateToProps)(UiAppBar))
