import { useEffect, useState } from 'react'

const ServiceWorkerManager = () => {
	const [serviceWorkerActive, setServiceWorkerActive] = useState(false)
	// Initialize service worker
	useEffect(() => {
		if (navigator.serviceWorker) {
			console.log('Registering service worker')
			navigator.serviceWorker
				.register('/assets/sw.js', { scope: '/' })
				.then((reg) => {
					console.log('Updating service worker')
					return reg.update()
				})
				.then(() => setServiceWorkerActive(true))
				.catch((err) => console.log('Failed to register service worker', err))
		} else {
			console.log('ServiceWorker is not available')
		}
	}, [])

	useEffect(() => {
		if (!serviceWorkerActive) return

		console.log('Service worker is active - Loading assets')
		
	}, [serviceWorkerActive])

	return null
}

export default ServiceWorkerManager
