import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Trans, t } from '@lingui/macro'

import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Zoom from '@material-ui/core/Zoom'
import Fade from '@material-ui/core/Fade'
import NotFound from '../NotFound'
import unsupported_device from '../../resources/unsupported_device.svg'
import af_rocket from '../../resources/af_rocket.svg'

import AppShortcut from './AppShortcut'

import browserHistory from '../browserHistory'
import { logout } from '#modules/authApi'
import { modelAnalytics_logPerformanceMeasure } from '#logger/logger'

import {
	getAppListWithTheme,
	hasNoAppsWithSupportedDevice,
	hasInsufficientPremissionsToAllApps,
	getHiddenAppListWithTheme,
} from '#selectors/appSelectors'

import { setAccountDialogOpenState, setAppActiveState } from '#actions/appStateActions'
import { isInDevelopMode } from '#selectors/metadataSelectors'

const styles = (theme) => ({
	root: {
		flex: 1,
		width: '100%',
		position: 'relative',
		overflowY: 'auto',
	},
	pageContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '100%',
	},
	centerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			marginTop: 48,
			marginBottom: 80,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 12,
		},
	},
	welcomeText: {
		marginBottom: 24,
	},
	appList: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginTop: 8,
		marginBottom: 24,
		[theme.breakpoints.up('sm')]: {
			width: 480,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	menuButton: {
		position: 'fixed',
		zIndex: 100,
		[theme.breakpoints.up('sm')]: {
			top: 6,
			left: 18,
		},
		[theme.breakpoints.down('xs')]: {
			top: 4,
			left: 12,
		},
	},
	avatar: {
		height: 60,
		width: 60,
	},
	drawerPaper: {
		[theme.breakpoints.up('xs')]: {
			width: 256,
		},
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 56px)',
		},
	},
	userInfoContainer: {
		[theme.breakpoints.up('xs')]: {
			padding: '16px 24px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: 16,
		},
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	userInfoText: {
		marginTop: 8,
	},
})

class AppListPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			drawerIsOpen: false,
			myProfileDialogOpen: false,
		}

		props.setAppActiveFalse()

		this.toggleDrawer = this.toggleDrawer.bind(this)
		this.openDrawer = this.openDrawer.bind(this)
		this.closeDrawer = this.closeDrawer.bind(this)
		this.openAccountDialog = this.openAccountDialog.bind(this)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.apps.length === 1) {
			browserHistory.replace('/' + nextProps.apps[0].readableId)
		} else if (nextProps.apps.length === 0 && nextProps.hiddenApps.length === 1) {
			browserHistory.replace('/' + nextProps.hiddenApps[0].readableId)
		}
	}

	componentDidUpdate(prevProps) {
		const applistLoaded = !prevProps.apps?.length && this.props.apps?.length
		if (applistLoaded && window.performance.now) {
			modelAnalytics_logPerformanceMeasure({ message: 'Applist loaded', timeEnd: window.performance.now() })
		}
	}

	toggleDrawer() {
		this.setState((state) => ({ drawerIsOpen: !state.drawerIsOpen }))
	}

	openDrawer() {
		this.setState({ drawerIsOpen: true })
	}

	closeDrawer() {
		this.setState({ drawerIsOpen: false })
	}

	openAccountDialog() {
		this.props.openAccountDialog()
		this.setState({ drawerIsOpen: false })
	}

	render() {
		const {
			apps,
			hiddenApps,
			hasNoAppsWithSupportedDevice,
			hasInsufficientPremissionsToAllApps,
			initialAppListSet,
			isAnonymous,
			isInDevelop,
			userFirstName,
			userFullName,
			userEmail,
			userProfileImage,
			width,
			classes,
		} = this.props

		if (isAnonymous) return null

		const drawerContent = (
			<div>
				<div className={classes.userInfoContainer}>
					<Avatar className={classes.avatar} src={userProfileImage} />
					<div className={classes.userInfoText}>
						<Typography variant="h6">{ userFullName }</Typography>
						<Typography variant="body2" color="textSecondary">
							{ userEmail }
						</Typography>
					</div>
				</div>
				<List>
					<ListItem onClick={this.openAccountDialog} button>
						<ListItemIcon>
							<Icon className="mdi mdi-account-settings" />
						</ListItemIcon>
						<ListItemText primary={t`Account Settings`} />
					</ListItem>
					<ListItem onClick={logout} button>
						<ListItemIcon>
							<Icon className="mdi mdi-logout" />
						</ListItemIcon>
						<ListItemText primary={t`Log out`} />
					</ListItem>
				</List>
			</div>
		)

		const onlyHiddenAppsAndInProduction = !!(
			!isInDevelop &&
			initialAppListSet &&
			hiddenApps.length > 1 &&
			!apps.length
		)
		const showNoApps =
			initialAppListSet &&
			!apps.length &&
			!hiddenApps.length &&
			!hasInsufficientPremissionsToAllApps &&
			!hasNoAppsWithSupportedDevice

		const showWelcome =
			initialAppListSet &&
			!hasInsufficientPremissionsToAllApps &&
			!hasNoAppsWithSupportedDevice &&
			!onlyHiddenAppsAndInProduction &&
			!showNoApps

		return (
			<div className={classes.root}>
				<SwipeableDrawer
					classes={{ paper: classes.drawerPaper }}
					variant="temporary"
					open={this.state.drawerIsOpen}
					onOpen={this.openDrawer}
					onClose={this.closeDrawer}
				>
					{ drawerContent }
				</SwipeableDrawer>
				<div className={classes.pageContent}>
					<Zoom in={!!initialAppListSet} style={{ transitionDelay: 500 }}>
						<IconButton className={classes.menuButton} onClick={this.toggleDrawer} aria-label={t`Menu`}>
							<Icon className="mdi mdi-menu" />
						</IconButton>
					</Zoom>
					<div className={classes.centerContainer}>
						<Zoom in={!!showWelcome}>
							<Typography
								className={classes.welcomeText}
								variant={isWidthUp('sm', width) ? 'h4' : 'h5'}
								color="textSecondary"
							>
								<Trans>Welcome, { userFirstName }!</Trans>
							</Typography>
						</Zoom>
						<Fade in={!!apps.length} style={{ transitionDelay: 500 }}>
							<div className={classes.appList}>
								{ apps.map((app) => (
									<AppShortcut key={app.id} app={app} />
								)) }
							</div>
						</Fade>
						{ isInDevelop && (
							<>
								<Zoom in={!!hiddenApps.length}>
									<div>
										<Typography variant="subtitle1" color="textSecondary" align="center">
											<Trans>Hidden Apps</Trans>
										</Typography>
										<Typography variant="caption" color="textSecondary" align="center">
											<Trans>(Shortcut visible in DEV only)</Trans>
										</Typography>
									</div>
								</Zoom>
								<Fade in={!!hiddenApps.length} style={{ transitionDelay: 500 }}>
									<div className={classes.appList}>
										{ hiddenApps.map((app) => (
											<AppShortcut key={app.id} app={app} />
										)) }
									</div>
								</Fade>
							</>
						) }
						{ showNoApps && (
							<NotFound
								title={t`No apps`}
								message={t`Start developing some apps to display :)`}
								imagePath={af_rocket}
							/>
						) }
						{ onlyHiddenAppsAndInProduction && (
							<NotFound
								title={t`App not found`}
								message={t`No app with this URL exists. Please check the spelling of the URL.`}
							/>
						) }
						{ hasNoAppsWithSupportedDevice && (
							<NotFound
								imagePath={unsupported_device}
								title={t`Unsupported device`}
								message={t`There are no apps available for this device size. Try another device.`}
							/>
						) }

						{ hasInsufficientPremissionsToAllApps && (
							<NotFound
								title={t`Access denied`}
								message={t`Your user does not have sufficient permissions to access any apps. Contact support for further assistance.`}
							/>
						) }
					</div>
				</div>
			</div>
		)
	}
}

AppListPage.propTypes = {
	apps: PropTypes.array.isRequired,
	hiddenApps: PropTypes.array.isRequired,
	hasNoAppsWithSupportedDevice: PropTypes.bool,
	hasInsufficientPremissionsToAllApps: PropTypes.bool,
	initialAppListSet: PropTypes.bool,
	userFirstName: PropTypes.string.isRequired,
	userFullName: PropTypes.string.isRequired,
	userEmail: PropTypes.string.isRequired,
	userProfileImage: PropTypes.string.isRequired,
	isAnonymous: PropTypes.bool,
	isInDevelop: PropTypes.bool,
	openAccountDialog: PropTypes.func.isRequired,
	setAppActiveFalse: PropTypes.func.isRequired,

	width: PropTypes.string.isRequired, // Injected bu withWidth()
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		apps: getAppListWithTheme(state),
		hiddenApps: getHiddenAppListWithTheme(state),
		hasNoAppsWithSupportedDevice: hasNoAppsWithSupportedDevice(state),
		hasInsufficientPremissionsToAllApps: hasInsufficientPremissionsToAllApps(state),
		initialAppListSet: state.appState.initialAppListSet,
		userFirstName: state.authState.userFirstName,
		userFullName: state.authState.userFullName,
		userEmail: state.authState.userEmail,
		userProfileImage: state.authState.userProfileImage,
		isAnonymous: state.authState.isAnonymous,
		isInDevelop: isInDevelopMode(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openAccountDialog: () => dispatch(setAccountDialogOpenState(true)),
		setAppActiveFalse: () => dispatch(setAppActiveState(false)),
	}
}

export default withStyles(styles)(withWidth()(connect(mapStateToProps, mapDispatchToProps)(AppListPage)))
