/******************************************************************************
 *
 * This will refresh all the checksums from current app
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setLatestChecksums, setWantedChecksums } from '#actions/metadataActions'
import { getDevToolsHotReloadDisabledAndInDev, getWantedChecksums } from '#selectors/metadataSelectors'

const ChecksumLoader = ({ activeAppId }) => {
	const checksumsReady = useSelector((state) => state.resourceReadyState.activeAppChecksums)
	const disableHotReload = useSelector(getDevToolsHotReloadDisabledAndInDev)
	const wantedChecksums = useSelector(getWantedChecksums)
	const initialWantedChecksumsReady = Object.values(wantedChecksums).some((checksum) => !!checksum)

	const dispatch = useDispatch()

	useEffect(() => {
		if (!activeAppId) return
		if (checksumsReady) return

		const controller = new AbortController()

		axios
			.get(`/api/v1/apps/${activeAppId}/checksums`, {
				signal: controller.signal,
			})
			.then((result) => {
				// The result is no longer relevant - just return
				if (controller.signal?.aborted) return

				// Set latest no matter what
				dispatch(setLatestChecksums(result.data))

				// Trigger metadata reload or set checksums if first load
				if (!disableHotReload || !initialWantedChecksumsReady) dispatch(setWantedChecksums(result.data))
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				console.log('Failed to get checksums for some reason', err)
			})

		// Abort request as cleanup
		return () => controller.abort()
	}, [activeAppId, checksumsReady])

	return null
}

export default ChecksumLoader
