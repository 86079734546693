import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = {
	root: { display: 'block' },
}

class UiCircularProgress extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromProps(nextProps) {
		const value = nextProps.appController.getDataFromDataValue(
			nextProps.component.value,
			nextProps.contextData
		)

		return {
			value,
		}
	}

	render() {
		const { component, styleProp, conditionalClassNames, classes } = this.props
		const { value } = this.state
		return (
			<CircularProgress
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				value={value}
				variant={component.type}
				size={component.progressSize || undefined}
				color={component.progressColor}
				style={styleProp}
			/>
		)
	}
}

UiCircularProgress.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		type: PropTypes.oneOf(['determinate', 'indeterminate']),
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		progressSize: PropTypes.number,
		progressColor: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
	}).isRequired,
	contextData: PropTypes.object,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	value: PropTypes.number,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiCircularProgress)
