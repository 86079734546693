/******************************************************************************
 *
 * This module will make sure all resources needed by the client
 * is loaded. It will also manage the WS-connection.
 *
 *****************************************************************************/
import React from 'react'

import GlobalSettingsLoader from './GlobalSettingsLoader'
import AppListLoader from './AppListLoader'
import AppLoader from './AppLoader'
import ThemeLoader from './ThemeLoader'
import IosPwaIconsLoader from './IosPwaIconsLoader'
import SocketController from './SocketController'
import CurrentUserLoader from './CurrentUserLoader'
import ServiceWorkerManager from './ServiceWorkerManager'
import GlobalResourceChecksumsLoader from './GlobalResourceChecksumsLoader'
import OfflineHouseKeeping from './OfflineHouseKeeping'

const ClientLoader = () => {
	return (
		<>
			<ServiceWorkerManager />
			<SocketController />
			<GlobalResourceChecksumsLoader />
			<GlobalSettingsLoader />
			<AppListLoader />
			<ThemeLoader />
			<AppLoader />
			<CurrentUserLoader />
			<IosPwaIconsLoader />
			<OfflineHouseKeeping />
		</>
	)
}

export default ClientLoader
