import { handleEvent } from '#actions/eventActions'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getOnAppLoadExecuted, getOnAppLoadedExecuted } from '#selectors/appStateSelectors'
import { getLoadedApp } from '#selectors/metadataSelectors'
import { setOnAppLoadedExecutedValue } from '#actions/appStateActions'

const OnAppLoaded = () => {
	const dispatch = useDispatch()

	const onAppLoadedExecuted = useSelector(getOnAppLoadedExecuted)
	const onAppLoadExecuted = useSelector(getOnAppLoadExecuted)

	const app = useSelector(getLoadedApp)
	const onAppLoadedEventHandler = app?.eventHandlers?.onAppLoaded

	useEffect(() => {
		if (onAppLoadedExecuted) return
		if (!onAppLoadExecuted) return // wait until onAppLoad is finished

		if (onAppLoadedEventHandler) {
			dispatch(
				handleEvent(
					onAppLoadedEventHandler,
					{},
					{},
					{
						resolve: () => dispatch(setOnAppLoadedExecutedValue(true)),
						reject: (err) => {
							console.error('onAppLoaded failed', err)
							dispatch(setOnAppLoadedExecutedValue(true))
						},
					}
				)
			)
		}
	}, [onAppLoadExecuted])

	return null
}

export default OnAppLoaded
