import { setThemeIdOverrideValue } from '#actions/appStateActions'
import { replaceTheme } from '#actions/metadataActions'
import { getTheme } from '#modules/afClientApi'

const p_setTheme = ({ actionNode, contextData, dispatch, getState, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!actionNode.value) {
			actionNodeLogger.debug('Reset theme to application default')
			dispatch(setThemeIdOverrideValue(null))
			appController.reevaluateActiveTheme()
			return resolve()
		}

		const themeId = appController.getDataFromDataValue(actionNode.value, contextData)
		if (!themeId) return reject(new Error('Could not find theme id to set'))

		const state = getState()
		const currentOverrideTheme = state.appState.themeIdOverride
		const themeChecksums = state.metaData.latestGlobalChecksums.themes || {}

		if (themeId === currentOverrideTheme) return resolve()

		const availableThemes = state.metaData.themes

		if (availableThemes[themeId]) {
			dispatch(setThemeIdOverrideValue(themeId))
			appController.reevaluateActiveTheme()
			return resolve()
		}

		actionNodeLogger.debug('Fetch theme from from server: ' + themeId)

		getTheme(themeId, themeChecksums[themeId])
			.then((theme) => {
				if (theme) {
					dispatch(replaceTheme(theme))
					dispatch(setThemeIdOverrideValue(themeId))
					appController.reevaluateActiveTheme()
				} else {
					actionNodeLogger.warning('Unable to change theme. Theme does not exist.')
				}
				resolve()
			})
			.catch(reject)
	})

export default p_setTheme
