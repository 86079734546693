/**
 * Save original references to logging
 */
const consolelog = console.log
const consoleinfo = console.info
const consolewarn = console.warn
const consoleerror = console.error
const consolegroup = console.group
const consolegroupCollapsed = console.groupCollapsed
const consolegroupEnd = console.groupEnd
const consoletable = console.table
const consoletime = console.time
const consoletimeEnd = console.timeEnd

export const disableLogging = () => {
	if (console.log) console.log = () => {}
	if (console.info) console.info = () => {}
	if (console.warn) console.warn = () => {}
	if (console.error) console.error = () => {}
	if (console.group) console.group = () => {}
	if (console.groupCollapsed) console.groupCollapsed = () => {}
	if (console.groupEnd) console.groupEnd = () => {}
	if (console.table) console.table = () => {}
	if (console.time) console.time = () => {}
	if (console.timeEnd) console.timeEnd = () => {}
}

export const enableLogging = () => {
	console.log = consolelog
	console.info = consoleinfo
	console.warn = consolewarn
	console.error = consoleerror
	console.group = consolegroup
	console.groupCollapsed = consolegroupCollapsed
	console.groupEnd = consolegroupEnd
	console.table = consoletable
	console.time = consoletime
	console.timeEnd = consoletimeEnd
}
