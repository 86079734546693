import isUndefined from 'lodash/isUndefined'
import isString from 'lodash/isString'
import validateAndCastValue from '#utils/validateAndCastValue'
import { ADD, REPLACE, UPDATE } from '@appfarm/common/enums/e_InsertObjectsOperation'
import { ONE } from '@appfarm/common/enums/e_Cardinality'
import { ENUM, MULTI_ENUM } from '@appfarm/common/enums/e_ObjectClassDataType'
import dayjs from '#controllers/dayjs'

const getDataForDSFromJSONValueMap = ({
	dataForDataSource,
	JSONValueMap,
	appController,
	parsedJsonData,
	registerIdChange,
	hasChangedIds,
	replaceChangedIds,
	logger,
}) => {
	const dataSource = appController.getDataSource(JSONValueMap.dataSourceId)
	if (!dataSource) return dataForDataSource
	if (!dataSource.local) return dataForDataSource // Don't allow insertion in non-local

	let data = parsedJsonData[dataSource.id]
	if (!data) return dataForDataSource

	const propertyMetaArray = Object.keys(dataSource.propertiesMetaDict).map(
		(propertyId) => dataSource.propertiesMetaDict[propertyId]
	)

	const existingData = dataSource.getAllObjects()
	const mappingNodeName = JSONValueMap.updateExisting && JSONValueMap.mappingProperty?.nodeName

	if (hasChangedIds()) {
		data = data.map((object) => replaceChangedIds(object))
	}
	// Clean data
	let cleanData = data.map((item) => {
		let newObject = dataSource.generateNewObject()
		if (JSONValueMap.updateObject) {
			newObject = {}
		} else if (JSONValueMap.updateExisting && mappingNodeName && item[mappingNodeName]) {
			const existingObject = existingData.find(
				(dataItem) => dataItem[mappingNodeName] === item[mappingNodeName]
			)
			if (existingObject) {
				newObject = { _id: existingObject._id }
				item._id && registerIdChange(item._id, existingObject._id)
				delete item._id
			}
		}
		return propertyMetaArray.reduce(
			(cleanObject, property) => {
				if (isUndefined(cleanObject[property.nodeName])) return cleanObject

				let enumValues
				if ([ENUM, MULTI_ENUM].includes(property.dataType) && property.enumTypeId) {
					const enumeratedType = appController.getEnumeratedType(property.enumTypeId)
					enumValues = enumeratedType?.values
				}
				cleanObject[property.nodeName] = validateAndCastValue({
					value: cleanObject[property.nodeName],
					dataType: property.dataType,
					enumValues,
					logger,
					dayjs,
				})
				return cleanObject
			},
			{ ...newObject, ...item }
		)
	})

	// No data - skip
	if (!cleanData.length) return dataForDataSource

	const operation = JSONValueMap.updateObject ? UPDATE : JSONValueMap.addToExisting ? ADD : REPLACE
	const setSelected = JSONValueMap.setSelected
	if (dataSource.cardinality === ONE && cleanData.length > 1) cleanData = [cleanData[0]]

	dataForDataSource[JSONValueMap.dataSourceId] = {
		data: cleanData,
		operation,
		setSelected,
	}

	if (JSONValueMap.nestedJsonValueMap) {
		dataForDataSource = JSONValueMap.nestedJsonValueMap.reduce((dataForDataSource, item) => {
			return getDataForDSFromJSONValueMap({
				dataForDataSource,
				JSONValueMap: item,
				appController,
				parsedJsonData,
				registerIdChange,
				hasChangedIds,
				replaceChangedIds,
				logger,
			})
		}, dataForDataSource)
	}

	return dataForDataSource
}

const getDataSourceDataFromJSONValueMap = ({ valueMaps, appController, parsedJsonData, logger }) => {
	/* Used to map ids to nested objects if objects are updated instead of added to the datasource */
	const idDictionary = {}
	const registerIdChange = (disregardedId, workingId) => (idDictionary[disregardedId] = workingId)
	const hasChangedIds = () => !!Object.keys(idDictionary).length
	const replaceChangedIds = (object) =>
		Object.keys(object).reduce((newObject, nodeName) => {
			const value = object[nodeName]
			newObject[nodeName] = isString(value) ? idDictionary[value] || value : value
			return newObject
		}, {})
	/*** */

	return valueMaps.reduce(
		(dataForDataSource, JSONValueMap) =>
			getDataForDSFromJSONValueMap({
				dataForDataSource,
				JSONValueMap,
				appController,
				parsedJsonData,
				registerIdChange,
				hasChangedIds,
				replaceChangedIds,
				logger,
			}),
		{}
	)
}

export default getDataSourceDataFromJSONValueMap
