import translateEnumeratedTypes from './translationUtils/translateEnumeratedTypes'

const generateEnumeratedTypeValueDict = (enumeratedTypes) =>
	Object.keys(enumeratedTypes).reduce((dict, enumTypeId) => {
		const enumeratedType = { ...enumeratedTypes[enumTypeId] }
		enumeratedType.valueDict = enumeratedType.values.reduce((dict, item) => {
			dict[item.value] = item
			return dict
		}, {})

		dict[enumTypeId] = enumeratedType
		return dict
	}, {})

const processAndTranslateEnumeratedTypes = (enumeratedTypeDict, enumLangDictionary) => {
	enumeratedTypeDict = {
		...enumeratedTypeDict,
	}

	if (enumLangDictionary)
		enumeratedTypeDict = translateEnumeratedTypes(enumeratedTypeDict, enumLangDictionary)

	return generateEnumeratedTypeValueDict(enumeratedTypeDict)
}

export default processAndTranslateEnumeratedTypes
