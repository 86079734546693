/**
 * Generic runner for server-only actions
 */
import { runActionNodeOnServer } from '#modules/afClientApi'

const p_runActionOnServer = ({ actionNode, contextData, actionNodeRunner, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.info('Run ActionNode on Server', { payload: actionNode })

		const rootActionId = actionNodeRunner.getRootAction().id

		runActionNodeOnServer(rootActionId, actionNode.id, { contextData })
			.then((result) => {
				actionNodeLogger.debug('Result: ', { payload: result })
				resolve()
			})
			.catch((err) => reject(err))
	})

export default p_runActionOnServer
