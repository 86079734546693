/******************************************************************************
 *
 * This module are hooked onto a data source and will make sure that
 * the data are stored in a client side storage.
 *
 * This will only handle data events and not the actual data storage.
 * The storage module can use any storage engine that implements the
 * same interface as the AppStorageEngine.
 *
 *****************************************************************************/

class StorageEngineAdapter {
	constructor(dataSourceId) {
		this.dataSourceId = dataSourceId

		this.__appStorageEngine = null

		this.attachStorageEngine = this.attachStorageEngine.bind(this)

		this.onReplaceAll = this.onReplaceAll.bind(this)
		this.onNewObject = this.onNewObject.bind(this)
		this.onObjectChanged = this.onObjectChanged.bind(this)
		this.onObjectDeleted = this.onObjectDeleted.bind(this)
		this.onMultipleObjectsDeleted = this.onMultipleObjectsDeleted.bind(this)
		this.onMultiOperation = this.onMultiOperation.bind(this)
	}

	/******************************************************************************
	 *
	 * Control Methods
	 *
	 *****************************************************************************/
	attachStorageEngine(appStorageEngine) {
		this.__appStorageEngine = appStorageEngine
	}

	/******************************************************************************
	 *
	 * Event Handlers
	 *
	 *****************************************************************************/
	onReplaceAll(objectList) {
		if (!this.__appStorageEngine) return
		this.__appStorageEngine.replaceAllObjects(this.dataSourceId, objectList)
	}

	onNewObject(newObject) {
		if (!this.__appStorageEngine) return
		this.__appStorageEngine.newObject(this.dataSourceId, newObject)
	}

	onObjectChanged(changedObject) {
		if (!this.__appStorageEngine) return
		this.__appStorageEngine.objectChanged(this.dataSourceId, changedObject)
	}

	onObjectDeleted(objectId) {
		if (!this.__appStorageEngine) return
		this.__appStorageEngine.objectDeleted(this.dataSourceId, objectId)
	}

	onMultipleObjectsDeleted(objectIdList) {
		if (!this.__appStorageEngine) return
		this.__appStorageEngine.multipleObjectsDeleted(this.dataSourceId, objectIdList)
	}

	// Array of operations for use in a transaction
	onMultiOperation(operationList) {
		if (!this.__appStorageEngine) return

		operationList.forEach((operation) => {
			switch (operation.op) {
				case 'update':
					this.onObjectChanged(operation.data)
					break
				case 'create':
					this.onNewObject(operation.data)
					break

				default:
					throw new Error(`Unknown operation ${operation.op}`)
			}
		})
	}
}

export default StorageEngineAdapter
