import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { useHotkeys } from 'react-hotkeys-hook'

import { addToActiveKeyboardShortcuts, removeFromActiveKeyboardShortcuts } from '#actions/runtimeStateActions'

const UiKeyboardShortcuts = ({ viewId, keyboardEventHandler, eventHandler }) => {
	const openDialogIds = useSelector((state) => state.runtimeState.openDialogIds)
	const isCurrentDialog = openDialogIds[openDialogIds.length - 1] === viewId
	const disabled = !!openDialogIds.length && !isCurrentDialog

	const dispatch = useDispatch()

	useEffect(() => {
		if (disabled) {
			dispatch(removeFromActiveKeyboardShortcuts(keyboardEventHandler.id))
		} else {
			dispatch(addToActiveKeyboardShortcuts(keyboardEventHandler))
		}
		return () => dispatch(removeFromActiveKeyboardShortcuts(keyboardEventHandler.id))
	}, [disabled])

	const handleKeyPress = useCallback(
		(event, action) => {
			event.preventDefault()
			eventHandler(action, null, { eventType: 'onKeyPress' }, event)
		},
		[eventHandler]
	)

	useHotkeys(
		keyboardEventHandler.keyBinding,
		(event) => handleKeyPress(event, keyboardEventHandler.onKeyPress),
		{
			enableOnTags: keyboardEventHandler.enableOnFormFields ? ['INPUT', 'TEXTAREA', 'SELECT'] : undefined,
			enabled: !disabled,
		},
		[handleKeyPress, keyboardEventHandler, disabled]
	)

	return null
}

UiKeyboardShortcuts.propTypes = {
	viewId: PropTypes.string.isRequired,
	keyboardEventHandler: PropTypes.object.isRequired,
	eventHandler: PropTypes.func.isRequired,
}

export default UiKeyboardShortcuts
