import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { withStyles, withTheme } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import classNames from 'classnames'

import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import Switch from '@material-ui/core/Switch'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import getColorValueFromMuiTheme from '@appfarm/common/utils/getColorValueFromMuiTheme'
import getContrastText from '#utils/getContrastText'

import {
	e_ListType,
	e_ListSelectType,
	e_ListThumbnailVariant,
	e_ListItemVisualType,
	e_ListItemRightActionType,
	e_ThumbnailImageFit,
} from '@appfarm/common/enums/e_PropertyTypes'

const styles = (theme) => ({
	listItem: {
		'&$metaTextPadding': {
			paddingRight: 64,
		},
	},
	iconPlaceholder: {
		height: 24,
		width: 24,
	},
	metaTextPadding: {},
	noWrap: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	secondaryTextThreeLine: {
		display: '-webkit-box',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	},
	inline: {
		display: 'inline',
	},
	metaText: {
		maxWidth: 56,
		marginTop: 13,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		flexShrink: 0,
		'&$dense': {
			marginTop: 9,
			fontSize: theme.typography.pxToRem(11),
		},
	},
	desktopThirdText: {
		width: 200,
		flexShrink: 0,
		margin: '6px 16px 6px 0',
		'&$dense': {
			fontSize: theme.typography.pxToRem(13),
		},
	},
	thumbnail: {
		flexShrink: 0,
		height: 40,
		width: 40,
		marginRight: 16,
		'&$largeThumbnail': {
			height: 56,
			width: 100,
		},
		'&$threeLinesThumbnail': {
			marginTop: 6,
		},
		objectFit: 'cover',
		'&$objectFitContain': {
			objectFit: 'contain',
		},
		'&$objectFitFill': {
			objectFit: 'fill',
		},
	},
	threeLinesThumbnail: {},
	largeThumbnail: {},
	objectFitContain: {},
	objectFitFill: {},
	dividerInset: {
		marginLeft: 72,
		'&$disableGutters': {
			marginLeft: 56,
		},
	},
	dividerLargeInset: {
		marginLeft: 132,
		'&$disableGutters': {
			marginLeft: 116,
		},
	},
	disableGutters: {},
	extendedActionContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		transform: 'unset',
		top: 0,
		bottom: 0,
		'& > :nth-child(2)': {
			marginTop: -6,
		},
	},
	embeddedToggle: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		opacity: 0,
		'&:hover, &$checked': {
			opacity: 1,
		},
	},
	iconToggleContainer: {
		position: 'relative',
		height: 40,
		width: 40,
		margin: '-3px 8px -3px -8px',
		padding: 8,
		'&:hover > :first-child:not($embeddedToggle)': {
			opacity: 0,
		},
	},
	avatarToggleContainer: {
		position: 'relative',
		height: 40,
		width: 40,
		marginRight: 16,
		'&:hover > :first-child:not($embeddedToggle)': {
			opacity: 0,
		},
	},
	imageToggleContainer: {
		position: 'relative',
		height: 40,
		width: 40,
		'&:hover > :first-child:not($embeddedToggle)': {
			opacity: 0,
		},
	},
	toggleControlLeft: {
		width: 40,
		height: 40,
		margin: '0px 24px 0px -8px',
	},
	checked: {},
	dense: {},
})

class UiListItem extends Component {
	constructor(props) {
		super(props)

		this.state = {}

		this.onCheckedChange = this.onCheckedChange.bind(this)
		this.onActionButtonClickHandler = this.onActionButtonClickHandler.bind(this)
		this.onListItemClickHandler = this.onListItemClickHandler.bind(this)
		this.onSecondaryCheckedChange = this.onSecondaryCheckedChange.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { contextData, theme } = nextProps
		let component = nextProps.component
		let visible = true
		if (!isNil(component.visible)) {
			visible = !!nextProps.appController.getDataFromDataValue(component.visible, contextData)
			if (!visible) {
				// No need to calculate rest if invisible
				if (!prevState.visible) return null
				return {
					...prevState,
					visible,
				}
			}
		}

		if (component.propertyConditions?.length) {
			const overrides = {}
			let applyOverride = false
			component.propertyConditions.forEach((propertyCondition) => {
				if (nextProps.appController.getDataFromDataValue(propertyCondition.condition, contextData)) {
					applyOverride = true
					propertyCondition.propertyConditionValues.forEach((item) => {
						overrides[item.propertyIdent] = item.value
					})
				}
			})

			if (applyOverride) {
				component = { ...component, ...overrides }
			}
		}

		let primaryText
		let secondaryText
		let thirdText
		let metaText
		let badgeValue
		let checkedValue
		let checkedData
		let imageValue
		let highlighted = false

		let disabled = false
		let iconColor
		let iconValue
		let avatarBackgroundColor

		if (component.primaryText)
			primaryText = nextProps.appController.getDataFromDataValue(component.primaryText, contextData, {
				getDisplayValue: true,
			})

		if (component.secondaryText)
			secondaryText = nextProps.appController.getDataFromDataValue(component.secondaryText, contextData, {
				getDisplayValue: true,
			})

		if (component.thirdText)
			thirdText = nextProps.appController.getDataFromDataValue(component.thirdText, contextData, {
				getDisplayValue: true,
			})

		if (component.metaText)
			metaText = nextProps.appController.getDataFromDataValue(component.metaText, contextData, {
				getDisplayValue: true,
			})

		if (component.badge && component.badgeValue)
			badgeValue = nextProps.appController.getDataFromDataValue(component.badgeValue, contextData, {
				getDisplayValue: true,
			})

		if (component.customSelectValue && component.checked) {
			const dataBinding = component.checked
			checkedData = nextProps.appController.getDataFromDataBinding({ contextData, dataBinding })
			const nodeName = dataBinding.edgeDataBinding
				? dataBinding.edgeDataBinding.nodeName
				: dataBinding.nodeName
			checkedValue = checkedData && checkedData[nodeName]
		} else if (nextProps.component.value) {
			const ownData = nextProps.contextData[nextProps.dataSourceId][0]
			checkedValue = !!ownData.__SELECTED__
		}

		if (component.iconColor) iconColor = getColorValueFromMuiTheme(component.iconColor, theme)

		if (component.iconValue)
			iconValue = nextProps.appController.getDataFromDataValue(component.iconValue, contextData, {
				getIcon: true,
			})

		if (component.avatarBackgroundColor) {
			avatarBackgroundColor = getColorValueFromMuiTheme(component.avatarBackgroundColor, theme)
			if (!iconColor) iconColor = getContrastText(avatarBackgroundColor)
		}

		let secondaryCheckedData
		let secondaryCheckedValue
		if (component.secondaryChecked) {
			const secondDataBinding = component.secondaryChecked
			secondaryCheckedData = nextProps.appController.getDataFromDataBinding({
				contextData,
				dataBinding: secondDataBinding,
			})
			const nodeName = secondDataBinding.edgeDataBinding
				? secondDataBinding.edgeDataBinding.nodeName
				: secondDataBinding.nodeName
			secondaryCheckedValue = secondaryCheckedData && secondaryCheckedData[nodeName]
		}

		if (!isNil(component.highlighted))
			highlighted = nextProps.appController.getDataFromDataValue(component.highlighted, contextData)

		if (!isNil(component.disabled))
			disabled = !!nextProps.appController.getDataFromDataValue(component.disabled, contextData)

		if (!isNil(component.imageValue))
			imageValue = nextProps.appController.getDataFromDataValue(component.imageValue, contextData)

		const isButton =
			!!nextProps.component.onClick ||
			!!nextProps.component.selectOnClick ||
			nextProps.component.listSelectType === e_ListSelectType.SINGLE_SELECT

		return {
			...prevState,
			primaryText,
			secondaryText,
			thirdText,
			metaText,

			checkedValue,
			checkedData,
			secondaryCheckedData,
			secondaryCheckedValue,

			icon: component.icon,
			iconValue,
			iconColor,
			avatarBackgroundColor,
			imageValue,
			badgeValue,
			highlighted,
			isButton,
			visible,
			disabled,
			isLastItem: nextProps.isLastItem,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.checkedValue !== this.state.checkedValue) return true
		if (nextState.checkedData !== this.state.checkedData) return true
		if (nextState.secondaryCheckedValue !== this.state.secondaryCheckedValue) return true
		if (nextState.secondaryCheckedData !== this.state.secondaryCheckedData) return true
		if (nextState.primaryText !== this.state.primaryText) return true
		if (nextState.secondaryText !== this.state.secondaryText) return true
		if (nextState.thirdText !== this.state.thirdText) return true
		if (nextState.metaText !== this.state.metaText) return true
		if (nextState.icon !== this.state.icon) return true
		if (nextState.iconValue !== this.state.iconValue) return true
		if (nextState.iconColor !== this.state.iconColor) return true
		if (nextState.avatarBackgroundColor !== this.state.avatarBackgroundColor) return true
		if (nextState.imageValue !== this.state.imageValue) return true
		if (nextState.badgeValue !== this.state.badgeValue) return true
		if (nextState.highlighted !== this.state.highlighted) return true
		if (nextState.visible !== this.state.visible) return true
		if (nextState.disabled !== this.state.disabled) return true
		if (nextState.isLastItem !== this.state.isLastItem) return true
		if (nextProps.width !== this.props.width) return true

		return false
	}

	onCheckedChange(event, checked) {
		const props = this.props
		if (props.component.customSelectValue) {
			props.appController.modifySingleValue(
				props.component.checked,
				this.state.checkedData,
				checked,
				this.props.contextData
			)
		} else if (props.component.listSelectType === e_ListSelectType.SINGLE_SELECT) {
			// Ikke mulig å modellere single select med checkbox
			const ownData = this.props.contextData[props.dataSourceId][0]
			props.appController.setSingleObjectSelected(props.dataSourceId, ownData._id)
		} else if (props.component.value) {
			const ownData = props.contextData[props.dataSourceId][0]
			props.appController.toggleSingleObject(props.dataSourceId, ownData._id)
		}
	}

	onActionButtonClickHandler(event) {
		this.props.eventHandler(
			this.props.component.onActionClick,
			this.props.contextData,
			{ eventType: 'onClick' },
			event
		)
	}

	onListItemClickHandler() {
		const props = this.props
		const isSingleSelect = props.component.listSelectType === e_ListSelectType.SINGLE_SELECT
		const contextData = props.contextData
		const oldObject = this.state.checkedData
		const checkedValue = this.state.checkedValue

		if (props.component.selectOnClick || isSingleSelect) {
			if (props.component.customSelectValue) {
				props.appController.modifySingleValue(props.component.checked, oldObject, !checkedValue, contextData)
			} else if (isSingleSelect) {
				const ownData = contextData[props.dataSourceId][0]
				props.appController.setSingleObjectSelected(props.dataSourceId, ownData._id)
			} else if (props.component.value) {
				const ownData = contextData[props.dataSourceId][0]
				props.appController.toggleSingleObject(props.dataSourceId, ownData._id)
			}
		}

		if (props.component.onClick) {
			this.props.eventHandler(this.props.component.onClick, contextData, { eventType: 'onClick' }, event)
		}
	}

	onSecondaryCheckedChange(event, checked) {
		this.props.appController.modifySingleValue(
			this.props.component.secondaryChecked,
			this.state.secondaryCheckedData,
			checked
		)
	}

	render() {
		const { component, disableGutters, enableDivider, insetDivider, dense, width, classes } = this.props

		const {
			primaryText,
			secondaryText,
			thirdText,
			metaText,

			checkedValue,
			secondaryCheckedValue,

			icon,
			iconValue,
			iconColor,
			avatarBackgroundColor,
			imageValue,
			badgeValue,
			highlighted,
			isButton,
			visible,
			disabled,
			isLastItem,
		} = this.state
		if (!visible) return null

		const showDesktopList =
			component.listType === e_ListType.DESKTOP ||
			(component.listType === e_ListType.RESPONSIVE && isWidthUp('md', width))

		const iconStyle = iconColor ? { color: iconColor } : undefined

		let listItemVisual

		if (iconValue || icon) listItemVisual = <Icon className={iconValue || icon} style={iconStyle} />

		let embeddedToggle

		if (component.listSelectType === e_ListSelectType.MULTI_SELECT) {
			embeddedToggle = (
				<Checkbox
					classes={{ root: classes.embeddedToggle, checked: classes.checked }}
					checked={!!checkedValue}
					onChange={component.selectOnClick ? undefined : this.onCheckedChange}
					tabIndex={-1}
					disableRipple
					color="primary"
					disabled={disabled}
				/>
			)
		} else if (component.listSelectType === e_ListSelectType.SINGLE_SELECT && component.showRadioButton) {
			embeddedToggle = (
				<Radio
					classes={{ root: classes.embeddedToggle, checked: classes.checked }}
					checked={!!checkedValue}
					onChange={component.selectOnClick ? undefined : this.onCheckedChange}
					tabIndex={-1}
					disableRipple
					color="primary"
					disabled={disabled}
				/>
			)
		}

		let largeThumbnail
		switch (component.visualType) {
			case e_ListItemVisualType.NONE:
				if (component.listSelectType === e_ListSelectType.MULTI_SELECT) {
					listItemVisual = (
						<Checkbox
							checked={!!checkedValue}
							onChange={component.selectOnClick ? undefined : this.onCheckedChange}
							tabIndex={-1}
							disableRipple
							color="primary"
							disabled={disabled}
							classes={{ root: classes.toggleControlLeft }}
						/>
					)
				} else if (component.listSelectType === e_ListSelectType.SINGLE_SELECT && component.showRadioButton) {
					listItemVisual = (
						<Radio
							checked={!!checkedValue}
							onChange={component.selectOnClick ? undefined : this.onCheckedChange}
							tabIndex={-1}
							disableRipple
							color="primary"
							disabled={disabled}
							classes={{ root: classes.toggleControlLeft }}
						/>
					)
				}
				break

			case e_ListItemVisualType.AVATAR: {
				let avatarStyle
				if (avatarBackgroundColor) avatarStyle = { backgroundColor: avatarBackgroundColor }

				listItemVisual = (
					<Avatar src={imageValue} style={avatarStyle}>
						{ !imageValue && listItemVisual }
					</Avatar>
				)

				if (component.badge && badgeValue)
					listItemVisual = (
						<Badge badgeContent={badgeValue} color={component.badgeColor} overlap="circular">
							{ listItemVisual }
						</Badge>
					)

				if (embeddedToggle) {
					listItemVisual = (
						<div className={classes.avatarToggleContainer}>
							{ !checkedValue && listItemVisual }
							{ embeddedToggle }
						</div>
					)
				}

				listItemVisual = <ListItemAvatar>{ listItemVisual }</ListItemAvatar>

				break
			}

			case e_ListItemVisualType.ICON:
				if (component.badge && badgeValue)
					listItemVisual = (
						<Badge badgeContent={badgeValue} color={component.badgeColor}>
							{ listItemVisual || <div className={classes.iconPlaceholder} /> }
						</Badge>
					)

				listItemVisual = (
					<ListItemIcon>{ listItemVisual || <span className={classes.iconPlaceholder} /> }</ListItemIcon>
				)

				if (embeddedToggle) {
					listItemVisual = (
						<div className={classes.iconToggleContainer}>
							{ !checkedValue && listItemVisual }
							{ embeddedToggle }
						</div>
					)
				}

				break

			case e_ListItemVisualType.THUMBNAIL: {
				largeThumbnail = component.thumbnailVariant === e_ListThumbnailVariant.LARGE
				const className = classNames(classes.thumbnail, {
					[classes.largeThumbnail]: largeThumbnail,
					[classes.threeLinesThumbnail]: component.threeLines,
					[classes.objectFitContain]: component.imageFit === e_ThumbnailImageFit.CONTAIN,
					[classes.objectFitFill]: component.imageFit === e_ThumbnailImageFit.FILL,
				})
				listItemVisual = imageValue ? (
					<img src={imageValue} className={className} />
				) : (
					<div className={className} />
				)

				if (embeddedToggle) {
					listItemVisual = (
						<div className={classes.imageToggleContainer}>
							{ !checkedValue && listItemVisual }
							{ embeddedToggle }
						</div>
					)
				}
				break
			}
		}

		let secondaryAction
		switch (component.rightActionType) {
			case e_ListItemRightActionType.NONE:
				break
			case e_ListItemRightActionType.ACTION_BUTTON:
				secondaryAction = (
					<IconButton edge="end" onClick={this.onActionButtonClickHandler} disabled={disabled}>
						<Icon className={component.actionIcon} />
					</IconButton>
				)
				break
			case e_ListItemRightActionType.CHECKBOX:
				secondaryAction = (
					<Checkbox
						checked={!!secondaryCheckedValue}
						onChange={this.onSecondaryCheckedChange}
						disableRipple
						color="primary"
						disabled={disabled}
						edge="end"
					/>
				)
				break
			case e_ListItemRightActionType.SWITCH:
				secondaryAction = (
					<Switch
						checked={!!secondaryCheckedValue}
						onChange={this.onSecondaryCheckedChange}
						disabled={disabled}
						edge="end"
					/>
				)
				break
			case e_ListItemRightActionType.TOGGLE_BUTTON:
				secondaryAction = (
					<Checkbox
						checked={!!secondaryCheckedValue}
						onChange={this.onSecondaryCheckedChange}
						disableRipple
						color="primary"
						disabled={disabled}
						icon={<Icon className={component.toggleOffIcon} />}
						checkedIcon={<Icon className={component.toggleOnIcon} />}
						edge="end"
					/>
				)
				break
		}

		const metaTextContent = !isNil(component.metaText) ? (
			<Typography
				variant="caption"
				color="textSecondary"
				className={classNames(classes.metaText, { [classes.dense]: component.dense })}
			>
				{ metaText }
			</Typography>
		) : undefined

		const isSelected = (component.highlight && !!checkedValue) || highlighted

		let dividerClassName
		if (!isSelected && listItemVisual && (insetDivider || component.insetDivider)) {
			dividerClassName = largeThumbnail ? classes.dividerLargeInset : classes.dividerInset

			if (disableGutters) dividerClassName = classNames(dividerClassName, classes.disableGutters)
		}

		const secondaryActionComponent =
			metaTextContent || secondaryAction ? (
				<ListItemSecondaryAction
					className={component.threeLines || metaTextContent ? classes.extendedActionContainer : undefined}
				>
					{ metaTextContent }
					{ secondaryAction }
				</ListItemSecondaryAction>
			) : undefined

		const thirdTextIsDefined = !isNil(component.thirdText)

		const desktopListContent =
			thirdTextIsDefined && showDesktopList ? (
				<Typography
					className={classNames(classes.desktopThirdText, {
						[classes.dense]: component.dense,
					})}
					variant="subtitle1"
					color="textPrimary"
				>
					{ thirdText }
				</Typography>
			) : undefined

		const secondaryTextComponent =
			thirdTextIsDefined && !showDesktopList ? (
				<>
					<Typography variant="body2" component="span" className={classes.inline} color="textPrimary">
						{ thirdText }
					</Typography>
					{ ' — ' + (secondaryText || '') }
				</>
			) : (
				secondaryText
			)

		const alignItems = component.threeLines || showDesktopList ? 'flex-start' : undefined
		return (
			<>
				<ListItem
					button={isButton}
					disabled={disabled}
					onClick={this.onListItemClickHandler}
					dense={dense || component.dense}
					disableGutters={disableGutters}
					selected={isSelected}
					alignItems={alignItems}
					classes={{ root: classes.listItem, dense: classes.dense }}
					className={classNames({
						[classes.metaTextPadding]: !isNil(metaText),
					})}
				>
					{ listItemVisual }
					{ desktopListContent }
					<ListItemText
						classes={{
							primary: classes.noWrap,
							secondary: component.threeLines ? classes.secondaryTextThreeLine : classes.noWrap,
						}}
						primary={primaryText}
						secondary={secondaryTextComponent}
						inset={component.inset}
					/>
					{ secondaryActionComponent }
				</ListItem>
				{ (enableDivider || component.divider) && !isLastItem && (
					<Divider component="li" className={dividerClassName} />
				) }
			</>
		)
	}
}

UiListItem.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		getDataFromDataBinding: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
		toggleSingleObject: PropTypes.func.isRequired,
		setSingleObjectSelected: PropTypes.func.isRequired,
	}).isRequired,
	enableDivider: PropTypes.bool,
	insetDivider: PropTypes.bool,
	dense: PropTypes.bool,
	dataSourceId: PropTypes.string.isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.object.isRequired,
	eventHandler: PropTypes.func.isRequired,
	disableGutters: PropTypes.bool,
	isLastItem: PropTypes.bool,

	width: PropTypes.string.isRequired,
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withWidth()(withTheme(withStyles(styles)(UiListItem)))
