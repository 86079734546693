import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'

import { motion } from 'framer-motion'

const styles = {
	root: {
		display: 'block',
		height: 48,
		width: 48,
		padding: 0,
	},
	lineBreak: { whiteSpace: 'pre-line' },
}

class UiIconButton extends Component {
	constructor(props) {
		super(props)

		this.state = {}
		this.onClickHandler = this.onClickHandler.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		const { component, contextData, styleProp, style } = nextProps

		let badgeValue

		if (component.badge && component.badgeValue)
			badgeValue = nextProps.appController.getDataFromDataValue(component.badgeValue, contextData, {
				getDisplayValue: true,
			})

		let iconValue
		if (component.iconValue)
			iconValue = nextProps.appController.getDataFromDataValue(component.iconValue, contextData, {
				getIcon: true,
			})

		let buttonStyle = {
			...styleProp,
			...style,
		}

		if (component.buttonSize || component.iconSize)
			buttonStyle = {
				...buttonStyle,
				height: component.buttonSize,
				width: component.buttonSize,
				fontSize: component.iconSize,
			}

		return {
			badgeValue,
			iconValue,
			buttonStyle,
		}
	}

	onClickHandler(event) {
		this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	render() {
		const {
			component,
			disabled,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			conditionalClassNames,
			classes,
		} = this.props
		const { badgeValue, iconValue, buttonStyle } = this.state

		let icon = (
			<Icon className={iconValue || component.icon} fontSize={component.iconSize ? 'inherit' : undefined} />
		)

		// Implicit hide if 0
		if (component.badge && badgeValue) {
			icon = (
				<Badge badgeContent={badgeValue} color={component.badgeColor}>
					{ icon }
				</Badge>
			)
		}

		const button = (
			<IconButton
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={buttonStyle}
				color={component.buttonColor}
				onClick={this.onClickHandler}
				disabled={disabled}
				tabIndex={component.tabIndex}
				component={this.props.useMotion ? motion.button : undefined}
				{...this.props.motionProps}
			>
				{ icon }
			</IconButton>
		)

		if (tooltipTitle)
			return (
				<Tooltip
					title={tooltipTitle}
					interactive={!tooltipDisableInteractive}
					classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
				>
					{ button }
				</Tooltip>
			)

		return button
	}
}

UiIconButton.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		badge: PropTypes.bool,
		badgeColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']).isRequired,
		badgeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
		buttonSize: PropTypes.number,
		buttonColor: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'default']).isRequired,
		icon: PropTypes.string.isRequired,
		iconValue: PropTypes.object,
		iconSize: PropTypes.number,
		tabIndex: PropTypes.number,
		onClick: PropTypes.object,
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	disabled: PropTypes.bool.isRequired,
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	style: PropTypes.object,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiIconButton)
