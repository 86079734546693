/******************************************************************************
 *
 * This will load all app-metadata
 *
 *****************************************************************************/
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getActiveAppId, isInaccessableApp } from '#selectors/metadataSelectors'

import WebPushManager from './WebPushManager'
import MetadataLoader from './MetadataLoader'
import ChecksumLoader from './ChecksumLoader'
import ImageServiceTokenLoader from './ImageServiceTokenLoader'
import AnalysisLogTransporter from './AnalysisLogTransporter'

import { setOnAppLoadExecutedValue } from '#actions/appStateActions'
import appController from '../../../controllers/appControllerInstance'
import { resetStateForAppChange } from '#actions/metadataActions'

// TODO:
// check state.authState.isLoggedIn
// check: isInaccessableApp(state)

const AppLoader = () => {
	const activeAppId = useSelector(getActiveAppId)
	const isInaccessable = useSelector(isInaccessableApp)
	const dispatch = useDispatch()

	useEffect(() => {
		console.log('AppLoader - change in activeAppId', activeAppId)

		// Reset state of app
		dispatch(resetStateForAppChange())
		dispatch(setOnAppLoadExecutedValue(false))

		// Tell AppController about the app
		appController.setActiveApp(activeAppId)
	}, [activeAppId])

	if (!activeAppId) return null
	if (isInaccessable) return null

	return (
		<>
			<WebPushManager />
			<ChecksumLoader activeAppId={activeAppId} />
			<MetadataLoader activeAppId={activeAppId} />
			<ImageServiceTokenLoader activeAppId={activeAppId} />
			<AnalysisLogTransporter />
		</>
	)
}

export default AppLoader
