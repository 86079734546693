/******************************************************************************
 *
 * This will refresh all the checksums for global resources
 * not tied to any single app.
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setLatestGlobalChecksums } from '#actions/metadataActions'

const GlobalResourceChecksumsLoader = () => {
	const [blocker, setBlocker] = useState(false)
	const checksumsReady = useSelector((state) => state.resourceReadyState.globalChecksums)

	const dispatch = useDispatch()

	useEffect(() => {
		if (checksumsReady) return
		if (blocker) return

		const controller = new AbortController()

		axios
			.get('/api/v1/checksums', {
				signal: controller.signal,
			})
			.then((result) => {
				// The result is no longer relevant - just return
				if (controller.signal?.aborted) return

				dispatch(setLatestGlobalChecksums(result.data))
			})
			.catch((err) => {
				if (axios.isCancel(err)) return

				// Debounce - dont retry before x seconds
				// TODO: Construct a generic debouncer
				setBlocker(true)
				setTimeout(() => setBlocker(false), 5000)
				console.log('Failed to get global checksums for some reason', err)
			})

		// Abort request as cleanup
		return () => controller.abort()
	}, [checksumsReady, blocker])

	return null
}

export default GlobalResourceChecksumsLoader
