import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const LinkStyleButton = withStyles({
	root: {
		padding: '2px 6px',
		fontSize: 'inherit',
		fontWeight: 'inherit',
		whiteSpace: 'nowrap',
	},
	label: {
		textTransform: 'none',
	},
})(Button)

export default LinkStyleButton
