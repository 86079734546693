import copy from 'copy-to-clipboard'

const p_copyToClipboard = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		const value = appController.getDataFromDataValue(actionNode.text, contextData)
		actionNodeLogger.debug('Copied value: ' + value)
		copy(value)
		resolve()
	})

export default p_copyToClipboard
