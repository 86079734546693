import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isPlainObject from 'lodash/isPlainObject'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { e_MarginType, e_SelectionColor } from '@appfarm/common/enums/e_PropertyTypes'

const styles = {
	root: {
		display: 'block',
		width: 'fit-content',
		height: 'fit-content',
	},
}

class UiSwitch extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onValueChange = this.onValueChange.bind(this)
		this.onSideEffectAction = this.onSideEffectAction.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		let label
		if (nextProps.component.label)
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})

		const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
		const nodeName = dataBinding?.nodeName

		return {
			label,
			nodeName,
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.contextData !== this.props.contextData) return true
		if (nextProps.ownData !== this.props.ownData) return true
		if (nextProps.disabled !== this.props.disabled) return true
		if (nextProps.conditionalClassNames !== this.props.conditionalClassNames) return true
		if (nextState.label !== this.state.label) return true
		return false
	}

	onValueChange(event, checked) {
		event.persist() // needed for react 16 and earlier: https://reactjs.org/docs/legacy-event-pooling.html
		if (this.props.readOnly) return
		const previousValue = this.props.ownData?.[this.state.nodeName]

		const onValueChangeEvent = this.props.component.onValueChange
			? () =>
				this.props.eventHandler(
					this.props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: { previousValue: previousValue },
					},
					event
				)
			: undefined

		this.props.appController.modifySingleValue(
			this.props.component.value,
			this.props.ownData,
			checked,
			{},
			onValueChangeEvent
		)
	}

	onSideEffectAction(actions) {
		if (!actions) return // actions may be null after a component unmounts
		if (this.props.component.autoFocus) actions.focusVisible()
	}

	render() {
		const { component, componentId, disabled, ownData, styleProp, conditionalClassNames, classes } =
			this.props
		const { label, nodeName } = this.state
		let ownValue = false

		const { marginType, autoFocus, tabIndex, switchColor, controlSize, labelPlacement } = component

		if (isPlainObject(ownData)) {
			ownValue = ownData[nodeName]
		}

		const switchComponent = (
			<Switch
				checked={!!ownValue}
				onChange={this.onValueChange}
				disabled={disabled}
				color={switchColor || e_SelectionColor.PRIMARY}
				autoFocus={autoFocus}
				action={autoFocus ? this.onSideEffectAction : undefined}
				tabIndex={tabIndex}
				size={controlSize}
			/>
		)

		return (
			<FormControl
				id={componentId}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				margin={marginType || e_MarginType.NORMAL}
			>
				{ label && (
					<FormControlLabel label={label} control={switchComponent} labelPlacement={labelPlacement} />
				) }
				{ !label && switchComponent }
			</FormControl>
		)
	}
}

UiSwitch.propTypes = {
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	appController: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	styleProp: PropTypes.object,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // But we need object to know what to display
	contextData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiSwitch)
