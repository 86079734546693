/* eslint-disable import/prefer-default-export */

import isNil from 'lodash/isNil'

export const sliceData = ({ skip, limit, data }) => {
	if (!data) {
		return data
	}

	if (!isNil(limit)) {
		return data.slice(skip, skip + limit)
	} else {
		return data.slice(skip)
	}
}
