import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import { requestFileUpload } from '#modules/afClientApi'

import p_runFileUpload from '../uploadFile/p_runFileUpload'

const p_placeFileInDataSource = async ({
	dataSource,
	actionNode,
	actionNodeRunner,
	actionNodeLogger,
	file,
	fileName,
	mimeType,
	contextData,
}) => {
	if (dataSource.cardinality === e_Cardinality.ONE && dataSource.getAllObjects().length) {
		return new Error('Cannot place file into datasource with cardinality one - object already exist')
	}
	const rootActionId = actionNodeRunner.getRootAction().id
	let fileObject
	if (dataSource.local) {
		fileObject = dataSource.generateNewObject(actionNode.defaultValues, contextData)

		fileObject = {
			...fileObject,
			__file: file,
			__actionNodeId: actionNode.id, // Need this for upload
			__actionId: rootActionId, // Need this for upload
			originalFileName: fileName,
			__mimeType: mimeType,
			__fileSize: file.size,
			__uploadComplete: false,
			__uploadProgress: 0,
		}

		fileObject.__fileContentLink = URL.createObjectURL(file)
		await dataSource.p_insertFileObject(fileObject)
		actionNodeLogger.debug('File Object: ')
		actionNodeLogger.table(fileObject, null, { dataSourceId: dataSource.id })
	} else {
		const defaultValues = dataSource.generateShellObject(actionNode.defaultValues, contextData)

		fileObject = await requestFileUpload({
			actionId: rootActionId,
			actionNodeId: actionNode.id,
			data: defaultValues,
		}).then((uploadPermissionResponse) =>
			p_runFileUpload({
				fileOrBlob: file,
				uploadPermissionResponse,
				fileName,
				defaultValues,
				dataSource: dataSource,
				logger: actionNodeLogger,
			})
		)
	}

	if (actionNode.setSelected) {
		await dataSource.p_setExactlyOneObjectSelected(fileObject._id)
	}
	return fileObject
}

export default p_placeFileInDataSource
