import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isPlainObject from 'lodash/isPlainObject'
import dayjs from '#controllers/dayjs'

import { t } from '@lingui/macro'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Icon from '@material-ui/core/Icon'

import { KeyboardTimePicker, KeyboardDatePicker, KeyboardDateTimePicker, Day } from '@material-ui/pickers'

import { e_DatePickerType, e_MarginType } from '@appfarm/common/enums/e_PropertyTypes'

const styles = (theme) => ({
	root: {
		display: 'block',
	},
	inputRoot: {
		width: '100%',
	},
	icon: {
		display: 'block', // SLA 04.07.2018 - Need this to position the icon correct inside the button
	},
	weekNumber: {
		position: 'absolute',
		fontSize: '0.66em',
		color: theme.palette.text.disabled,
		left: 3,
		lineHeight: '36px',
		textAlign: 'center',
		borderRight: `1px solid ${theme.palette.divider}`,
		paddingRight: 2,
		width: 15,
	},
})

class UiDatePicker extends Component {
	constructor(props) {
		super(props)

		this.state = {}

		this.onChange = this.onChange.bind(this)
		this.renderDay = this.renderDay.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		let value
		const ownData = nextProps.ownData
		const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
		const nodeName = dataBinding?.nodeName

		if (isPlainObject(ownData) && nodeName) value = ownData[nodeName]

		if (!value) value = null

		let dateFormat
		switch (nextProps.component.datePickerType) {
			case e_DatePickerType.TIME:
				dateFormat = 't'
				break
			case e_DatePickerType.DATETIME:
				dateFormat = 'D t'
				break
			case e_DatePickerType.DATE:
			default:
				dateFormat = 'D'
		}

		let helperText
		if (nextProps.component.helperText)
			helperText = nextProps.appController.getDataFromDataValue(
				nextProps.component.helperText,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		let minDate
		if (nextProps.component.minDate)
			minDate = nextProps.appController.getDataFromDataValue(
				nextProps.component.minDate,
				nextProps.contextData
			)
		if (!minDate) minDate = undefined // undefined set minDate back to pickers own default minDate. Null crashes picker

		let maxDate
		if (nextProps.component.maxDate)
			maxDate = nextProps.appController.getDataFromDataValue(
				nextProps.component.maxDate,
				nextProps.contextData
			)
		if (!maxDate) maxDate = undefined // undefined set minDate back to pickers own default minDate. Null crashes picker

		let label
		if (nextProps.component.label) {
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})
		}

		let placeholder
		if (nextProps.component.placeholder) {
			placeholder = nextProps.appController.getDataFromDataValue(
				nextProps.component.placeholder,
				nextProps.contextData,
				{
					getDisplayValue: true,
				}
			)
		}

		return {
			value,
			nodeName,
			dateFormat,
			helperText,
			minDate,
			maxDate,
			label,
			placeholder,
		}
	}

	onChange(luxonDate) {
		const previousValue = this.props.ownData?.[this.state.nodeName]

		let newValue
		if (luxonDate && luxonDate.isValid) {
			const date = dayjs(luxonDate.toJSON()) // make luxon date a dayjs date
			let dayjsValue = this.state.value ? dayjs(this.state.value) : dayjs()
			switch (this.props.component.datePickerType) {
				case e_DatePickerType.TIME:
					dayjsValue = dayjsValue
						.set('hour', date.hour())
						.set('minute', date.minute())
						.set('second', date.second())
						.set('millisecond', date.millisecond())

					break
				case e_DatePickerType.DATETIME:
					dayjsValue = date
					break
				default: // e_DatePickerType.DATE:
					dayjsValue = dayjsValue.set('year', date.year()).set('month', date.month()).set('date', date.date())
			}
			newValue = dayjsValue.toJSON()
		}

		if (!this.props.component.value) return

		const onValueChangeEvent = this.props.component.onValueChange
			? () =>
				this.props.eventHandler(
					this.props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: { previousValue: previousValue },
					},
					null
				)
			: undefined

		this.props.appController.modifySingleValue(
			this.props.component.value,
			this.props.ownData,
			newValue,
			{},
			onValueChangeEvent
		)
	}

	renderDay(luxonDate, luxonSelectedDate, dayInCurrentMonth, ReactNode) {
		const disabled = !!ReactNode?.props?.disabled
		const date = dayjs(luxonDate.toJSON())
		const selectedDate = dayjs(luxonSelectedDate.toJSON())
		return (
			<>
				{ date.day() === 1 && <div className={this.props.classes.weekNumber}>{ date.isoWeek() }</div> }
				<Day
					current={date.isSame(dayjs(), 'day')}
					hidden={!dayInCurrentMonth}
					selected={date.isSame(selectedDate, 'day')}
					disabled={disabled}
				>
					{ date.date() }
				</Day>
			</>
		)
	}

	render() {
		const { component, disabled, readOnly, error, styleProp, conditionalClassNames, classes } = this.props

		const { value, dateFormat, helperText, minDate, maxDate, label, placeholder } = this.state
		const InputProps = {
			className: classes.inputRoot,
			inputProps: {
				readOnly,
				disabled,
				tabIndex: component.tabIndex,
			},
		}

		if (component.disableUnderline) InputProps.disableUnderline = true

		let KeyboardButtonProps
		if (component.tabIndex > 0)
			KeyboardButtonProps = {
				tabIndex: component.tabIndex + 1,
			}

		const commonProps = {
			value: value,
			className: classNames(classes.root, 'c' + component.id, conditionalClassNames),
			style: styleProp,
			format: dateFormat,
			label: label,
			placeholder: placeholder,
			helperText: helperText,
			margin: component.marginType || e_MarginType.NORMAL,
			autoFocus: component.autoFocus,
			onChange: this.onChange,
			disabled: disabled,
			readOnly: readOnly,
			clearable: true,
			error: error,
			inputVariant: component.variant,
			variant: component.inline ? 'inline' : 'dialog',
			autoOk: !!component.inline,
			InputProps,
			KeyboardButtonProps,
			renderDay: component.showWeekNumbers ? this.renderDay : undefined,
		}

		if (!component.inline) {
			commonProps.cancelLabel = t`Cancel`
			commonProps.clearLabel = t`Clear`
			commonProps.okLabel = t`OK`
		}

		if (component.inline && component.onlyCalendar) commonProps.disableToolbar = true

		if (component.datePickerType === e_DatePickerType.TIME) {
			return (
				<KeyboardTimePicker
					keyboardIcon={<Icon className={classNames('mdi mdi-clock', classes.icon)} />}
					ampm={false}
					{...commonProps}
				/>
			)
		} else if (component.datePickerType === e_DatePickerType.DATETIME) {
			return (
				<KeyboardDateTimePicker
					minDate={minDate}
					maxDate={maxDate}
					keyboardIcon={<Icon className={classNames('mdi mdi-calendar-clock', classes.icon)} />}
					ampm={false}
					leftArrowIcon={<Icon className={classNames('mdi mdi-chevron-left', classes.icon)} />}
					rightArrowIcon={<Icon className={classNames('mdi mdi-chevron-right', classes.icon)} />}
					dateRangeIcon={<Icon className={classNames('mdi mdi-calendar', classes.icon)} />}
					timeIcon={<Icon className={classNames('mdi mdi-clock', classes.icon)} />}
					{...commonProps}
				/>
			)
		}
		return (
			<KeyboardDatePicker
				keyboardIcon={<Icon className={classNames('mdi mdi-calendar', classes.icon)} />}
				leftArrowIcon={<Icon className={classNames('mdi mdi-chevron-left', classes.icon)} />}
				rightArrowIcon={<Icon className={classNames('mdi mdi-chevron-right', classes.icon)} />}
				minDate={minDate}
				maxDate={maxDate}
				{...commonProps}
			/>
		)
	}
}

UiDatePicker.propTypes = {
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	component: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	error: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // But we need object to know what to display
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiDatePicker)
