import NoSleep from '#utils/noSleep'
import { DISABLE, TOGGLE } from '@appfarm/common/enums/e_EnableDisableMode'
const noSleep = new NoSleep()
let noSleepEnabled = false

const p_preventDeviceSleep = ({ actionNode, actionNodeLogger }) => {
	switch (actionNode.toggleMode) {
		case DISABLE:
			actionNodeLogger.debug('Disable device sleep prevention')
			noSleepEnabled = false
			noSleep.disable()
			break

		case TOGGLE:
			noSleepEnabled = !noSleepEnabled
			actionNodeLogger.debug(`Toggle device sleep prevention: ${noSleepEnabled}`)
			if (noSleepEnabled) {
				noSleep.enable()
			} else {
				noSleep.disable()
			}
			break

		default:
			// ENABLE
			actionNodeLogger.debug('Enable device sleep prevention')
			noSleepEnabled = true
			noSleep.enable()
	}

	return Promise.resolve()
}

export default p_preventDeviceSleep
