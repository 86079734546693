import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'
import e_AppfarmEnvironment from '@appfarm/common/enums/e_AppfarmEnvironment'
import { e_ViewType } from '@appfarm/common/enums/e_PropertyTypes'
import { getHotReloadDisabledFromLocalStorage } from '../components/DevTools/utils/localStorageGetters'

/******************************************************************************
 *
 * Environment Selectors
 *
 *****************************************************************************/

const CURRENT_ENVIRONMENT = window.AF_PARAMS.afEnvironment
export const isInDevelopMode = () => CURRENT_ENVIRONMENT === e_AppfarmEnvironment.DEVELOP
export const isOnDevelopData = () =>
	CURRENT_ENVIRONMENT === e_AppfarmEnvironment.DEVELOP || CURRENT_ENVIRONMENT === e_AppfarmEnvironment.TEST

/******************************************************************************
 *
 * App Level Selectors
 *
 *****************************************************************************/

const getAppList = (state) => state.metaData.allApps

export const getActiveAppReadableId = (state) => state.appState.activeAppReadableId
export const getLoadedApp = (state) => state.metaData.app

export const getCurrentAppEntryFromAppList = createSelector(
	[getActiveAppReadableId, getAppList],
	(activeAppReadableId, appList) => {
		if (!activeAppReadableId) return null

		// try map on unique Id
		const activeAppEntryById = appList.find((appEntry) => appEntry.id === activeAppReadableId) // Find by ID first
		if (activeAppEntryById) return activeAppEntryById

		// map on possible none-unique id
		const activeAppEntries = appList.filter((appEntry) => appEntry.readableId === activeAppReadableId)
		if (activeAppEntries.length === 0) {
			return null
		} else if (activeAppEntries.length === 1) {
			return activeAppEntries[0]
		} else {
			const activeValidAppEntries = activeAppEntries.filter((appEntry) => !appEntry.error)
			if (activeValidAppEntries.length) return activeValidAppEntries[0]

			const activeAppEntriesWithUnsupportedDevice = activeAppEntries.filter(
				(appEntry) => appEntry.error?.type === 'UnsupportedDevice'
			)
			if (activeAppEntriesWithUnsupportedDevice.length) return activeAppEntriesWithUnsupportedDevice[0]

			return activeAppEntries[0]
		}
	}
)

export const getActiveAppId = createSelector([getCurrentAppEntryFromAppList], (appEntry) => {
	return appEntry && appEntry.id
})

export const hasActiveAppId = createSelector([getActiveAppReadableId], (activeAppId) => !!activeAppId)

export const isAppNotFoundError = createSelector(
	[hasActiveAppId, (state) => state.resourceReadyState.appList, getActiveAppId],
	(hasActiveAppId, appListLoaded, activeAppId) => hasActiveAppId && appListLoaded && !activeAppId
)
export const isNoAccessError = createSelector(
	[getCurrentAppEntryFromAppList],
	(appEntry) => appEntry?.error?.type === 'NoAccess'
)
export const isUnsupportedDeviceError = createSelector(
	[getCurrentAppEntryFromAppList],
	(appEntry) => appEntry?.error?.type === 'UnsupportedDevice'
)

export const isInaccessableApp = createSelector(
	[isAppNotFoundError, isNoAccessError, isUnsupportedDeviceError],
	(isAppNotFoundError, isNoAccessError, isUnsupportedDeviceError) =>
		isAppNotFoundError || isNoAccessError || isUnsupportedDeviceError
)

export const isCurrentAppLoaded = createSelector(
	[getLoadedApp, getCurrentAppEntryFromAppList],
	(loadedApp, currentAppEntry) => {
		if (!loadedApp.id) return false
		if (!currentAppEntry) return false

		return loadedApp.id === currentAppEntry.id
	}
)

export const getActiveLanguageId = (state) => state.metaData.translations.activeLanguageId
export const getAppTranslation = (state) => state.metaData.translations.appTranslation
export const getEnumsTranslation = (state) => state.metaData.translations.enumsTranslation
export const getObjectClassTranslation = (state) => state.metaData.translations.ocTranslation
const getAppliedLayoutTranslation = (state) => state.metaData.translations.appliedLayoutTranslation
const getAppliedDataModelTranslation = (state) => state.metaData.translations.appliedDataModelTranslation

/**
 * True if an app is active - false if user is in applist or login screen
 */
export const getAppIsActive = (state) => state.appState.appIsActive

/**
 * Deploy information
 */
export const getDeployData = (state) => ({
	activeAppName: state.metaData.app.name,
	deployComment: state.metaData.currentDeployment.comment,
	personalizedDeploy: state.metaData.currentDeployment.personalizedDeploy,
	deployedByName: state.metaData.currentDeployment.createdBy,
	deployedByImageUrl: state.metaData.currentDeployment.profileImage,

	appDeployData: state.metaData.app.deployNotifier,
})

/******************************************************************************
 *
 * Selectors for metadata ready management (loaded vs wanted)
 *
 *****************************************************************************/

const getLatestChecksums = (state) => state.metaData.latestChecksums
export const getWantedChecksums = (state) => state.metaData.wantedChecksums
const getLoadedChecksums = (state) => state.metaData.loadedChecksums

const getLoadedAppLayoutChecksum = (state) => state.metaData.loadedChecksums.layout
const getLoadedFunctionsChecksum = (state) => state.metaData.loadedChecksums.functions
const getLoadedEnumeratedTypesChecksum = (state) => state.metaData.loadedChecksums.enumeratedTypes

const getCurrentAppHasInitialData = (state) => state.appState.currentAppHasInitialData

export const isDataModelReady = createSelector(
	[(state) => state.metaData.wantedChecksums, (state) => state.metaData.loadedChecksums],
	(wantedCheksums, loadedChecksums) => {
		const { dataSources, objectClasses, enumeratedTypes } = loadedChecksums
		// Not loaded at all yet
		if (!dataSources || !objectClasses || !enumeratedTypes) return false

		if (dataSources !== wantedCheksums.dataSources) return false
		if (objectClasses !== wantedCheksums.objectClasses) return false
		if (enumeratedTypes !== wantedCheksums.enumeratedTypes) return false

		return true
	}
)

/**
 * React key on top level to trigger re-render
 */
export const getAppRenderKey = createSelector(
	[
		getLoadedAppLayoutChecksum,
		getLoadedFunctionsChecksum,
		getLoadedEnumeratedTypesChecksum,
		getAppliedLayoutTranslation,
		getAppliedDataModelTranslation,
		(state, appTimeZone) => appTimeZone,
	],
	(
		appChecksum,
		functionsChecksum,
		enumTypesChecksum,
		appliedLayoutTranslation,
		appliedDataModelTranslation,
		appTimeZone
	) => {
		return (
			appChecksum +
			functionsChecksum +
			enumTypesChecksum +
			appliedLayoutTranslation +
			appliedDataModelTranslation +
			appTimeZone
		)
	}
)

export const getLoadedChecksumsHasValue = createSelector(
	[getLoadedChecksums],
	(loadedChecksums) => !Object.values(loadedChecksums).some((value) => !value)
)

export const getIsAppDescriptionReady = createSelector(
	[getWantedChecksums, getLoadedChecksums],
	(wantedChecksums, loadedChecksums) => {
		if (!wantedChecksums.app) return false
		return wantedChecksums.app === loadedChecksums.app
	}
)

const areActionsReady = createSelector(
	[getWantedChecksums, getLoadedChecksums],
	(wantedChecksums, loadedChecksums) => {
		if (!wantedChecksums.actions) return false
		return wantedChecksums.actions === loadedChecksums.actions
	}
)

const areFunctionsReady = createSelector(
	[getWantedChecksums, getLoadedChecksums],
	(wantedChecksums, loadedChecksums) => {
		if (!wantedChecksums.functions) return false
		return wantedChecksums.functions === loadedChecksums.functions
	}
)

export const getIsAppReadyToRunOnAppLoadAction = createSelector(
	[
		getIsAppDescriptionReady,
		isDataModelReady,
		areActionsReady,
		areFunctionsReady,
		getCurrentAppHasInitialData,
	],
	(appDescriptionReady, dataModelReady, actionsReady, functionsReady, initialDataReady) => {
		return appDescriptionReady && dataModelReady && actionsReady && functionsReady && initialDataReady
	}
)

// TODO: Optimize. Want to load as little as possible,
// if On App Load is defined we wait until this is executed before view render
// On App Load will run after initial data is ready
// else we render view as long as metadata is ready
export const getIsAppReadyForRender = createSelector(
	[
		(state) => !!state.metaData.loadedChecksums.layout,
		(state) => !!state.metaData.loadedChecksums.dataSources,
		(state) => !!state.metaData.loadedChecksums.actions,
		(state) => !!state.metaData.loadedChecksums.objectClasses,
		(state) => !!state.metaData.loadedChecksums.enumeratedTypes,
		(state) => !!state.metaData.loadedChecksums.functions,
		(state) => state.appState.onAppLoadExecuted,
		(state) => state.metaData.app,
	],
	(
		hasLayout,
		hasDataSources,
		hasActions,
		hasObjectClasses,
		hasEnums,
		hasFunctions,
		onAppLoadExecuted,
		appDescription
	) => {
		if (!hasLayout || !hasDataSources || !hasActions || !hasObjectClasses || !hasEnums || !hasFunctions)
			return false

		if (!appDescription) return false

		if (appDescription.eventHandlers?.onAppLoad && !onAppLoadExecuted) return false

		return true
	}
)

// True when app is ready for interaction
export const isAppReady = createSelector(
	[getIsAppReadyForRender, (state) => state.appState.serverClientStateInSync],
	(renderReady, inSync) => {
		if (!renderReady) return false
		return !!inSync
	}
)

/******************************************************************************
 *
 * Selectors for detecting old metadata (checks latest vs loaded)
 *
 *****************************************************************************/

/**
 * Will check if at least one of the app layers
 * are outdated. Returns null if no apps are loaded.
 */
export const getIsCurrentAppOutdated = createSelector(
	[getLatestChecksums, getLoadedChecksums],
	(latestChecksums, loadedChecksums) =>
		Object.keys(latestChecksums).some((key) => latestChecksums[key] !== loadedChecksums[key])
)

export const getOutdatedChecksums = createSelector(
	[getLatestChecksums, getLoadedChecksums],
	(latestChecksums, loadedChecksums) =>
		Object.keys(latestChecksums).reduce((outdatedKeys, key) => {
			if (latestChecksums[key] !== loadedChecksums[key]) {
				outdatedKeys[key] = true
			}
			return outdatedKeys
		}, {})
)
/******************************************************************************
 *
 * Layout selectors
 *
 *****************************************************************************/

const getAllViews = (state) => state.metaData.views

export const getNormalViews = createSelector([getAllViews], (views) => {
	return views.filter((item) => item.viewType === e_ViewType.NORMAL)
})

export const getDialogs = createSelector([getAllViews], (views) => {
	return views.filter((item) => item.viewType === e_ViewType.DIALOG)
})

export const getMainDrawer = createSelector([getAllViews, getLoadedApp], (views, app) => {
	return views.find((item) => item.id === app.drawerId)
})

export const getAllDrawers = createSelector([getAllViews], (views) => {
	return views.filter((item) => item.viewType === e_ViewType.DRAWER)
})

export const getRegularDrawers = createSelector([getAllViews, getLoadedApp], (views, app) => {
	return views.filter((item) => item.viewType === e_ViewType.DRAWER && item.id !== app.drawerId)
})

export const getDefaultViewPath = createSelector([getLoadedApp, getNormalViews], (app, views) => {
	const defaultView = views.find((item) => item.id === app.defaultViewId)

	if (!defaultView) return null

	return defaultView.path || defaultView.id
})

export const getViewById = createSelector([getAllViews, (state, viewId) => viewId], (views, viewId) => {
	return views.find((item) => item.id === viewId)
})

/***************************************************************
 *
 * Developer tools
 *
 ***************************************************************/

export const getSortedActionList = createSelector([(state) => state.metaData.actions], (actionDict) => {
	if (!actionDict) return []
	const actionList = Object.values(actionDict)
	return orderBy(actionList, ['name'])
})

export const getDevToolsHotReloadDisabled = (state) => {
	const disabled = getHotReloadDisabledFromLocalStorage()
	if (disabled) return disabled

	return state.appState.devToolsHotReloadDisabled
}

export const getDevToolsHotReloadDisabledAndInDev = (state) => {
	if (!isInDevelopMode()) return false
	return getDevToolsHotReloadDisabled(state)
}

export const getDevToolsIncludeGeneratedData = (state) => {
	return state.appState.devToolsIncludeGeneratedData
}

export const getDevToolsEnabled = (state) => {
	const enabled = state.appState.devToolsEnabled

	if (isInDevelopMode(state)) {
		if (enabled === false) return false
		return true
	} else {
		if (enabled === true) return true
		return false
	}
}
export const getDevToolsLogEntriesCounter = (state) => state.appState.devToolsLogEntriesCounter
export const getDevToolsActiveActionId = (state) => state.appState.devToolsActiveActionId
export const getDevToolsActiveDataSourceId = (state) => state.appState.devToolsActiveDataSourceId

/******************************************************************************
 *
 * Misc
 *
 *****************************************************************************/

/**
 * Set to true if the client code on the server is newer.
 */
export const getClientOutdated = (state) => state.appState.clientOutdated
