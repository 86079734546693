import ClientDataSource from './ClientDataSource'

/**
 * Static dataSource like
 * - CurrentUser
 * - CurrentUserGroup
 * - Resource Files
 */
class StaticDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		super(dataSourceMeta, appController, logger)

		this.isStatic = true

		if (dataSourceMeta.builtInProperties) {
			this.propertiesMetadataInUseList = dataSourceMeta.builtInProperties
		}
	}

	// Devtools injection of metadata
	setExtendMetadata(extendedMetadata) {
		super.setExtendMetadata(extendedMetadata)

		const { roles } = extendedMetadata
		if (this.id === '__BUILT_IN_CURRENT_USER_GROUPS__DS__') {
			this.propertiesMetadataInUseList = roles.map((item) => {
				return {
					id: item._id,
					name: item.name,
					nodeName: item._id,
					isBuiltIn: true,
					readOnly: true,
					dataType: 'boolean',
				}
			})
		}
	}

	modifySingleValueOptimistic() {
		throw 'Tried to modify StaticDataSource'
	}

	getDataSourceConfigForSync() {
		return undefined
	}
}

export default StaticDataSource
