import axios from 'axios'
import accountLocalStorageHandler from '../modules/accountLocalStorageHandler'
import sessionStorageHandler from '../modules/sessionStorageHandler'

const logout = () => {
	accountLocalStorageHandler.clearValues()
	sessionStorageHandler.clearValues()
	return axios({
		method: 'get',
		url: '/auth/logout',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	})
}

export default logout
