import { setProfileImageData } from '#actions/appStateActions'
import { deleteProfileImage } from '#modules/afClientApi'
import reduxStore from '../../store'

const openImage = (callback) => {
	const fileInput = document.createElement('input')
	fileInput.setAttribute('type', 'file')
	fileInput.setAttribute('accept', 'image/*')

	if (!fileInput) throw new Error('uploadFile: Unable to find input element')

	const previousOnfocusEvent = document.body.onfocus
	let rejectTimer = null

	fileInput.onchange = (event) => {
		clearTimeout(rejectTimer)
		document.body.onfocus = previousOnfocusEvent

		const files = fileInput.files
		if (files.length !== 1) return

		const file = files[0]

		callback({ fileData: file, originalFileName: file.name })
	}

	document.body.onfocus = () => {
		rejectTimer = setTimeout(() => {
			document.body.onfocus = previousOnfocusEvent
			callback(null)
		}, 500)
	}

	fileInput.click()
}

const p_setUserAccountImage = ({ actionNode, dispatch, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (actionNode.clearImage) {
			actionNodeLogger.debug('Delete profile image')
			deleteProfileImage()
				.then(({ profileImageExists, profileImage }) => {
					appController.setCurrentUserProfileImage({ profileImageExists, profileImage })
					resolve()
				})
				.catch(reject)
		} else {
			const callback = (filedata) => dispatch(setProfileImageData(filedata))
			openImage(callback)

			const unsubscribe = reduxStore.subscribe(() => {
				const state = reduxStore.getState()
				if (!state.appState.profileImageData) {
					unsubscribe()
					resolve()
				}
			})
		}
	})

export default p_setUserAccountImage
