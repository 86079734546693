import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import classNames from 'classnames'

import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import AfMarkdown from './AfMarkdown'

import { withStyles } from '@material-ui/core/styles'

import defaultLogo from '../resources/appfarm_logo_white.svg'

const styles = (theme) => ({
	outerContainer: {
		height: '100%',
		width: '100%',
		display: 'block',
		margin: '0 auto',
	},
	innerContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 460,
	},
	container: {
		[theme.breakpoints.up('sm')]: {
			padding: 24,
		},
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	logo: {
		maxHeight: 70,
	},
	content: {
		[theme.breakpoints.down('sm')]: {
			padding: 24,
			width: '100%',
			height: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			padding: '24px 0',
			width: 500,
			margin: '0 auto',
		},
	},
	messageContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	icon: {
		fontSize: 72,
		lineHeight: '72px',
		height: 72,
		width: 72,
		color: theme.palette.primary.main,
		marginBottom: 16,
		alignSelf: 'center',
	},
})

const markdownOptions = {
	linkify: true,
}

const MaintenanceMode = ({ logo, classes, maintenanceModeText }) => (
	<div className={classes.outerContainer}>
		<div className={classes.innerContainer}>
			<Fade in unmountOnExit mountOnEnter timeout={{ enter: 300, exit: 100 }}>
				<div className={classes.container}>
					<div className={classes.logoContainer}>
						<img className={classes.logo} src={logo || defaultLogo} />
					</div>
					<div className={classes.content}>
						<div className={classes.messageContainer}>
							<Icon className={classNames('mdi mdi-worker', classes.icon)} />
							<Typography variant="subtitle1" align="center" gutterBottom>
								<AfMarkdown options={markdownOptions} source={maintenanceModeText} />
							</Typography>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	</div>
)

MaintenanceMode.propTypes = {
	logo: PropTypes.string,
	maintenanceModeText: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		logo: state.authState.logo,
		maintenanceModeText: state.authState.maintenanceModeText,
	}
}

export default withStyles(styles)(connect(mapStateToProps)(MaintenanceMode))
