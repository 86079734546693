import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()

// browserHistory.listen((location, action) => {
//     console.log('location: ', location)
//     console.log('action: ', action)
// })

export default browserHistory
