/**
 * Will create an actual fileInput DOM element
 */
import isArray from 'lodash/isArray'
import getMimeTypesFromAcceptedTypes from './utils'

const createFileInput = (acceptedTypes = [], useCamera = false, multiselect = false) => {
	if (!isArray(acceptedTypes)) throw new Error('acceptedTypes must be an array if provided')

	const mimeTypes = getMimeTypesFromAcceptedTypes(acceptedTypes)
	const fileInput = document.createElement('input')
	fileInput.setAttribute('type', 'file')
	if (multiselect) fileInput.setAttribute('multiple', true)
	if (mimeTypes.length) fileInput.setAttribute('accept', mimeTypes.join(','))
	if (useCamera) fileInput.setAttribute('capture', 'camera')
	if (!fileInput) throw new Error('Unable to create input element')

	return fileInput
}

export default createFileInput
