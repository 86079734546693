import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles, withTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Slide from '@material-ui/core/Slide'

import PenTool from './PenTool'

const styles = (theme) => ({
	root: {
		position: 'absolute',
		bottom: 0,
		top: 0,
		right: 0,
		left: 0,
	},
	hidden: {
		opacity: 0,
		zIndex: -10,
	},
	actionsToolbar: {
		position: 'absolute',
		display: 'flex',
		top: 4,
		left: 4,
		zIndex: 1251,
	},
	actionButtons: {
		color: 'white',
		textShadow: '0 0 1px rgb(25, 25, 25)',
		'&$buttonDisabled': {
			color: 'rgba(255, 255, 255, 0.3)',
		},
	},
	buttonDisabled: {},
	toolbar: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		width: 50,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1251,
		padding: '2px 2px 0 0',
		background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12))',
	},
	toolIsActive: {},
	buttonsContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		color: 'white',
		textShadow: '0 0 1px rgb(25, 25, 25)',
		transition: theme.transitions.create(['opacity', 'top', 'background-color']),
		'&:nth-child(1)': {
			top: 0,
		},
		'&:nth-child(2)': {
			top: 48,
		},
		'&:nth-child(3)': {
			top: 96,
		},
		'&:nth-child(4)': {
			top: 144,
		},
	},
	selectedButton: {
		top: '0 !important',
		textShadow: '0',
		backgroundColor: `${theme.palette.primary.main} !important`,
	},
	hiddenButton: {
		opacity: 0,
		zIndex: -10,
	},
	toolContainer: {
		flex: 1,
		paddingTop: 50,
	},
})

class Toolpanel extends Component {
	render() {
		const {
			component,
			lineWidth,
			brushColor,
			onBrushWidthChange,
			setBrushColor,
			setActiveTool,
			undo,
			redo,
			canUndo,
			canRedo,
			show,
			theme,
			classes,
		} = this.props

		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen,
		}

		const buttons = []

		if (component.enablePen) {
			buttons.push(
				<IconButton
					key="pen"
					className={classNames(classes.button, {
						[classes.selectedButton]: this.props.activeTool === 'pen',
						[classes.hiddenButton]: !!this.props.activeTool && this.props.activeTool !== 'pen',
					})}
					onClick={() => setActiveTool('pen')}
				>
					<Icon className="mdi mdi-pencil" />
				</IconButton>
			)
		}

		if (component.enableText) {
			buttons.push(
				<IconButton
					key="text"
					className={classNames(classes.button, {
						[classes.selectedButton]: this.props.activeTool === 'text',
						[classes.hiddenButton]: !!this.props.activeTool && this.props.activeTool !== 'text',
					})}
					onClick={() => setActiveTool('text')}
				>
					<Icon className="mdi mdi-format-text" />
				</IconButton>
			)
		}

		if (component.enableShape) {
			buttons.push(
				<IconButton
					key="shape"
					className={classNames(classes.button, {
						[classes.selectedButton]: this.props.activeTool === 'shape',
						[classes.hiddenButton]: !!this.props.activeTool && this.props.activeTool !== 'shape',
					})}
					onClick={() => setActiveTool('shape')}
				>
					<Icon className="mdi mdi-shape" />
				</IconButton>
			)
		}

		if (component.enableCrop) {
			buttons.push(
				<IconButton
					key="crop"
					className={classNames(classes.button, {
						[classes.selectedButton]: this.props.activeTool === 'crop',
						[classes.hiddenButton]: !!this.props.activeTool && this.props.activeTool !== 'crop',
					})}
					onClick={() => setActiveTool('crop')}
				>
					<Icon className="mdi mdi-crop" />
				</IconButton>
			)
		}

		const tools = [
			{
				key: 'pen',
				component: (
					<PenTool
						lineWidth={lineWidth}
						brushColor={brushColor}
						onBrushWidthChange={onBrushWidthChange}
						setBrushColor={setBrushColor}
					/>
				),
			},
			{
				key: 'text',
				component: <div />,
			},
			{
				key: 'shape',
				component: <div />,
			},
			{
				key: 'crop',
				component: <div />,
			},
		]

		return (
			<div className={classNames(classes.root, { [classes.hidden]: !show })}>
				{ this.props.activeTool && (
					<div className={classes.actionsToolbar}>
						<IconButton
							classes={{ root: classes.actionButtons, disabled: classes.buttonDisabled }}
							onClick={undo}
							disabled={!canUndo}
						>
							<Icon className="mdi mdi-undo" />
						</IconButton>
						<IconButton
							classes={{ root: classes.actionButtons, disabled: classes.buttonDisabled }}
							onClick={redo}
							disabled={!canRedo}
						>
							<Icon className="mdi mdi-redo" />
						</IconButton>
					</div>
				) }
				<div
					className={classNames(classes.toolbar, {
						[classes.toolIsActive]: !!this.props.activeTool,
					})}
				>
					<div className={classes.buttonsContainer}>{ buttons.map((item) => item) }</div>
					<div className={classes.toolContainer}>
						{ tools.map((item) => (
							<Slide
								key={item.key}
								direction="up"
								in={this.props.activeTool === item.key}
								timeout={transitionDuration}
								style={{
									transitionDelay: this.props.activeTool === item.key ? transitionDuration.exit : 0,
								}}
								unmountOnExit
							>
								<div>{ item.component }</div>
							</Slide>
						)) }
					</div>
				</div>
			</div>
		)
	}
}

Toolpanel.propTypes = {
	component: PropTypes.object.isRequired,
	lineWidth: PropTypes.number,
	activeTool: PropTypes.string,
	brushColor: PropTypes.string,
	show: PropTypes.bool,
	setBrushColor: PropTypes.func.isRequired,
	onBrushWidthChange: PropTypes.func.isRequired,
	undo: PropTypes.func.isRequired,
	redo: PropTypes.func.isRequired,
	setActiveTool: PropTypes.func.isRequired,
	canUndo: PropTypes.bool.isRequired,
	canRedo: PropTypes.bool.isRequired,
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(Toolpanel))
