import { handleEvent } from '../actions/eventActions'
import { getStore } from '../store/reduxStoreUtils'

class AppTimer {
	constructor(timerDesc, appController) {
		this.id = timerDesc.id
		this.name = timerDesc.name
		this.enabled = timerDesc.enabled
		this.interval = timerDesc.interval
		this.onTimer = timerDesc.eventHandlers && timerDesc.eventHandlers.onTimer

		this._timer = null
		this._timerIsRunning = false
		this.__appController = appController
		this._globalEnable = false

		this._onTimerEvent = this._onTimerEvent.bind(this)
	}

	evalEnable() {
		if (!this._globalEnable) return
		if (!this.interval) return

		const newValue = !!this.__appController.getDataFromDataValue(this.enabled)
		if (newValue && !this._timerIsRunning) {
			console.log('Starting timer :', this.name)
			this._timerIsRunning = true
			this._timer = setInterval(this._onTimerEvent, this.interval * 1000)
			return
		}

		if (!newValue && this._timerIsRunning) {
			console.log('Stopping timer: ' + this.name)
			clearInterval(this._timer)
			this._timer = null
			this._timerIsRunning = false
		}
	}

	init() {
		if (!this.interval) return console.warn(`Timer ${this.name} has no interval set. Timer disabled.`)
		if (!this.onTimer)
			return console.warn(`Timer ${this.name} has no On Timer event handler set. Timer disabled.`)
		this._globalEnable = true
		this.evalEnable()
	}

	destroy() {
		console.log('Destroy timer: ' + this.name)
		clearInterval(this._timer)
		this._globalEnable = false
		this._timerIsRunning = false
		this._timer = null
	}

	_onTimerEvent() {
		console.info(`${this.name} triggered`)
		if (this.__appController.getIsActionRunning(this.onTimer)) {
			return console.warn(
				'Timer tried to fire action that was aleady running. Timer is probably running to often.'
			)
		}

		getStore().dispatch(handleEvent(this.onTimer))
	}
}

export default AppTimer
