class Heartbeat {
	constructor(options) {
		this.heartbeatInterval = options.heartbeatInterval
		this.heartbeatTimeout = options.heartbeatTimeout
		this._sendPing = options.sendPing
		this._onTimeout = options.onTimeout
		this._seenPacket = false

		this._heartbeatIntervalHandle = null
		this._heartbeatTimeoutHandle = null
	}

	start() {
		this.stop()
		this._startHeartbeatIntervalTimer()
	}

	stop() {
		this._clearHeartbeatIntervalTimer()
		this._clearHeartbeatTimeoutTimer()
	}

	messageReceived() {
		// Tell periodic checkin that we have seen a packet, and thus it
		// does not need to send a ping this cycle.
		this._seenPacket = true
		// If we were waiting for a pong, we got it.
		if (this._heartbeatTimeoutHandle) {
			this._clearHeartbeatTimeoutTimer()
		}
	}

	/************************************************
	 * Internal methods
	 ************************************************/

	_startHeartbeatIntervalTimer() {
		this._heartbeatIntervalHandle = setInterval(
			this._heartbeatIntervalFired.bind(this),
			this.heartbeatInterval
		)
	}

	_startHeartbeatTimeoutTimer() {
		this._heartbeatTimeoutHandle = setTimeout(this._heartbeatTimeoutFired.bind(this), this.heartbeatTimeout)
	}

	_clearHeartbeatIntervalTimer() {
		if (this._heartbeatIntervalHandle) {
			clearInterval(this._heartbeatIntervalHandle)
			this._heartbeatIntervalHandle = null
		}
	}

	_clearHeartbeatTimeoutTimer() {
		if (this._heartbeatTimeoutHandle) {
			clearTimeout(this._heartbeatTimeoutHandle)
			this._heartbeatTimeoutHandle = null
		}
	}

	_heartbeatIntervalFired() {
		if (!this._seenPacket && !this._heartbeatTimeoutHandle) {
			this._sendPing()
			this._startHeartbeatTimeoutTimer()
		}
		this._seenPacket = false
	}

	// The heartbeat timeout timer is fired when we sent a ping, but we
	// timed out waiting for the pong.
	_heartbeatTimeoutFired() {
		this._heartbeatTimeoutHandle = null
		this._onTimeout()
	}
}

export default Heartbeat
