const locale = {
	name: 'nb',
	weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
	weekdaysShort: 'sø._ma._ti._on._to._fr._lø.'.split('_'),
	weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
	months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split('_'),
	monthsShort: 'jan._feb._mars_april_mai_juni_juli_aug._sep._okt._nov._des.'.split('_'),
	ordinal: (n) => `${n}.`,
	weekStart: 1,
	yearStart: 4,
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D. MMMM YYYY',
		LLL: 'D. MMMM YYYY [kl.] HH:mm',
		LLLL: 'dddd D. MMMM YYYY [kl.] HH:mm',
	},
	relativeTime: {
		future: 'om %s',
		past: '%s siden',
		s: 'noen sekunder',
		m: 'ett minutt',
		mm: '%d minutter',
		h: 'en time',
		hh: '%d timer',
		d: 'en dag',
		dd: '%d dager',
		M: 'en måned',
		MM: '%d måneder',
		y: 'ett år',
		yy: '%d år',
	},
	calendar: {
		sameDay: '[i dag kl.] LT',
		nextDay: '[i morgen kl.] LT',
		nextWeek: 'dddd [kl.] LT',
		lastDay: '[i går kl.] LT',
		lastWeek: '[forrige] dddd [kl.] LT',
		sameElse: 'L',
	},
}

export default locale
