import isInteger from 'lodash/isInteger'

const p_sleep = (actionNode) =>
	new Promise((resolve) => {
		let duration = 0

		if (isInteger(actionNode.duration)) duration = actionNode.duration

		setTimeout(() => resolve(), duration)
	})

export default p_sleep
