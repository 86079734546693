export enum e_Level {
	EMERG = 7,
	CRIT = 6,
	ERROR = 5,
	WARNING = 4,
	NOTICE = 3,
	INFO = 2,
	DEBUG = 1,
}

export enum LogItemType {
	TABLE = 'table',
	MESSAGE = 'message',
	CONTEXT = 'context',
}

interface Error {
	name: string
	message: string
	stack?: string
	metadata: any
}
export interface MetadataArgument {
	err?: Error
	prefix?: string
	labels?: Array<{ [key: string]: string }>
	[key: string]: any
}

export interface LogItem {
	type: LogItemType
	logName: string
	loggerId: string
	level: e_Level
	message: string
	timestamp: number
	count?: number
	metadata: MetadataArgument
}

export interface LogTableItem extends LogItem {
	data: any
	columns?: string[]
}

export interface LogContextItem extends LogItem {
	data: any
}

export interface Transport {
	id: string
	level: e_Level
	log: (payload: LogItem) => void
	table?: (payload: LogTableItem) => void
	context?: (payload: LogContextItem) => void
}
