export const max = (dataArray) => (dataArray.length ? Math.max(...dataArray) : 0)
export const min = (dataArray) => (dataArray.length ? Math.min(...dataArray) : 0)

export const sum = (dataArray) => dataArray.reduce((result, item) => result + item, 0)
export const average = (dataArray) => (dataArray.length ? sum(dataArray) / dataArray.length : 0)

export const median = (dataArray) => {
	if (dataArray.length === 0) return 0

	dataArray.sort(function (a, b) {
		return a - b
	})

	let half = Math.floor(dataArray.length / 2)
	if (dataArray.length % 2) return dataArray[half]
	return (dataArray[half - 1] + dataArray[half]) / 2.0
}
