import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Tab from '@material-ui/core/Tab'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'

const styles = (theme) => ({
	labelBadge: {
		padding: `0 ${theme.spacing(1)}px`,
	},
})

const UiTab = (props) => {
	const handleOnClick = useCallback(() => {
		props.handleOnTabClick(props.tab.value)
		props.tab.onClick && props.tab.onClick()
	}, [props.tab])

	const { tab, onTabClick, handleOnTabClick, classes, dispatch, ...other } = props

	let icon = tab.icon ? <Icon className={tab.icon} /> : undefined
	let label = tab.name

	if (tab.badgeValue) {
		if (icon) {
			icon = (
				<Badge badgeContent={tab.badgeValue} color={tab.badgeColor}>
					{ icon }
				</Badge>
			)
		} else {
			label = (
				<Badge className={classes.labelBadge} badgeContent={tab.badgeValue} color={tab.badgeColor}>
					{ label }
				</Badge>
			)
		}
	}

	return <Tab {...other} icon={icon} label={label} onClick={handleOnClick} disabled={tab.disabled} />
}

UiTab.propTypes = {
	tab: PropTypes.shape({
		icon: PropTypes.string,
		name: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		disabled: PropTypes.bool,
		badgeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		badgeColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']).isRequired,
		onClick: PropTypes.func,
	}),
	handleOnTabClick: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiTab)
