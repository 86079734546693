import executeBlockInSequence from './helpers/executeBlockInSequence'
import getIteratorParamsDataForIteration from './helpers/getIteratorParamsDataForIteration'

const whileActionNode = ({ actionNode, contextData, actionNodeRunner, appController, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		if (!actionNodeRunner.children.length) {
			actionNodeLogger.debug('While Action Node has no children')
			return resolve()
		}

		const runSingleWhileIteration = (indexForExec) => {
			if (appController.getDataFromDataValue(actionNode.condition, contextData)) {
				actionNodeLogger.debug('While condition is true')

				const contextDataForIteration = {
					...contextData,
				}

				if (actionNode.iteratorParamIdsInUse && Object.keys(actionNode.iteratorParamIdsInUse)?.length) {
					const iteratorParams = getIteratorParamsDataForIteration(actionNode, {
						indexForExec,
						logger: actionNodeLogger,
						iteratorParamIdsInUse: actionNode.iteratorParamIdsInUse,
					})

					contextDataForIteration.iterator_params = {
						...contextData?.iterator_params,
						...iteratorParams,
					}
				}

				actionNodeLogger.context(contextDataForIteration)

				// Still true - run and then check again
				executeBlockInSequence(actionNodeRunner.children, contextDataForIteration, actionNodeLogger)
					.then((result) => {
						if (result) {
							const { endExecution, exitLoop } = result

							if (endExecution) return resolve(result)

							// Absorb message when exiting this loop
							if (exitLoop) return resolve()
						}

						// Run again (result.nextIteration is covered by this as well)
						runSingleWhileIteration(indexForExec + 1)
					})
					.catch((err) => reject(err))
			} else {
				// condition not true - Signal to parent to run next
				actionNodeLogger.debug('While condition is false')
				resolve()
			}
		}

		runSingleWhileIteration(0)
	})

export default whileActionNode
