import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Modal from '@material-ui/core/Modal'

import UiImageFullscreen from './UiImageFullscreen'

import { DATA_BINDING } from '@appfarm/common/enums/e_DataValueType'

import { motion } from 'framer-motion'

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	clickable: {
		cursor: 'pointer',
	},
}

class UiImage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fullscreen: false,
			src: undefined,
			altText: undefined,
			ownData: undefined,
		}

		this.handleOnClick = this.handleOnClick.bind(this)
		this.handleOnLoad = this.handleOnLoad.bind(this)
		this.toggleFullscreen = this.toggleFullscreen.bind(this)

		this.adaptiveResolutionWidths = [1280, 768, 480]
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let ownData = prevState.ownData

		const src = nextProps.appController.getDataFromDataValue(nextProps.component.value, nextProps.contextData)

		if (nextProps.component.enableImageTools) {
			if (nextProps.component.value && nextProps.component.value.type === DATA_BINDING) {
				ownData = nextProps.appController.getDataFromDataBinding({
					dataBinding: nextProps.component.value.dataBinding,
					contextData: nextProps.contextData,
				})
			}
		}

		let altText
		if (nextProps.component.altText)
			altText = nextProps.appController.getDataFromDataValue(
				nextProps.component.altText,
				nextProps.contextData,
				{ getDisplayValue: true }
			)

		// No changes. No need to update
		if (ownData === prevState.ownData && src === prevState.src && altText === prevState.altText) return null

		return {
			...prevState,
			src,
			altText,
			ownData,
			styleProp: {
				...nextProps.styleProp,
				...nextProps.style,
			},
		}
	}

	handleOnClick(event) {
		if (this.props.disabled) return

		if (this.props.component.fullscreenPreview) this.toggleFullscreen()

		if (this.props.component.onClick)
			this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	handleOnLoad() {
		if (this.props.component.onLoad)
			this.props.eventHandler(this.props.component.onLoad, null, { eventType: 'onLoad' })
	}

	toggleFullscreen() {
		this.setState({ fullscreen: !this.state.fullscreen })
	}

	getSrcSet(src, fromPrivateBucket) {
		const withHttps = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url)

		const srcsets = []
		for (const width of this.adaptiveResolutionWidths) {
			const resizeAPIURL = new URL('api/v1/operation/resize', withHttps(window.AF_PARAMS.imageServiceTarget))
			resizeAPIURL.searchParams.append('url', src)
			resizeAPIURL.searchParams.append('width', width)
			if (fromPrivateBucket) {
				resizeAPIURL.searchParams.append('token', window.AF_PARAMS.imageServiceToken)
			}
			srcsets.push(resizeAPIURL.href + ' ' + width + 'w')
		}

		return srcsets
	}

	getSrcSizes() {
		let sizeAttr = ''
		for (const width of this.adaptiveResolutionWidths) {
			sizeAttr += `(min-width: ${width}px) ${width}px, `
		}
		return sizeAttr
	}

	isPrivateSolutionBucket = (src) => {
		let urlPath

		try {
			urlPath = new URL(src).pathname
		} catch {
			console.log(`Failed to parse image src=${src}`)
			return false
		}

		return urlPath.startsWith(`/${window.AF_PARAMS.solutionBucket}/`)
	}

	isResourceFilesBucket = (src) => {
		let urlPath

		try {
			urlPath = new URL(src).pathname
		} catch {
			console.log(`Failed to parse image src=${src}`)
			return false
		}

		return urlPath.startsWith(`/${window.AF_PARAMS.resourceFilesBucket}/`)
	}

	permittedToUseImageAPI = (src) => {
		return this.isPrivateSolutionBucket(src) || this.isResourceFilesBucket(src)
	}

	render() {
		const { component, appController, disabled, readOnly, conditionalClassNames, classes } = this.props
		const { src, altText, ownData, styleProp, fullscreen } = this.state

		if (!src) return null

		const Component = this.props.useMotion ? motion.img : 'img'

		const responseImageAttrs = {}
		if (component.responsive && this.permittedToUseImageAPI(src)) {
			responseImageAttrs['srcSet'] = this.getSrcSet(src, this.isPrivateSolutionBucket(src))
			responseImageAttrs['sizes'] = this.getSrcSizes()
		}

		return (
			<>
				<Component
					className={classNames(classes.root, 'c' + component.id, conditionalClassNames, {
						[classes.clickable]: !disabled && (component.fullscreenPreview || !!component.onClick),
					})}
					onClick={this.handleOnClick}
					src={src}
					alt={altText}
					onLoad={this.handleOnLoad}
					loading={component.lazyLoad ? 'lazy' : undefined}
					style={styleProp}
					{...this.props.motionProps}
					{...responseImageAttrs}
				/>
				{ component.fullscreenPreview && !disabled && (
					<Modal open={fullscreen} onClose={this.toggleFullscreen}>
						<UiImageFullscreen
							src={src}
							altText={altText}
							ownData={ownData}
							toggleFullscreen={this.toggleFullscreen}
							component={component}
							appController={appController}
							readOnly={readOnly}
						/>
					</Modal>
				) }
			</>
		)
	}
}

UiImage.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.object.isRequired,
	contextData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	style: PropTypes.object,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiImage)
