import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames'

import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

import LoginSection from './LoginSection'

const styles = (theme) => ({
	icon: {
		fontSize: 72,
		lineHeight: '72px',
		height: 72,
		width: 72,
		color: theme.palette.primary.main,
		marginBottom: 16,
		alignSelf: 'center',
	},
	inlineButton: {
		fontWeight: 500,
		marginLeft: 2,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
})

const InfoMessage = ({ icon, message, backText, backFunction, classes }) => {
	return (
		<Fragment>
			<LoginSection>
				<Icon className={classNames(icon, classes.icon)} />
				<Typography variant="body1" align="center">
					{ message }
				</Typography>
			</LoginSection>
			{ backText && (
				<LoginSection>
					<Typography variant="body1" align="center">
						<a className={classes.inlineButton} role="button" tabIndex="0" onClick={backFunction}>
							{ backText }
						</a>
					</Typography>
				</LoginSection>
			) }
		</Fragment>
	)
}

InfoMessage.propTypes = {
	icon: PropTypes.string,
	message: PropTypes.string,
	backText: PropTypes.string,
	backFunction: PropTypes.func,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(InfoMessage)
