import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import red from '@material-ui/core/colors/red'

import Toolpanel from './Toolpanel'
import UiImageCanvas from './UiImageCanvas'

const styles = (theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		overflow: 'hidden',
	},
})

class UiImageTools extends Component {
	constructor(props) {
		super(props)

		this.history = []
		this.redoStack = []
		this.currentCanvasData = null

		this.state = {
			brushColor: red[500],
			lineWidth: 8,
			activeTool: undefined,

			isDrawing: false,
		}

		this.onBrushWidthChange = this.onBrushWidthChange.bind(this)
		this.setBrushColor = this.setBrushColor.bind(this)
		this.setActiveTool = this.setActiveTool.bind(this)

		this.setIsDrawing = this.setIsDrawing.bind(this)

		this.saveToHistory = this.saveToHistory.bind(this)
		this.undo = this.undo.bind(this)
		this.redo = this.redo.bind(this)
		this.setCanvasReference = this.setCanvasReference.bind(this)
	}

	setBrushColor(value) {
		this.setState({ brushColor: value })
	}

	onBrushWidthChange(event, value) {
		this.setState({ lineWidth: value })
	}

	setActiveTool(newValue) {
		const activeTool = newValue === this.state.activeTool ? undefined : newValue
		this.setState({ activeTool })
		this.props.toggleToolIsActive()
	}

	setIsDrawing(newValue) {
		this.setState({ isDrawing: newValue })
	}

	saveToHistory(sourceCanvas) {
		this.redoStack = [] // Clear redo
		this.props.setImageIsChanged(sourceCanvas)

		if (!this.currentCanvasData) {
			this.currentCanvasData = sourceCanvas
			this.forceUpdate() // trigger UI update to enable undo
			return
		}

		this.history.push(this.currentCanvasData)
		this.currentCanvasData = sourceCanvas
	}

	undo() {
		if (!this.canvasEditReference) return
		if (!this.currentCanvasData) return

		const width = this.canvasEditReference.width
		const height = this.canvasEditReference.height
		const ctx = this.canvasEditReference.getContext('2d')
		this.redoStack.push(this.currentCanvasData)

		if (!this.history.length) {
			ctx.clearRect(0, 0, width, height)
			this.currentCanvasData = null
			this.props.setImageIsChanged(false)
			return
		}

		this.currentCanvasData = this.history.pop()
		this.props.setImageIsChanged(this.currentCanvasData)

		ctx.clearRect(0, 0, width, height)
		ctx.drawImage(this.currentCanvasData, 0, 0)
	}

	redo() {
		if (!this.canvasEditReference) return
		if (!this.redoStack.length) return

		if (this.currentCanvasData) this.history.push(this.currentCanvasData)

		this.currentCanvasData = this.redoStack.pop()
		this.props.setImageIsChanged(this.currentCanvasData)

		const width = this.canvasEditReference.width
		const height = this.canvasEditReference.height
		const ctx = this.canvasEditReference.getContext('2d')
		ctx.clearRect(0, 0, width, height)
		ctx.drawImage(this.currentCanvasData, 0, 0)
	}

	setCanvasReference(element) {
		this.canvasEditReference = element
	}

	render() {
		const { component, imageRef, classes } = this.props
		const canvasHistory = this.currentCanvasData ? [...this.history, this.currentCanvasData] : this.history
		return (
			<div className={classes.root}>
				{ imageRef && (
					<UiImageCanvas
						imageSize={this.props.imageSize}
						readOnly={this.state.activeTool !== 'pen'}
						brushColor={this.state.brushColor}
						lineWidth={this.state.lineWidth}
						setIsDrawing={this.setIsDrawing}
						isDrawing={this.state.isDrawing}
						imageRef={imageRef}
						saveToHistory={this.saveToHistory}
						history={canvasHistory}
						setCanvasReference={this.setCanvasReference}
					/>
				) }
				<Toolpanel
					component={component}
					activeTool={this.state.activeTool}
					lineWidth={this.state.lineWidth}
					brushColor={this.state.brushColor}
					onBrushWidthChange={this.onBrushWidthChange}
					setBrushColor={this.setBrushColor}
					setActiveTool={this.setActiveTool}
					undo={this.undo}
					redo={this.redo}
					canUndo={!!this.currentCanvasData}
					canRedo={this.redoStack.length > 0}
					show={!this.state.isDrawing}
				/>
			</div>
		)
	}
}

UiImageTools.propTypes = {
	component: PropTypes.object.isRequired,
	imageRef: PropTypes.object,
	imageSize: PropTypes.object,
	toggleToolIsActive: PropTypes.func.isRequired,
	setImageIsChanged: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiImageTools)
