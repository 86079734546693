import accountLocalStorageHandler from '../../../modules/accountLocalStorageHandler'

import isNil from 'lodash/isNil'

export const setHotReloadDisabledToLocalStorage = (devToolsHotReloadDisabled: boolean): void => {
	if (devToolsHotReloadDisabled)
		accountLocalStorageHandler.setValue(['devToolsHotReloadDisabled'], devToolsHotReloadDisabled)
	else accountLocalStorageHandler.clearValue(['devToolsHotReloadDisabled'])
}

export const setDevToolsEnabledToLocalStorage = (devToolsEnabled: boolean): void => {
	accountLocalStorageHandler.setValue(['devToolsEnabled'], devToolsEnabled)
}

export const setPanelCollapsedToLocalStorage = (collapsed: boolean): void => {
	accountLocalStorageHandler.setValue(['devToolsPanelCollapsed'], collapsed)
}

export const setPanelSizeToLocalStorage = (size: { height: number }): void => {
	accountLocalStorageHandler.setValue(['devToolsPanelHeight'], size.height)
}

export const setActivePanelToLocalStorage = (activePanel: string): void => {
	if (activePanel) accountLocalStorageHandler.setValue(['devToolsActivePanel'], activePanel)
	else accountLocalStorageHandler.clearValue(['devToolsActivePanel'])
}

export const setMutedActionsToLocalStorage = (mutedActions: { [key: string]: boolean }): void => {
	if (mutedActions) accountLocalStorageHandler.setValue(['devToolsMutedActions'], mutedActions)
	else accountLocalStorageHandler.clearValue(['devToolsMutedActions'])
}

export const setDataViewPaneSizeToLocalStorage = (size: { width: number; height: number }): void => {
	if (!isNil(size.width) && typeof size.width === 'number')
		accountLocalStorageHandler.setValue(['devToolsDataViewPanelWidth'], size.width)
	if (!isNil(size.height) && typeof size.height === 'number')
		accountLocalStorageHandler.setValue(['devToolsDataViewPanelHeight'], size.height)
}

export const setActionViewPaneSizeToLocalStorage = (size: { width: number; height: number }): void => {
	if (!isNil(size.width) && typeof size.width === 'number')
		accountLocalStorageHandler.setValue(['devToolsActionViewPanelWidth'], size.width)
	if (!isNil(size.height) && typeof size.height === 'number')
		accountLocalStorageHandler.setValue(['devToolsActionViewPanelHeight'], size.height)
}

export const setActiveThemeTypeToLocalStorage = (activeThemeType: string): void => {
	if (activeThemeType) accountLocalStorageHandler.setValue(['activeThemeType'], activeThemeType)
	else accountLocalStorageHandler.clearValue(['activeThemeType'])
}

export const setEnabledLoggersToLocalStorage = (enabledLoggers: { [key: string]: boolean }): void => {
	accountLocalStorageHandler.setValue(['devToolsLoggingEnabledLoggers'], enabledLoggers)
}
