import { createStore, applyMiddleware, compose } from 'redux'

/**
 * Misc middleware
 */
import thunk from 'redux-thunk'
import { init as reduxStoreUtilInit } from './reduxStoreUtils'

/**
 * Root reducer
 */
import rootReducer from '../reducers'

const configureStore = (initialState) => {
	const finalCreateStore = compose(applyMiddleware(thunk))(createStore)

	const store = finalCreateStore(rootReducer, initialState)

	return store
}

const store = configureStore()
reduxStoreUtilInit(store)

export default store
