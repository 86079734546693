import React from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import LoadingComponent from '../uiComponents/utils/LoadingComponent'

const UnsplashDialog = loadable(() => import('./UnsplashDialog'), {
	fallback: <LoadingComponent />,
})

const LoadableUnsplashDialog = () => {
	const open = useSelector((state) => state.appState.unsplashDialogOpen)

	if (open) {
		return <UnsplashDialog />
	}

	return null
}

export default LoadableUnsplashDialog
