import translateItemRecursive from './translateItemRecursive'

const translateActions = (actions, langDictionary) => {
	return Object.keys(actions).reduce((translatedActions, actionId) => {
		const actionDictionary = langDictionary[actionId] || {}
		const action = { ...actions[actionId] }
		action.actionNodes = translateItemRecursive(action.actionNodes, actionDictionary)
		translatedActions[actionId] = action

		return translatedActions
	}, {})
}

export default translateActions
