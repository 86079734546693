import axios from 'axios'

import { generateContentDisposition } from '@appfarm/common/utils/contentDisposition'
import { finalizeFileUpload } from '#modules/afClientApi'

const p_runFileUpload = ({
	uploadPermissionResponse,
	fileOrBlob,
	fileName,
	defaultValues,
	dataSource,
	logger,
}) =>
	new Promise((resolve, reject) => {
		if (uploadPermissionResponse.code !== 200) throw new Error('Unable to get upload token')

		const data = new FormData()

		const emptyFileObject = {
			...uploadPermissionResponse.payload.fileObject,
			...defaultValues,
		}
		const fileObjectId = emptyFileObject._id

		dataSource
			.p_insertFileObject(emptyFileObject)
			.then(() => {})
			.catch(() => {})

		const { bucket, accessId, policy, signature, key, acl, jwtPayload } = uploadPermissionResponse.payload

		// File-Object name
		data.append('key', key)

		// Misc
		data.append(
			'Content-Disposition',
			generateContentDisposition(fileName || fileObjectId, { type: 'attachment' })
		)
		data.append('Cache-Control', 'public,max-age=3600')
		data.append('acl', acl)

		// Security stuff
		data.append('GoogleAccessId', accessId)
		data.append('policy', policy)
		data.append('signature', signature)

		// Metadata
		data.append('x-goog-meta-originalname', fileName || fileObjectId)

		// File must be last
		data.append('file', fileOrBlob)

		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				logger.debug('PROGRESS: ' + percentCompleted)
				dataSource.setFileUploadProgress(emptyFileObject._id, percentCompleted)
			},
		}

		Promise.all([
			axios.post(`https://storage.googleapis.com/${bucket}`, data, config),
			Promise.resolve(jwtPayload),
		])
			.then(([uploadResult, jwtPayload]) => finalizeFileUpload(jwtPayload))
			.then((result) => {
				if (result && result.code && result.code !== 200) {
					dataSource.removeFailedUploadObject(fileObjectId)
					return reject(new Error('Unable to upload file'))
				}

				const fileObject = result.fileObject
				logger.debug('Final file object:')
				logger.table(fileObject, null, { dataSourceId: dataSource.id })

				// Update file object with new data
				dataSource.setFileUploadComplete(fileObject)
				resolve(fileObject)
			})
			.catch((err) => {
				dataSource.removeFailedUploadObject(fileObjectId)
				reject(err)
			})
	})

export default p_runFileUpload
