import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import PrimaryLogin from './PrimaryLogin'
import { isNoAccessError } from '#selectors/metadataSelectors'

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (rest.isLoggedIn) {
				if (rest.isAnonymous && (rest.isNoAccessError || window.location.pathname.length <= 1)) {
					return <PrimaryLogin />
				} else {
					return <Component {...props} />
				}
			} else {
				return <PrimaryLogin />
			}
		}}
	/>
)

PrivateRoute.propTypes = {
	isLoggedIn: PropTypes.bool,
	isAnonymous: PropTypes.bool,
	isNoAccessError: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
	return {
		isLoggedIn: state.authState.isLoggedIn,
		isAnonymous: state.authState.isAnonymous,

		isNoAccessError: isNoAccessError(state),
	}
}

export default connect(mapStateToProps)(PrivateRoute)
