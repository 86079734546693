import axios from 'axios'

const p_sendClientState = ({ appController, userComment, actionNodeLogger }) =>
	new Promise((resolve, reject) => {
		actionNodeLogger.debug('Sending complete client dump to Appfarm')

		const appId = appController.getActiveAppId()
		const appData = appController.getAllDataForDebug()
		const state = appController.getState()

		const dump = {
			timestamp: new Date().toJSON(),
			clientVersion: window.__BRANCH,
			clientCommitId: window.__COMMIT,
			buildTime: window.__BUILD_TIME,
			url: window.location.href,
			appState: state.appState,
			metaData: state.metaData,
			runtimeState: state.runtimeState,
			appData,
		}

		if (userComment) dump.userComment = userComment

		axios
			.get(`/api/v1/apps/${appId}/sentinel/request_dump_upload`)
			.then((result) => {
				const {
					policy,
					signature,
					bucket,
					key,
					accessId,
					acl,
					solutionId,
					userId,
					fileName,
					appId,
					appfarmEnv,
					serverVersion,
					serverCommit,
				} = result.data

				dump.appfarmEnv = appfarmEnv
				dump.serverVersion = serverVersion
				dump.serverCommit = serverCommit

				const formData = new FormData()

				const config = {
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						actionNodeLogger.debug('Progress: ' + percentCompleted)
					},
				}

				const payloadString = JSON.stringify(dump)
				actionNodeLogger.debug('Dump Size: ' + payloadString.length + ' bytes')

				// File-Object name
				formData.append('key', key)

				// Misc (filename is solely ASCII, no need to encode Content-Disposition)
				formData.append('Content-Disposition', `attachment; filename="${fileName}"`)
				formData.append('Cache-Control', 'public,max-age=3600')
				formData.append('Content-Type', 'application/json')
				formData.append('acl', acl)

				// Security stuff
				formData.append('GoogleAccessId', accessId)
				formData.append('policy', policy)
				formData.append('signature', signature)

				// Metadata
				formData.append('x-goog-meta-solutionid', solutionId)
				formData.append('x-goog-meta-afenv', appfarmEnv)
				formData.append('x-goog-meta-appid', appId)
				formData.append('x-goog-meta-uploadedby', userId)
				formData.append('x-goog-meta-servercommit', serverCommit)
				formData.append('x-goog-meta-serverversion', serverVersion)

				// File must be last
				formData.append('file', new File([new Blob([payloadString])], fileName))

				return axios.post(`https://storage.googleapis.com/${bucket}`, formData, config)
			})
			.then(() => {
				actionNodeLogger.debug('Successfully uploaded dump to Appfarm')
				resolve()
			})
			.catch(reject)
	})

export default p_sendClientState
