import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

const AppSnackbar = ({ open, autohideDuration, text, actionButtonText, actionCallback, dismissCallback }) => {
	const actions = []
	if (actionCallback && actionButtonText)
		actions.push(
			<Button key="action" color="secondary" size="small" onClick={actionCallback}>
				{ actionButtonText }
			</Button>
		)

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			autoHideDuration={autohideDuration}
			onClose={dismissCallback}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id="message-id">{ text }</span>}
			action={actions}
		/>
	)
}

AppSnackbar.propTypes = {
	open: PropTypes.bool,
	autohideDuration: PropTypes.number,
	text: PropTypes.string,
	actionButtonText: PropTypes.string,
	actionCallback: PropTypes.func,
	dismissCallback: PropTypes.func,
}

const mapStateToProps = (state) => ({
	open: state.appState.snackbarOpen,
	autohideDuration: state.appState.snackbarAutohideDuration,
	text: state.appState.snackbarText,
	actionButtonText: state.appState.snackbarActionText,
	actionCallback: state.appState.snackbarActionCallback,
	dismissCallback: state.appState.snackbarDismissCallback,
})

export default connect(mapStateToProps)(AppSnackbar)
