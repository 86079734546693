import { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { isServerSessionReady, isServerClientStateInSync } from '#selectors/appStateSelectors'

import { analysisStorageHandler, analysisEventHandler } from '#utils/logger/transports/AnalysisTransport'

const MAX_LOGS_PER_POSTING = 20
const AnalysisLogTransporter = () => {
	const clientStateInSync = useSelector(isServerClientStateInSync)
	const serverSessionIsReady = useSelector(isServerSessionReady)

	const [logsForPosting, setLogsForPosting] = useState(false)
	const initiateLogSendingTimer = useRef()

	const initiateLogSending = useCallback(() => {
		clearTimeout(initiateLogSendingTimer.current)
		initiateLogSendingTimer.current = setTimeout(() => setLogsForPosting(true), 200)
	}, [])

	useEffect(() => {
		analysisEventHandler.on('logadded', initiateLogSending)
		return () => analysisEventHandler.off('logadded', initiateLogSending)
	}, [initiateLogSending])

	useEffect(() => {
		if (serverSessionIsReady && clientStateInSync) initiateLogSending()
	}, [serverSessionIsReady, clientStateInSync])

	useEffect(() => {
		if (!logsForPosting) return

		if (!serverSessionIsReady) return
		if (!clientStateInSync) return

		const logsByTimestamp = analysisStorageHandler.getParsedStorage()
		if (Object.keys(logsByTimestamp).length) {
			let keys = Object.keys(logsByTimestamp)
			let logs = Object.values(logsByTimestamp)

			// limit sending to MAX elements
			if (keys.length > MAX_LOGS_PER_POSTING) {
				keys = keys.slice(0, MAX_LOGS_PER_POSTING)
				logs = logs.slice(0, MAX_LOGS_PER_POSTING)
			}

			axios({ method: 'post', url: '/api/v1/analytics/analysislogs', data: logs })
				.then((result) => {
					if (result.data.code === 200) {
						keys.forEach((key) => analysisStorageHandler.clearValue([key]))
						setLogsForPosting(false)

						// check for new logs while processing current
						const newLogsByTimestamp = analysisStorageHandler.getParsedStorage()
						if (Object.keys(newLogsByTimestamp).length) initiateLogSending()
					}
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}, [logsForPosting])

	return null
}

export default AnalysisLogTransporter
