const locale = {
	name: 'sv',
	weekdays: 'söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag'.split('_'),
	weekdaysShort: 'sön_mån_tis_ons_tor_fre_lör'.split('_'),
	weekdaysMin: 'sö_må_ti_on_to_fr_lö'.split('_'),
	months: 'januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december'.split('_'),
	monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
	weekStart: 1,
	yearStart: 4,
	ordinal: (n) => {
		const b = n % 10
		const o = b === 1 || b === 2 ? 'a' : 'e'
		return `[${n}${o}]`
	},
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'YYYY-MM-DD',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY [kl.] HH:mm',
		LLLL: 'dddd D MMMM YYYY [kl.] HH:mm',
		lll: 'D MMM YYYY HH:mm',
		llll: 'ddd D MMM YYYY HH:mm',
	},
	relativeTime: {
		future: 'om %s',
		past: 'för %s sedan',
		s: 'några sekunder',
		m: 'en minut',
		mm: '%d minuter',
		h: 'en timme',
		hh: '%d timmar',
		d: 'en dag',
		dd: '%d dagar',
		M: 'en månad',
		MM: '%d månader',
		y: 'ett år',
		yy: '%d år',
	},
	calendar: {
		sameDay: '[Idag] LT',
		nextDay: '[Imorgon] LT',
		lastDay: '[Igår] LT',
		nextWeek: '[På] dddd LT',
		lastWeek: '[I] dddd[s] LT',
		sameElse: 'L',
	},
}

export default locale
