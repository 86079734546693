export class RequestTimeoutError extends Error {
	constructor(message) {
		super(message)
		this.name = 'RequestTimeoutError'
		this.message = message || 'Server did not respond in time'
	}
}

export class ConnectionNotReadyError extends Error {
	constructor(message) {
		super(message)
		this.name = 'ConnectionNotReadyError'
		this.message = message || 'Connection is not ready'
	}
}

export class PingTimeoutError extends Error {
	constructor(message) {
		super(message)
		this.name = 'PingTimeoutError'
		this.message = message || 'Did not get a ping response in time'
	}
}
