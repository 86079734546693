import React from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import LoadingComponent from '../uiComponents/utils/LoadingComponent'

const ProfileImageEdit = loadable(() => import('./ProfileImageEdit'), {
	fallback: <LoadingComponent />,
})

const LoadableProfileImageEdit = () => {
	const open = useSelector((state) => !!state.appState.profileImageData)

	if (open) {
		return <ProfileImageEdit />
	}

	return null
}

export default LoadableProfileImageEdit
