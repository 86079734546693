import axios from 'axios'
import websocketClientInstance from './websocketClientInstance'
import appController from '../controllers/appControllerInstance'

// Server side actions
// defined as enum in ClientSessionApp.ts
const ClientRequestType = {
	RUN_ACTION_NODE: 'RUN_ACTION_NODE',
	READ_OBJECTS: 'READ_OBJECTS',
	SET_DATA_SOURCE_DISABLED: 'SET_DATA_SOURCE_DISABLED',
	SET_SUBSCRIBE_TO_UPDATES: 'SET_SUBSCRIBE_TO_UPDATES',
	SET_SKIP: 'SET_SKIP',
	SET_LIMIT: 'SET_LIMIT',
	DELETE_OBJECTS: 'DELETE_OBJECTS',
	SET_SELECTION: 'SET_SELECTION',
	GET_SIDE_EFFECTS: 'GET_SIDE_EFFECTS',
	MODIFY_MULTIPLE_OBJECTS: 'MODIFY_MULTIPLE_OBJECTS',
	CREATE_OBJECT: 'CREATE_OBJECT',
	GET_OBJECT_COUNT: 'DATA/GET_OBJECT_COUNT',
	GET_REFRESHED_DATA: 'GET_REFRESHED_DATA',
	REQUEST_INITIAL_DATA: 'REQ/REQUEST_INITIAL_DATA',
}

/******************************************************************************
 *
 * TODO: Figure out offline/fail-rules for the following ops
 *
 *****************************************************************************/
export const runActionNodeOnServer = (actionId, actionNodeId, clientPayload = {}, overrideTimeout) =>
	websocketClientInstance.sendRequest(
		{
			type: ClientRequestType.RUN_ACTION_NODE,
			payload: {
				actionId,
				actionNodeId,
				clientPayload,
				clientLanguageId: appController.getActiveLanguageId(),
				timeZone: appController.getAppTimeZone(),
			},
		},
		overrideTimeout
	)

/******************************************************************************
 *
 * TODO: Figure out offline/fail-rules for the following ops
 *
 *****************************************************************************/
export const readFilteredObjectsOnServer = (dataSourceId, filter, { contextData, actionId, actionNodeId }) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.READ_OBJECTS,
		payload: {
			dataSourceId,
			filter,
			contextData,
			actionId,
			actionNodeId,
		},
	})

export const deleteObjectsOnServer = (dataSourceId, objectArray, dataForServerFilter) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.DELETE_OBJECTS,
		payload: {
			dataSourceId,
			objectArray,
			dataForServerFilter,
		},
	})

export const deleteFilteredObjectsOnServer = (
	dataSourceId,
	filter,
	{ contextData, actionNodeId, actionId }
) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.DELETE_OBJECTS,
		payload: {
			dataSourceId,
			filter,
			contextData,
			actionId,
			actionNodeId,
		},
	})

export const setSelectionOnServer = (dataSourceId, selectionData) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.SET_SELECTION,
		payload: {
			dataSourceId,
			selectionData,
		},
	})

// Må sende med selectionData
export const modifyMultipleObjects = (dataSourceId, objects, selectionData, changedNodeNames) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.MODIFY_MULTIPLE_OBJECTS,
		payload: {
			dataSourceId,
			objects,
			selectionData,
			changedNodeNames,
		},
	})

export const modifyFilteredObjectsOnServer = (
	dataSourceId,
	filter,
	changes,
	changedNodeNames,
	{ contextData, actionId, actionNodeId }
) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.MODIFY_MULTIPLE_OBJECTS,
		payload: {
			dataSourceId,
			changedNodeNames,
			filter,
			changes,
			contextData,
			actionId,
			actionNodeId,
		},
	})

export const createObject = (dataSourceId, object) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.CREATE_OBJECT,
		payload: {
			dataSourceId,
			object,
		},
	})

export const getTotalObjectCount = (dataSourceId) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.GET_OBJECT_COUNT,
		payload: {
			dataSourceId,
		},
	})

/******************************************************************************
 *
 * If triggered while offline, handshake will include fetching of fresh
 * data
 *
 *****************************************************************************/
export const getSideEffects = (dataSourceId, changeDescription, selectionData) =>
	websocketClientInstance.sendRequest({
		type: ClientRequestType.GET_SIDE_EFFECTS,
		payload: {
			dataSourceId,
			changeDescription,
			selectionData,
		},
	})

/******************************************************************************
 *
 * Config Change on dataSource - Reconnect will need to send APPLY_CONFIG
 * if one of these are invoked
 *
 *****************************************************************************/
export const setDataSourceDisabledOnServer = (dataSourceId, dataSourceDisabled) =>
	websocketClientInstance.sendRequest({
		type: 'SET_DATA_SOURCE_DISABLED',
		payload: {
			dataSourceId,
			dataSourceDisabled,
		},
	})

export const setSubscribeToUpdatesOnServer = (dataSourceId, subscribeToUpdates) =>
	websocketClientInstance.sendRequest({
		type: 'SET_SUBSCRIBE_TO_UPDATES',
		payload: {
			dataSourceId,
			subscribeToUpdates,
		},
	})

export const setLimitOnServer = (dataSourceId, limit) =>
	websocketClientInstance.sendRequest({
		type: 'SET_LIMIT',
		payload: {
			dataSourceId,
			limit,
		},
	})

export const setSkipOnServer = (dataSourceId, skip) =>
	websocketClientInstance.sendRequest({
		type: 'SET_SKIP',
		payload: {
			dataSourceId,
			skip,
		},
	})

export const setTimeZoneOnServer = (timeZone) =>
	websocketClientInstance.sendRequest({
		type: 'SET_TIMEZONE',
		payload: {
			timeZone,
		},
	})
/******************************************************************************
 *
 * Metadata Requests
 *
 *****************************************************************************/

export const getTheme = (themeId, checksum) =>
	new Promise((resolve, reject) => {
		axios
			.get(`/api/v1/theme/${themeId}?v=${checksum}`)
			.then((result) => resolve(result.data))
			.catch(reject)
	})

export const getRefreshedData = (dataSourceId) =>
	websocketClientInstance.sendRequest({
		type: 'GET_REFRESHED_DATA',
		payload: { dataSourceId },
	})

export const getInitialData = (payload, abortController) =>
	websocketClientInstance.sendRequest(
		{
			type: ClientRequestType.REQUEST_INITIAL_DATA,
			payload: payload,
		},
		30000,
		abortController
	)

/******************************************************************************
 *
 * TODO: Move the following to REST based
 *
 *****************************************************************************/
export const requestFileUpload = (payload) =>
	websocketClientInstance.sendRequest({
		type: 'REQUEST_FILE_UPLOAD',
		payload: payload,
	})

export const finalizeFileUpload = (payload) =>
	websocketClientInstance.sendRequest({
		type: 'FINALIZE_FILE_UPLOAD',
		payload: payload,
	})

export const pofileImageUploadRequest = () =>
	new Promise((resolve, reject) => {
		axios
			.get('/api/v1/profileimage/uploadrequest')
			.then((result) => resolve(result.data))
			.catch(reject)
	})

export const pofileImageConfirm = () =>
	new Promise((resolve, reject) => {
		axios
			.post('/api/v1/profileimage/confirm')
			.then((result) => resolve(result.data))
			.catch(reject)
	})

export const deleteProfileImage = () =>
	new Promise((resolve, reject) => {
		axios
			.delete('/api/v1/profileimage/delete')
			.then((result) => resolve(result.data))
			.catch(reject)
	})
