import React, { useCallback, useEffect, createContext, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import isPlainObject from 'lodash/isPlainObject'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'

import { viewConnector } from '#store/dataConnector'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import UiDialog from './UiDialog'
import UiDrawer from './UiDrawer'
import UiComponent from './UiComponent'
import UiAppBar from './UiAppBar'
import LoaderAnimation from '../LoaderAnimation'

import { generateBackgroundStyle } from './utils'

import e_SwitchTransitionType from '@appfarm/common/enums/e_SwitchTransitionType'

import { e_ViewType } from '@appfarm/common/enums/e_PropertyTypes'
import { DragDropContext } from 'react-beautiful-dnd'

import UiKeyboardShortcuts from '../UiKeyboardShortcuts'

import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { getIsReadyForOnViewLoad } from '#selectors/appStateSelectors'

export const ViewContext = createContext({})

const useStyles = makeStyles((theme) => ({
	viewRoot: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		'@media print': {
			position: 'unset',
		},
	},
	defaultColors: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	viewContent: {
		flex: 1,
		position: 'relative',
	},
	hiddenOverflow: {
		overflow: 'hidden',
	},
}))

// Z-index consideration:
// 1150 is between z-index of App Bar (1100) and Drawer (1200)

const variants = {
	[e_SwitchTransitionType.SLIDE]: {
		enter: (direction) => {
			return {
				x: direction === 'back' ? '-20%' : '100%',
				zIndex: direction === 'back' ? 0 : 1150,
				opacity: 1,
			}
		},
		center: {
			x: 0,
			zIndex: 0,
		},
		exit: (direction) => {
			return {
				x: direction === 'back' ? '100%' : '-20%',
				zIndex: direction === 'back' ? 1150 : 0,
				opacity: 1,
			}
		},
	},
	[e_SwitchTransitionType.FADE]: {
		enter: {
			opacity: 0,
			x: 0,
		},
		center: {
			opacity: 1,
			zIndex: 0,
			x: 0,
		},
		exit: {
			opacity: 0,
			zIndex: 1150,
			x: 0,
		},
	},
	[e_SwitchTransitionType.NONE]: {
		enter: {
			opacity: 0,
			x: 0,
		},
		center: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: 0,
		},
	},
}

const containedVariants = {
	[e_SwitchTransitionType.SLIDE]: {
		enter: (direction) => {
			return {
				x: direction === 'back' ? '-100%' : '100%',
				opacity: 0.5,
				zIndex: 1150,
			}
		},
		center: {
			x: 0,
			opacity: 1,
			zIndex: 0,
			transitionEnd: {
				//workaround to set transform:'none' at the end of the animation
				x: 0,
			},
		},
		exit: (direction) => {
			return {
				x: direction === 'back' ? '100%' : '-100%',
				opacity: 0,
			}
		},
	},
	[e_SwitchTransitionType.FADE]: {
		enter: {
			opacity: 0,
			x: 0,
		},
		center: {
			opacity: 1,
			zIndex: 0,
			x: 0,
		},
		exit: {
			opacity: 0,
			zIndex: 1150,
			x: 0,
		},
	},
	[e_SwitchTransitionType.ZOOM]: {
		enter: {
			scale: 0,
			opacity: [0, 1, 1],
			x: 0,
		},
		center: {
			scale: 1,
			opacity: 1,
			zIndex: 0,
		},
		exit: {
			scale: 0,
			opacity: [1, 1, 0],
			zIndex: 1150,
			x: 0,
		},
	},
	[e_SwitchTransitionType.NONE]: {
		enter: {
			opacity: 0,
			x: 0,
		},
		center: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: 0,
		},
	},
}

const transition = {
	[e_SwitchTransitionType.SLIDE]: { duration: 0.3, ease: [0.4, 0.0, 0.2, 1] },
	[e_SwitchTransitionType.FADE]: { duration: 0.2, ease: [0.4, 0.0, 0.2, 1] },
	[e_SwitchTransitionType.NONE]: { duration: 0 },
}

const containedTransition = {
	[e_SwitchTransitionType.SLIDE]: {
		duration: 0.3,
		opacity: { duration: 0.2, times: [0, 0.2, 0.3] },
		ease: [0.4, 0.0, 0.2, 1],
	},
	[e_SwitchTransitionType.FADE]: { duration: 0.2, ease: [0.4, 0.0, 0.2, 1] },
	[e_SwitchTransitionType.ZOOM]: {
		duration: 0.3,
		opacity: { duration: 0.2, times: [0, 0.2, 0.3] },
		ease: [0.4, 0.0, 0.2, 1],
	},
	[e_SwitchTransitionType.NONE]: { duration: 0 },
}

const UiView = (props) => {
	const prevViewIdRef = useRef()
	const readyForOnViewLoad = useSelector(getIsReadyForOnViewLoad)
	const [readyForViewRender, setReadyForViewRender] = useState(
		(() => {
			if (!readyForOnViewLoad) return false

			return !(
				props.view.onViewLoad &&
				props.view.viewType !== e_ViewType.DIALOG &&
				props.view.viewType !== e_ViewType.DRAWER
			)
		})()
	)

	useEffect(() => {
		if (!readyForOnViewLoad) return

		if (
			props.view.onViewLoad &&
			props.view.viewType !== e_ViewType.DIALOG &&
			props.view.viewType !== e_ViewType.DRAWER &&
			props.view.id !== prevViewIdRef.current
		) {
			prevViewIdRef.current = props.view.id
			props.eventHandler(props.view.onViewLoad, undefined, undefined, undefined, {
				resolve: () => setReadyForViewRender(true),
				reject: () => setReadyForViewRender(true),
			})
		} else {
			setReadyForViewRender(true)
		}
	}, [props.view, readyForOnViewLoad])

	useEffect(() => {
		if (!readyForViewRender) return

		if (
			props.view.onViewLoaded &&
			props.view.viewType !== e_ViewType.DIALOG &&
			props.view.viewType !== e_ViewType.DRAWER
		) {
			props.eventHandler(props.view.onViewLoaded)
		}
		return () => {
			if (props.view.onViewUnload && props.view.viewType !== e_ViewType.DIALOG) {
				props.eventHandler(props.view.onViewUnload)
			}
		}
	}, [props.view, readyForViewRender])

	const onDragStart = useCallback((start) => {
		const { draggableId } = start

		const draggableParams = JSON.parse(draggableId)
		const { contextData: draggableContextData, onDragStartEventHandler } = draggableParams

		if (onDragStartEventHandler) {
			props.eventHandler(onDragStartEventHandler, draggableContextData)
		}
	}, [])

	const onDragEnd = useCallback((result) => {
		const { source, destination, draggableId } = result

		if (!destination) return
		if (destination.droppableId === source.droppableId && destination.index === source.index) return

		const draggableParams = JSON.parse(draggableId)
		const { contextData: draggableContextData } = draggableParams

		const droppableParams = JSON.parse(destination.droppableId)
		const { contextData: droppableContextData, onDropEventHandler } = droppableParams

		if (onDropEventHandler) {
			props.eventHandler(onDropEventHandler, {
				...draggableContextData,
				...droppableContextData,
			})
		}
	}, [])

	const muiTheme = useTheme()

	const [styleCache, setStyleCache] = useState()
	const [outerStyleCache, setOuterStyleCache] = useState()

	useEffect(() => {
		const viewStyle = props.view['style_props']

		if (!viewStyle) {
			setStyleCache(undefined)
			return
		}

		const hasDataBoundImage = viewStyle.backgrounds?.some((backgroundItem) =>
			isPlainObject(backgroundItem.imageValue)
		)
		const dataBoundWidth =
			!isNil(viewStyle.widthValue?.dataValue) && isPlainObject(viewStyle.widthValue.dataValue)
		const dataBoundHeight =
			!isNil(viewStyle.heightValue?.dataValue) && isPlainObject(viewStyle.heightValue.dataValue)

		if (!hasDataBoundImage && !dataBoundHeight && !dataBoundWidth) {
			setStyleCache(undefined)
			return
		}

		let style = {}
		const outerStyle = {}
		if (hasDataBoundImage) {
			const getImageUrl = (dataValue) => props.getDataFromDataValue(dataValue)
			const backgroundStyle = generateBackgroundStyle(props.view, muiTheme, { getImageUrl })

			if (backgroundStyle) style = backgroundStyle
		}
		const widthValue = dataBoundWidth ? props.getDataFromDataValue(viewStyle.widthValue.dataValue) : undefined
		const heightValue = dataBoundHeight
			? props.getDataFromDataValue(viewStyle.heightValue.dataValue)
			: undefined

		if (widthValue) {
			outerStyle.width = widthValue
			if (viewStyle.widthValue.unitValue) outerStyle.width += viewStyle.widthValue.unitValue
		}
		if (heightValue) {
			outerStyle.height = heightValue
			if (viewStyle.widthValue.unitValue) outerStyle.height += viewStyle.widthValue.unitValue
		}

		if (Object.keys(style)?.length && !isEqual(style, styleCache)) setStyleCache(style)
		if (Object.keys(outerStyle)?.length && !isEqual(outerStyle, outerStyleCache))
			setOuterStyleCache(outerStyle)
	}, [props.view, muiTheme, props.dataUpdateReference])

	const keyboardEventHandlers = useMemo(() => {
		if (!props.view.keyboardEventHandlers?.length) return undefined

		if (!props.view.keyboardEventHandlers.find((item) => !!item.disabled))
			return props.view.keyboardEventHandlers

		return props.view.keyboardEventHandlers.filter((item) => {
			if (!item.keyBinding || !item.onKeyPress) return false

			const disabled = props.getDataFromDataValue(item.disabled)

			return !disabled
		})
	}, [props.view, props.dataUpdateReference])

	const conditionalClassNames = useMemo(() => {
		const classNames = []
		props.view.conditionalStyles?.forEach((condition, index) => {
			if (!isNil(condition.condition) && props.getDataFromDataValue(condition.condition)) {
				classNames.push('c' + index)
			}
		})
		return classNames.join(' ') || undefined
	}, [props.view, props.dataUpdateReference])

	const classes = useStyles()

	const { view, isContained, dataUpdateReference } = props

	let viewComponent
	if (view.viewType === e_ViewType.DIALOG) {
		viewComponent = (
			<UiDialog
				view={view}
				eventHandler={props.eventHandler}
				conditionalClassNames={conditionalClassNames}
				styleProp={styleCache}
				outerStyleProp={outerStyleCache}
				keyboardEventHandlers={keyboardEventHandlers}
			/>
		)
	} else if (view.viewType === e_ViewType.DRAWER) {
		viewComponent = (
			<UiDrawer
				view={view}
				isMainDrawer={props.isMainDrawer}
				eventHandler={props.eventHandler}
				conditionalClassNames={conditionalClassNames}
				styleProp={styleCache}
				outerStyleProp={outerStyleCache}
				keyboardEventHandlers={keyboardEventHandlers}
			/>
		)
	} else {
		const currentTransition = isContained ? containedTransition : transition
		const currentVariants = isContained ? containedVariants : variants

		viewComponent = readyForViewRender ? (
			<>
				<motion.div
					key={view.id}
					className={classNames(classes.viewRoot, 'c' + view.id + '-bg', conditionalClassNames, {
						[classes.defaultColors]: !isContained,
					})}
					style={styleCache}
					custom={props.direction}
					transition={currentTransition[props.viewTransition]}
					variants={currentVariants[props.viewTransition]}
					initial="enter"
					animate="center"
					exit="exit"
				>
					{ view.appBar?.enabled && <UiAppBar component={view.appBar} /> }
					<div
						className={classNames(classes.viewContent, 'c' + view.id, conditionalClassNames, {
							[classes.hiddenOverflow]: !isContained,
						})}
					>
						{ view.children.map((component) => (
							<UiComponent
								key={component.id}
								component={component}
								dataUpdateReference={dataUpdateReference}
							/>
						)) }
					</div>
				</motion.div>
				{ keyboardEventHandlers?.map((keyboardEventHandler) => (
					<UiKeyboardShortcuts
						key={keyboardEventHandler.id}
						viewId={view.id}
						keyboardEventHandler={keyboardEventHandler}
						eventHandler={props.eventHandler}
					/>
				)) }
			</>
		) : (
			<LoaderAnimation />
		)
	}

	if (view.enableDragAndDrop) {
		viewComponent = (
			<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
				{ viewComponent }
			</DragDropContext>
		)
	}

	return viewComponent
}

UiView.propTypes = {
	app: PropTypes.object.isRequired,
	view: PropTypes.object.isRequired,
	isMainDrawer: PropTypes.bool,
	direction: PropTypes.oneOf(['forward', 'back']),
	viewTransition: PropTypes.oneOf([
		e_SwitchTransitionType.NONE,
		e_SwitchTransitionType.FADE,
		e_SwitchTransitionType.SLIDE,
		e_SwitchTransitionType.ZOOM,
	]),
	isContained: PropTypes.bool, // Contained in UiViewContainer
	theme: PropTypes.object,
	dataUpdateReference: PropTypes.number,
	getDataFromDataValue: PropTypes.func.isRequired,
	eventHandler: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
	const mapStateToProps = (_ownProps, { getDataFromDataValue }) => {
		return {
			getDataFromDataValue,
		}
	}
	return mapStateToProps
}

export default viewConnector(makeMapStateToProps)(UiView)
