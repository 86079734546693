import { Transport, e_Level, MetadataArgument } from '../types'

const formatByLevel = {
	[e_Level.EMERG]: 'color: #400000; background-color: #ffc7c7; font-weight: bold',
	[e_Level.CRIT]: 'color: #d32f2f; background-color: #fceded; font-weight: bold',
	[e_Level.ERROR]: 'color: #d32f2f; background-color: #fceded',
	[e_Level.WARNING]: 'color: #f57c00; font-weight: bold',
	[e_Level.NOTICE]: 'color: #004b96; font-weight: bold',
	[e_Level.INFO]: 'color: #004b96',
	[e_Level.DEBUG]: '',
}

const iconByLevel = {
	[e_Level.EMERG]: String.fromCodePoint(0x274c),
	[e_Level.CRIT]: String.fromCodePoint(0x2757),
	[e_Level.ERROR]: String.fromCodePoint(0x2716),
	[e_Level.WARNING]: String.fromCodePoint(0x26a0),
	[e_Level.NOTICE]: '',
	[e_Level.INFO]: '',
	[e_Level.DEBUG]: '',
}

class ConsoleTransport implements Transport {
	id: string
	level: e_Level

	constructor({ level }: { level: e_Level }) {
		this.id = 'console'
		this.level = level
	}

	shouldLog() {
		return true
	}

	getMetaInfo(metadata: MetadataArgument) {
		const metaInfo = { ...metadata }
		if (metaInfo.prefix) delete metaInfo.prefix
		if (metaInfo.labels) delete metaInfo.labels
		if (metaInfo.err) delete metaInfo.err
		return metaInfo
	}

	log({
		level,
		message,
		metadata,
	}: {
		level: e_Level
		message: string
		timestamp: number
		metadata: MetadataArgument
	}) {
		let msg = message
		if (typeof message !== 'string') {
			msg = JSON.stringify(message)
		}

		let levelIcon: string = iconByLevel[level]
		const metaInfo = this.getMetaInfo(metadata)
		if (levelIcon) msg = `${levelIcon} ${msg}`

		const args: any[] = [`%c${msg}`, formatByLevel[level]]
		if (Object.keys(metaInfo).length) {
			args.push(metaInfo)
		}

		console.log(...args)

		if (metadata.err) {
			console.error(metadata.err)
		}

		return msg
	}

	table({ metadata, data, columns }: { metadata: MetadataArgument; data: any; columns?: string[] }) {
		const metaInfo = this.getMetaInfo(metadata)
		const args = Object.keys(metaInfo).length ? [metaInfo] : []

		console.log(...args)
		console.table(data, columns)
		return data
	}

	context({ metadata, data }: { metadata: MetadataArgument; data: any }) {
		const metaInfo = this.getMetaInfo(metadata)
		const args = Object.keys(metaInfo).length ? [metaInfo] : []

		console.log('Context data: ')
		console.log(...args)
		console.log(data)
		return data
	}
}

export default ConsoleTransport
