// Northern Sami [se]

const locale = {
	name: 'se',
	weekdays: 'sotnabeaivi_vuossárga_maŋŋebárga_gaskavahkku_duorastat_bearjadat_lávvardat'.split('_'),
	months:
		'ođđajagemánnu_guovvamánnu_njukčamánnu_cuoŋománnu_miessemánnu_geassemánnu_suoidnemánnu_borgemánnu_čakčamánnu_golggotmánnu_skábmamánnu_juovlamánnu'.split(
			'_'
		),
	weekStart: 1,
	weekdaysShort: 'sotn_vuos_maŋ_gask_duor_bear_láv'.split('_'),
	monthsShort: 'ođđj_guov_njuk_cuo_mies_geas_suoi_borg_čakč_golg_skáb_juov'.split('_'),
	weekdaysMin: 's_v_m_g_d_b_L'.split('_'),
	ordinal: (n) => n,
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'MMMM D. [b.] YYYY',
		LLL: 'MMMM D. [b.] YYYY [ti.] HH:mm',
		LLLL: 'dddd, MMMM D. [b.] YYYY [ti.] HH:mm',
	},
	relativeTime: {
		future: '%s geažes',
		past: 'maŋit %s',
		s: 'moadde sekunddat',
		m: 'okta minuhta',
		mm: '%d minuhtat',
		h: 'okta diimmu',
		hh: '%d diimmut',
		d: 'okta beaivi',
		dd: '%d beaivvit',
		M: 'okta mánnu',
		MM: '%d mánut',
		y: 'okta jahki',
		yy: '%d jagit',
	},
}

export default locale
