export interface KeyValueMap {
	[key: string]: string | number | boolean | undefined | null | KeyValueMap
}

export type keyValue = string | number | boolean | undefined | null | KeyValueMap

class StorageController {
	private storageKey: string
	private storage: any
	private parsedStorage: KeyValueMap
	constructor(storageKey: string, storage: any) {
		this.storageKey = storageKey || '__APPFARM__UNSET'
		this.storage = storage || window.localStorage
		this.parsedStorage = {}

		this._init()
	}

	private _getAndSetParsedStorage(): keyValue {
		try {
			const localStorage = this.storage.getItem(this.storageKey)
			if (localStorage) this.parsedStorage = JSON.parse(localStorage)
			else this.parsedStorage = {}
		} catch (err) {
			this.parsedStorage = {}
			localStorage.removeItem(this.storageKey)
		}

		return this.parsedStorage
	}

	private _init(): void {
		this._getAndSetParsedStorage()
	}

	private _getkeyValueByPath(path: string[], storage: KeyValueMap): keyValue {
		if (!path || !path.length || !storage) return

		const key = path[0]

		if (path.length === 1) {
			return storage[key]
		} else {
			const partStorage = storage[key]
			if (typeof partStorage === 'object' && partStorage)
				return this._getkeyValueByPath(path.slice(1), partStorage)
		}
	}

	getParsedStorage(): keyValue {
		return this.parsedStorage
	}

	getValue(path: string[]): keyValue {
		return this._getkeyValueByPath(path, this.parsedStorage)
	}

	private _getUpdatedStorage({
		path,
		value,
		storage = {},
		clearValue,
	}: {
		path: string[]
		value?: keyValue
		storage: KeyValueMap
		clearValue?: boolean
	}): KeyValueMap {
		const key = path[0]

		if (path.length === 1) {
			if (clearValue) {
				delete storage[key]
			} else {
				storage[key] = value
			}
			return storage
		} else {
			const partStorage = storage[key] || {}
			if (typeof partStorage === 'object' && partStorage)
				return {
					...storage,
					[key]: this._getUpdatedStorage({ path: path.slice(1), value, storage: partStorage, clearValue }),
				}
		}

		return storage
	}

	private _setValue(path: string[] = [], value: keyValue): void {
		if (!path.length) {
			if (typeof value === 'object' && value) this.parsedStorage = value
		} else {
			this.parsedStorage = this._getUpdatedStorage({ path, value, storage: this.parsedStorage })
		}
	}

	private _setStorage(): void {
		const stringifiedStorage = JSON.stringify(this.parsedStorage)
		try {
			this.storage.setItem(this.storageKey, stringifiedStorage)
		} catch (err) {
			console.log('Unable to store value to storage.')
		}
	}

	setValue(path: string[], value: keyValue): void {
		this._setValue(path, value)
		this._setStorage()
	}

	private _clearStorage(): void {
		this.storage.removeItem(this.storageKey)
	}

	clearValues(): void {
		if (!Object.keys(this.parsedStorage).length) return

		this.parsedStorage = {}
		this._clearStorage()
	}

	private _clearValue(path: string[] = []): void {
		if (!path.length) {
			this.parsedStorage = {}
		} else {
			this.parsedStorage = this._getUpdatedStorage({
				path,
				clearValue: true,
				storage: this.parsedStorage,
			})
		}
	}

	clearValue(path: string[]): void {
		this._clearValue(path)
		if (!Object.keys(this.parsedStorage).length) {
			this._clearStorage()
		} else {
			this._setStorage()
		}
	}

	cleanKeyValuesByPath(path: string[], keyDict: { [key: string]: boolean }): void {
		const keyValues = this.getValue(path)

		if (!keyValues) return

		Object.keys(keyValues).forEach(([key, _value]) => {
			if (!keyDict[key]) {
				this.clearValue([...path, key])
			}
		})
	}
}

export default StorageController
