import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
	isServerSessionReady,
	isServerClientStateInSync,
	isLastConnectionToSameSession,
} from '#selectors/appStateSelectors'
import { isDataModelReady } from '#selectors/metadataSelectors'
import { getInitialData } from '#modules/afClientApi'
import { setServerClientStateInSyncState, setCurrentAppHasInitialData } from '#actions/appStateActions'
import sessionStorageHandler from '#modules/sessionStorageHandler'

import appController from '../../../controllers/appControllerInstance'
import IndexDbAppStorageEngine from '../../../controllers/IndexDbAppStorageEngine'
import logger from '#logger/logger'

const getRandomInteger = (max) => Math.floor(Math.random() * max)

const MAX_LOAD_TIME = 10 * 1000
const RANDOM_TIME = getRandomInteger(3000) // 0-3 sec randomly
const MAX_MIN_TIME_BETWEEN_LOADS = 20 * 1000

const restructureDataFromCache = (dataFromCache) => {
	const returnData = {}
	Object.entries(dataFromCache).forEach(([key, value]) => {
		returnData[key] = {
			data: value,
		}
	})
	return returnData
}

const DataLoader = ({ activeAppId, appStorageEngine }) => {
	// Local State
	const [initialDataSet, setInitialDataSet] = useState(false)
	const [latestData, setLatestData] = useState(null)
	const [dataFromCache, setDataFromCache] = useState(null)
	const [dataFromCacheSet, setDataFromCacheSet] = useState(false)
	const [debounceActive, setDebounceActive] = useState(true)
	const initiationTimer = useRef(null)

	// External State
	const clientStateInSync = useSelector(isServerClientStateInSync)
	const serverSessionIsReady = useSelector(isServerSessionReady)
	const dataModelReady = useSelector(isDataModelReady)
	const lastConnectionToSameSession = useSelector(isLastConnectionToSameSession)
	const dispatch = useDispatch()

	/******************************************************************************
	 *
	 * Housekeeping effects
	 *
	 *****************************************************************************/

	// Effect for clearing data on app change
	useEffect(() => {
		setInitialDataSet(false)
		setLatestData(null)

		// Functionallity for delayed data load when app is refreshed several times
		const appId = sessionStorageHandler.getValue(['dataLoader', 'appId'])
		if (appId !== activeAppId) {
			clearTimeout(initiationTimer.current)
			sessionStorageHandler.setValue(['dataLoader', 'appId'], activeAppId)
			sessionStorageHandler.clearValue(['dataLoader', 'initiatedAt'])
			sessionStorageHandler.clearValue(['dataLoader', 'loadTime'])
		}
	}, [activeAppId])

	// Clear status as long as datamodel is not ready
	useEffect(() => {
		if (dataModelReady) return
		// Reset sync functionallity on data model change
		setInitialDataSet(false)
		setLatestData(null)
		dispatch(setServerClientStateInSyncState(false))
	}, [dataModelReady])

	// Debounce logic
	useEffect(() => {
		const lastInitiation = sessionStorageHandler.getValue(['dataLoader', 'initiatedAt'])
		const lastLoadTime = sessionStorageHandler.getValue(['dataLoader', 'loadTime'])
		let initiationTimeout = lastLoadTime
		if (!initiationTimeout || initiationTimeout > MAX_LOAD_TIME) initiationTimeout = MAX_LOAD_TIME

		let minTimeBetweenLoads = lastLoadTime
		if (!minTimeBetweenLoads || minTimeBetweenLoads > MAX_MIN_TIME_BETWEEN_LOADS)
			minTimeBetweenLoads = MAX_MIN_TIME_BETWEEN_LOADS

		const currentInitiation = Date.now()
		if (lastInitiation && currentInitiation - lastInitiation < minTimeBetweenLoads) {
			sessionStorageHandler.setValue(['dataLoader', 'initiatedAt'], currentInitiation)
			clearTimeout(initiationTimer.current)
			initiationTimer.current = setTimeout(() => setDebounceActive(false), initiationTimeout + RANDOM_TIME)
		} else {
			sessionStorageHandler.setValue(['dataLoader', 'initiatedAt'], currentInitiation)
			setDebounceActive(false)
		}
	}, [])

	// Effect for merging server data into cache
	useEffect(() => {
		if (!clientStateInSync) return
		if (appStorageEngine) {
			appStorageEngine.populateAllInitialData(appController.getAllDataForCache())
		}
	}, [clientStateInSync])

	/******************************************************************************
	 *
	 * Effect for populating datasources from local state in dataLoader
	 *
	 *****************************************************************************/

	useEffect(() => {
		// Always wait for datamodel
		if (!dataModelReady) return
		let markReady = false

		// Set data from cache once
		if (!dataFromCacheSet && dataFromCache) {
			setDataFromCacheSet(true)
			appController.setInitialDataFromCache(dataFromCache)
			markReady = true
		}

		if (latestData) {
			appController.setInitialDataFromServer(latestData)
			markReady = true
			dispatch(setServerClientStateInSyncState(true))
			setInitialDataSet(true)
		}

		if (markReady) {
			dispatch(setCurrentAppHasInitialData())
		}
	}, [dataModelReady, dataFromCache, latestData, dataFromCacheSet])

	/******************************************************************************
	 *
	 * Effects for loading data into local state
	 *
	 *****************************************************************************/

	// Effect for getting initial data from cache
	useEffect(() => {
		if (!appStorageEngine) {
			appController.initializeRuntimeDataSources()
			return
		}

		if (appStorageEngine.getIsPopulated()) {
			appStorageEngine
				.getAllData()
				.then((data) => {
					console.log('Found data in cache')
					setDataFromCache(restructureDataFromCache(data))
					appController.initializeEnumDataSources()
				})
				.catch((error) => {
					console.warn('Failed to load data from cache', error)
					appController.initializeRuntimeDataSources()
				})
		} else {
			console.log("Storage engine isn't populated")
			appController.initializeRuntimeDataSources()
		}
	}, [])

	// Effect for getting initial data from server
	useEffect(() => {
		if (!activeAppId) return // No active app - not really sure if this will happen
		if (!dataModelReady) return
		if (initialDataSet) return // App already have data
		if (clientStateInSync) return // No need to act
		if (!serverSessionIsReady) return // Not connected
		if (debounceActive) return // Debounce active

		// Runtime datasources must be populated before this
		// is triggered. Eiter from cache or initialized.
		const controller = new AbortController()
		const startTime = Date.now()
		const configForSync = appController.getDataSourceConfigForSynchronization()
		getInitialData({ appId: activeAppId, config: configForSync }, controller)
			.then((result) => {
				const endTime = Date.now()
				const loadTime = endTime - startTime
				sessionStorageHandler.setValue(['dataLoader', 'loadTime'], loadTime)

				if (controller.signal?.aborted) return
				console.log('Initial data', result.data)
				setLatestData(result.data)
			})
			.catch((err) => {
				setLatestData({})
				logger.error('Unable to load initial data for app', { err })
			})

		return () => controller.abort()
	}, [activeAppId, dataModelReady, initialDataSet, clientStateInSync, serverSessionIsReady, debounceActive])

	// Effect for handling debounce logic

	/******************************************************************************
	 *
	 * Synchronizing data on reconnection
	 *
	 *****************************************************************************/
	useEffect(() => {
		if (!initialDataSet) return
		if (!serverSessionIsReady) return // Not connected
		if (clientStateInSync) return

		// No need for data-sync. Attributes and selections
		// ared handled by handshake
		// TODO: run data-sync if the user has done selection changes
		// while offline.
		if (lastConnectionToSameSession) {
			dispatch(setServerClientStateInSyncState(true))
			return
		}

		const controller = new AbortController()

		const configForSync = appController.getDataSourceConfigForSynchronization()
		getInitialData({ appId: activeAppId, config: configForSync }, controller)
			.then((result) => {
				if (controller.signal?.aborted) return
				appController.setSynchronizedData(result.data)
				dispatch(setServerClientStateInSyncState(true))
			})
			.catch((err) => {
				logger.error('Unable to get synchronized data on reconnection', { err })
			})

		return () => controller.abort()
	}, [initialDataSet, serverSessionIsReady, clientStateInSync, lastConnectionToSameSession])

	return null
}

DataLoader.propTypes = {
	activeAppId: PropTypes.string.isRequired,
	appStoargeEnine: PropTypes.instanceOf(IndexDbAppStorageEngine),
}

export default DataLoader
