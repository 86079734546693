import executeBlockInSequence from './helpers/executeBlockInSequence'
import jsonDataMapper from '@appfarm/common/utils/jsonDataMapper'
import evaluateFunctionValue from '#utils/functionEvaluator'
import getDataSourceDataFromJSONValueMap from './helpers/getDataForDSFromJSONValueMap'
import { CONSTANT } from '@appfarm/common/enums/e_FunctionParameterType'

const mapExceptionMetadata = ({ actionNode, appController, error, actionNodeLogger, contextData }) => {
	const body = error.metadata && error.metadata.responseData
	let data = {
		...body,
		code: error.code,
		httpStatusCode: error.httpStatusCode,
		message: error.message,
		name: error.name,
		body,
	}

	if (actionNode.resultParser) {
		// Do not change parameters - defined in CatchException model.
		const resultParameters = [
			{
				id: 'dummy_id_http_status_code',
				name: 'httpStatusCode',
				functionParameterType: CONSTANT,
				value: data.httpStatusCode,
			},
			{
				id: 'dummy_id_code',
				name: 'code',
				functionParameterType: CONSTANT,
				value: data.code,
			},
			{
				id: 'dummy_id_name',
				name: 'name',
				functionParameterType: CONSTANT,
				value: data.name,
			},
			{
				id: 'dummy_id_message',
				name: 'message',
				functionParameterType: CONSTANT,
				value: data.message,
			},
			{
				id: 'dummy_id_body',
				name: 'body',
				functionParameterType: CONSTANT,
				value: data.body,
			},
		]

		const jsonData = evaluateFunctionValue({
			ignoreReturnDatatypeCheck: true,
			appController: appController,
			contextData: contextData, // Old object in context
			reThrowError: true,
			functionValue: {
				...actionNode.resultParser,
				functionParameters: [...(actionNode.resultParser.functionParameters || []), ...resultParameters],
			},
		})

		actionNodeLogger.debug('Parsed Result', { payload: jsonData })
		data = jsonData
	}

	// Map Result
	if (data && actionNode.resultMapping && actionNode.resultMapping.length) {
		actionNodeLogger.debug('Got error data: ', { payload: data })

		const parsedJsonData = actionNode.resultMapping.reduce((mappedResult, item) => {
			const mappedDataSource = jsonDataMapper(item, data)

			return {
				...mappedResult,
				...mappedDataSource,
			}
		}, {})

		// Clean the result
		const dataForDataSource = getDataSourceDataFromJSONValueMap({
			valueMaps: actionNode.resultMapping,
			appController,
			parsedJsonData,
			logger: actionNodeLogger,
		})

		appController.replaceOrAddDataInMultipleDataSources(dataForDataSource, actionNodeLogger)
	}
}

const catchException = ({
	actionNode,
	contextData,
	appController,
	error,
	actionNodeRunner,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		if (actionNode.disabled) return resolve()

		if (error) {
			actionNodeLogger.info('Catching Exception', { err: error })
			mapExceptionMetadata({
				actionNode,
				appController,
				error,
				actionNodeLogger,
				contextData,
			})
			if (actionNodeRunner.children.length) {
				executeBlockInSequence(actionNodeRunner.children, contextData, actionNodeLogger)
					.then((result) => resolve(result))
					.catch((err) => reject(err))
			} else {
				resolve()
			}
		} else {
			actionNodeLogger.debug('Catch Exception: No need to run if not an exception')
			resolve()
		}
	})

export default catchException
