const locale = {
	name: 'hy-am',
	weekdays: 'կիրակի_երկուշաբթի_երեքշաբթի_չորեքշաբթի_հինգշաբթի_ուրբաթ_շաբաթ'.split('_'),
	months:
		'հունվարի_փետրվարի_մարտի_ապրիլի_մայիսի_հունիսի_հուլիսի_օգոստոսի_սեպտեմբերի_հոկտեմբերի_նոյեմբերի_դեկտեմբերի'.split(
			'_'
		),
	weekStart: 1,
	weekdaysShort: 'կրկ_երկ_երք_չրք_հնգ_ուրբ_շբթ'.split('_'),
	monthsShort: 'հնվ_փտր_մրտ_ապր_մյս_հնս_հլս_օգս_սպտ_հկտ_նմբ_դկտ'.split('_'),
	weekdaysMin: 'կրկ_երկ_երք_չրք_հնգ_ուրբ_շբթ'.split('_'),
	ordinal: (n) => n,
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D MMMM YYYY թ.',
		LLL: 'D MMMM YYYY թ., HH:mm',
		LLLL: 'dddd, D MMMM YYYY թ., HH:mm',
	},
	relativeTime: {
		future: '%s հետո',
		past: '%s առաջ',
		s: 'մի քանի վայրկյան',
		m: 'րոպե',
		mm: '%d րոպե',
		h: 'ժամ',
		hh: '%d ժամ',
		d: 'օր',
		dd: '%d օր',
		M: 'ամիս',
		MM: '%d ամիս',
		y: 'տարի',
		yy: '%d տարի',
	},
	calendar: {
		sameDay: '[այսօր] LT',
		nextDay: '[վաղը] LT',
		lastDay: '[երեկ] LT',
		nextWeek: 'dddd [օրը ժամը] LT',
		lastWeek: '[անցած] dddd [օրը ժամը] LT',
		sameElse: 'L',
	},
}

export default locale
