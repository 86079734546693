/**
 * This method will take a filter and an object and return a boolean
 * telling if the object is included or excluded by the filter.
 *
 * This module supports a subset of the filter syntax that
 * is used by MongoDB
 */
import isPlainObject from 'lodash/isPlainObject'
import isUndefined from 'lodash/isUndefined'
import isArray from 'lodash/isArray'

import {
	EQUALS,
	NOT_EQUALS,
	LESS_THAN,
	LESS_THAN_OR_EQUAL,
	GREATER_THAN,
	GREATER_THAN_OR_EQUAL,
	EXISTS,
	IN,
	NOT_IN,
	AND,
	OR,
	NOR,
	CONTAINS,
	CONTAINS_ALL,
	CONTAINS_ALL_WORDS,
	CONTAINS_ANY,
	NOT_CONTAINS,
	NOT_CONTAINS_ALL,
	NOT_CONTAINS_ALL_WORDS,
	NOT_CONTAINS_ANY,
	HAS_VALUE,
	HAS_NO_VALUE,
	SIZE,
	ALL,
	NOT,
	EXPRESSION,
} from '@appfarm/common/enums/e_FilterOperator'
import {
	evaluateContains,
	evaluateContainsAll,
	evaluateContainsAllWords,
	evaluateContainsAny,
} from './containsEvaluatorUtils'

export const evaluateSinglePropertyFilter = (object, propertyPath, filterNode) => {
	let propertyName = propertyPath
	let checkFirstInMultiProperty // TODO: finn bedre navn..
	if (propertyPath.includes('.0')) {
		propertyName = propertyPath.split('.')[0]
		checkFirstInMultiProperty = true
	}
	// Filteret er et objekt av noe slag
	if (isPlainObject(filterNode)) {
		return Object.keys(filterNode).every((operator) => {
			let propertyValue
			let filterValue
			if (propertyPath === EXPRESSION) {
				// get both values from self
				if (filterNode[operator].length === 2) {
					propertyValue = object[filterNode[operator][0].replace('$', '')]
					filterValue = object[filterNode[operator][1].replace('$', '')]
				}
			} else {
				propertyValue = object[propertyName]
				filterValue = filterNode[operator]
			}

			// Handle wierd js quirk
			/* eslint-disable no-fallthrough */
			switch (operator) {
				case LESS_THAN:
				case LESS_THAN_OR_EQUAL:
					if (propertyValue === null) return false
				case GREATER_THAN:
				case GREATER_THAN_OR_EQUAL:
					if (filterValue === null) return false
			}

			switch (operator) {
				case EQUALS:
					/* if (isArray(propertyValue) && isArray(filterValue))
						return (
							propertyValue.length === filterValue.length &&
							propertyValue.every((subValue) => filterValue.includes(subValue))
						)
					if (!isArray(propertyValue) && isArray(filterValue))
						return filterValue.length === 1 && filterValue[0] === propertyValue
					if (isArray(propertyValue) && !isArray(filterValue))
						return propertyValue.length === 1 && propertyValue[0] === filterValue */

					if (isArray(propertyValue) || isArray(filterValue))
						throw new Error(
							'evaluateSinglePropertyFilter - operator ' +
								operator +
								' not valid for multi cardinality properties'
						)

					if (isUndefined(filterValue)) filterValue = null
					return propertyValue === filterValue

				case NOT_EQUALS:
					/* if (isArray(propertyValue) && isArray(filterValue))
						return (
							propertyValue.length !== filterValue.length ||
							!propertyValue.every((subValue) => filterValue.includes(subValue))
						)
					if (!isArray(propertyValue) && isArray(filterValue))
						return filterValue.length !== 1 || filterValue[0] !== propertyValue
					if (isArray(propertyValue) && !isArray(filterValue))
						return propertyValue.length !== 1 || propertyValue[0] !== filterValu */

					if (isArray(propertyValue) || isArray(filterValue))
						throw new Error(
							'evaluateSinglePropertyFilter - operator ' +
								operator +
								' not valid for multi cardinality properties'
						)

					if (filterValue === null && isUndefined(propertyValue)) return false
					return propertyValue !== filterValue

				case LESS_THAN:
					return propertyValue < filterValue

				case LESS_THAN_OR_EQUAL:
					return propertyValue <= filterValue

				case GREATER_THAN:
					return propertyValue > filterValue

				case GREATER_THAN_OR_EQUAL:
					return propertyValue >= filterValue

				// HAS_VALUE og HAS_NO_VALUE blir nok aldri evaluert her
				// de konverteres til mongoDB-kompatible spørringer på server
				case HAS_VALUE:
					return !(
						isUndefined(propertyValue) ||
						propertyValue === null ||
						propertyValue === '' ||
						(isArray(propertyValue) && propertyValue.length === 0)
					)

				case HAS_NO_VALUE:
					return (
						isUndefined(propertyValue) ||
						propertyValue === null ||
						propertyValue === '' ||
						(isArray(propertyValue) && propertyValue.length === 0)
					)

				case EXISTS: {
					if (checkFirstInMultiProperty) {
						if (filterValue) {
							return !isUndefined(propertyValue) && isArray(propertyValue) && propertyValue.length
						} else {
							return isUndefined(propertyValue) || (isArray(propertyValue) && !propertyValue.length)
						}
					}
					return filterValue ? !isUndefined(propertyValue) : isUndefined(propertyValue)
				}

				case ALL:
					return isArray(propertyValue) && filterValue.every((subValue) => propertyValue.includes(subValue))

				case IN: {
					return filterValue.some((subValue) => {
						//MultiProperty && Multi Reference
						if (isArray(propertyValue) && isArray(subValue))
							return propertyValue.some((subProperty) => subValue.includes(subProperty))

						//MultiProperty && Single Reference
						if (isArray(propertyValue) && !isArray(subValue)) return propertyValue.includes(subValue)

						// SingleProperty && Multi Reference
						if (isArray(subValue)) return subValue.includes(propertyValue)

						// SingleProperty && Single Reference
						return subValue === propertyValue
					})
				}

				case NOT_IN: {
					return filterValue.every((subValue) => {
						//MultiProperty && Multi Reference
						if (isArray(propertyValue) && isArray(subValue))
							return propertyValue.every((subProperty) => !subValue.includes(subProperty))

						//MultiProperty && Single Reference
						if (isArray(propertyValue) && !isArray(subValue)) return !propertyValue.includes(subValue)

						// SingleProperty && Multi Reference
						if (isArray(subValue)) return !subValue.includes(propertyValue)

						// SingleProperty && Single Reference
						return subValue !== propertyValue
					})
				}

				case CONTAINS:
					return evaluateContains(propertyValue, filterValue)
				case CONTAINS_ALL:
					return evaluateContainsAll(propertyValue, filterValue)
				case CONTAINS_ALL_WORDS:
					return evaluateContainsAllWords(propertyValue, filterValue)
				case CONTAINS_ANY:
					return evaluateContainsAny(propertyValue, filterValue)

				case NOT_CONTAINS:
					return !evaluateContains(propertyValue, filterValue)
				case NOT_CONTAINS_ALL:
					return !evaluateContainsAll(propertyValue, filterValue)
				case NOT_CONTAINS_ALL_WORDS:
					return !evaluateContainsAllWords(propertyValue, filterValue)
				case NOT_CONTAINS_ANY:
					return !evaluateContainsAny(propertyValue, filterValue)

				case SIZE:
					return propertyValue.length === filterValue

				case NOT:
					return !evaluateSinglePropertyFilter(object, propertyPath, filterValue)

				default:
					throw new Error('evaluateSinglePropertyFilter - Unknown operator: ' + operator)
			}
		})
	} else {
		const propertyValue = object[propertyName]
		const filterValue = filterNode
		// Filteret er en verdi og kan sammenlignes direkte
		// Samme som { $eq: value }

		/* if (filterValue === null && isUndefined(propertyValue)) return true

		if (isArray(propertyValue) && isArray(filterValue))
			return (
				propertyValue.length === filterValue.length &&
				propertyValue.every((subValue) => filterValue.includes(subValue))
			)
		if (!isArray(propertyValue) && isArray(filterValue))
			return filterValue.length === 1 && filterValue[0] === propertyValue
		if (isArray(propertyValue) && !isArray(filterValue))
			return propertyValue.length === 1 && propertyValue[0] === filterValue */

		if (isArray(propertyValue) || isArray(filterValue))
			throw new Error('evaluateSinglePropertyFilter - operator not valid for multi cardinality properties')

		if (filterValue === null && isUndefined(propertyValue)) return true
		return propertyValue === filterValue
	}
}

// Top level and group nodes
// Filter looks like a mongoDB query
export const evaluateObjectWithFilterNode = (object, filterNode) => {
	// No valid filter - disqualify
	if (!filterNode) return false

	return Object.keys(filterNode).every((filterKey) => {
		switch (filterKey) {
			// Group operators
			case AND:
				return filterNode[filterKey].every((subFilterNode) =>
					evaluateObjectWithFilterNode(object, subFilterNode)
				)

			case OR:
				return filterNode[filterKey].some((subFilterNode) =>
					evaluateObjectWithFilterNode(object, subFilterNode)
				)

			case NOR:
				return filterNode[filterKey].every(
					(subFilterNode) => !evaluateObjectWithFilterNode(object, subFilterNode)
				)

			default:
				// objectKey
				return evaluateSinglePropertyFilter(object, filterKey, filterNode[filterKey])
		}
	})
}

/**
 * This function will filter an array of objects
 * according to the filter provided. Can be either a generated
 * filter or a static one.
 */

export const filterEvaluator = (objectArray, filter) =>
	objectArray.filter((dataObject) => evaluateObjectWithFilterNode(dataObject, filter))
