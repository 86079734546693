import { useEffect } from 'react'
import PropTypes from 'prop-types'

const createElement = (url) => {
	const element = document.createElement('link')
	element.rel = 'stylesheet'
	element.href = url
	element.media = 'all'
	element.class = 'css_loader_dynamic'
	return element
}

const CssLoader = ({ cssUrls = [] }) => {
	useEffect(() => {
		if (!cssUrls?.length) return

		const elementList = cssUrls.map((url) => {
			const element = createElement(url)
			document.head.appendChild(element)
			return element
		})

		return () => elementList.map((element) => document.head.removeChild(element))
	}, [cssUrls])

	return null
}

CssLoader.propTypes = {
	cssUrls: PropTypes.arrayOf(PropTypes.string),
}

export default CssLoader
