import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import ResetPasswordPage from './ResetPasswordPage'
import StatusPage from './StatusPage'
import TokenLoginPage from './TokenLoginPage'

import browserHistory from '../../browserHistory'

import LoginWrapper from '../common/LoginWrapper'

const SecondaryLogin = () => {
	return (
		<LoginWrapper>
			<Router history={browserHistory}>
				<Switch>
					<Route path="/afa/resetpassword/:serverToken" exact component={ResetPasswordPage} />
					<Route path="/afa/tokenlogin/:serverToken" exact component={TokenLoginPage} />
					<Route path="/afa/status/:serverToken" exact component={StatusPage} />
				</Switch>
			</Router>
		</LoginWrapper>
	)
}

export default SecondaryLogin
