import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1251,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	canvas: {
		display: 'block',
		zIndex: 1251,
		position: 'absolute',
	},
})

class UiImageCanvas extends Component {
	constructor(props) {
		super(props)

		this.scalingFactor = 1

		this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
		this.handleOnTouchStart = this.handleOnTouchStart.bind(this)
		this.handleOnMouseMove = this.handleOnMouseMove.bind(this)
		this.handleOnTouchMove = this.handleOnTouchMove.bind(this)
		this.handleonMouseUp = this.handleonMouseUp.bind(this)

		this.draw = this.draw.bind(this)
		this.resetCanvas = this.resetCanvas.bind(this)

		this.saveToHistory = this.saveToHistory.bind(this)
		this.setCanvasReference = this.setCanvasReference.bind(this)

		this.setCanvasSize = this.setCanvasSize.bind(this)
	}

	componentDidMount() {
		this.setCanvasSize()

		document.body.style.position = 'fixed'
		document.body.style.top = `-${window.scrollY}px`
		document.body.style.touchAction = 'none'
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps?.imageSize?.height !== this.props?.imageSize?.height ||
			prevProps?.imageSize?.width !== this.props?.imageSize?.width
		) {
			this.setCanvasSize()
			// redraw
			this.canvasContext = this.canvas.getContext('2d')
			this.props.history.forEach((data) => {
				this.canvasContext.drawImage(data, 0, 0)
			})
		}
	}

	componentWillUnmount() {
		const scrollY = document.body.style.top
		document.body.style.position = ''
		document.body.style.top = ''
		document.body.style.touchAction = 'auto'
		window.scrollTo(0, parseInt(scrollY || '0') * -1)
	}

	setCanvasSize() {
		this.canvas.style.width = '100%'
		this.canvas.style.height = '100%'

		this.canvasContext = this.canvas.getContext('2d')

		this.canvas.height = this.props.imageRef.naturalHeight
		this.canvas.width = this.props.imageRef.naturalWidth

		// Get the actual space for canvas control
		const canvasOffsetHeight = this.canvasContainer.offsetHeight
		const canvasOffsetWidth = this.canvasContainer.offsetWidth

		if (
			canvasOffsetHeight >= this.props.imageRef.naturalHeight &&
			canvasOffsetWidth >= this.props.imageRef.naturalWidth
		) {
			// Just set size
			this.canvas.style.width = this.props.imageRef.naturalWidth + 'px'
			this.canvas.style.height = this.props.imageRef.naturalHeight + 'px'
		} else {
			// Scale down
			let heightScale = 1
			let widthScale = 1

			if (canvasOffsetHeight < this.props.imageRef.naturalHeight)
				heightScale = canvasOffsetHeight / this.props.imageRef.naturalHeight

			if (canvasOffsetWidth < this.props.imageRef.naturalWidth)
				widthScale = canvasOffsetWidth / this.props.imageRef.naturalWidth

			this.scalingFactor = heightScale < widthScale ? heightScale : widthScale

			this.canvas.style.width = Math.floor(this.props.imageRef.naturalWidth * this.scalingFactor) + 'px'
			this.canvas.style.height = Math.floor(this.props.imageRef.naturalHeight * this.scalingFactor) + 'px'
		}
	}

	scaleCoordinate(number) {
		// return number
		return Math.floor(number / this.scalingFactor)
	}

	handleOnTouchStart(event) {
		if (this.props.readOnly) return
		const rect = this.canvas.getBoundingClientRect()
		this.canvasContext.beginPath()
		this.lastX = this.scaleCoordinate(event.targetTouches[0].pageX - rect.left)
		this.lastY = this.scaleCoordinate(event.targetTouches[0].pageY - rect.top)
		this.props.setIsDrawing(true)
		clearTimeout(this.saveTimer)
	}

	handleOnMouseDown(event) {
		if (this.props.readOnly) return
		const rect = this.canvas.getBoundingClientRect()
		this.canvasContext.beginPath()
		this.lastX = this.scaleCoordinate(event.clientX - rect.left)
		this.lastY = this.scaleCoordinate(event.clientY - rect.top)
		this.props.setIsDrawing(true)
		clearTimeout(this.saveTimer)
	}

	handleOnTouchMove(event) {
		if (this.props.readOnly) return
		if (this.props.isDrawing) {
			const rect = this.canvas.getBoundingClientRect()
			const lastX = this.lastX
			const lastY = this.lastY
			let currentX = this.scaleCoordinate(event.targetTouches[0].pageX - rect.left)
			let currentY = this.scaleCoordinate(event.targetTouches[0].pageY - rect.top)
			this.draw(lastX, lastY, currentX, currentY)
			this.lastX = currentX
			this.lastY = currentY
		}
	}

	handleOnMouseMove(event) {
		if (this.props.readOnly) return
		if (this.props.isDrawing) {
			const rect = this.canvas.getBoundingClientRect()
			const lastX = this.lastX
			const lastY = this.lastY
			let currentX = this.scaleCoordinate(event.clientX - rect.left)
			let currentY = this.scaleCoordinate(event.clientY - rect.top)

			this.draw(lastX, lastY, currentX, currentY)
			this.lastX = currentX
			this.lastY = currentY
		}
	}

	handleonMouseUp() {
		if (this.props.readOnly) return
		this.props.setIsDrawing(false)

		clearTimeout(this.saveTimer)
		this.saveTimer = setTimeout(this.saveToHistory, 300)
	}

	draw(lX, lY, cX, cY) {
		const newContext = this.canvasContext
		newContext.lineWidth = this.props.lineWidth
		newContext.strokeStyle = this.props.brushColor
		newContext.lineJoin = newContext.lineCap = 'round'
		newContext.shadowColor = this.props.brushColor
		this.canvasContext.moveTo(lX, lY)
		this.canvasContext.lineTo(cX, cY)
		this.canvasContext.stroke()
	}

	resetCanvas() {
		const width = this.canvas.width
		const height = this.canvas.height
		this.canvasContext && this.canvasContext.clearRect(0, 0, width, height)
	}

	saveToHistory() {
		let historyCanvas = document.createElement('canvas')

		historyCanvas.style.width = this.canvas.style.width
		historyCanvas.style.height = this.canvas.style.height
		historyCanvas.height = this.canvas.height
		historyCanvas.width = this.canvas.width

		const canvasContext = historyCanvas.getContext('2d')
		canvasContext.drawImage(this.canvas, 0, 0)
		this.props.saveToHistory(historyCanvas)
	}

	setCanvasReference(element) {
		this.canvas = element
		// Parent handles undo/redo - Let it manipulate canvas
		this.props.setCanvasReference(element)
	}

	render() {
		const { classes } = this.props

		return (
			<div className={classes.root} ref={(node) => (this.canvasContainer = node)}>
				<canvas
					className={classes.canvas}
					ref={this.setCanvasReference}
					onMouseDown={this.handleOnMouseDown}
					onTouchStart={this.handleOnTouchStart}
					onMouseMove={this.handleOnMouseMove}
					onTouchMove={this.handleOnTouchMove}
					onMouseUp={this.handleonMouseUp}
					onTouchEnd={this.handleonMouseUp}
				/>
			</div>
		)
	}
}

UiImageCanvas.propTypes = {
	imageRef: PropTypes.object,
	readOnly: PropTypes.bool,
	isDrawing: PropTypes.bool,
	lineWidth: PropTypes.number,
	brushColor: PropTypes.string,
	setCanvasReference: PropTypes.func.isRequired,
	saveToHistory: PropTypes.func.isRequired,
	setIsDrawing: PropTypes.func.isRequired,
	history: PropTypes.array,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiImageCanvas)
