let deferredPrompt

window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent Chrome 67 and earlier from automatically showing the prompt
	e.preventDefault()
	// Stash the event so it can be triggered later.
	deferredPrompt = e

	window.beforeInstallPrompt = deferredPrompt
})

const p_installApp = ({ actionNodeLogger }) =>
	new Promise((resolve) => {
		actionNodeLogger.debug('Requesting user to install app')

		if (deferredPrompt) {
			deferredPrompt.prompt().catch((err) => {
				actionNodeLogger.error('Error occurred while triggering install prompt', { err })
				resolve()
			})
			// Wait for the user to respond to the prompt
			deferredPrompt.userChoice
				.then((choiceResult) => {
					if (choiceResult.outcome === 'accepted') {
						actionNodeLogger.info('User accepted the Add to Home screen prompt')
					} else {
						actionNodeLogger.info('User dismissed the Add to Home screen prompt')
					}

					deferredPrompt = null
				})
				.catch((err) => {
					actionNodeLogger.error('Error occurred while getting user choice for install prompt', { err })
					resolve()
				})
		} else {
			actionNodeLogger.warning('Unable to trigger install prompt')
		}

		resolve()
	})

export default p_installApp
