/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import isUndefined from 'lodash/isUndefined'

import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Zoom from '@material-ui/core/Zoom'

import { e_ActionButtonType, e_ButtonIconPlacement, e_BadgeVariant } from '@appfarm/common/enums/e_PropertyTypes'

const styles = {
	gutter: {
		width: 4,
		display: 'inline-block',
	},
	badge: {
		top: 3,
	},
}

const addBadge = (component, children, classes) => {
	let badgeContent = component.__badgeValue
	let invisible
	if (component.badgeVariant === e_BadgeVariant.DOT) {
		badgeContent = undefined
		invisible = !component.__badgeValue
	}
	return (
		<Badge
			badgeContent={badgeContent}
			color={component.badgeColor || 'secondary'}
			variant={component.badgeVariant}
			max={component.badgeMax}
			classes={classes}
			invisible={invisible}
		>
			{ children }
		</Badge>
	)
}

class UiAppBarAction extends Component {
	constructor(props) {
		super(props)

		this.onClick = this.onClick.bind(this)
	}

	onClick(event) {
		const props = this.props
		props.closeMenu && props.closeMenu()
		this.props.eventHandler(props.component.actionId, props.contextData, { eventType: 'actionId' }, event)
	}

	render() {
		const {
			component,
			isLeftAction,
			overflowMenu,
			eventHandler,
			appController,
			denseAppBar,
			edge,
			closeMenu,
			classes,
			...other
		} = this.props

		const { __badgeValue, __visible, __disabled, __title } = component

		let icon = component.__icon ? <Icon className={component.__icon} /> : undefined
		let appBarAction

		if (overflowMenu) {
			if (!__visible) return null

			if (icon && component.badge) icon = addBadge(component, icon)

			return (
				<MenuItem disabled={__disabled} onClick={this.onClick} {...other}>
					{ icon && <ListItemIcon>{ icon }</ListItemIcon> }
					<ListItemText primary={__title} />
				</MenuItem>
			)
		}

		let preGutter
		let postGutter
		if (component.type === e_ActionButtonType.BUTTON) {
			const startIcon = icon && component.iconPlacement !== e_ButtonIconPlacement.RIGHT ? icon : undefined
			const endIcon = icon && component.iconPlacement === e_ButtonIconPlacement.RIGHT ? icon : undefined

			appBarAction = (
				<Button
					disabled={__disabled}
					onClick={this.onClick}
					color={component.buttonColor || 'inherit'}
					startIcon={startIcon}
					endIcon={endIcon}
					variant={component.buttonVariant}
					size={denseAppBar ? 'small' : 'medium'}
					{...other}
				>
					{ __title }
				</Button>
			)

			if (component.badge) {
				let badgeClasses
				if (denseAppBar) {
					badgeClasses = {
						anchorOriginTopRightRectangle: classes.badge,
					}
				}

				appBarAction = addBadge(component, appBarAction, badgeClasses)
			}

			if (!isUndefined(component.__visible)) {
				appBarAction = (
					<Zoom in={__visible} unmountOnExit>
						{ appBarAction }
					</Zoom>
				)
			}

			if (edge !== 'start') preGutter = __visible
			if (edge !== 'end') postGutter = __visible
		} else {
			if (icon && component.badge && __badgeValue) icon = addBadge(component, icon)

			appBarAction = (
				<IconButton disabled={__disabled} onClick={this.onClick} color="inherit" edge={edge} {...other}>
					{ icon }
				</IconButton>
			)

			if (__title) appBarAction = <Tooltip title={__title}>{ appBarAction }</Tooltip>

			if (!isLeftAction && !isUndefined(component.__visible)) {
				appBarAction = (
					<Zoom in={__visible} unmountOnExit>
						{ appBarAction }
					</Zoom>
				)
			}
		}

		return (
			<>
				{ preGutter && <div className={classes.gutter} /> }
				{ appBarAction }
				{ postGutter && <div className={classes.gutter} /> }
			</>
		)
	}
}

UiAppBarAction.propTypes = {
	component: PropTypes.object,
	appController: PropTypes.object,
	overflowMenu: PropTypes.bool,
	isLeftAction: PropTypes.bool,
	denseAppBar: PropTypes.bool,
	edge: PropTypes.oneOf(['start', 'end']),
	eventHandler: PropTypes.func.isRequired,
	closeMenu: PropTypes.func,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiAppBarAction)
