/******************************************************************************
 *
 *  This is a structure for managing metadata for the  client.
 *
 * All metadata are first and foremost held here on a per app basis, but
 * some global resources like enumerated types can be held across apps as well
 *
 * It will take some responsibilites away from the AppController but it shall
 * not do any business logic. Only hold and server metadata
 *
 *****************************************************************************/

import ObjectClassMetadata from './ObjectClassMetadata'

class MetadataController {
	constructor() {
		this.objectClasses = {}
	}

	/**************************************************************************
	 *
	 * Setters
	 *
	 *************************************************************************/

	/**
	 * Overwrite any existing instances and remove any that existed before.
	 */
	setMetadata({ objectClasses }) {
		this.objectClasses = objectClasses.reduce((dict, ocDesc) => {
			dict[ocDesc.id] = new ObjectClassMetadata(ocDesc)
			return dict
		}, {})
	}

	clearAll() {
		this.objectClasses = {}
	}

	/**************************************************************************
	 *
	 * Getters
	 *
	 *************************************************************************/

	getObjectClassMetadata(objectClassId) {
		return this.objectClasses[objectClassId]
	}
}

export default MetadataController
