import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'

import UiComponent from '../UiComponent'

import e_VisibilityGroupControlMode from '@appfarm/common/enums/e_VisibilityGroupControlMode'
import e_VisibilityGroupTransitionType from '@appfarm/common/enums/e_VisibilityGroupTransitionType'

import { LoadingComponent } from '../utils'
import loadable from '@loadable/component'

const SwipeableComponent = loadable(() => import('./SwipeableComponent'), {
	fallback: <LoadingComponent />,
})

const emptyArray = []

const UiVisibilityGroup = (props) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [visibilityArray, setVisibilityArray] = useState(emptyArray)

	const isControlled = props.component.controlMode === e_VisibilityGroupControlMode.CONTROLLED

	// Calculate visibility array
	useEffect(() => {
		const newVisibilityArray = props.component.children?.length
			? props.component.children?.map(
				(childComponent) =>
					isNil(childComponent.visible) ||
						!!props.appController.getDataFromDataValue(childComponent.visible, props.contextData)
			  )
			: emptyArray
		if (!isEqual(newVisibilityArray, visibilityArray)) setVisibilityArray(newVisibilityArray)
	})

	// Controlled
	useEffect(() => {
		if (props.component.value) {
			const currentIndexValue = props.ownData?.[props.component.value.nodeName]

			if (isNil(currentIndexValue)) {
				props.appController.modifySingleValue(props.component.value, props.ownData, activeIndex, {})
			} else {
				setActiveIndex(currentIndexValue)
			}
		}
	}, [props.ownData, activeIndex])

	// Uncontrolled
	useEffect(() => {
		if (!isControlled) {
			const firstVisibleIndex = visibilityArray.indexOf(true)
			if (firstVisibleIndex !== activeIndex && firstVisibleIndex > -1) setActiveIndex(firstVisibleIndex)
		}
	}, [visibilityArray])

	const updateActiveIndex = useCallback(
		(newIndexValue) => {
			if (!props.component.value) return

			props.appController.modifySingleValue(props.component.value, props.ownData, newIndexValue, {})
		},
		[props.ownData]
	)

	const childComponents = useMemo(() => {
		if (!isControlled) return props.component.children
		if (!props.component.children?.length) return emptyArray
		return props.component.children.filter((_, index) => visibilityArray[index])
	}, [visibilityArray, props.component.children])

	let swipeDisabled = false
	if (!isNil(props.component.swipeDisabled))
		swipeDisabled = props.appController.getDataFromDataValue(props.component.swipeDisabled, props.contextData)

	const {
		component,
		disabled,
		readOnly,
		dataUpdateReference,
		contextData,
		styleProp,
		conditionalClassNames,
	} = props

	if (component.transitionType === e_VisibilityGroupTransitionType.SLIDE) {
		return (
			<SwipeableComponent
				component={component}
				activeIndex={activeIndex}
				handleChangeIndex={updateActiveIndex}
				disabled={swipeDisabled}
				isControlled={isControlled}
				styleProp={styleProp}
				conditionalClassNames={conditionalClassNames}
			>
				{ childComponents?.map((childComponent) => (
					<UiComponent
						key={childComponent.id}
						index={childComponent.sortIndex}
						dataUpdateReference={dataUpdateReference}
						component={childComponent}
						contextData={contextData}
						disabled={disabled}
						readOnly={readOnly}
					/>
				)) }
			</SwipeableComponent>
		)
	}

	const childComponent = childComponents?.[activeIndex]

	if (!childComponent) return null

	return (
		<UiComponent
			key={childComponent.id} // always initiate new instance on component change
			index={childComponent.sortIndex}
			dataUpdateReference={dataUpdateReference}
			component={childComponent}
			contextData={contextData}
			disabled={disabled}
			readOnly={readOnly}
		/>
	)
}

UiVisibilityGroup.propTypes = {
	component: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool.isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.object,
	dataUpdateReference: PropTypes.number.isRequired,
	styleProp: PropTypes.object,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
	}).isRequired,
	conditionalClassNames: PropTypes.string,
}

export default UiVisibilityGroup
