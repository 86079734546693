import axios from 'axios'
import sha1 from 'sha1'

const checkPassword = (password) =>
	new Promise((resolve, reject) => {
		const passwordHash = sha1(password).toUpperCase()
		const searchHash = passwordHash.substr(0, 5)
		const restHash = passwordHash.substr(5)

		axios
			.get('https://api.pwnedpasswords.com/range/' + searchHash)
			.then((result) => {
				let match = 0

				result.data.split('\n').forEach((item) => {
					const [value, num] = item.split(':')

					if (value === restHash) {
						console.log('Found breaches: ', num)
						match = num
					}
				})

				resolve(match)
			})
			.catch((err) => {
				console.log('ERROR', err)
				reject(err)
			})
	})

export default checkPassword
