/**
 * Login form for email based login.
 * - Password (with or without totp)
 * - Magic Link
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'

import LinkStyleButton from '../common/LinkStyleButton'
import CheckYourEmailMessage from '../common/CheckYourEmailMessage'
import OneTimePinLogin from './OneTimePinLogin'

import { validateEmail } from '@appfarm/common/utils/validateEmail'
import stripAllWhitespace from '@appfarm/common/utils/stripAllWhitespace'

import {
	setEmailValue,
	setPasswordValue,
	runPasswordLogin,
	setEmailLoginActiveState,
	setloginErrorMessage,
	resetLogin,
	initiateForgotPassword,
	runGetLoginLink,
	runGetLoginPin,
	setLoginLinkOrderedState,
	setLoginPinOrderedState,
	setCreateFirstTimePasswordStatus,
} from '#actions/authActions'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	horizontalContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 6,
	},
	passwordNotCreated: {
		paddingLeft: 6,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	leftMarginText: {
		marginLeft: 6,
		whiteSpace: 'nowrap',
		marginRight: 2,
	},
	backToLoginButton: {
		alignSelf: 'center',
	},
	flex: {
		flex: 1,
	},
	gutter: {
		height: 16,
	},
}

class EmailLogin extends Component {
	constructor(props) {
		super(props)

		this.state = {
			emailNotValid: false,
			passwordNotValid: false,
			showPassword: false,
		}

		this.runPrimaryAction = this.runPrimaryAction.bind(this)
		this.passwordLogin = this.passwordLogin.bind(this)
		this.createFirstTimePassword = this.createFirstTimePassword.bind(this)
		this.forgotPassword = this.forgotPassword.bind(this)
		this.handleEmailOnKeyDown = this.handleEmailOnKeyDown.bind(this)
		this.handlePasswordOnKeyDown = this.handlePasswordOnKeyDown.bind(this)
		this.reset = this.reset.bind(this)
		this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
	}

	isEmailValid() {
		const email = stripAllWhitespace(this.props.emailValue).toLowerCase()

		if (!email) {
			this.setState({ emailNotValid: true })
			return false
		}

		if (!validateEmail(email)) {
			this.setState({ emailNotValid: true })
			return false
		}

		this.setState({ emailNotValid: false })
		return true
	}

	/**
	 * This is what happens when the user push the button right after
	 * email is entered
	 */
	runPrimaryAction() {
		if (!this.isEmailValid()) {
			console.warn('Email is not valid')
			return
		}

		const { passwordLoginEnabled, loginLinkEnabled, otpLoginEnabled } = this.props

		this.props.activateEmailLogin()

		if (!passwordLoginEnabled && loginLinkEnabled !== otpLoginEnabled) {
			loginLinkEnabled && this.props.getLoginLink()
			otpLoginEnabled && this.props.getLoginPin()
		}
	}

	passwordLogin() {
		if (this.props.passwordValue.length < 3) return this.setState({ passwordNotValid: true })

		this.props.passwordLogin()
	}

	createFirstTimePassword() {
		this.props.setCreateFirstTimePasswordStatus(true)
		this.props.initiateForgotPassword()
	}

	forgotPassword() {
		this.props.setCreateFirstTimePasswordStatus(false)
		this.props.initiateForgotPassword()
	}

	handleEmailOnKeyDown(event) {
		this.setState({ passwordNotValid: false })
		if (event.keyCode === 13)
			// enter
			this.runPrimaryAction()
	}

	handlePasswordOnKeyDown(event) {
		if (event.keyCode === 13)
			// enter
			this.passwordLogin()
	}

	reset() {
		this.setState({ passwordNotValid: false })
		this.props.setLoginLinkOrderedState(false)
		this.props.setLoginPinOrderedState(false)
		this.props.resetLogin()
	}

	handleClickShowPassword() {
		this.setState((state) => ({ showPassword: !state.showPassword }))
	}

	render() {
		const {
			classes,

			emailValue,
			passwordValue,

			setEmailValue,
			setPasswordValue,

			loginLinkOrdered,
			loginPinOrdered,

			passwordLoginEnabled,
			loginLinkEnabled,
			otpLoginEnabled,

			emailLoginActive,
		} = this.props

		// No applicable methods enabled
		if (!loginLinkEnabled && !passwordLoginEnabled && !otpLoginEnabled) return null

		// Getting label for primary action.
		let primaryActionLabel
		let mulipleMethodsEnabled = false
		if (!passwordLoginEnabled && otpLoginEnabled !== loginLinkEnabled) {
			primaryActionLabel = otpLoginEnabled ? 'Send me a PIN' : 'Send me a login link'
		} else {
			primaryActionLabel = 'Next'
			mulipleMethodsEnabled = true
		}

		// Calculate visibility flags for elements
		let showLoginLinkSeparator = false
		let showOtpLoginSeparator = false
		if (emailLoginActive) {
			if (passwordLoginEnabled) showLoginLinkSeparator = true
			if (passwordLoginEnabled || loginLinkEnabled) showOtpLoginSeparator = true
		}

		if (loginLinkOrdered)
			return (
				<CheckYourEmailMessage onTryAgainClick={this.reset}>
					<Typography variant="body2" color="textSecondary" paragraph>
						We sent a new login link to <strong>{ emailValue }</strong>.
					</Typography>
					<Typography variant="body2" color="textSecondary" paragraph>
						If you don't see the email, check your junk, spam or other folders it might be in.
					</Typography>
				</CheckYourEmailMessage>
			)

		if (loginPinOrdered) {
			return <OneTimePinLogin reset={this.reset} />
		}

		return (
			<div className={classes.container}>
				{ !emailLoginActive && (
					<TextField
						id="email"
						type="text"
						label="Enter your email"
						value={emailValue}
						autoFocus
						onChange={setEmailValue}
						onKeyDown={this.handleEmailOnKeyDown}
						margin="normal"
						helperText={this.state.emailNotValid ? 'Please enter a valid email' : ''}
						error={this.state.emailNotValid}
						variant="filled"
						autoComplete="username"
						name="username"
						inputProps={{
							'aria-label': 'Enter your email',
							autoCapitalize: 'off',
							spellCheck: 'false',
						}}
					/>
				) }

				{ emailLoginActive && passwordLoginEnabled && (
					<>
						<TextField
							id="password"
							type={this.state.showPassword ? 'text' : 'password'}
							label="Enter your password"
							autoFocus
							value={passwordValue}
							onChange={setPasswordValue}
							onKeyDown={this.handlePasswordOnKeyDown}
							margin="normal"
							helperText={this.state.passwordNotValid ? 'Please enter your password' : ''}
							error={this.state.passwordNotValid}
							variant="filled"
							name="password"
							autoComplete="current-password"
							inputProps={{
								'aria-label': 'Enter your password',
								autoCapitalize: 'off',
								spellCheck: 'false',
								autoCorrect: 'off',
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title={this.state.showPassword ? 'Hide password' : 'Show password'}>
											<IconButton
												aria-label="Toggle password visibility"
												onClick={this.handleClickShowPassword}
											>
												<Icon
													className={
														this.state.showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'
													}
												/>
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
						<div className={classes.horizontalContainer}>
							<LinkStyleButton onClick={this.forgotPassword} color="primary" variant="text">
								Forgot password?
							</LinkStyleButton>
							<div className={classes.flex} />
							<Button variant="contained" color="primary" onClick={this.passwordLogin}>
								Sign In
							</Button>
						</div>
						<div className={classes.gutter} />
						<div className={classes.gutter} />
						<Typography
							className={classes.passwordNotCreated}
							variant="body2"
							color="textSecondary"
							align="left"
							component="div"
						>
							<div className={classes.leftMarginText}>Haven't set a password yet?</div>
							<LinkStyleButton onClick={this.createFirstTimePassword} color="primary" variant="text">
								Set password
							</LinkStyleButton>
						</Typography>
						<div className={classes.gutter} />
					</>
				) }

				{ emailLoginActive && mulipleMethodsEnabled && (
					<>
						{ passwordLoginEnabled && <Divider /> }
						{ loginLinkEnabled && (
							<>
								{ showLoginLinkSeparator && (
									<>
										<div className={classes.gutter} />
										<Typography variant="body2" align="center" color="textSecondary">
											or
										</Typography>
									</>
								) }
								<div className={classes.gutter} />
								<Button variant="contained" color="primary" onClick={this.props.getLoginLink}>
									Send me a Login Link
								</Button>
							</>
						) }

						{ otpLoginEnabled && (
							<>
								{ showOtpLoginSeparator && (
									<>
										<div className={classes.gutter} />
										<Typography variant="body2" align="center" color="textSecondary">
											or
										</Typography>
									</>
								) }
								<div className={classes.gutter} />
								<Button variant="contained" color="primary" onClick={this.props.getLoginPin}>
									Send me a PIN
								</Button>
							</>
						) }
					</>
				) }

				{ emailLoginActive && (
					<>
						<div className={classes.gutter} />
						<div className={classes.gutter} />
						<LinkStyleButton
							className={classes.backToLoginButton}
							onClick={this.reset}
							color="default"
							variant="text"
						>
							Back to login
						</LinkStyleButton>
					</>
				) }

				{ !emailLoginActive && (
					<Button variant="contained" color="primary" onClick={this.runPrimaryAction}>
						{ primaryActionLabel }
					</Button>
				) }
			</div>
		)
	}
}

EmailLogin.propTypes = {
	/**
	 * Available login methods
	 */
	passwordLoginEnabled: PropTypes.bool.isRequired,
	loginLinkEnabled: PropTypes.bool.isRequired,
	otpLoginEnabled: PropTypes.bool.isRequired,

	/**
	 * User has decided to use some form of email based authentication.
	 * i.e. pressed next after entering email.
	 */
	emailLoginActive: PropTypes.bool.isRequired,

	emailValue: PropTypes.string,
	passwordValue: PropTypes.string,
	loginLinkOrdered: PropTypes.bool,
	loginPinOrdered: PropTypes.bool,
	setLoginLinkOrderedState: PropTypes.func.isRequired,
	setLoginPinOrderedState: PropTypes.func.isRequired,
	setCreateFirstTimePasswordStatus: PropTypes.func.isRequired,

	activateEmailLogin: PropTypes.func.isRequired,
	setEmailValue: PropTypes.func.isRequired,
	setPasswordValue: PropTypes.func.isRequired,
	passwordLogin: PropTypes.func.isRequired,
	getLoginLink: PropTypes.func.isRequired,
	getLoginPin: PropTypes.func.isRequired,

	resetLogin: PropTypes.func.isRequired,
	initiateForgotPassword: PropTypes.func.isRequired,
	redirectPath: PropTypes.string,

	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	const redirectPath = state.appState.afterLoginRedirect
		? btoa(state.appState.afterLoginRedirect)
		: btoa(window.location.pathname + window.location.search)

	return {
		passwordLoginEnabled: state.authState.passwordLoginEnabled,
		loginLinkEnabled: state.authState.loginLinkEnabled,
		otpLoginEnabled: state.authState.otpLoginEnabled,

		emailLoginActive: state.authState.emailLoginActive,

		loginLinkOrdered: state.authState.loginLinkOrdered,
		loginPinOrdered: state.authState.loginPinOrdered,

		/**
		 * String values for fields
		 */
		emailValue: state.authState.emailValue,
		passwordValue: state.authState.passwordValue,

		redirectPath: redirectPath,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		activateEmailLogin: () => {
			dispatch(setEmailLoginActiveState(true))
			dispatch(setloginErrorMessage(null))
		},

		getLoginLink: () => dispatch(runGetLoginLink()),
		getLoginPin: () => dispatch(runGetLoginPin()),
		setLoginLinkOrderedState: (value) => dispatch(setLoginLinkOrderedState(value)),
		setLoginPinOrderedState: (value) => dispatch(setLoginPinOrderedState(value)),
		setCreateFirstTimePasswordStatus: (value) => dispatch(setCreateFirstTimePasswordStatus(value)),

		setEmailValue: (event) => dispatch(setEmailValue(event.target.value)),
		setPasswordValue: (event) => dispatch(setPasswordValue(event.target.value)),

		passwordLogin: () => dispatch(runPasswordLogin()),

		resetLogin: () => dispatch(resetLogin()),

		initiateForgotPassword: () => dispatch(initiateForgotPassword()),
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EmailLogin))
