import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = {
	outerContainer: {
		height: '100%',
		display: 'block',
		margin: '0 auto',
	},
	container: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 460,
	},
	progress: {
		color: '#bdbdbd',
	},
}

const LoaderAnimation = (props) => {
	const { classes } = props

	return (
		<div className={classes.outerContainer}>
			<div className={classes.container}>
				<CircularProgress className={classes.progress} />
			</div>
		</div>
	)
}

LoaderAnimation.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoaderAnimation)
