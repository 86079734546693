/* eslint-disable react/no-danger */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, lighten, darken, alpha } from '@material-ui/core/styles'

import { Remarkable } from 'remarkable'
import { linkify } from 'remarkable/linkify'
import hljs from 'highlight.js'

import afMarkdownExternalLink from './afMarkdownExternalLink'

const useStyles = makeStyles((theme) => ({
	markdownRoot: {
		'& > *:first-child': {
			marginTop: '0 !important',
		},
		'& > *:last-child': {
			marginBottom: '0 !important',
		},
		'& a:link, & a:visited, & a:active': {
			color: theme.palette.link || '#2196f3',
		},
		'& a:hover': {
			textDecoration: 'underline',
		},
		'& h1': {
			...theme.typography.h1,
		},
		'& h2': {
			...theme.typography.h2,
		},
		'& h3': {
			...theme.typography.h3,
		},
		'& h4': {
			...theme.typography.h4,
		},
		'& h5': {
			...theme.typography.h5,
		},
		'& h6': {
			...theme.typography.h6,
		},
		'& img': {
			maxWidth: '100%',
		},
		'& table': {
			borderCollapse: 'collapse',
			borderSpacing: 0,
			...theme.typography.body2,
		},
		'& td, & th': {
			textAlign: 'left',
			padding: '6px 24px 6px 16px',
			borderBottom: `1px solid ${
				theme.palette.type === 'light'
					? lighten(alpha(theme.palette.divider, 1), 0.88)
					: darken(alpha(theme.palette.divider, 1), 0.68)
			}`,
		},
		'& blockquote': {
			backgroundColor: theme.palette.action.hover,
			borderLeft: `4px solid ${theme.palette.primary.main}`,
			margin: '16px 0',
			padding: 16,
			'& > *:first-child': {
				marginTop: '0 !important',
			},
			'& > *:last-child': {
				marginBottom: '0 !important',
			},
		},
		'& code': {
			fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
			hyphens: 'auto',
			fontSize: '90%',
		},
		'& :not(pre) > code': {
			color: theme.palette.primary.main,
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			borderRadius: 4,
			padding: '2px 4px',
			whiteSpace: 'nowrap',
		},
		'& pre': {
			backgroundColor: theme.palette.action.hover,
			boxShadow: `inset 0 0 0 1px ${theme.palette.divider}`,
			margin: '16px 0',
			padding: '8px 16px',
			borderRadius: 4,
		},
		'& pre > code': {
			overflowWrap: 'break-word',
			wordWrap: 'break-word',
			wordBreak: 'break-word',
			'& .hljs-comment, & .hljs-quote': {
				color: theme.palette.text.disabled,
				fontStyle: 'italic',
			},
			'& .hljs-keyword, & .hljs-selector-tag, & .hljs-subst': {
				color: theme.palette.info.main,
				fontWeight: 'bold',
			},
			'& .hljs-number, & .hljs-literal, & .hljs-variable, & .hljs-template-variable, & .hljs-tag .hljs-attr':
				{
					color: theme.palette.info.dark,
				},
			'& .hljs-string, & .hljs-doctag': {
				color: darken(theme.palette.error.main, 0.24),
			},
			'& .hljs-title, & .hljs-section, & .hljs-selector-id': {
				color: darken(theme.palette.error.main, 0.24),
				fontWeight: 'bold',
			},
			'& .hljs-subst': {
				fontWeight: 'normal',
			},
			'& .hljs-type, & .hljs-class .hljs-title': {
				color: theme.palette.info.dark,
				fontWeight: 'bold',
			},
			'& .hljs-tag, & .hljs-name, & .hljs-attribute': {
				color: theme.palette.info.dark,
				fontWeight: 'normal',
			},
			'& .hljs-regexp, & .hljs-link': {
				color: theme.palette.success.main,
			},
			'& .hljs-symbol, & .hljs-bullet': {
				color: theme.palette.warning.dark,
			},
			'& .hljs-built_in, & .hljs-builtin-name': {
				color: theme.palette.info.light,
			},
			'& .hljs-meta': {
				color: theme.palette.text.disabled,
				fontWeight: 'bold',
			},
			'& .hljs-deletion': {
				background: alpha(theme.palette.error.main, theme.palette.action.selectedOpacity),
			},
			'& .hljs-addition': {
				background: alpha(theme.palette.success.main, theme.palette.action.selectedOpacity),
			},
			'& .hljs-emphasis': {
				fontStyle: 'italic',
			},
			'& .hljs-strong': {
				fontWeight: 'bold',
			},
		},
		'& hr': {
			border: 0,
			height: 1,
			background: theme.palette.divider,
			margin: '24px 0',
		},
	},
}))

const markdownOptions = (options) => {
	const parsedOptions = {
		breaks: true,
	}

	if (options.syntaxHighlight) {
		parsedOptions.highlight = (str, lang) => {
			if (lang && hljs.getLanguage(lang)) {
				try {
					return hljs.highlight(lang, str).value
				} catch (error) {
					console.log('Code highlight failed in Markdown enabled text', error)
				}
			}
			try {
				return hljs.highlightAuto(str).value
			} catch (error) {
				console.log('Code highlight failed in Markdown enabled text', error)
			}
			return ''
		}
	}

	return parsedOptions
}

const AfMarkdown = ({ options = {}, source }) => {
	const createMarkdown = useCallback((options, plugins) => {
		return plugins.reduce((md, plugin) => {
			return md.use(plugin)
		}, new Remarkable('full', options))
	}, [])

	const markdownRef = useRef(null)

	const renderMarkdown = useCallback(
		(source) => {
			if (!markdownRef.current) {
				const plugins = []
				if (options.linkify) plugins.push(linkify)
				if (options.linkNewWindow) plugins.push(afMarkdownExternalLink)

				markdownRef.current = createMarkdown(markdownOptions(options), plugins)
			}

			return markdownRef.current.render(source)
		},
		[options]
	)

	const classes = useStyles()

	return <div className={classes.markdownRoot} dangerouslySetInnerHTML={{ __html: renderMarkdown(source) }} />
}

AfMarkdown.propTypes = {
	options: PropTypes.shape({
		syntaxHighlight: PropTypes.bool,
		linkify: PropTypes.bool,
		linkNewWindow: PropTypes.bool,
	}),
	source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AfMarkdown
