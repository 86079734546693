import queryString from 'query-string'
import ClientDataSource from './ClientDataSource'
import browserHistory from '../../components/browserHistory'
import dayjs from '#controllers/dayjs'
import dataTypeParser from '@appfarm/common/utils/dataTypeParser'
import { getSideEffects } from '../../modules/afClientApi'

/**
 * Special DataSource for URL PATH
 */
class UrlParamsDataSource extends ClientDataSource {
	constructor(dataSourceMeta, appController, logger) {
		super(dataSourceMeta, appController, logger)

		this.lastSearchString = null

		this._updateOwnDataFromLocation = this._updateOwnDataFromLocation.bind(this)
		this.p_updateOwnDataFromLocation = this.p_updateOwnDataFromLocation.bind(this)

		// Listen to changes
		this.unlistenBrowserHistory = browserHistory.listen((location) =>
			this.p_updateOwnDataFromLocation(location.search)
		)

		// Metadata for debugger
		if (dataSourceMeta.properties) this.propertiesMetadataInUseList = Object.values(dataSourceMeta.properties)
	}

	setInitialData() {
		super.setInitialData({ data: [{ _id: 'dummyId' }] })
		this._updateOwnDataFromLocation(window.location.search)
	}

	_updateOwnDataFromLocation(searchString) {
		if (this.lastSearchString === searchString) return
		this.lastSearchString = searchString

		const existingUrlParams = this.data[0]
		const queryParameters = queryString.parse(searchString)

		let hasChanged = false

		const newData = Object.keys(this.propertiesMetaDict).reduce((newData, propertyId) => {
			const propertyMeta = this.propertiesMetaDict[propertyId]
			const value = dataTypeParser(queryParameters[propertyMeta.name], propertyMeta.dataType, { dayjs })

			if (existingUrlParams[propertyMeta.name] !== value) hasChanged = true
			newData[propertyMeta.name] = value

			return newData
		}, {})

		if (hasChanged) {
			this._modifyObject('dummyId', newData)
			this._writeToRedux()
		}
	}

	p_updateOwnDataFromLocation(searchString) {
		return new Promise((resolve, reject) => {
			if (this.lastSearchString === searchString) return resolve()
			this.lastSearchString = searchString

			const existingUrlParams = this.data[0]
			const queryParameters = queryString.parse(searchString)

			let hasChanged = false

			const newData = Object.keys(this.propertiesMetaDict).reduce((newData, propertyId) => {
				const propertyMeta = this.propertiesMetaDict[propertyId]
				const value = dataTypeParser(queryParameters[propertyMeta.name], propertyMeta.dataType)

				if (existingUrlParams[propertyMeta.name] !== value) hasChanged = true
				newData[propertyMeta.name] = value

				return newData
			}, {})

			if (hasChanged) {
				this._modifyObject('dummyId', newData)
				if (this.reverseDependencies?.length) {
					const selectionData = this.getDataForSynchronization()
					const invalidatedDataSourceIdDict = this.__appController.invalidateDataSourcesById(
						this.reverseDependencies.map((item) => item.dataSourceId),
						{ updateGui: true }
					)
					return getSideEffects(this.id, { objectsModified: [newData] }, selectionData)
						.then((sideEffects) => {
							// Apply side effects
							this.__appController.writeSideEffects(this, sideEffects, {
								logger: this.logger,
								invalidatedDataSourceIdDict,
							})
							this._writeToRedux()
							resolve()
						})
						.catch(reject)
				} else {
					this._writeToRedux()
				}
			}
			resolve()
		})
	}

	initializeStorage() {
		// Intentionally left empty
		// We don't want to use storage
	}

	destroy() {
		this.unlistenBrowserHistory()
		super.destroy()
	}
}

export default UrlParamsDataSource
