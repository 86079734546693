const translateEnumeratedTypes = (enumeratedTypeDict, enumLangDictionary) => {
	if (!enumLangDictionary) return enumeratedTypeDict

	return Object.keys(enumeratedTypeDict).reduce((dict, enumTypeId) => {
		const enumeratedType = { ...enumeratedTypeDict[enumTypeId] }
		enumeratedType.values = enumeratedType.values.map((item) => {
			const enumLangValues = enumLangDictionary[item.id] || {}
			if (Object.keys(enumLangValues).length) {
				return {
					...item,
					...enumLangValues,
				}
			} else {
				return item
			}
		})

		dict[enumTypeId] = enumeratedType
		return dict
	}, {})
}

export default translateEnumeratedTypes
