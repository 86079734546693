// Lithuanian [lt]

const monthFormat =
	'sausio_vasario_kovo_balandžio_gegužės_birželio_liepos_rugpjūčio_rugsėjo_spalio_lapkričio_gruodžio'.split(
		'_'
	)
const monthStandalone =
	'sausis_vasaris_kovas_balandis_gegužė_birželis_liepa_rugpjūtis_rugsėjis_spalis_lapkritis_gruodis'.split('_')
// eslint-disable-next-line no-useless-escape
const MONTHS_IN_FORMAT = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?|MMMM?(\[[^\[\]]*\]|\s)+D[oD]?/

const months = (dayjsInstance, format) => {
	if (MONTHS_IN_FORMAT.test(format)) {
		return monthFormat[dayjsInstance.month()]
	}
	return monthStandalone[dayjsInstance.month()]
}
months.s = monthStandalone
months.f = monthFormat

const locale = {
	name: 'lt',
	weekdays: 'sekmadienis_pirmadienis_antradienis_trečiadienis_ketvirtadienis_penktadienis_šeštadienis'.split(
		'_'
	),
	weekdaysShort: 'sek_pir_ant_tre_ket_pen_šeš'.split('_'),
	weekdaysMin: 's_p_a_t_k_pn_š'.split('_'),
	months,
	monthsShort: 'sau_vas_kov_bal_geg_bir_lie_rgp_rgs_spa_lap_grd'.split('_'),
	ordinal: (n) => `${n}.`,
	weekStart: 1,
	relativeTime: {
		future: 'už %s',
		past: 'prieš %s',
		s: 'kelias sekundes',
		m: 'minutę',
		mm: '%d minutes',
		h: 'valandą',
		hh: '%d valandas',
		d: 'dieną',
		dd: '%d dienas',
		M: 'mėnesį',
		MM: '%d mėnesius',
		y: 'metus',
		yy: '%d metus',
	},
	format: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'YYYY-MM-DD',
		LL: 'YYYY [m.] MMMM D [d.]',
		LLL: 'YYYY [m.] MMMM D [d.], HH:mm [val.]',
		LLLL: 'YYYY [m.] MMMM D [d.], dddd, HH:mm [val.]',
		l: 'YYYY-MM-DD',
		ll: 'YYYY [m.] MMMM D [d.]',
		lll: 'YYYY [m.] MMMM D [d.], HH:mm [val.]',
		llll: 'YYYY [m.] MMMM D [d.], ddd, HH:mm [val.]',
	},
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'YYYY-MM-DD',
		LL: 'YYYY [m.] MMMM D [d.]',
		LLL: 'YYYY [m.] MMMM D [d.], HH:mm [val.]',
		LLLL: 'YYYY [m.] MMMM D [d.], dddd, HH:mm [val.]',
		l: 'YYYY-MM-DD',
		ll: 'YYYY [m.] MMMM D [d.]',
		lll: 'YYYY [m.] MMMM D [d.], HH:mm [val.]',
		llll: 'YYYY [m.] MMMM D [d.], ddd, HH:mm [val.]',
	},
}

export default locale
