const p_consoleLog = ({ actionNode, contextData, appController, actionNodeLogger }) =>
	new Promise((resolve) => {
		try {
			const value = appController.getDataFromDataValue(actionNode.text, contextData, {
				getDisplayValue: true,
			})
			actionNodeLogger.debug(value)
		} catch (err) {
			actionNodeLogger.error('Could not get text for console log', { err })
		}

		resolve()
	})

export default p_consoleLog
