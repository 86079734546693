import e_IteratorParamType, {
	IS_FIRST_ITERATION,
	IS_LAST_ITERATION,
	INDEX,
	ITERATOR_COUNT,
	RECURSION_LEVEL,
} from '@appfarm/common/enums/e_IteratorParamType'

const getIteratorParamsDataForIteration = (
	component,
	{ index, iteratorCount, iteratorLevel, iteratorParamIdsInUse }
) => {
	if (!iteratorParamIdsInUse || !Object.keys(iteratorParamIdsInUse)?.length) return {}

	return e_IteratorParamType.options
		.filter(
			(item) =>
				iteratorParamIdsInUse[`${component.id}_${item.ident}`] &&
				item.componentTypes.includes(component.componentType)
		)
		.reduce((params, item) => {
			const id = `${component.id}_${item.ident}` // Do not changes! Used in create and inflator
			switch (item.ident) {
				case IS_FIRST_ITERATION: {
					params[id] = index === 0
					break
				}
				case IS_LAST_ITERATION: {
					params[id] = index + 1 === iteratorCount
					break
				}
				case INDEX: {
					params[id] = index
					break
				}
				case RECURSION_LEVEL: {
					params[id] = iteratorLevel
					break
				}
				case ITERATOR_COUNT: {
					params[id] = iteratorCount
					break
				}
				default:
					console.log('Found unknown iteration param of type: ' + item.ident)
			}
			return params
		}, {})
}

export default getIteratorParamsDataForIteration
