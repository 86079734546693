export const getCurrentUserId = (state) => state.authState.userId

export const getAutoRedirectItemId = (state) => {
	const {
		redirectToAuth,
		googleEnabled,
		loginLinkEnabled,
		otpLoginEnabled,
		passwordLoginEnabled,
		customAuthProviders,
	} = state.authState

	if (!redirectToAuth) return null
	if (googleEnabled || loginLinkEnabled || otpLoginEnabled || passwordLoginEnabled) return null

	// Must be exactly 1 enabled
	if (customAuthProviders && customAuthProviders.length === 1) {
		return customAuthProviders[0].id
	}

	return null
}

export const getRedirectPath = (state) => {
	return state.appState.afterLoginRedirect
		? encodeURIComponent(state.appState.afterLoginRedirect)
		: encodeURIComponent(window.location.pathname + window.location.search)
}

const EMPTY_ARRAY = []
export const getCustomAuthProviders = (state) => state.authState.customAuthProviders || EMPTY_ARRAY
export const getGoogleEnabled = (state) => state.authState.googleEnabled

export const getIsLoggedIn = (state) => state.authState.isLoggedIn
export const getMaintenanceModeEnabled = (state) => state.authState.maintenanceModeEnabled

export const getIsAuthenticatedUser = (state) => state.authState.isLoggedIn && !state.authState.isAnonymous
