import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import browserHistory from '../../browserHistory'
import serverMessageDecoder from '../common/serverMessageDecoder'

import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import LoginSection from '../common/LoginSection'

const styles = (theme) => ({
	icon: {
		fontSize: 72,
		lineHeight: '72px',
		height: 72,
		width: 72,
		color: theme.palette.primary.main,
		marginBottom: 16,
		alignSelf: 'center',
	},
	inlineButton: {
		fontWeight: 500,
		marginLeft: 2,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
})

const StatusPage = (props) => {
	const { classes, serverMessage } = props

	const icon = 'mdi mdi-alert-circle-outline'
	let message = 'Unknown error. Please contact support.'
	const backText = serverMessage.backText

	// We can use known codes to generate useful messages
	switch (serverMessage.code) {
		/**
		 * User exists, but is not not in a security group that is
		 * allowed to log on to this business
		 */
		case 11403:
			message =
				'Error 11403 - You dont have sufficient permissions to log on to this environment. Please contact support.'
			break

		/**
		 * Login Link
		 * - User does not exist any more
		 * - User is blocked globally
		 * - User is not a member of the current business any more
		 * - User is member of business, but membership has been disabled
		 */
		case 10403:
			message = 'Error 10403 - You are not allowed to log on to current solution. Please contact support.'
			break

		/**
		 * Internal server error. For example error when communicating with the core service.
		 */
		case 11500:
			{
				message = `Error ${serverMessage.code} - Internal server error. Please try again later or contact support.`
			}
			break

		/**
		 * Google account not found
		 */
		case 11002:
			{
				const email = serverMessage.metadata && serverMessage.metadata.email
				message = 'Error 11002 - Unable to find Google account. Check that you used the correct one.'
				if (email) message += ` You tried to use ${email}`
			}
			break

		/**
		 * General error
		 */
		default: {
			message = serverMessage.message
				? `Error ${serverMessage.code} - ${serverMessage.message}`
				: `Error ${serverMessage.code} - Internal server error. Please contact support.`
		}
	}

	return (
		<>
			<LoginSection>
				<Icon className={classNames(icon, classes.icon)} />
				<Typography variant="subtitle1" align="center">
					{ message }
				</Typography>
			</LoginSection>
			{ backText && (
				<LoginSection>
					<Typography variant="subtitle1" align="center">
						<a
							className={classes.inlineButton}
							role="button"
							tabIndex="0"
							onClick={() => {
								browserHistory.replace('/')
							}}
						>
							{ backText }
						</a>
					</Typography>
				</LoginSection>
			) }
		</>
	)
}

StatusPage.propTypes = {
	serverMessage: PropTypes.shape({
		code: PropTypes.number,
		metadata: PropTypes.object,
		message: PropTypes.string,
	}).isRequired,
	classes: PropTypes.object.isRequired,
}

export default serverMessageDecoder(withStyles(styles)(StatusPage))
