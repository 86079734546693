import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import { e_ButtonIconPlacement } from '@appfarm/common/enums/e_PropertyTypes'

import { motion } from 'framer-motion'

const styles = {
	root: {
		display: 'block',
		width: 'fit-content',
		height: 'fit-content',
		minWidth: 'unset',
	},
	label: {
		display: 'flex',
	},
	noLabel: {
		marginLeft: -4,
		marginRight: -4,
		'&.MuiButton-iconSizeSmall': {
			marginLeft: -2,
			marginRight: -2,
		},
	},
	lineBreak: { whiteSpace: 'pre-line' },
}

class UiButton extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onSideEffectAction = this.onSideEffectAction.bind(this)
		this.onClickHandler = this.onClickHandler.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		let label
		if (nextProps.component.label) {
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})
		}

		let iconValue
		if (nextProps.component.iconValue)
			iconValue = nextProps.appController.getDataFromDataValue(
				nextProps.component.iconValue,
				nextProps.contextData,
				{ getIcon: true }
			)

		return {
			label: label || (nextProps.component.icon ? '' : '<button>'),
			iconValue,
			styleProp: {
				...nextProps.styleProp,
				...nextProps.style,
			},
		}
	}

	onClickHandler(event) {
		this.props.eventHandler(this.props.component.onClick, null, { eventType: 'onClick' }, event)
	}

	onSideEffectAction(actions) {
		if (!actions) return // actions may be null after a component unmounts
		// https://github.com/mui/material-ui/issues/30953
		// if (this.props.component.autoFocus) actions.focusVisible() // autofocus on buttonbase is disable until upgrade to mui v5
	}

	render() {
		const {
			component,
			componentId,
			tooltipTitle,
			tooltipDisableInteractive,
			tooltipLineBreak,
			disabled,
			conditionalClassNames,
			classes,
		} = this.props

		let startIcon
		let endIcon
		if (this.state.iconValue || component.icon) {
			if (component.iconPlacement === e_ButtonIconPlacement.RIGHT) {
				endIcon = <Icon className={this.state.iconValue || component.icon} />
			} else {
				startIcon = <Icon className={this.state.iconValue || component.icon} />
			}
		}

		const button = (
			<Button
				id={componentId}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				classes={{
					label: classes.label,
					startIcon: !this.state.label ? classes.noLabel : undefined,
					endIcon: !this.state.label ? classes.noLabel : undefined,
				}}
				style={this.state.styleProp}
				variant={component.buttonType}
				color={component.buttonColor || 'primary'}
				size={component.buttonSize}
				onClick={this.onClickHandler}
				disabled={disabled}
				// https://github.com/mui/material-ui/issues/30953
				// autoFocus={component.autoFocus} // autofocus on buttonbase is disable until upgrade to mui v5
				action={this.onSideEffectAction}
				tabIndex={component.tabIndex}
				startIcon={startIcon}
				endIcon={endIcon}
				component={this.props.useMotion ? motion.button : undefined}
				{...this.props.motionProps}
			>
				{ this.state.label }
			</Button>
		)

		if (tooltipTitle)
			return (
				<Tooltip
					title={tooltipTitle}
					interactive={!tooltipDisableInteractive}
					classes={tooltipLineBreak ? { tooltip: classes.lineBreak } : undefined}
				>
					{ button }
				</Tooltip>
			)

		return button
	}
}

UiButton.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
		icon: PropTypes.string,
		iconValue: PropTypes.object,
		buttonColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']),
		buttonType: PropTypes.oneOf(['text', 'outlined', 'contained']),
		buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),
		iconPlacement: PropTypes.oneOf(['left', 'right']),
		onClick: PropTypes.shape({
			actionId: PropTypes.string.isRequired,
			actionInputParams: PropTypes.array,
		}),
		autoFocus: PropTypes.bool,
		tabIndex: PropTypes.number,
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	componentId: PropTypes.string.isRequired,
	contextData: PropTypes.object,
	disabled: PropTypes.bool.isRequired,
	tooltipTitle: PropTypes.string,
	tooltipDisableInteractive: PropTypes.bool,
	tooltipLineBreak: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	style: PropTypes.object,
	styleProp: PropTypes.object,
	useMotion: PropTypes.bool,
	motionProps: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiButton)
