import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isPlainObject from 'lodash/isPlainObject'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { e_MarginType, e_SelectionColor } from '@appfarm/common/enums/e_PropertyTypes'

const styles = {
	root: {
		display: 'block',
		width: 'fit-content',
		height: 'fit-content',
	},
	noPaddingCheckboxWithLabel: {
		width: 24,
		height: 24,
		padding: 0,
		marginRight: 4,
	},
	noPaddingCheckbox: {
		width: 24,
		height: 24,
		padding: 0,
	},
	noPaddingLabel: {
		marginLeft: 0,
	},
}

class UiCheckbox extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.onValueChange = this.onValueChange.bind(this)
		this.onSideEffectAction = this.onSideEffectAction.bind(this)
	}

	static getDerivedStateFromProps(nextProps) {
		let label
		if (nextProps.component.label)
			label = nextProps.appController.getDataFromDataValue(nextProps.component.label, nextProps.contextData, {
				getDisplayValue: true,
			})

		const dataBinding = nextProps.component.value?.referenceDataBinding || nextProps.component.value
		const nodeName = dataBinding?.nodeName

		return {
			label,
			nodeName,
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.contextData !== this.props.contextData) return true
		if (nextProps.ownData !== this.props.ownData) return true
		if (nextProps.component !== this.props.component) return true
		if (nextProps.label !== this.props.label) return true
		if (nextProps.disabled !== this.props.disabled) return true
		return false
	}

	onValueChange(event, checked) {
		if (this.props.readOnly) return
		const previousValue = this.props.ownData?.[this.state.nodeName]

		const onValueChangeEvent = this.props.component.onValueChange
			? () =>
				this.props.eventHandler(
					this.props.component.onValueChange,
					null,
					{
						eventType: 'onValueChange',
						eventHandlerValues: { previousValue: previousValue },
					},
					event
				)
			: undefined

		this.props.appController.modifySingleValue(
			this.props.component.value,
			this.props.ownData,
			checked,
			{},
			onValueChangeEvent
		)
	}

	onSideEffectAction(actions) {
		if (!actions) return // actions may be null after a component unmounts
		// https://github.com/mui/material-ui/issues/30953
		// if (this.props.component.autoFocus) actions.focusVisible() // autofocus is disabled until upgrade to mui v5
	}

	render() {
		const { component, componentId, disabled, ownData, styleProp, conditionalClassNames, classes } =
			this.props

		const { label, nodeName } = this.state

		const { tabIndex, autoFocus, marginType, removePadding, checkboxColor, controlSize, labelPlacement } =
			component

		let ownValue = false

		if (isPlainObject(ownData)) {
			ownValue = ownData[nodeName]
		}

		const checkbox = (
			<Checkbox
				checked={!!ownValue}
				onChange={this.onValueChange}
				disabled={disabled}
				color={checkboxColor || e_SelectionColor.PRIMARY}
				className={classNames({
					[classes.noPaddingCheckboxWithLabel]: label && removePadding,
					[classes.noPaddingCheckbox]: !label && removePadding,
				})}
				// https://github.com/mui/material-ui/issues/30953
				// autoFocus={autoFocus} // autofocus is disabled until upgrade to mui v5
				action={autoFocus ? this.onSideEffectAction : undefined}
				tabIndex={tabIndex}
				size={controlSize}
			/>
		)

		return (
			<FormControl
				id={componentId}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				margin={marginType || e_MarginType.NORMAL}
			>
				{ label ? (
					<FormControlLabel
						label={label}
						control={checkbox}
						className={classNames({
							[classes.noPaddingLabel]: label && removePadding,
						})}
						labelPlacement={labelPlacement}
					/>
				) : (
					checkbox
				) }
			</FormControl>
		)
	}
}

UiCheckbox.propTypes = {
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	appController: PropTypes.object.isRequired,
	contextData: PropTypes.object,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // But we need object to know what to display
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiCheckbox)
