import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import List from '@material-ui/core/List'

import UiListItem from './UiListItem'
import UiListSubheader from './UiListSubheader'
import UiDivider from './UiDivider'

import { e_ListItemType } from '@appfarm/common/enums/e_PropertyTypes'

const styles = {
	root: {
		display: 'flex',
	},
	list: {
		width: '100%',
	},
}

class UiMenuList extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { component, appController, contextData, eventHandler, styleProp, conditionalClassNames, classes } =
			this.props

		const list = (
			<List className={classes.list} dense={component.dense} disablePadding={component.disablePadding}>
				{ component.staticListItems.map((item) => {
					switch (item.type) {
						case e_ListItemType.ITEM:
							return (
								<UiListItem
									key={item.id}
									ownData={item}
									component={item}
									appController={appController}
									contextData={contextData}
									disableGutters={component.disableGutters}
									enableDivider={component.divider}
									insetDivider={component.insetDivider}
									dense={component.dense}
									eventHandler={eventHandler}
								/>
							)
						case e_ListItemType.SUBHEADER:
							return (
								<UiListSubheader
									key={item.id}
									component={item}
									appController={appController}
									contextData={contextData}
								/>
							)
						case e_ListItemType.DIVIDER:
							return (
								<UiDivider
									key={item.id}
									component={item}
									appController={appController}
									contextData={contextData}
								/>
							)
					}
				}) }
			</List>
		)

		return (
			<div className={classNames(classes.root, 'c' + component.id, conditionalClassNames)} style={styleProp}>
				{ list }
			</div>
		)
	}
}

UiMenuList.propTypes = {
	component: PropTypes.object.isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		getDataFromDataBinding: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	ownData: PropTypes.array,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiMenuList)
