import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import LinearProgress from '@material-ui/core/LinearProgress'

class UiLinearProgress extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromProps(nextProps) {
		let value = nextProps.appController.getDataFromDataValue(nextProps.component.value, nextProps.contextData)

		if (!Number.isFinite(value)) value = 0

		let valueBuffer
		if (nextProps.component.valueBuffer)
			valueBuffer = nextProps.appController.getDataFromDataValue(
				nextProps.component.valueBuffer,
				nextProps.contextData
			)

		return {
			value,
			valueBuffer,
		}
	}

	render() {
		const { component, styleProp, conditionalClassNames } = this.props
		const { value, valueBuffer } = this.state

		return (
			<LinearProgress
				value={value}
				valueBuffer={valueBuffer}
				variant={component.type}
				color={component.progressColor}
				className={classNames('c' + component.id, conditionalClassNames)}
				style={styleProp}
			/>
		)
	}
}

UiLinearProgress.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		progressColor: PropTypes.oneOf(['primary', 'secondary']),
		type: PropTypes.oneOf(['determinate', 'indeterminate', 'buffer', 'query']),
		valueBuffer: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
}

export default UiLinearProgress
