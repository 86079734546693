import {
	SET_RUNTIME_STATE_VALUE,
	SET_RUNTIME_STATE_OBJECT_VALUE,
	TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE,
	OPEN_DIALOG,
	CLOSE_DIALOG,
	OPEN_POPOVER,
	CLOSE_POPOVER,
	OPEN_DRAWER,
	CLOSE_DRAWER,
	ADD_KEYBOARD_SHORTCUT,
	REMOVE_KEYBOARD_SHORTCUT,
} from './actionTypes'

export const setRuntimeStateValue = (key, value) => ({
	type: SET_RUNTIME_STATE_VALUE,
	payload: { key, value },
})

export const setRuntimeStateObjectValue = (key, value) => ({
	type: SET_RUNTIME_STATE_OBJECT_VALUE,
	payload: { key, value },
})

export const toggleRuntimeStateBooleanValue = (key) => ({
	type: TOGGLE_RUNTIME_STATE_BOOLEAN_VALUE,
	payload: { key },
})

export const setViewTransition = (value) => setRuntimeStateValue('viewTransition', value)

export const openDialog = (id) => ({
	type: OPEN_DIALOG,
	payload: { id },
})

export const closeDialog = (id) => ({
	type: CLOSE_DIALOG,
	payload: { id },
})

export const openPopover = (id, contextData, anchorElement, anchorPosition) => ({
	type: OPEN_POPOVER,
	payload: { id, contextData, anchorElement, anchorPosition },
})

export const closePopover = (id) => ({
	type: CLOSE_POPOVER,
	payload: { id },
})

export const openDrawer = (id) => ({
	type: OPEN_DRAWER,
	payload: { id },
})

export const closeDrawer = (id) => ({
	type: CLOSE_DRAWER,
	payload: { id },
})

export const addToActiveKeyboardShortcuts = (eventHandler) => ({
	type: ADD_KEYBOARD_SHORTCUT,
	payload: { eventHandler },
})

export const removeFromActiveKeyboardShortcuts = (id) => ({
	type: REMOVE_KEYBOARD_SHORTCUT,
	payload: { id },
})
