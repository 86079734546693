import { ONE, MANY } from '@appfarm/common/enums/e_Cardinality'
import { getSideEffects } from '../../modules/afClientApi'

const insertNewObjects = function ({
	dataSource,
	setSelectedAfterCreate,
	replaceObjects,
	newObjects,
	logger,
}) {
	return new Promise((resolve, reject) => {
		if (!dataSource.local)
			reject(new Error('Unable to create multiple objects. The Data Source has to be runtime.'))

		if (dataSource.cardinality === ONE && dataSource.getAllObjects().length !== 0 && !replaceObjects)
			return reject(
				new Error(
					'ModelError: Duplicate object failed - Cannot create object in non-empty dataSource with cardinality one'
				)
			)

		let changeDescription
		if (dataSource.cardinality === ONE) {
			dataSource._replaceAllObjects([newObjects[0]])
			changeDescription = { objectsReplaced: [newObjects[0]] }
		} else if (replaceObjects) {
			dataSource._replaceAllObjects(newObjects)
			changeDescription = { objectsReplaced: newObjects }
		} else {
			dataSource._addOrMergeObjects(newObjects)
			changeDescription = { objectsAdded: newObjects }
		}

		dataSource._writeToRedux()

		const creationPromise = () => {
			if (dataSource.reverseDependencies.length === 0)
				return Promise.resolve({ sideEffects: {}, invalidatedDataSourceIdDict: {} })

			const selectionData = dataSource.getDataForSynchronization()

			const invalidatedDataSourceIdDict = dataSource.__appController.invalidateDataSourcesById(
				dataSource.reverseDependencies.map((item) => item.dataSourceId),
				{ updateGui: true }
			)
			return getSideEffects(dataSource.id, changeDescription, selectionData).then((sideEffects) =>
				Promise.resolve({ sideEffects, invalidatedDataSourceIdDict })
			)
		}

		// Now tell the server about the new object
		creationPromise()
			.then(({ sideEffects, invalidatedDataSourceIdDict }) => {
				// Apply side effects
				dataSource.__appController.writeSideEffects(dataSource, sideEffects, {
					logger,
					invalidatedDataSourceIdDict,
				})

				// Set active
				if (dataSource.cardinality === MANY && setSelectedAfterCreate) {
					dataSource
						.p_filteredSelection({
							staticFilter: { _id: { $in: newObjects.map((newObject) => newObject._id) } },
						})
						.then(() => resolve(newObjects))
						.catch((err) => {
							logger.error('Failed to set selection after creation', { err })
							reject(new Error('Failed to set selection after creation'))
						})
				} else {
					resolve(newObjects)
				}
			})
			.catch((err) => {
				// Something happened reverse stuff
				dataSource._removeMultipleObjects(newObjects.map((newObject) => newObject._id))
				dataSource._writeToRedux()

				reject(err)
			})
	})
}

export default insertNewObjects
