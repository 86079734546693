const locale = {
	name: 'da',
	weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
	weekdaysShort: 'søn._man._tirs._ons._tors._fre._lør.'.split('_'),
	weekdaysMin: 'sø._ma._ti._on._to._fr._lø.'.split('_'),
	months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split('_'),
	monthsShort: 'jan._feb._mar._apr._maj_juni_juli_aug._sept._okt._nov._dec.'.split('_'),
	weekStart: 1,
	ordinal: (n) => `${n}.`,
	formats: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D. MMMM YYYY',
		LLL: 'D. MMMM YYYY HH:mm',
		LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
	},
	relativeTime: {
		future: 'om %s',
		past: '%s siden',
		s: 'få sekunder',
		m: 'et minut',
		mm: '%d minutter',
		h: 'en time',
		hh: '%d timer',
		d: 'en dag',
		dd: '%d dage',
		M: 'en måned',
		MM: '%d måneder',
		y: 'et år',
		yy: '%d år',
	},
	calendar: {
		sameDay: '[i dag kl.] LT',
		nextDay: '[i morgen kl.] LT',
		nextWeek: 'på dddd [kl.] LT',
		lastDay: '[i går kl.] LT',
		lastWeek: '[i] dddd[s kl.] LT',
		sameElse: 'L',
	},
}

export default locale
