import ObjectClassPropertyMetadata from './ObjectClassPropertyMetadata'

class ObjectClassMetadata {
	constructor(description) {
		this.id = description.id
		this.displayProperty = description.displayProperty //ID
		this.isFileObjectClass = description.isFileObjectClass
		this.storeCreatedBy = description.storeCreatedBy
		this.storeUpdatedBy = description.storeUpdatedBy
		this.storeRandomId = description.storeRandomId

		this.propertiesByNodeName = {}
		this.propertiesById = {}
		this.displayNameProperty = null

		this.propertyList =
			description.properties?.map((objectClassPropertyDescription) => {
				const objectClassMetadata = new ObjectClassPropertyMetadata(objectClassPropertyDescription)

				this.propertiesByNodeName[objectClassPropertyDescription.nodeName] = objectClassMetadata
				this.propertiesById[objectClassPropertyDescription.id] = objectClassMetadata

				// Place naming metadata easily accessible
				if (description.displayProperty && description.displayProperty === objectClassPropertyDescription.id)
					this.displayNameProperty = objectClassMetadata

				return objectClassMetadata
			}) || []
	}

	getPropertyIdDictionary() {
		return this.propertiesById
	}

	getPropertyList() {
		return this.propertyList
	}
}

export default ObjectClassMetadata
