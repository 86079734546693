import {
	OBJECT_COUNT,
	SELECTED_OBJECTS_COUNT,
	IS_EMPTY,
	IS_NOT_EMPTY,
	HAS_SELECTED_OBJECTS,
	HAS_NO_SELECTED_OBJECTS,
} from '@appfarm/common/enums/e_BuiltInDataSourceAttributeIds'

const getAttributeChanges = (prevStats, newStats, changedNodeNames = {}) => {
	const prevNumObjects = prevStats.numObjects
	const prevNumSelectedObjects = prevStats.numSelectedObjects
	const newNumObjects = newStats.numObjects
	const newNumSelectedObjects = newStats.numSelectedObjects

	if (prevNumObjects !== newNumObjects) {
		changedNodeNames[OBJECT_COUNT] = true

		if (newNumObjects === 0 || prevNumObjects === 0) {
			changedNodeNames[IS_EMPTY] = true
			changedNodeNames[IS_NOT_EMPTY] = true
		}
	}

	if (prevNumSelectedObjects !== newNumSelectedObjects) {
		changedNodeNames[SELECTED_OBJECTS_COUNT] = true

		if (newNumSelectedObjects === 0 || prevNumSelectedObjects === 0) {
			changedNodeNames[HAS_SELECTED_OBJECTS] = true
			changedNodeNames[HAS_NO_SELECTED_OBJECTS] = true
		}
	}

	return changedNodeNames
}

export default getAttributeChanges
