/**
 * This will be run when applying persist to a local file data source
 */
import axios from 'axios'

import { generateContentDisposition } from '@appfarm/common/utils/contentDisposition'
import { OBJECT_STATE } from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_ObjectState from '@appfarm/common/enums/e_ObjectState'
import { requestFileUpload, finalizeFileUpload } from '#modules/afClientApi'
import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'

const uploadSingle = ({
	actionNodeRunner,
	actionNodeLogger,
	actionNode,
	dataSource,
	fileObject,
	newOrUpdatedFileObject,
}) =>
	new Promise((resolve, reject) => {
		// File already uploaded and filedata unchanged
		if (!fileObject.__file) {
			// Do a regular persist
			return resolve({ fileContentUnchanged: true })
		}

		// Dont need file content to be sent just yet
		const dataForToken =
			newOrUpdatedFileObject && Object.keys(newOrUpdatedFileObject).length
				? { ...newOrUpdatedFileObject }
				: { _id: fileObject._id }
		delete dataForToken.__file
		delete dataForToken.__fileContentLink
		delete dataForToken.__actionId
		delete dataForToken.__actionNodeId

		const newFile = fileObject[OBJECT_STATE] === e_ObjectState.NEW

		const rootActionId = actionNodeRunner.getRootAction().id

		requestFileUpload({
			actionId: fileObject.__actionId || rootActionId,
			actionNodeId: fileObject.__actionNodeId || actionNode.id,
			data: dataForToken,
			newFile,
		})
			.then((tokenResponse) => {
				if (tokenResponse.code !== 200) throw new Error('Unable to get upload token')

				const data = new FormData()

				const { bucket, accessId, policy, signature, key, acl, jwtPayload } = tokenResponse.payload

				// File-Object name
				data.append('key', key)

				// Misc
				data.append(
					'Content-Disposition',
					generateContentDisposition(fileObject.originalFileName, { type: 'attachment' })
				)
				data.append('Cache-Control', 'public,max-age=3600')
				data.append('acl', acl)

				// Security stuff
				data.append('GoogleAccessId', accessId)
				data.append('policy', policy)
				data.append('signature', signature)

				// Metadata
				data.append('x-goog-meta-originalname', fileObject.originalFileName)

				// File must be last
				data.append('file', fileObject.__file)

				const config = {
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						actionNodeLogger.debug('PROGRESS: ' + percentCompleted)
						dataSource.setFileUploadProgress(fileObject._id, percentCompleted)
					},
				}

				return Promise.all([
					axios.post(`https://storage.googleapis.com/${bucket}`, data, config),
					Promise.resolve(jwtPayload),
				])
			})
			.then(([uploadResult, jwtPayload]) => finalizeFileUpload(jwtPayload))
			.then((result) => {
				if (result && result.code && result.code !== 200) {
					dataSource.setFileUploadProgress(fileObject._id, 0)
					return reject(new Error('Unable to upload file'))
				}
				actionNodeLogger.debug('Final result: ')
				actionNodeLogger.table(result.fileObject, null, { dataSourceId: dataSource.id })

				const resultFileObject = result.fileObject
				resultFileObject.__uploadComplete = true
				resultFileObject.__uploadProgress = 100
				resultFileObject.__file = null

				dataSource.setFileUploadComplete(resultFileObject)

				resolve()
			})
			.catch((err) => {
				dataSource.setFileUploadProgress(fileObject._id, 0)
				actionNodeLogger.error('Error uploading file', { err })
				reject(err)
			})
	})

const p_persistFiles = ({
	dataSource,
	actionNode,
	actionNodeRunner,
	actionNodeLogger,
	newOrUpdatedData,
	contextData,
}) =>
	new Promise((resolve, reject) => {
		// No data or no changed data
		if (!dataSource.getAllObjects().length) return resolve()
		if (!newOrUpdatedData.length) return resolve()

		const { selectionType, staticFilter, filterDescriptor } = actionNode
		const data = dataSource.getObjectsBySelectionType({
			selectionType: selectionType || e_ActionNodeSelectionType.ALL,
			staticFilter,
			filterDescriptor,
			contextData,
		})
		const newOrUpdatedDataDict = newOrUpdatedData.reduce((acc, object) => {
			acc[object._id] = object
			return acc
		}, {})

		let currentIndex = 0
		let runRegularPersist = false

		const uploadNext = () => {
			const nextObject = data[currentIndex]

			if (!nextObject) return resolve({ runRegularPersist, objectIds: data.map((item) => item._id) })

			const newOrUpdatedNextObject = newOrUpdatedDataDict[nextObject._id]

			uploadSingle({
				actionNodeRunner,
				actionNodeLogger,
				dataSource,
				actionNode,
				fileObject: nextObject,
				newOrUpdatedFileObject: newOrUpdatedNextObject,
			})
				.then((result) => {
					if (result && result.fileContentUnchanged) runRegularPersist = true

					currentIndex++
					uploadNext()
				})
				.catch(reject)
		}

		uploadNext()
	})

export default p_persistFiles
