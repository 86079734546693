import { GenericAppfarmError, ApiRequestError } from '../utils/clientErrors'

const axiosErrorParser = (err) => {
	if (err.response) {
		// Try to parse error
		if (err.response.data) {
			if (err.response.data.name && err.response.data.code) return new GenericAppfarmError(err.response.data)

			if (err.response.data.error && err.response.data.error.code) {
				return new GenericAppfarmError(err.response.data.error)
			}
		}

		return new ApiRequestError(
			'The request was made and the server responded with a status code that falls out of the range of 2xx',
			{
				statusCode: err.response.status,
				statusText: err.response.statusText,
				responseHeaders: err.response.headers,
				responseData: err.response.data,
			},
			err.response.status
		)
	} else if (err.request) {
		return new ApiRequestError('The request was made but no response was received', { url: null })
	} else {
		if (err instanceof Error) {
			return err
		} else {
			return new ApiRequestError('Unknown error', { err: err })
		}
	}
}

export default axiosErrorParser
