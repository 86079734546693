import isString from 'lodash/isString'
import isNil from 'lodash/isNil'
import { ASCENDING } from '@appfarm/common/enums/e_SortOrder'

const objectSorter = (objectArray, sortDescriptorArray, { appController } = {}) => {
	if (!objectArray) return objectArray
	if (!sortDescriptorArray) return objectArray
	if (!sortDescriptorArray.length) return objectArray

	return sortDescriptorArray.reduceRight(
		(sortedObjects, sortDescriptor) => {
			const sortValueNegative = sortDescriptor.sortOrder === ASCENDING ? -1 : 1
			const sortValuePositive = sortDescriptor.sortOrder === ASCENDING ? 1 : -1

			let enumValueToIndex
			if (appController && sortDescriptor.sortField?.enumeratedTypeId) {
				const enumType = appController.getEnumeratedType(sortDescriptor.sortField.enumeratedTypeId)
				enumValueToIndex = enumType.values.reduce((dict, enumValue, index) => {
					dict[enumValue.value] = index
					return dict
				}, {})
			}

			sortedObjects.sort((a, b) => {
				let valueA = a[sortDescriptor.sortNodeName]
				let valueB = b[sortDescriptor.sortNodeName]

				// nil -> null or undefined
				if (isNil(valueA) && isNil(valueB)) return 0 // both nil -- keep current sorting
				if (isNil(valueA)) return sortValuePositive
				if (isNil(valueB)) return sortValueNegative

				if (enumValueToIndex) {
					valueA = enumValueToIndex[valueA]
					valueB = enumValueToIndex[valueB]
				}

				if (isString(valueA)) valueA = valueA.toUpperCase()
				if (isString(valueB)) valueB = valueB.toUpperCase()

				if (valueA < valueB) return sortValueNegative
				if (valueA > valueB) return sortValuePositive
				return 0
			})

			return sortedObjects
		},
		[...objectArray]
	)
}

export default objectSorter
