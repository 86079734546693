import React from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import LoadingComponent from '../uiComponents/utils/LoadingComponent'

const ScanBarcodeDialog = loadable(() => import('./ScanBarcodeDialog'), {
	fallback: <LoadingComponent />,
})

const LoadableScanBarcodeDialog = () => {
	const open = useSelector((state) => state.appState.scanBarcodeDialogOpen)

	if (open) return <ScanBarcodeDialog />

	return null
}

export default LoadableScanBarcodeDialog
