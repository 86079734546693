import { e_Level } from './types'
import AppfarmLogger from './AppfarmLogger'
import ConsoleTransport from './transports/ConsoleTransport'
import AnalysisTransport from './transports/AnalysisTransport'

let LOGGING_LEVEL = e_Level.DEBUG
let enabled = process.env.NODE_ENV !== 'production'

/* eslint-disable no-undef */
// @ts-ignore
if (process.env.NODE_ENV === 'production' && AF_PARAMS && AF_PARAMS.enableClientLog) {
	enabled = true
}
/* eslint-enable no-undef */

const consoleTransport = new ConsoleTransport({ level: e_Level.DEBUG })
const analysisTransport = new AnalysisTransport({ level: e_Level.DEBUG })

const logger = new AppfarmLogger({
	enabled,
	level: LOGGING_LEVEL,
	metadata: { prefix: 'Appfarm' },
	logName: 'default',
	transports: [consoleTransport],
})

export const actionLogger = new AppfarmLogger({
	enabled,
	level: LOGGING_LEVEL,
	metadata: { prefix: 'Actions' },
	logName: 'Actions',
	transports: [consoleTransport],
})

export const liveUpdateLogger = new AppfarmLogger({
	enabled,
	level: LOGGING_LEVEL,
	metadata: { prefix: 'LiveUpdate' },
	logName: 'LiveUpdate',
	transports: [consoleTransport],
})

export const modelAnalyticsLogger = new AppfarmLogger({
	enabled: true,
	level: LOGGING_LEVEL,
	metadata: {}, // TODO
	logName: 'model-analysis',
	transports: [analysisTransport],
})

export const modelAnalytics_logPerformanceMeasure = ({
	message = '',
	metadata = {},
	timeStart = 0,
	timeEnd,
}: {
	message?: string
	timeStart?: number
	timeEnd: number
	metadata: { [key: string]: any }
}) => {
	const timeDiff = Math.round((timeEnd - timeStart + Number.EPSILON) * 1000) / 1000
	modelAnalyticsLogger.info(`${message} - elapsed time: ${timeDiff}ms`, {
		...metadata,
		labels: { ...metadata.labels, advisory: 'true', type: 'performance', traceId: modelAnalyticsLogger.id },
		timeDiff,
	})
}

export default logger
