import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withStyles, withTheme } from '@material-ui/core/styles'

import appfarm_light from '../../../resources/appfarm_logo_black.svg'
import appfarm_dark from '../../../resources/appfarm_logo_white.svg'

const styles = (theme) => ({
	outerContainer: {
		height: '100%',
		width: '100%',
		display: 'block',
		margin: '0 auto',
		overflowY: 'auto',
		transition: theme.transitions.create(['background-color']),
		backgroundColor: theme.palette.background.default,
	},
	container: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 568,
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '16px 16px 8px 16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '100%',
			maxWidth: 450,
			minHeight: 450,
		},
		[theme.breakpoints.up('md')]: {
			width: 360,
			minHeight: 568,
			margin: '0 auto 58px auto',
		},
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	logo: {
		maxHeight: 100,
		maxWidth: '100%',
	},
	loginSectionsContainer: {
		position: 'relative',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
})

const LoginWrapper = (props) => {
	const { logo, children, style, theme, classes } = props

	const defaultLogo = theme.palette.type === 'dark' ? appfarm_dark : appfarm_light

	return (
		<div className={classes.outerContainer} style={style}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.logoContainer}>
						<img className={classes.logo} src={logo || defaultLogo} />
					</div>
					<div className={classes.loginSectionsContainer}>{ children }</div>
				</div>
			</div>
		</div>
	)
}

LoginWrapper.propTypes = {
	logo: PropTypes.string,
	style: PropTypes.object,
	theme: PropTypes.object,
	children: PropTypes.any,
	classes: PropTypes.object,
}

const makeMapStateToProps = () => {
	let style
	const mapStateToProps = (state) => {
		const authState = state.authState

		if (!style) {
			style = {}

			if (authState.backgroundColor) style.backgroundColor = authState.backgroundColor

			if (authState.backgroundImageValue) {
				style.backgroundImage = `url(${authState.backgroundImageValue})`
				style.backgroundSize = authState.backgroundImageSize
				style.backgroundRepeat = authState.backgroundImageRepeat
				style.backgroundPosition = 'center'
			}

			if (authState.backgroundGradient) style.background = authState.backgroundGradient
		}

		return {
			logo: state.authState.logo,
			style,
		}
	}

	return mapStateToProps
}

export default connect(makeMapStateToProps)(withTheme(withStyles(styles)(LoginWrapper)))
