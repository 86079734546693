import React, { useState } from 'react'

import { Trans } from '@lingui/macro'

import { makeStyles } from '@material-ui/core/styles'

import { useSelector } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'

import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		padding: '16px 24px',
	},
	row: {
		display: 'flex',
		whiteSpace: 'nowrap',
	},
	description: {
		flex: 1,
		padding: '4px 0',
		color: theme.palette.text.secondary,
		marginRight: 48,
	},
	keyBinding: {
		padding: '4px 0',
		textAlign: 'right',
		textTransform: 'capitalize',
	},
	keyItem: {
		backgroundColor: theme.palette.action.selected,
		borderRadius: 3,
		padding: '2px 5px',
		marginLeft: 6,
		fontSize: '80%',
		minWidth: 22,
		display: 'inline-block',
		textAlign: 'center',
	},
	buttonContainer: {
		paddingTop: 18,
		display: 'flex',
		justifyContent: 'flex-end',
	},
}))

const UiKeyboardShortcutsManager = () => {
	const [showDialog, setShowDialog] = useState(false)

	const activeKeyboardShortcuts = useSelector((state) => state.runtimeState.activeKeyboardShortcuts)

	const enabled = !!activeKeyboardShortcuts.length

	useHotkeys('F1', () => setShowDialog(true), { enabled }, [enabled])

	const classes = useStyles()

	if (!enabled) return null

	return (
		<Dialog open={showDialog} onClose={() => setShowDialog(false)} classes={{ paper: classes.dialogPaper }}>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				<Trans>Keyboard Shortcuts</Trans>
			</Typography>
			{ activeKeyboardShortcuts
				.sort((itemA, itemB) => {
					const valueA = itemA.description?.toUpperCase()
					const valueB = itemB.description?.toUpperCase()
					if (valueA < valueB) return -1
					if (valueA > valueB) return 1
					return 0
				})
				.map((shortcut) => {
					return (
						<div key={shortcut.id} className={classes.row}>
							<div className={classes.description}>{ shortcut.description || '' }</div>
							<div className={classes.keyBinding}>
								{ shortcut.keyBinding?.split('+').map((item) => (
									<span key={item} className={classes.keyItem}>
										{ item }
									</span>
								)) }
							</div>
						</div>
					)
				}) }
			<div className={classes.buttonContainer}>
				<Button variant="contained" color="primary" onClick={() => setShowDialog(false)}>
					<Trans>Close</Trans>
				</Button>
			</div>
		</Dialog>
	)
}

export default UiKeyboardShortcutsManager
