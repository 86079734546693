/******************************************************************************
 *
 * This module will register and update web push subscriptions on apps
 * which has this enabled
 *
 *****************************************************************************/

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ObjectId from 'bson-objectid'
import axios from 'axios'

import { p_enableNotification, p_registerPushSubscription } from '#utils/pwaUtils'
import { getCurrentAppEntryFromAppList } from '#selectors/metadataSelectors'
import { getCurrentUserId } from '#selectors/authStateSelectors'

const WEB_PUSH_PUBLIC_KEY = window.AF_PARAMS.webPushPublicKey

// TODO: To be able to support the iOS, we need to have the
// web push subscription be triggered by a user action.
// This module needs to render a dialog or similar so that the
// user can initiate the request

const WebPushManager = () => {
	const activeApp = useSelector(getCurrentAppEntryFromAppList)
	const userId = useSelector(getCurrentUserId)

	useEffect(() => {
		if (!activeApp) return
		if (!userId) return

		if (activeApp.pwaEnablePush) {
			const controller = new AbortController()
			p_enableNotification()
				.then(() => p_registerPushSubscription(WEB_PUSH_PUBLIC_KEY))
				.then((sub) => {
					if (!localStorage) throw new Error('LocalStorage is not available - no need to register push')

					const itemKey = `__AF_WEB_PUSH_SUB__${userId}`

					let subId = localStorage.getItem(itemKey)

					if (!subId) {
						subId = new ObjectId().toString()
						localStorage.setItem(itemKey, subId)
					}

					return axios.post(
						`/api/v1/apps/${activeApp.id}/webpush/${subId}`,
						{ subscription: sub },
						{ signal: controller.signal }
					)
				})

				.then(() => console.log('Web Push Subscription update ok '))
				.catch((err) => console.error('Unable to enable push messages: ', err))

			return () => controller.abort()
		}
	}, [activeApp, userId])

	return null
}

export default WebPushManager
