import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import useResizeObserver from 'use-resize-observer/polyfilled'

import { getDataSourcesFromContext } from '#utils/contextDataUtils'

import classNames from 'classnames'

import Popover from '@material-ui/core/Popover'

import UiComponent from './UiComponent'

import {
	TOP_LEFT,
	TOP_CENTER,
	TOP_RIGHT,
	CENTER_LEFT,
	CENTER_CENTER,
	CENTER_RIGHT,
	BOTTOM_LEFT,
	BOTTOM_CENTER,
	BOTTOM_RIGHT,
} from '@appfarm/common/enums/e_ElementPosition'

import { closePopover } from '#actions/runtimeStateActions'

const TransitionProps = {
	mountOnEnter: true,
	unmountOnExit: true,
}

const elementPositions = {
	[TOP_LEFT]: undefined, // default: { vertical: 'top', horizontal: 'left' },
	[TOP_CENTER]: { vertical: 'top', horizontal: 'center' },
	[TOP_RIGHT]: { vertical: 'top', horizontal: 'right' },
	[CENTER_LEFT]: { vertical: 'center', horizontal: 'left' },
	[CENTER_CENTER]: { vertical: 'center', horizontal: 'center' },
	[CENTER_RIGHT]: { vertical: 'center', horizontal: 'right' },
	[BOTTOM_LEFT]: { vertical: 'bottom', horizontal: 'left' },
	[BOTTOM_CENTER]: { vertical: 'bottom', horizontal: 'center' },
	[BOTTOM_RIGHT]: { vertical: 'bottom', horizontal: 'right' },
}

const UiPopover = (props) => {
	const popoverActions = useRef()

	const { ref } = useResizeObserver({
		onResize: () => {
			if (popoverActions.current) popoverActions.current.updatePosition()
		},
	})

	const handleClose = useCallback(
		(event) => {
			event && event.stopPropagation()
			props.closePopover()

			if (props.component.onClose) {
				props.eventHandler.call(
					props.eventHandler,
					props.component.onClose,
					null,
					{ eventType: 'onClose' },
					event
				)
			}
		},
		[props.component.onClose, props.eventHandler]
	)

	useEffect(() => {
		return () => {
			props.closePopover()
		}
	}, [])

	const anchorOrigin = useMemo(
		() => elementPositions[props.component.anchorOrigin],
		[props.component.anchorOrigin]
	)
	const transformOrigin = useMemo(
		() => elementPositions[props.component.transformOrigin],
		[props.component.transformOrigin]
	)
	const BackdropProps = useMemo(
		() => ({ invisible: !props.component.showBackdrop }),
		[props.component.showBackdrop]
	)

	const {
		component,
		disabled,
		readOnly,
		dataUpdateReference,
		contextData,
		anchorElement,
		anchorPosition,
		anchorReference,
		conditionalClassNames,
	} = props

	const isOpen = Boolean(anchorElement) || Boolean(anchorPosition)

	return (
		<Popover
			open={isOpen}
			anchorEl={anchorElement}
			anchorPosition={anchorPosition}
			anchorReference={anchorReference}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			TransitionProps={TransitionProps}
			BackdropProps={BackdropProps}
			action={popoverActions}
			PaperProps={{
				className: classNames('c' + component.id, conditionalClassNames),
				style: props.styleProp,
			}}
		>
			<div ref={ref}>
				{ component.children?.map((childComponent) => (
					<UiComponent
						key={childComponent.id}
						index={childComponent.sortIndex}
						dataUpdateReference={dataUpdateReference}
						component={childComponent}
						contextData={contextData}
						disabled={disabled}
						readOnly={readOnly}
					/>
				)) }
			</div>
		</Popover>
	)
}

UiPopover.propTypes = {
	component: PropTypes.object.isRequired,
	closePopover: PropTypes.func.isRequired,
	anchorElement: PropTypes.any,
	anchorPosition: PropTypes.object,
	anchorReference: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	dataUpdateReference: PropTypes.number,
	readOnly: PropTypes.bool.isRequired,
	contextData: PropTypes.object,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	eventHandler: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
	let anchorElement
	let anchorPosition
	const openPopoverDescription = state.runtimeState.openPopovers.find(
		(item) => item.id === ownProps.component.id
	)

	if (openPopoverDescription) {
		// Found match - now check context
		const openingContext = openPopoverDescription.contextData
		const ownContext = ownProps.contextData || {}

		const openingContextDataSources = getDataSourcesFromContext(openingContext)
		const ownContextDataSources = getDataSourcesFromContext(ownContext)

		// Opening context must overlap with ownContext
		const matchingContext = Object.keys(ownContextDataSources).every((dataSourceId) => {
			if (!openingContextDataSources[dataSourceId]) return false
			if (ownContextDataSources[dataSourceId][0]._id === openingContextDataSources[dataSourceId][0]._id)
				return true
			return false
		})

		if (matchingContext) {
			anchorElement = openPopoverDescription.anchorElement
			anchorPosition = openPopoverDescription.anchorPosition
		}
	}

	return {
		anchorElement: anchorElement,
		anchorPosition: anchorPosition,
		anchorReference: anchorPosition ? 'anchorPosition' : 'anchorEl',
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		closePopover: () => dispatch(closePopover(ownProps.component.id)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UiPopover)
