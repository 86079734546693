import { INVALIDATE_RESOURCE, VALIDATE_RESOURCE } from './actionTypes'

export const validateResource = (resourceName) => ({
	type: VALIDATE_RESOURCE,
	payload: resourceName,
})

export const invalidateResource = (resourceName) => ({
	type: INVALIDATE_RESOURCE,
	payload: resourceName,
})

export const invalidateClientStatus = () => invalidateResource('clientStatus')
