const translatObjectClasses = (objectClasses, ocTranslations) => {
	if (!ocTranslations || !Object.keys(ocTranslations).length) return objectClasses

	objectClasses = objectClasses.map((objectClass) => {
		const ocTranslation = ocTranslations[objectClass.id]
		if (!ocTranslation) return objectClass

		objectClass = { ...objectClass }
		objectClass.properties = objectClass.properties.map((property) => {
			const translatedProperty = { ...property }
			const translationItem = ocTranslation[property.id]
			if (translationItem) {
				Object.entries(translationItem).forEach(([ident, value]) => {
					translatedProperty[ident] = value
				})
			}
			return translatedProperty
		})

		return objectClass
	})

	return objectClasses
}

export default translatObjectClasses
