import StorageController, { keyValue } from '../controllers/StorageController'
import { getState } from '../store/reduxStoreUtils'

const getPartialState = (): { isAnonymous: boolean | null } => {
	const state = getState()

	const isAnonymous = state?.authState?.isAnonymous
	return {
		isAnonymous,
	}
}

const userLocalStorageInstance = new StorageController('__APPFARM_USER', window.localStorage)
const anonLocalStorageInstance = new StorageController('__APPFARM_ANONYMOUS', window.localStorage)

const getValue = (path: string[]): keyValue => {
	const { isAnonymous } = getPartialState()

	if (isAnonymous) {
		return anonLocalStorageInstance.getValue(path)
	} else {
		return userLocalStorageInstance.getValue(path)
	}
}

const setValue = (path: string[], value: keyValue): void => {
	const { isAnonymous } = getPartialState()

	if (isAnonymous) {
		return anonLocalStorageInstance.setValue(path, value)
	} else {
		return userLocalStorageInstance.setValue(path, value)
	}
}

const clearValue = (path: string[]): void => {
	const { isAnonymous } = getPartialState()

	if (isAnonymous) {
		return anonLocalStorageInstance.clearValue(path)
	} else {
		return userLocalStorageInstance.clearValue(path)
	}
}

const clearValues = (): void => {
	const { isAnonymous } = getPartialState()

	if (isAnonymous) {
		return anonLocalStorageInstance.clearValues()
	} else {
		return userLocalStorageInstance.clearValues()
	}
}

const cleanKeyValuesByPath = (path: string[], keyDict: { [key: string]: boolean }): void => {
	const { isAnonymous } = getPartialState()

	if (isAnonymous) {
		return anonLocalStorageInstance.cleanKeyValuesByPath(path, keyDict)
	} else {
		return userLocalStorageInstance.cleanKeyValuesByPath(path, keyDict)
	}
}

export default {
	setValue,
	getValue,
	clearValue,
	clearValues,
	cleanKeyValuesByPath,
}
