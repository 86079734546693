import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'

import { e_LoaderVariant } from '@appfarm/common/enums/e_PropertyTypes'

const useStyles = makeStyles({
	circularProgressContainer: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	circularProgress: {
		marginBottom: 8,
	},
	linearProgressTop: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
	},
	linearProgressBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
	},
})

const UiContainerLoader = ({ variant, color, message, visible }) => {
	const classes = useStyles()

	let loader
	switch (variant) {
		case e_LoaderVariant.LINEAR_TOP:
			loader = <LinearProgress className={classes.linearProgressTop} color={color} />
			break
		case e_LoaderVariant.LINEAR_BOTTOM:
			loader = <LinearProgress className={classes.linearProgressBottom} color={color} />
			break
		default:
			// CIRCULAR
			loader = (
				<div className={classes.circularProgressContainer}>
					<CircularProgress className={classes.circularProgress} color={color} />
					{ message && (
						<Typography variant="body2" color="textSecondary">
							{ message }
						</Typography>
					) }
				</div>
			)
	}

	return (
		<Fade in={visible} style={{ transitionDelay: visible ? '500ms' : '0ms' }} mountOnEnter unmountOnExit>
			{ loader }
		</Fade>
	)
}

UiContainerLoader.propTypes = {
	variant: PropTypes.string,
	color: PropTypes.string,
	message: PropTypes.string,
	visible: PropTypes.bool.isRequired,
}

export default UiContainerLoader
