import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import axios from 'axios'

import browserHistory from '../../browserHistory'
import serverMessageDecoder from '../common/serverMessageDecoder'

import { withStyles, darken, alpha } from '@material-ui/core/styles'
import classNames from 'classnames'

import green from '@material-ui/core/colors/green'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import LoginSection from '../common/LoginSection'

import { setEmailValue } from '#actions/authActions'
import checkPassword from '#modules/pwnedPasswordsApi'

const styles = (theme) => ({
	button: {
		marginTop: 12,
	},
	checkIcon: {
		fontSize: 70,
		color: green[600],
	},
	wrapper: {
		alignSelf: 'center',
		position: 'relative',
		backgroundColor: alpha(darken(theme.palette.background.default, 0.05), 0.8),
		height: 80,
		width: 80,
		padding: 5,
		borderRadius: '50%',
		marginBottom: 16,
	},
	progress: {
		position: 'absolute',
		left: 0,
		top: 0,
	},
	buttonProgress: {
		position: 'absolute',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	gutter: {
		height: 16,
	},
	warningContainer: {
		padding: 12,
		marginTop: 12,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(darken(theme.palette.background.default, 0.05), 0.8),
	},
})

class ResetPasswordPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			validationActive: false,
			//noMatchError: false,
			tooShortError: false,
			passwordOne: '',
			//passwordTwo: '',
			infoMessage: 'Saving password...',
			passwordResetFailed: false,
			passwordResetFailedMessage: '',
			passwordBreaches: 0,
			showPassord: false,
			showRepeatPassword: false,
			passwordChangeInProgress: false,
			passwordChangeSuccess: false,
			passwordChangeRedirecting: false,
		}

		this.setPasswordOne = this.setPasswordOne.bind(this)
		this.handleOnPasswordOneKeyDown = this.handleOnPasswordOneKeyDown.bind(this)
		this.validatePassword = this.validatePassword.bind(this)
		this.initiatePasswordChange = this.initiatePasswordChange.bind(this)
		this.gotToLogin = this.gotToLogin.bind(this)
		this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
		this.handleClickShowRepeatPassword = this.handleClickShowRepeatPassword.bind(this)
	}

	setPasswordOne(event) {
		this.setState(
			{
				errorMessage: '',
				passwordOne: event.target.value,
			},
			() => {
				if (this.state.validationActive) this.validatePassword()

				clearTimeout(this.pwnedPasswordCheckTimeout)
				this.pwnedPasswordCheckTimeout = setTimeout(() => {
					if (this.state.passwordOne.length > 7) {
						checkPassword(this.state.passwordOne)
							.then((result) => {
								this.setState({ passwordBreaches: result })
							})
							.catch((err) => console.error('Error checking password', err))
					} else {
						this.setState({ passwordBreaches: 0 })
					}
				}, 500)
			}
		)
	}

	handleOnPasswordOneKeyDown(event) {
		if (event.keyCode === 13) this.initiatePasswordChange()
	}

	validatePassword() {
		this.setState({ validationActive: true })

		if (this.state.passwordOne.length < 8) {
			this.setState({ tooShortError: true })
		} else {
			this.setState({ tooShortError: false })
		}

		//if (this.state.passwordOne !== this.state.passwordTwo) return false
		if (this.state.passwordOne.length < 8) return false
		return true
	}

	gotToLogin() {
		browserHistory.replace('/')
	}

	initiatePasswordChange() {
		if (this.validatePassword()) {
			this.setState({
				passwordChangeInProgress: true,
				passwordResetFailed: false,
				passwordResetFailedMessage: '',
			})

			const logIn = () =>
				axios({
					method: 'post',
					url: '/auth/pwlogin',
					data: {
						email: this.props.serverMessage.email,
						password: this.state.passwordOne,
					},
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				})

			axios({
				method: 'post',
				url: '/auth/pwlogin/setpassword',
				data: {
					reference: this.props.serverMessage.token,
					password: this.state.passwordOne,
				},
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(() => {
					this.props.setEmailValue(this.props.serverMessage.email)

					// Try to log in directly
					logIn()
						.then((result) => {
							// setTimeout(() => {
							this.setState({
								passwordChangeRedirecting: true,
								passwordChangeInProgress: false,
								passwordChangeSuccess: true,
							})
							setTimeout(() => {
								const return_path = this.props.serverMessage.return_path || '/'
								// Added security - makin sure user are not redirected outside origin
								window.location.href = window.location.origin + return_path
							}, 2000)
						})
						.catch((err) => {
							// Redirect back
							this.setState({ passwordChangeInProgress: false, passwordChangeSuccess: true })
							setTimeout(() => {
								this.setState({ passwordChangeRedirecting: true })
								setTimeout(() => {
									browserHistory.replace('/')
								}, 1500)
							}, 2000)
						})
				})
				.catch((err) => {
					let message = ''
					if (err?.response?.status === 410) {
						message =
							'Link has expired, or has already been used. Please request a new login link and try again.'
					}
					console.error('Unable to set password', err)
					this.setState({
						passwordResetFailed: true,
						passwordResetFailedMessage: message,
						passwordChangeInProgress: false,
					})
				})
		}
	}

	handleClickShowPassword() {
		this.setState((state) => ({ showPassword: !state.showPassword }))
	}

	handleClickShowRepeatPassword() {
		this.setState((state) => ({ showRepeatPassword: !state.showRepeatPassword }))
	}

	render() {
		const { classes } = this.props
		return (
			<>
				{ this.state.passwordChangeSuccess && !this.state.passwordResetFailed && (
					<LoginSection>
						<Typography variant="h5" paragraph>
							Password saved!
						</Typography>
						{ this.state.passwordChangeSuccess && (
							<div className={classes.wrapper}>
								<Icon className={classNames('mdi mdi-check', classes.checkIcon)} />
								{ this.state.passwordChangeRedirecting && (
									<CircularProgress className={classes.progress} size={80} thickness={2.2} />
								) }
							</div>
						) }
						{ this.state.passwordChangeRedirecting && (
							<Typography variant="body2" color="textSecondary" gutterBottom>
								Redirecting...
							</Typography>
						) }
					</LoginSection>
				) }
				{ !this.state.passwordChangeSuccess && (
					<LoginSection>
						{ !this.state.passwordResetFailed && (
							<>
								<Typography variant="h5" gutterBottom>
									Enter a password
								</Typography>
								<Typography variant="body2" color="textSecondary" gutterBottom>
									The password must be <strong>at least</strong> 8 characters long
								</Typography>
							</>
						) }
						{ this.state.passwordResetFailed && (
							<Typography variant="body1" color="error" gutterBottom>
								{ this.state.passwordResetFailedMessage ||
									'Password reset failed. Please try again. If this message continues to pop up, please contact support for further assistance.' }
							</Typography>
						) }
						<TextField
							variant="filled"
							type={this.state.showPassword ? 'text' : 'password'}
							label="New password"
							value={this.state.passwordOne}
							onChange={this.setPasswordOne}
							onKeyDown={this.handleOnPasswordOneKeyDown}
							margin="normal"
							disabled={this.state.passwordChangeInProgress}
							helperText={this.state.tooShortError ? 'Password is too short, minimum 8 chars' : ''}
							error={this.state.tooShortError}
							autoFocus
							autoComplete="new-password"
							name="password"
							inputProps={{
								'aria-label': 'New password',
								autoCapitalize: 'off',
								autoCorrect: 'off',
								spellCheck: 'false',
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title={this.state.showPassword ? 'Hide password' : 'Show password'}>
											<IconButton
												aria-label="Toggle password visibility"
												onClick={this.handleClickShowPassword}
												disabled={this.state.passwordChangeInProgress}
											>
												<Icon
													className={
														this.state.showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'
													}
												/>
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>

						{ this.state.passwordBreaches > 0 &&
							!this.state.passwordResetFailed &&
							!this.state.passwordChangeInProgress && (
							<Typography
								variant="body2"
								color="error"
								align="left"
								className={classes.warningContainer}
								gutterBottom
							>
								<strong>Warning</strong> This password has been leaked on the internet{ ' ' }
								<strong>{ this.state.passwordBreaches }</strong> times. We highly reccommend you to choose
								another password to keep your account safe.
							</Typography>
						) }
						<Button
							className={classes.button}
							variant="contained"
							color="primary"
							onClick={this.initiatePasswordChange}
							disabled={this.state.passwordChangeInProgress}
						>
							{ this.state.passwordChangeInProgress && (
								<CircularProgress size={24} className={classes.buttonProgress} />
							) }
							{ this.state.passwordChangeInProgress ? 'Saving password...' : 'Save password' }
						</Button>
					</LoginSection>
				) }
			</>
		)
	}
}

ResetPasswordPage.propTypes = {
	serverMessage: PropTypes.shape({
		token: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		return_path: PropTypes.string,
	}).isRequired,
	setEmailValue: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
	return {
		setEmailValue: (email) => dispatch(setEmailValue(email)),
	}
}

export default serverMessageDecoder(connect(null, mapDispatchToProps)(withStyles(styles)(ResetPasswordPage)))
