import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import isString from 'lodash/isString'

const styles = {
	root: {
		border: 0,
	},
}

class UiIFrame extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sandboxString: null,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const url = nextProps.appController.getDataFromDataValue(nextProps.component.url, nextProps.contextData)
		const title = nextProps.appController.getDataFromDataValue(
			nextProps.component.title,
			nextProps.contextData
		)

		if (title === prevState.title && url === prevState.url) return null

		let sandboxString = null

		const {
			enableSandbox,
			sandboxAllowTopNavigation,
			sandboxAllowTopNavigationByUser,
			sandboxAllowForms,
			sandboxAllowScripts,
			sandboxAllowSameOrigin,
			sandboxAllowPopups,
			sandboxAllowPointerLock,
		} = nextProps.component

		if (enableSandbox) {
			const sandboxRules = []
			if (sandboxAllowTopNavigation) sandboxRules.push('allow-top-navigation')
			if (sandboxAllowTopNavigationByUser) sandboxRules.push('allow-top-navigation-by-user-activation')
			if (sandboxAllowForms) sandboxRules.push('allow-forms')
			if (sandboxAllowScripts) sandboxRules.push('allow-scripts')
			if (sandboxAllowSameOrigin) sandboxRules.push('allow-same-origin')
			if (sandboxAllowPopups) sandboxRules.push('allow-popups')
			if (sandboxAllowPointerLock) sandboxRules.push('allow-pointer-lock')
			sandboxString = sandboxRules.join(' ')
		}

		return { url, title, sandboxString }
	}

	render() {
		const { component, styleProp, conditionalClassNames, classes } = this.props
		const { url, title, sandboxString } = this.state

		// sandbox with empty string === deny all
		if (isString(sandboxString))
			return (
				<iframe
					src={url}
					title={title}
					sandbox={sandboxString}
					style={styleProp}
					className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
					allowFullScreen
				/>
			)

		// No sandbox
		return (
			<iframe
				src={url}
				title={title}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				allowFullScreen
			/>
		)
	}
}

UiIFrame.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		enableSandbox: PropTypes.bool,
		sandboxAllowTopNavigation: PropTypes.bool,
		sandboxAllowTopNavigationByUser: PropTypes.bool,
		sandboxAllowForms: PropTypes.bool,
		sandboxAllowScripts: PropTypes.bool,
		sandboxAllowSameOrigin: PropTypes.bool,
		sandboxAllowPopups: PropTypes.bool,
		sandboxAllowPointerLock: PropTypes.bool,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UiIFrame)
