import React from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import LoadingComponent from '../uiComponents/utils/LoadingComponent'

const UserAccountDialog = loadable(() => import('./UserAccountDialog'), {
	fallback: <LoadingComponent />,
})

const LoadableUserAccountDialog = () => {
	const open = useSelector((state) => state.appState.accountDialogOpenState)

	if (open) {
		return <UserAccountDialog />
	}

	return null
}

export default LoadableUserAccountDialog
