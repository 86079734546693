import translateItemRecursive from './translateItemRecursive'

const translateViews = (viewArray, langDictionary) => {
	return viewArray.map((view) => {
		const viewDict = langDictionary[view.id] || {}
		return translateItemRecursive(view, viewDict)
	})
}

export default translateViews
