const p_setDataSourceAttributes = ({ actionNode, appController, actionNodeLogger, contextData }) =>
	new Promise((resolve, reject) => {
		if (!actionNode.dataSourceAttributeValues || !actionNode.dataSourceAttributeValues.length)
			return resolve()

		const dataSource = appController.getDataSource(actionNode.dataSourceId)
		if (!dataSource) return reject(new Error('Unable to find DataSource'))

		dataSource
			.p_setDataSourceAttributes({
				dataSourceAttributeValues: actionNode.dataSourceAttributeValues,
				logger: actionNodeLogger,
				contextData,
			})
			.then(resolve)
			.catch(reject)
	})

export default p_setDataSourceAttributes
