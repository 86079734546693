import { createSelector } from 'reselect'
import { defaultThemeId } from '@appfarm/common/builtins/builtInThemeDefinition'

const nameSortFunction = (itemA, itemB) => {
	const itemAValue = itemA.name || ''
	const itemBValue = itemB.name || ''
	if (itemAValue.toUpperCase() < itemBValue.toUpperCase()) return -1

	return 1
}

export const hasInsufficientPremissionsToAllApps = createSelector(
	[(state) => state.metaData.allApps],
	(allApps) => !!allApps.length && allApps.every((app) => app?.error?.type === 'NoAccess')
)

export const hasNoAppsWithSupportedDevice = createSelector(
	[(state) => state.metaData.allApps],
	(allApps) =>
		!!allApps.length &&
		allApps.filter((app) => !app.error).length === 0 &&
		allApps.some((app) => app?.error?.type === 'UnsupportedDevice')
)

export const getAppDict = createSelector([(state) => state.metaData.allApps], (allApps) =>
	allApps.reduce((dict, item) => {
		dict[item.id] = item
		return dict
	}, {})
)

export const getAppListWithTheme = createSelector(
	[(state) => state.metaData.allApps.filter((app) => !app.error), (state) => state.metaData.themes],
	(allApps, themes) =>
		allApps
			.filter((app) => !app.hideFromAppList)
			.sort(nameSortFunction)
			.map((app) => {
				const theme = themes[app.themeId] || themes[defaultThemeId]

				return {
					...app,
					theme,
				}
			})
)

export const getHiddenAppListWithTheme = createSelector(
	[(state) => state.metaData.allApps.filter((app) => !app.error), (state) => state.metaData.themes],
	(allApps, themes) =>
		allApps
			.filter((app) => app.hideFromAppList)
			.sort(nameSortFunction)
			.map((app) => {
				const theme = themes[app.themeId] || themes[defaultThemeId]

				return {
					...app,
					theme,
				}
			})
)
