//! moment.js locale configuration
//! locale : Dutch [nl]
//! author : Joris Röling : https://github.com/jorisroling
//! author : Jacob Middag : https://github.com/middagj

let monthsShortWithDots = 'jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.'.split('_'),
	monthsShortWithoutDots = 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_'),
	monthsParse = [
		/^jan/i,
		/^feb/i,
		/^maart|mrt.?$/i,
		/^apr/i,
		/^mei$/i,
		/^jun[i.]?$/i,
		/^jul[i.]?$/i,
		/^aug/i,
		/^sep/i,
		/^okt/i,
		/^nov/i,
		/^dec/i,
	],
	monthsRegex = /^(januari|februari|maart|april|mei|ju[nl]i|augustus|september|oktober|november|december|jan\.?|feb\.?|mrt\.?|apr\.?|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i

export default {
	months: 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split(
		'_'
	),
	monthsShort: function (m, format) {
		if (!m) {
			return monthsShortWithDots
		} else if (/-MMM-/.test(format)) {
			return monthsShortWithoutDots[m.month()]
		} else {
			return monthsShortWithDots[m.month()]
		}
	},

	monthsRegex: monthsRegex,
	monthsShortRegex: monthsRegex,
	monthsStrictRegex: /^(januari|februari|maart|april|mei|ju[nl]i|augustus|september|oktober|november|december)/i,
	monthsShortStrictRegex: /^(jan\.?|feb\.?|mrt\.?|apr\.?|mei|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i,

	monthsParse: monthsParse,
	longMonthsParse: monthsParse,
	shortMonthsParse: monthsParse,

	weekdays: 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
	weekdaysShort: 'zo._ma._di._wo._do._vr._za.'.split('_'),
	weekdaysMin: 'zo_ma_di_wo_do_vr_za'.split('_'),
	weekdaysParseExact: true,
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD-MM-YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd D MMMM YYYY HH:mm',
	},
	calendar: {
		sameDay: '[vandaag om] LT',
		nextDay: '[morgen om] LT',
		nextWeek: 'dddd [om] LT',
		lastDay: '[gisteren om] LT',
		lastWeek: '[afgelopen] dddd [om] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: 'over %s',
		past: '%s geleden',
		s: 'een paar seconden',
		ss: '%d seconden',
		m: 'één minuut',
		mm: '%d minuten',
		h: 'één uur',
		hh: '%d uur',
		d: 'één dag',
		dd: '%d dagen',
		w: 'één week',
		ww: '%d weken',
		M: 'één maand',
		MM: '%d maanden',
		y: 'één jaar',
		yy: '%d jaar',
	},
	dayOfMonthOrdinalParse: /\d{1,2}(ste|de)/,
	ordinal: function (number) {
		return number + (number === 1 || number === 8 || number >= 20 ? 'ste' : 'de')
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 4, // The week that contains Jan 4th is the first week of the year.
	},
}
