import axios from 'axios'

const getToken = async (appController, actionNode, rootActionId) => {
	const params = { actionNodeId: actionNode.id, actionId: rootActionId }
	const token = await axios.get('/api/v1/apps/' + appController.getActiveAppId() + '/token/external', {
		params,
	})

	if (token.data) {
		return token.data
	}

	throw new Error('Unable to extract token')
}

export default getToken
