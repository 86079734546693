const isInternalUrl = (host, href) => {
	return href.host === host || (!href.protocol && !href.host && (href.pathname || href.hash))
}

const afMarkdownExternalLink = (md) => {
	const locationUrl = new URL(window.location.href)

	const originalRender = md.renderer.rules.link_open

	md.renderer.rules.link_open = (tokens, idx, options = {}) => {
		const link = tokens[idx]

		let result = originalRender(tokens, idx, options)

		try {
			const href = new URL(link.href, locationUrl.origin)

			if (!isInternalUrl(locationUrl.host, href))
				result = result.replace('>', ' target="_blank" rel="nofollow noreferrer noopener">')

			return result
		} catch (err) {
			return '[Invalid URL]'
		}
	}
}

export default afMarkdownExternalLink
