import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'

import ListSubheader from '@material-ui/core/ListSubheader'

class UiListSubheader extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { component, contextData } = nextProps

		let subheaderTitle
		let visible = true

		if (!isUndefined(component.visible)) {
			visible = !!nextProps.appController.getDataFromDataValue(component.visible, contextData)
			if (!visible && !prevState.visible) return null
		}

		if (component.subheaderTitle) {
			subheaderTitle = nextProps.appController.getDataFromDataValue(component.subheaderTitle, contextData, {
				getDisplayValue: true,
			})

			if (visible && prevState.visible && subheaderTitle === prevState.subheaderTitle) return null
		}

		return {
			subheaderTitle,
			visible,
		}
	}

	render() {
		const { visible, subheaderTitle } = this.state
		if (!visible) return null

		return <ListSubheader disableSticky>{ subheaderTitle }</ListSubheader>
	}
}

UiListSubheader.propTypes = {
	component: PropTypes.shape({
		visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
		subheaderTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	}).isRequired,
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
	}).isRequired,
	contextData: PropTypes.object,
	subheaderTitle: PropTypes.any,
	visible: PropTypes.bool,
}

export default UiListSubheader
