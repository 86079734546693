import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

const styles = {
	root: {
		margin: '16px 0',
		'&$isHidden': {
			visibility: 'hidden',
		},
	},
	isHidden: {},
}

const InfoTextSection = ({ infoText, hideMessage, classes }) => (
	<div className={classNames(classes.root, { [classes.isHidden]: hideMessage })}>
		<Typography variant="body2" color="textSecondary" align="center">
			{ infoText }
		</Typography>
	</div>
)

InfoTextSection.propTypes = {
	infoText: PropTypes.string,
	hideMessage: PropTypes.bool,
	classes: PropTypes.object,
}

const mapStateToProps = (state) => {
	return {
		infoText: state.authState.infoText,
	}
}

export default connect(mapStateToProps)(withStyles(styles)(InfoTextSection))
