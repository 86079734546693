import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isString from 'lodash/isString'

import AppError from './AppError'

class AppErrorHandler extends Component {
	constructor(props) {
		super(props)

		this.state = {
			hasError: false,
			error: {},
			info: {},
			showDetails: false,
		}
	}

	componentDidCatch(error, info) {
		if (error instanceof Error === false) {
			if (isString(error)) {
				error = new Error(error)
			} else {
				error = new Error('Unknown error')
			}
		}

		this.setState({ hasError: true, error, info })
	}

	render() {
		if (this.state.hasError) {
			return <AppError error={this.state.error} info={this.state.info} />
		}

		return this.props.children
	}
}

AppErrorHandler.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default AppErrorHandler
