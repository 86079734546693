import accountLocalStorageHandler from '../../../modules/accountLocalStorageHandler'
import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'

export const getHotReloadDisabledFromLocalStorage = (): boolean => {
	return !!accountLocalStorageHandler.getValue(['devToolsHotReloadDisabled'])
}

export const getDevToolsEnabledFromLocalStorage = (): boolean | undefined => {
	return <boolean>accountLocalStorageHandler.getValue(['devToolsEnabled'])
}

const defaultPanelConfig = {
	collapsed: true,
	activePanel: 'logs',
	devToolsPanelSize: { height: 500 },
	dataViewPanelSize: { width: 280, height: 280 },
	actionViewPanelSize: { width: 280, height: 280 },
	activeThemeType: 'light',
}

export const getPanelCollapsedFromLocalStorage = (): boolean => {
	const collapsed = accountLocalStorageHandler.getValue(['devToolsPanelCollapsed'])
	if (!isNil(collapsed) && typeof collapsed === 'boolean') return collapsed

	return defaultPanelConfig.collapsed
}

export const getPanelSizeFromLocalStorage = (): { height: number } => {
	const size = { ...defaultPanelConfig.devToolsPanelSize }

	const height = accountLocalStorageHandler.getValue(['devToolsPanelHeight'])
	if (!isNil(height) && typeof height === 'number') size.height = height

	return size
}

export const getActivePanelFromLocalStorage = (): string => {
	const activePanel = accountLocalStorageHandler.getValue(['devToolsActivePanel'])
	if (!isNil(activePanel) && typeof activePanel === 'string') return activePanel

	return defaultPanelConfig.activePanel
}

export const getDataViewPaneSizeToLocalStorage = (): { width: number; height: number } => {
	const size = { ...defaultPanelConfig.dataViewPanelSize }

	const width = accountLocalStorageHandler.getValue(['devToolsDataViewPanelWidth'])
	const height = accountLocalStorageHandler.getValue(['devToolsDataViewPanelHeight'])
	if (!isNil(width) && typeof width === 'number') size.width = width
	if (!isNil(height) && typeof height === 'number') size.height = height

	return size
}

export const getActionViewPaneSizeToLocalStorage = (): { width: number; height: number } => {
	const size = { ...defaultPanelConfig.actionViewPanelSize }

	const width = accountLocalStorageHandler.getValue(['devToolsActionViewPanelWidth'])
	const height = accountLocalStorageHandler.getValue(['devToolsActionViewPanelHeight'])
	if (!isNil(width) && typeof width === 'number') size.width = width
	if (!isNil(height) && typeof height === 'number') size.height = height

	return size
}

export const getActiveThemeTypeFromLocalStorage = (): string => {
	const activeThemeType = accountLocalStorageHandler.getValue(['activeThemeType'])
	if (!isNil(activeThemeType) && typeof activeThemeType === 'string') return activeThemeType

	return defaultPanelConfig.activeThemeType
}

export const getMutedActionsFromLocalStorage = (): { [actionId: string]: boolean } => {
	const mutedActions = <{ [key:string]: boolean }>accountLocalStorageHandler.getValue(['devToolsMutedActions'])
	if (!isNil(mutedActions) && typeof mutedActions === 'object') return mutedActions

	return {}
}

export const getEnabledLoggersFromLocalStorage = (): { [key: string]: boolean } => {
	const enabledLoggers = accountLocalStorageHandler.getValue(['devToolsLoggingEnabledLoggers'])
	if (isObject(enabledLoggers))
		return {
			Appfarm: !!enabledLoggers.Appfarm,
			Actions: !!enabledLoggers.Actions,
			LiveUpdate: !!enabledLoggers.LiveUpdate,
		}

	return {
		Appfarm: true,
		Actions: true,
		LiveUpdate: true,
	}
}
