import e_SelectionEffectType from '@appfarm/common/enums/e_SelectionEffectType'
import e_SelectionOperationType from '@appfarm/common/enums/e_SelectionOperationType'

const p_setSelection = ({ actionNode, contextData, appController, actionNodeLogger }) => {
	const selectionType =
		actionNode.operationType === e_SelectionOperationType.TOGGLE
			? e_SelectionEffectType.CONTEXT
			: actionNode.selectionType
	actionNodeLogger.debug('Setting selection: ' + selectionType)

	const isContextEffectType =
		actionNode.selectionType === e_SelectionEffectType.CONTEXT ||
		actionNode.operationType === e_SelectionOperationType.TOGGLE
	if (isContextEffectType) {
		let selectionPomise
		if (actionNode.dataSourceId && actionNode.operationType === e_SelectionOperationType.TOGGLE) {
			// only set selection for selected datasource
			const contextDataForSelection = { [actionNode.dataSourceId]: contextData[actionNode.dataSourceId] }
			selectionPomise = appController.p_setContextSelection(contextDataForSelection, {
				operationType: actionNode.operationType || e_SelectionOperationType.SELECT,
				keepExistingSelection: actionNode.keepExistingSelection,
				logger: actionNodeLogger,
			})
		} else {
			// set selection for all context data
			selectionPomise = appController.p_setContextSelection(contextData, {
				operationType: actionNode.operationType || e_SelectionOperationType.SELECT,
				keepExistingSelection: actionNode.keepExistingSelection,
				logger: actionNodeLogger,
			})
		}

		if (actionNode.runAsync) {
			selectionPomise
				.then(() => {})
				.catch((err) => actionNodeLogger.error('Failed to set selection in ' + actionNode.nodeType, { err }))
			return Promise.resolve()
		} else {
			return selectionPomise
		}
	}

	const dataSource = appController.getDataSource(actionNode.dataSourceId)
	if (!dataSource) return Promise.reject(new Error('Unable to find datasource for selection'))

	let selectionPomise

	switch (actionNode.selectionType) {
		case e_SelectionEffectType.FIRST:
			selectionPomise = dataSource.p_selectFirst()
			break

		case e_SelectionEffectType.LAST:
			selectionPomise = dataSource.p_selectLast()
			break

		case e_SelectionEffectType.NEXT:
			selectionPomise = dataSource.p_selectNext()
			break

		case e_SelectionEffectType.PREVIOUS:
			selectionPomise = dataSource.p_selectPrevious()
			break

		case e_SelectionEffectType.ALL:
			selectionPomise = dataSource.p_selectAll()
			break

		case e_SelectionEffectType.NONE:
			selectionPomise = dataSource.p_selectNone()
			break

		case e_SelectionEffectType.FILTERED: {
			if (actionNode.operationType === e_SelectionOperationType.UNSELECT) {
				selectionPomise = dataSource.p_filteredDeselection({
					staticFilter: actionNode.staticFilter,
					filterDescriptor: actionNode.filterDescriptor,
					contextData,
				})
			} else {
				selectionPomise = dataSource.p_filteredSelection({
					staticFilter: actionNode.staticFilter,
					filterDescriptor: actionNode.filterDescriptor,
					contextData,
					keepExistingSelection: actionNode.keepExistingSelection,
				})
			}
			break
		}

		default:
			throw new Error('Unknown selectionType')
	}

	if (actionNode.runAsync) {
		selectionPomise
			.then(() => {})
			.catch((err) => actionNodeLogger.error('Failed to set selection in ' + actionNode.nodeType, { err }))

		return Promise.resolve()
	} else {
		return selectionPomise
	}
}

export default p_setSelection
