/**
 * Module for resizing images before upload.
 * Will resolve with a blob if successfull.
 * TODO: Sjekke ut denne: https://github.com/blueimp/JavaScript-Load-Image
 */

const imageResizer = (file, maxSize, quality) =>
	new Promise((resolve, reject) => {
		if (!file.type.startsWith('image/')) return reject('Image resizer can only be used with images')

		const img = document.createElement('img')
		const reader = new FileReader()
		reader.onload = function (e) {
			img.src = e.target.result
		}

		img.onerror = () => {
			img.src = null
			reject(`Image resizer was unable to load image of type '${file.type}'`)
		}

		img.onload = () => {
			let width = img.width
			let height = img.height

			let processImage = false

			if (maxSize) {
				processImage = true
				const MAX_WIDTH = maxSize
				const MAX_HEIGHT = maxSize

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width
						width = MAX_WIDTH
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height
						height = MAX_HEIGHT
					}
				}
			}

			const canvas = document.createElement('canvas')
			canvas.width = width
			canvas.height = height
			const ctx = canvas.getContext('2d')

			ctx.drawImage(img, 0, 0, width, height)

			let mimeType = 'image/png'
			if (quality && quality < 1) {
				processImage = true
				mimeType = 'image/jpeg'
			}

			if (processImage) {
				canvas.toBlob(
					(fileBlob) => {
						resolve(fileBlob)
					},
					mimeType,
					quality
				)
			} else {
				resolve(file)
			}
		}

		reader.readAsDataURL(file)
	})

export default imageResizer
