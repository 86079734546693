class ObjectClassPropertyMetadata {
	constructor(description) {
		this.id = description.id

		this.updateDescription(description)
	}

	updateDescription(description) {
		this.name = description.name
		this.nodeName = description.nodeName
		this.dataType = description.dataType

		this.referenceId = description.referenceId
		this.enumTypeId = description.enumTypeId
		this.isFunction = description.isFunction
		this.functionValue = description.functionValue
		this.defaultValue = description.defaultValue
		this.readOnly = description.readOnly
		this.isBuiltIn = description.isBuiltIn
		this.required = description.required
		this.unique = description.unique
		this.maxLength = description.maxLength
		this.minValue = description.minValue
		this.maxValue = description.maxValue
		this.regexString = description.regexString
		this.icon = description.icon

		this.labelTrue = description.labelTrue
		this.labelFalse = description.labelFalse
		this.labelUndefined = description.labelUndefined
	}
}

export default ObjectClassPropertyMetadata
