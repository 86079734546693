/* eslint-disable global-require */

import 'regenerator-runtime/runtime'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'

if (process.env.NODE_ENV === 'production') {
	const initSentry = require('./initSentry').default
	initSentry()

	// Disable React Dev Tools extension in production
	// See: https://github.com/facebook/react-devtools/issues/191
	// And: https://github.com/fvilers/disable-react-devtools
	if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
		for (const [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
				key === 'renderers' ? new Map() : typeof value === 'function' ? () => {} : null
		}
	}
}

import browserHistory from './components/browserHistory'
import AppfarmClient from './components/AppfarmClient'
import AppErrorHandler from './components/AppErrorHandler'
import StatusLoader from './components/ClientLoader/StatusLoader'

import '@mdi/font/scss/materialdesignicons.scss'
import './style.scss'

import reduxStore from './store'

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
	<Provider store={reduxStore}>
		<I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
			<AppErrorHandler>
				<AppfarmClient />
				<StatusLoader />
			</AppErrorHandler>
		</I18nProvider>
	</Provider>
)

require('./modules/sleepHandler')

/* eslint-disable no-undef */
if (AF_PARAMS && AF_PARAMS.enableClientLog) require('./appfarmDebug')
/* eslint-disable no-undef */

if (process.env.NODE_ENV === 'production') {
	/* eslint-disable no-undef */
	window.__BUILD_TIME = __BUILD_TIME
	window.__BRANCH = __BRANCH
	window.__COMMIT = __COMMIT

	window.dataLayer = window.dataLayer || []
	window.gtag = function (...args) {
		window.dataLayer.push(...args)
	}

	if (window.gtag && window.AF_PARAMS && window.AF_PARAMS.googleAnalyticsId) {
		const trackingId = window.AF_PARAMS.googleAnalyticsId
		const gtag = window.gtag

		gtag('js', new Date())
		gtag('config', trackingId, { send_page_view: false })
		gtag('config', trackingId, { page_path: `${window.location.pathname}` })
		browserHistory.listen((event) => {
			gtag('config', trackingId, { page_path: `${event.pathname}` })
		})
	}
	/* eslint-enable no-undef */
} else {
	require('./appfarmDev')
}
