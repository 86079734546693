/******************************************************************************
 *
 * Client for interacting with ClientSession
 *
 *****************************************************************************/

import axios from 'axios'

import retry from '@appfarm/common/utils/retry'

import AmpClient from './AmpClient'
import Heartbeat from './Heartbeat'
import { RequestTimeoutError, ConnectionNotReadyError, PingTimeoutError } from './errors'
import appController from '../controllers/appControllerInstance'
import { setCurrentDeployment } from '#actions/metadataActions'
import { invalidateResource } from '#actions/resourceStateActions'
import logoutAndReload from '#utils/logoutAndReload'
import { ServerError } from '#utils/clientErrors'
import { Settings as luxonSettings } from 'luxon'

import {
	MODIFY_OBJECT,
	REPLACE_DATA_IN_DATASOURCE,
	INSERT_OBJECT,
	DELETE_MULTIPLE_OBJECTS,
	INSERT_MULTIPLE_OBJECTS,
} from '#actions/actionTypes'

/******************************************************************************
 *
 * Constants
 *
 *****************************************************************************/
const operations = {
	GET_SELECTED_OBJECT_IDS: 'GET_SELECTED_OBJECT_IDS',
	GET_FILTERED_OBJECT_IDS: 'GET_FILTERED_OBJECT_IDS',
	GET_SINGLE_OBJECT: 'GET_SINGLE_OBJECT',
	GET_SINGLE_VALUE: 'GET_SINGLE_VALUE',
	GET_OBJECT_BY_ID: 'GET_OBJECT_BY_ID',
	GET_FULL_DATASOURCE: 'GET_FULL_DATASOURCE',
	GET_OBJECT_BY_SELECTION_TYPE: 'GET_OBJECT_BY_SELECTION_TYPE',
	GET_RUNTIME_OBJECT_VALUES_DICT: 'GET_RUNTIME_OBJECT_VALUES_DICT',
}

/******************************************************************************
 *
 * Protocol Definition
 *
 *****************************************************************************/

const MessageType = {
	REDUX_ACTION: 'redux_action',
	COMMAND: 'command',
	SYN: 'syn',
	ACK: 'ack',
	APPLY_CONFIG: 'apply_config',
	APPLY_DATA: 'apply_data',
	ACTIVATE_APP: 'activate_app',
}

const CommandType = {
	LOGOUT: 'logout',
	CLOSE_APP: 'close_app',
	INVALIDATE_APPLIST: 'invalidate_applist',
	INVALIDATE_CHECKSUMS: 'invalidate_checksums',
	INVALIDATE_STATUS: 'invalidate_status',
	SET_CURRENT_DEPLOYMENT: 'set_current_deployment',
}

const authFunction = () =>
	new Promise((resolve, reject) => {
		axios
			.get('/api/auth/ws/token')
			.then((result) => {
				const tokenResponse = result.data
				resolve(tokenResponse.token)
			})
			.catch(reject)
	})

/******************************************************************************
 *
 * Client
 *
 *****************************************************************************/
class WsClient {
	constructor({ dispatch }) {
		// State

		this.appId = null
		this.communicationReady = false

		this.__reduxDispatch = dispatch

		this.currentHandshakeIsSameSessionReconnect = false

		// handlers that will be connected in init
		this.__onErrorHandler = () => {}
		this.__onConnectionStateChange = () => {}
		this.__serverRequestHandler = () => {}

		this.__heartbeat = new Heartbeat({
			heartbeatInterval: 15000,
			heartbeatTimeout: 5000,
			sendPing: () => {
				this.sendRequest({
					type: 'PING',
				})
					.then(() => this.__heartbeat.messageReceived())
					.catch((err) => {
						// Only care about errors from controller
						if (this.communicationReady) {
							// Ignore connection errors
							if (err instanceof RequestTimeoutError || err instanceof ConnectionNotReadyError) return
							this._errorHandler(err)
						}
					})
			},
			onTimeout: () => this._errorHandler(new PingTimeoutError()),
		})

		// Bind
		this._messageHandler = this._messageHandler.bind(this)
		this._requestHandler = this._requestHandler.bind(this)
		this._runtimeMessageHandler = this._runtimeMessageHandler.bind(this)
		this._commandHandler = this._commandHandler.bind(this)
		this._errorHandler = this._errorHandler.bind(this)
		this._onAmpStateChange = this._onAmpStateChange.bind(this)
		this.sendRequest = this.sendRequest.bind(this)

		// AmpClient
		this.__ampClient = new AmpClient({
			appMessageHandler: this._messageHandler,
			appRequestHandler: this._requestHandler,
			onErrorHandler: this._errorHandler,
			onStateChange: this._onAmpStateChange,
			authFunction: () => authFunction(),
		})

		this.sendRequest = this.sendRequest.bind(this)
	}

	/******************************************************************************
	 *
	 * Low level message handler
	 *
	 *****************************************************************************/
	_messageHandler(message) {
		this.__heartbeat.messageReceived()

		// Handle handshake
		switch (message.type) {
			case MessageType.ACK: {
				switch (message.payload.ack) {
					case MessageType.SYN: {
						// Deployment info is part of initial handshake (no need for SET_CURRENT_DEPLOYMENT)
						if (message.payload.deployment) {
							this.__reduxDispatch(setCurrentDeployment(message.payload.deployment))
						}

						if (this.appId) {
							if (this.appId === message.payload.activeApp) {
								if (message.payload.reconnected) {
									console.log('Reconnected to same session - optimizing handshake')
									this.currentHandshakeIsSameSessionReconnect = true

									// Nothing new
									this.__ampClient.sendMessage({
										type: MessageType.ACTIVATE_APP,
									})
									return
								}

								// TODO: Wait for config ready if necessary
								// Get config and apply
								const configList = appController.getDataSourceConfigForSynchronization()
								this.__ampClient.sendMessage({
									type: MessageType.APPLY_CONFIG,
									payload: {
										configList: configList,
									},
								})
							} else {
								// TODO: Dispatch something that will lock the client
								console.error('Server and client has different apps')
								this.disconnect()
							}
						} else {
							// No app wanted (just applist)
							this.__onConnectionStateChange(true)
						}
						break
					}

					case MessageType.APPLY_CONFIG: {
						const syncData = appController.getDataForSynchronization()
						console.log('Config applied - apply selection data', syncData)
						this.__ampClient.sendMessage({
							type: MessageType.APPLY_DATA,
							payload: {
								data: syncData,
							},
						})
						break
					}

					case MessageType.APPLY_DATA: {
						this.__ampClient.sendMessage({
							type: MessageType.ACTIVATE_APP,
						})
						console.log('Ready for communication')
						break
					}

					case MessageType.ACTIVATE_APP: {
						console.log('App activated server side')
						this.__onConnectionStateChange(true, this.currentHandshakeIsSameSessionReconnect)
						break
					}
				}
				// Initiate
				break
			}

			default:
				this._runtimeMessageHandler(message)
		}
	}

	_runtimeMessageHandler(message) {
		switch (message.type) {
			// Normal Operation
			case MessageType.COMMAND:
				return this._commandHandler(message.payload)

			case MessageType.REDUX_ACTION: {
				const action = message.payload
				action.fromServer = true
				const type = action.type

				switch (type) {
					// Live update for the most part
					case MODIFY_OBJECT:
					case REPLACE_DATA_IN_DATASOURCE:
					case INSERT_OBJECT:
					case INSERT_MULTIPLE_OBJECTS:
					case DELETE_MULTIPLE_OBJECTS:
						return appController.serverActionHandler(action)

					default:
						console.warn('Got non-recognized action')
					// dispatch(action)
				}

				break
			}

			default:
				console.error('Unknown message type from server', message)
		}
	}

	/**
	 * One way commands from server
	 */
	_commandHandler(command) {
		switch (command.type) {
			case CommandType.LOGOUT:
				return logoutAndReload()

			case CommandType.INVALIDATE_CHECKSUMS:
				this.__reduxDispatch(invalidateResource('activeAppChecksums'))
				break

			case CommandType.INVALIDATE_APPLIST:
				this.__reduxDispatch(invalidateResource('globalChecksums'))
				break

			case CommandType.INVALIDATE_STATUS:
				this.__reduxDispatch(invalidateResource('clientStatus'))
				break

			case CommandType.SET_CURRENT_DEPLOYMENT:
				if (command.payload.deployment) {
					this.__reduxDispatch(setCurrentDeployment(command.payload.deployment))
				}
				break

			case CommandType.CLOSE_APP:
				window.location = '/'
				break

			default:
				console.warn('Got unrecognized command')
		}
	}

	/******************************************************************************
	 *
	 * Low level request handler
	 *
	 *****************************************************************************/
	_requestHandler(request) {
		return new Promise((resolve, reject) => {
			switch (request.type) {
				case operations.GET_SELECTED_OBJECT_IDS:
				case operations.GET_FILTERED_OBJECT_IDS:
				case operations.GET_SINGLE_OBJECT:
				case operations.GET_SINGLE_VALUE:
				case operations.GET_OBJECT_BY_ID:
				case operations.GET_FULL_DATASOURCE:
				case operations.GET_OBJECT_BY_SELECTION_TYPE:
				case operations.GET_RUNTIME_OBJECT_VALUES_DICT: {
					const response = appController.serverRequestHandler(request)
					return resolve(response)
				}

				default:
					console.warn('Unknown operation requested by server')
					reject(new Error('Invalid request type' + request.type))
			}
		})
	}

	_errorHandler(err) {
		this.__onErrorHandler(err)
	}

	_onAmpStateChange(state) {
		this.communicationReady = state.communicationReady
		if (this.communicationReady) {
			this.__heartbeat.start()

			// Initiate Handshake
			this.__ampClient.sendMessage({
				type: MessageType.SYN,
				payload: {
					appId: this.appId,
					timeZone: luxonSettings.defaultZoneName,
				},
			})
		} else {
			this.__heartbeat.stop()
			this.__onConnectionStateChange(false)
			this.currentHandshakeIsSameSessionReconnect = false
			// TODO: Create a better sync when connection to same session
		}
	}

	/**
	 * Interface for use by other parts of the client
	 */
	sendRequest(requestPayload, timeout = 30000, abortController) {
		return retry(() => this.__ampClient.sendRequest(requestPayload, timeout, abortController), {
			// Avoid retrying any legit server responses (e.g. a Web Request resulting in a non-200 status code)
			filter: (err) => !(err instanceof ServerError),
			maxTries: 3,
			retryInterval: 1000,
		})
	}

	/******************************************************************************
	 *
	 * Control Methods
	 *
	 *****************************************************************************/
	disconnect() {
		// TODO: Make sure server side runs immediate termination of
		// the session. No need to keep it in memory.

		this.__ampClient.disconnect()
	}

	connect(wantedAppId) {
		console.log('Connect called with wanted appId: ' + wantedAppId)
		this.appId = wantedAppId
		this.__ampClient.connect()
	}

	/******************************************************************************
	 *
	 * Init
	 *
	 *****************************************************************************/

	setHandlerMethods(handlers = {}) {
		if (handlers.onErrorHandler) this.onErrorHandler = handlers.onErrorHandler
		if (handlers.onConnectionStateChange) this.__onConnectionStateChange = handlers.onConnectionStateChange
	}
}

export default WsClient
