import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Tabs from '@material-ui/core/Tabs'

import UiTab from './UiTab'

const useStyles = makeStyles({
	root: {
		position: 'relative',
	},
})

const UiTabs = (props) => {
	const [activeTab, setAvtiveTab] = useState(null)
	const [defaultTab, setDefaultTab] = useState(null)
	const [tabDefinitions, setTabDefinitions] = useState([])

	useEffect(() => {
		if (props.ownData && props.component.value) {
			let activeTabValue = props.ownData[props.component.value.nodeName]
			if (!isNil(activeTabValue)) activeTabValue += '' // always use string values
			setAvtiveTab(activeTabValue)
		}
	}, [props.ownData])

	useEffect(() => {
		let newDefaultTab
		const generatedTabDefinitions = props.component.tabs
			.map((tab, index) => {
				let tabValue = index
				let badgeValue
				let visible = true
				let disabled = false
				let onClick

				if (!isNil(tab.value))
					tabValue = props.appController.getDataFromDataValue(tab.value, props.contextData)

				if (tab.badge && tab.badgeValue)
					badgeValue = props.appController.getDataFromDataValue(tab.badgeValue, props.contextData)

				if (!isNil(tab.visible))
					visible = !!props.appController.getDataFromDataValue(tab.visible, props.contextData)

				if (!isNil(tab.disabled))
					disabled = !!props.appController.getDataFromDataValue(tab.disabled, props.contextData)

				if (tab.onClick)
					onClick = () => {
						props.eventHandler(tab.onClick, null, { eventType: 'onClick' }, event)
					}

				if (!isNil(tabValue)) tabValue += '' // always use string values

				if (isNil(newDefaultTab) && visible && !disabled) newDefaultTab = tabValue

				return {
					...tab,
					value: tabValue,
					badgeValue,
					visible,
					disabled,
					onClick,
				}
			})
			.filter((tab) => tab.visible)

		setDefaultTab(newDefaultTab)
		setTabDefinitions(generatedTabDefinitions)
	}, [props])

	const handleOnTabClick = useCallback(
		(newValue) => {
			if (!props.component.value) {
				console.log('Please assign a data binding to Active Destination on the Bottom Navigation component')
				return
			}

			props.appController.modifySingleValue(props.component.value, props.ownData, newValue, {})
		},
		[props.ownData]
	)

	const { textColor, indicatorColor, centered, variant, orientation } = props.component

	const classes = useStyles()
	return (
		<Tabs
			value={isNil(activeTab) ? defaultTab : activeTab}
			textColor={textColor}
			indicatorColor={indicatorColor}
			centered={centered}
			variant={variant}
			orientation={orientation}
			className={classNames(classes.root, 'c' + props.component.id, props.conditionalClassNames)}
			style={props.styleProp}
		>
			{ tabDefinitions.map((tab) => (
				<UiTab key={tab.id} value={tab.value} tab={tab} handleOnTabClick={handleOnTabClick} />
			)) }
		</Tabs>
	)
}

UiTabs.propTypes = {
	component: PropTypes.shape({
		id: PropTypes.string.isRequired,
		tabs: PropTypes.arrayOf(
			PropTypes.shape({
				icon: PropTypes.string,
				name: PropTypes.string,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
				disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
				visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
				badgeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
				badgeColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']).isRequired,
				onClick: PropTypes.shape({
					actionId: PropTypes.string.isRequired,
					actionInputParams: PropTypes.array,
				}),
			})
		).isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
		textColor: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
		indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
		centered: PropTypes.bool,
		variant: PropTypes.oneOf(['standard', 'fullWidth', 'scrollable']),
		orientation: PropTypes.oneOf(['horizontal', 'vertical']),
	}).isRequired,
	ownData: PropTypes.object,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	appController: PropTypes.shape({
		getDataFromDataValue: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
	}).isRequired,
	generatedTabDefinitions: PropTypes.array,
	contextData: PropTypes.object,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	conditionalClassNames: PropTypes.string,
}

export default UiTabs
