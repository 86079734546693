import p_setSelection from '../actionNodes/setSelection'
import p_setDataSourceAttributes from '../actionNodes/setDataSourceAttributes'
import p_createObject from '../actionNodes/createObject'
import p_modifyObject from '../actionNodes/modifyObject'
import p_deleteObject from '../actionNodes/deleteObject'
import p_readObjects from '../actionNodes/readObjects'
import p_duplicateObjects from '../actionNodes/duplicateObjects'
import p_openDialog from '../actionNodes/openDialog'
import p_closeDialog from '../actionNodes/closeDialog'
import p_openPopover from '../actionNodes/openPopover'
import p_closePopover from '../actionNodes/closePopover'
import p_navigate from '#actions/actionNodes/navigate'
import p_toggleDrawer from '../actionNodes/toggleDrawer'
import p_importDataFromFile from '../actionNodes/importDataFromFile'
import p_persist from '../actionNodes/persist'
import p_runAction from '../actionNodes/runAction'
import p_uploadFile from '../actionNodes/uploadFile'
import p_createFileArchive from '../actionNodes/createFileArchive'
import p_sortObjects from '../actionNodes/sortObjects'
import p_logOut from '../actionNodes/logOut'
import p_logIn from '../actionNodes/logIn'
import p_consoleLog from '../actionNodes/consoleLog'
import p_sendClientState from '../actionNodes/sendClientState'
import p_runActionOnServer from '../actionNodes/runActionOnServer'
import p_webRequest from '../actionNodes/webRequest'
import p_createUserAccount from '../actionNodes/createUserAccount'
import p_modifyUserAccount from '../actionNodes/modifyUserAccount'
import p_deleteUserAccount from '../actionNodes/deleteUserAccount'
import p_openUrl from '../actionNodes/openUrl'
import p_copyToClipboard from '../actionNodes/copyToClipboard'
import p_requestSystemPermission from '../actionNodes/requestSystemPermission'
import p_sleep from '../actionNodes/sleep'
import p_runService from '../actionNodes/runService'
import p_createShortlink from '../actionNodes/createShortlink'
import p_setLanguage from '../actionNodes/setLanguage'
import p_setTheme from '../actionNodes/setTheme'
import p_openAccountDialog from '../actionNodes/openAccountDialog'
import p_preventDeviceSleep from '../actionNodes/preventDeviceSleep'
import p_installApp from '../actionNodes/installApp'
import p_generateDocument from '../actionNodes/generateDocument'
import p_setUserAccountImage from '../actionNodes/setUserAccountImage'
import p_logGoogleAnalyticsEvent from '../actionNodes/logGoogleAnalyticsEvent'
import p_createCalendarEvent from '../actionNodes/createCalendarEvent'
import openConfirmDialog from '../actionNodes/openConfirmDialog'
import openSnackbar from '../actionNodes/openSnackbar'
import p_openUnsplashDialog from '../actionNodes/openUnsplashDialog'
import p_openPrintDialog from '../actionNodes/openPrintDialog'
import p_runCode from '../actionNodes/runCode'
import p_exportData from '../actionNodes/exportData'
import p_scanBarcode from '../actionNodes/scanBarcode'
import p_advancedSearch from '../actionNodes/advancedSearch'
import p_throwException from '#actions/actionNodes/throwException'

import forEachActionNode from '../actionNodes/forEach'
import ifActionNode from '../actionNodes/if'
import whileActionNode from '../actionNodes/while'

import blockActionNode from '../actionNodes/block'
import catchException from '../actionNodes/catchException'

import e_ActionNodeType from '@appfarm/common/enums/e_ActionNodeType'
import { isOnDevelopData } from '#selectors/metadataSelectors'
import e_ExceptionType from '@appfarm/common/enums/e_ExceptionType'
import appController from '../../controllers/appControllerInstance'
import p_authOperations from '#actions/actionNodes/authOperations'

/**
 *
 */
class ActionNodeRunner {
	constructor(parents, indexInParent, actionNode, dispatch, getState, parentLogger) {
		this.rootActionRunner = parents.root
		this.parent = parents.parent
		this.dispatch = dispatch
		this.getState = getState
		this.indexInParent = indexInParent
		this.actionNode = actionNode

		this.actionNodeLogger = parentLogger.createChildLogger({
			prefix: `Action Node: ${this.actionNode.name}`,
		})

		if (actionNode.children)
			this.children = actionNode.children.map(
				(childActionNode, index) =>
					new ActionNodeRunner(
						{ root: this.rootActionRunner, parent: this },
						index,
						childActionNode,
						dispatch,
						getState,
						this.actionNodeLogger
					)
			)

		this.run = this.run.bind(this)
		this.getRootAction = this.getRootAction.bind(this)
		this.getEventContext = this.getEventContext.bind(this)
		this.isValidExceptionHandler = this.isValidExceptionHandler.bind(this)
	}

	getEventContext() {
		return this.rootActionRunner.eventContext
	}

	getRootAction() {
		return this.rootActionRunner.actionInstance
	}

	async run({ contextData, error, contextLogger }) {
		// just skip / do nothing
		if (this.actionNode.disabled) {
			return
		}

		const logger = contextLogger
			? contextLogger.createChildLogger({ prefix: `Action Node: ${this.actionNode.name}` })
			: this.actionNodeLogger

		if (this.actionNode.nodeType === e_ActionNodeType.CATCH_EXCEPTION) {
			return await catchException({
				actionNode: this.actionNode,
				contextData,
				appController,
				error,
				actionNodeRunner: this,
				actionNodeLogger: logger,
			})
		}

		logger.time(this.actionNode.name)
		console.group(this.actionNode.name)
		logger.debug(this.actionNode.name)

		if (this.actionNode.disabledInDevelop && isOnDevelopData(this.getState())) {
			logger.info('Action Node is disabled in DEV and TEST environment')
			console.groupEnd()
			logger.timeEnd(this.actionNode.name)
			return
		}

		const actionNode = this.actionNode
		const eventContext = this.getEventContext()

		const payload = {
			actionNode,
			contextData,
			appController,
			actionNodeRunner: this,
			actionNodeLogger: logger,
			dispatch: this.dispatch,
			getState: this.getState,
			eventContext,
		}

		const execute = async () => {
			// prettier-ignore
			switch (actionNode.nodeType) {
			/**
			 * Block Action Nodes
			 */
				case e_ActionNodeType.FOREACH: return await forEachActionNode(payload)
				case e_ActionNodeType.WHILE: return await whileActionNode(payload)
				case e_ActionNodeType.IF: return await ifActionNode(payload)
				case e_ActionNodeType.BLOCK: return await blockActionNode(payload)

					/**
			 * CRUD and Data
			 */
				case e_ActionNodeType.CREATE_OBJECT: return await p_createObject(payload)
				case e_ActionNodeType.MODIFY_OBJECT: return await p_modifyObject(payload)
				case e_ActionNodeType.DELETE_OBJECT: return await p_deleteObject(payload)
				case e_ActionNodeType.READ_OBJECTS: return await p_readObjects(payload)
				case e_ActionNodeType.DUPLICATE_OBJECTS: return await p_duplicateObjects(payload)
				case e_ActionNodeType.SET_SELECTION: return await p_setSelection(payload)
				case e_ActionNodeType.SET_DATA_SOURCE_ATTRIBUTES: return await p_setDataSourceAttributes(payload)
				case e_ActionNodeType.PERSIST: return await p_persist(payload)
				case e_ActionNodeType.SORT_OBJECTS: return await p_sortObjects(payload)
				case e_ActionNodeType.ADVANCED_SEARCH: return await p_advancedSearch(payload)
				case e_ActionNodeType.IMPORT_DATA_FROM_FILE: return await p_importDataFromFile(payload)
				case e_ActionNodeType.EXPORT_DATA: return await p_exportData(payload)
				case e_ActionNodeType.UPLOAD_FILE: return await p_uploadFile(payload)
				case e_ActionNodeType.CREATE_FILE_ARCHIVE: return await p_createFileArchive(payload)
				case e_ActionNodeType.GENERATE_DOCUMENT: return await p_generateDocument(payload)
				case e_ActionNodeType.CREATE_CALENDAR_EVENT: return await p_createCalendarEvent(payload)
				case e_ActionNodeType.WEB_REQUEST: return await p_webRequest(payload)

					/**
			 * Navigation and UI
			 */
				case e_ActionNodeType.OPEN_DIALOG: return await p_openDialog(payload)
				case e_ActionNodeType.CLOSE_DIALOG: return await p_closeDialog(payload)
				case e_ActionNodeType.OPEN_POPOVER: return await p_openPopover(payload)
				case e_ActionNodeType.CLOSE_POPOVER: return await p_closePopover(payload)
				case e_ActionNodeType.NAVIGATE: return await p_navigate(payload)
				case e_ActionNodeType.TOGGLE_DRAWER: return await p_toggleDrawer(payload)
				case e_ActionNodeType.OPEN_CONFIRM_DIALOG: return openConfirmDialog(payload)
				case e_ActionNodeType.OPEN_SNACKBAR: return openSnackbar(payload)
				case e_ActionNodeType.LOG_OUT: return await p_logOut(payload)
				case e_ActionNodeType.LOG_IN: return await p_logIn(payload)
				case e_ActionNodeType.AUTH_OPERATIONS: return await p_authOperations(payload)
				case e_ActionNodeType.OPEN_URL: return await p_openUrl(payload)
				case e_ActionNodeType.SET_LANGUAGE: return await p_setLanguage(payload)
				case e_ActionNodeType.SET_THEME: return await p_setTheme(payload)
				case e_ActionNodeType.OPEN_ACCOUNT_DIALOG: return await p_openAccountDialog(payload)
				case e_ActionNodeType.OPEN_UNSPLASH_DIALOG: return await p_openUnsplashDialog(payload)
				case e_ActionNodeType.OPEN_PRINT_DIALOG: return await p_openPrintDialog(payload)

					/**
			 * Server Actions
			 */
				case e_ActionNodeType.SEND_EMAIL:
				case e_ActionNodeType.SEND_SMS:
				case e_ActionNodeType.WEB_PUSH:
					return await p_runActionOnServer(payload)

					/**
			 * User Account
			 */
				case e_ActionNodeType.MODIFY_USER_ACCOUNT: return await p_modifyUserAccount(payload)
				case e_ActionNodeType.SET_USER_ACCOUNT_IMAGE: return await p_setUserAccountImage(payload)
				case e_ActionNodeType.CREATE_USER_ACCOUNT: return await p_createUserAccount(payload)
				case e_ActionNodeType.DELETE_USER_ACCOUNT: return await p_deleteUserAccount(payload)

					/**
			 * Misc
			 */
				case e_ActionNodeType.SLEEP: return await p_sleep(actionNode)
				case e_ActionNodeType.RUN_SERVICE: return await p_runService(payload)
				case e_ActionNodeType.CREATE_SHORTLINK: return await p_createShortlink(payload)
				case e_ActionNodeType.PREVENT_DEVICE_SLEEP: return await p_preventDeviceSleep(payload)
				case e_ActionNodeType.REQUEST_SYSTEM_PERMISSION: return await p_requestSystemPermission(payload)
				case e_ActionNodeType.LOG_GOOGLE_ANALYTICS_EVENT: return await p_logGoogleAnalyticsEvent(payload)
				case e_ActionNodeType.INSTALL_APP: return await p_installApp(payload)
				case e_ActionNodeType.SCAN_BARCODE: return await p_scanBarcode(payload)
				case e_ActionNodeType.RUN_CODE: return await p_runCode(payload)
				case e_ActionNodeType.COPY_TO_CLIPBOARD: return await p_copyToClipboard(payload)

					/**
			 * Debug
			 */
				case e_ActionNodeType.CONSOLE_LOG: return await p_consoleLog(payload)
				case e_ActionNodeType.SEND_CLIENT_STATE: return await p_sendClientState(payload)

					/**
			 * Flow
			 */
				case e_ActionNodeType.RUN_ACTION: return await p_runAction(payload)
				case e_ActionNodeType.NEXT_ITERATION: return { nextIteration: true }
				case e_ActionNodeType.EXIT_LOOP: return { exitLoop: true }
				case e_ActionNodeType.END_EXECUTION: return { endExecution: true }
				case e_ActionNodeType.THROW_EXCEPTION: return await p_throwException(payload)

				default:
					throw new Error('Unknown actionNodeType: ' + actionNode.nodeType)
			}
		}

		/**
		 * Execute it
		 */

		let result
		try {
			// ActionRunner has debugger attached. Check for breakpoint
			if (this.rootActionRunner.actionDebugger) {
				this.rootActionRunner._debug_setContextData(actionNode.id, payload.contextData)
				await this.rootActionRunner._debug_executionRequest(actionNode.id, actionNode.name)
			}

			result = await execute()
		} catch (err) {
			logger.error(`Action Node failed: ${this.actionNode.name}`, { err })

			if (err.requiredPermissions)
				logger.debug('Required permissions: ' + JSON.stringify(err.requiredPermissions))

			// Print whatever debug info from action node
			if (err.debug) {
				const message = err.debug.message || ''
				logger.debug('Debug info: ' + message, { payload: err.debug })
			}

			if (this.rootActionRunner.actionDebugger) {
				await this.rootActionRunner._debug_actionNodeFailed(actionNode.id, err)
			}

			throw err
		} finally {
			console.groupEnd()
			logger.timeEnd(this.actionNode.name)
		}

		if (this.rootActionRunner.actionDebugger) {
			await this.rootActionRunner._debug_actionNodeSuccess(actionNode.id)
		}

		return result
	}

	// Use static to enable testing
	static isValidExceptionHandler(exception, actionNode) {
		if (actionNode.disabled) return false
		if (actionNode.nodeType === e_ActionNodeType.CATCH_EXCEPTION) {
			if (actionNode.catchAll) return true

			// Cannot catch non-error exceptions
			if (exception instanceof Error === false) return false

			let name = null
			if (exception && exception.name) name = exception.name

			// Check if custom Exception
			if (name === e_ExceptionType.CUSTOM_EXCEPTION && actionNode.exceptionTypes?.includes(name)) {
				if (actionNode.customCodes?.length) {
					return actionNode.customCodes.includes(exception.customCode)
				} else {
					// No code - allow catch
					return true
				}
			}

			// Check if http error
			if (name === e_ExceptionType.WEB_REQUEST_EXCEPTION && actionNode.exceptionTypes?.includes(name)) {
				// Catch all http errors
				if (!actionNode.httpCodes?.length) return true
				if (actionNode.httpCodes.includes(exception.httpStatusCode)) return true
			}

			// Any other selectable actionNode errors
			if (name !== e_ExceptionType.CUSTOM_EXCEPTION && name !== e_ExceptionType.WEB_REQUEST_EXCEPTION)
				if (actionNode.exceptionTypes?.includes(name)) return true

			// Catch all defined error codes thrown by appfarm
			if (actionNode.exceptionTypes?.includes(e_ExceptionType.DEFINED_ERRORS)) {
				if (actionNode.afErrorCodes?.includes(exception.code)) return true
			}

			return false
		} else {
			return false
		}
	}

	isValidExceptionHandler(exception) {
		return ActionNodeRunner.isValidExceptionHandler(exception, this.actionNode)
	}
}

export default ActionNodeRunner
