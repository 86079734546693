import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { t } from '@lingui/macro'

import { makeStyles, alpha } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Avatar from '@material-ui/core/Avatar'

import {
	getClientOutdated,
	getIsCurrentAppOutdated,
	getIsAppReadyForRender,
	getLoadedChecksumsHasValue,
	getDeployData,
	getOutdatedChecksums,
} from '#selectors/metadataSelectors'

import appController from '../controllers/appControllerInstance'
const getDataFromDataValue = appController.getDataFromDataValue

const useStyles = makeStyles((theme) => ({
	dialogRoot: {
		zIndex: 1600,
	},
	snackbarRoot: {
		zIndex: 1700,
	},
	dialogPaper: {
		padding: '32px 0',
		maxWidth: 540,
		overflow: 'hidden',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: '16px 0',
		},
	},
	dialogHeader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 32px',
		[theme.breakpoints.down('xs')]: {
			padding: '0 16px',
		},
	},
	icon: {
		color: alpha(theme.palette.text.primary, 0.12),
		fontSize: 42,
		[theme.breakpoints.down('xs')]: {
			fontSize: 34,
		},
	},
	symbolContainer: {
		backgroundColor: alpha(theme.palette.text.primary, 0.07),
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 88,
		width: 88,
		[theme.breakpoints.down('xs')]: {
			height: 64,
			width: 64,
		},
	},
	image: {
		maxWidth: 270,
		maxHeight: 270,
		marginBottom: 8,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 4,
		},
	},
	rocket: {
		fill: alpha(theme.palette.text.primary, 0.12),
		height: 42,
		width: 42,
		[theme.breakpoints.down('xs')]: {
			height: 34,
			width: 34,
		},
	},
	dialogTitle: {
		textAlign: 'center',
	},
	textContentContainer: {
		overflowY: 'auto',
		padding: '0 32px',
		[theme.breakpoints.down('xs')]: {
			padding: '0 16px',
		},
	},
	wrapText: {
		whiteSpace: 'pre-wrap',
	},
	closeButton: {
		marginLeft: 8,
	},
	button: {
		minWidth: 132,
		whiteSpace: 'nowrap',
		marginTop: 32,
		[theme.breakpoints.down('xs')]: {
			marginTop: 16,
		},
	},
	standaloneAvatarContainer: {
		gap: 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 24,
	},
	avatarContainer: {
		gap: 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	commentContainer: {
		display: 'flex',
		gap: 16,
		borderRadius: theme.shape.borderRadius,
		marginTop: 32,
		padding: 16,
		backgroundColor: alpha(theme.palette.text.primary, 0.07),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'center',
			gap: 8,
			textAlign: 'center',
		},
	},
	divider: {
		margin: '16px 0',
	},
}))

const checksumsRequiringReload = ['dataSources', 'objectClasses', 'enumeratedTypes']

const DeployNotifier = () => {
	const reloadApp = () => window.location.reload(true)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [snackbarOpen, setSnackbarOpen] = useState(false)

	const [appDeployImage, setAppDeployImage] = useState(null)
	const [appDeployTitle, setAppDeployTitle] = useState(null)
	const [appDeployMessage, setAppDeployMessage] = useState(null)
	const [appDeployButtonText, setAppDeployButtonText] = useState(null)

	const {
		activeAppName,
		deployComment,
		deployedByImageUrl,
		deployedByName,
		personalizedDeploy,
		appDeployData,
	} = useSelector(getDeployData)

	const readyForRender = useSelector(getIsAppReadyForRender)
	const loadedChecksumsHasValue = useSelector(getLoadedChecksumsHasValue)
	const clientOutdated = useSelector(getClientOutdated)
	const isCurrentAppOutdated = useSelector(getIsCurrentAppOutdated)
	const outdatedKeys = useSelector(getOutdatedChecksums)

	useEffect(() => {
		if (!appDeployData) return
		setAppDeployImage(getDataFromDataValue(appDeployData.deployImage))
		setAppDeployTitle(appDeployData.deployTitle)
		setAppDeployMessage(appDeployData.deployMessage)
		setAppDeployButtonText(appDeployData.deployButtonText)
	}, [appDeployData])

	/**
	 * TODO:  Add more granularity (Hot reload of UI if possible)
	 */
	useEffect(() => {
		if (dialogOpen) return // Avoid snackbar to open after clientOutdated has opened the dialog
		if (!readyForRender) return // let the app load before we evaluate
		if (!loadedChecksumsHasValue) return // Let the app populate before eval
		if (clientOutdated) return setDialogOpen(true)
		if (isCurrentAppOutdated) {
			if (Object.keys(outdatedKeys).some((key) => checksumsRequiringReload.includes(key))) setDialogOpen(true)
			else setSnackbarOpen(true)
		}
	}, [
		dialogOpen,
		clientOutdated,
		isCurrentAppOutdated,
		outdatedKeys,
		readyForRender,
		loadedChecksumsHasValue,
	])

	const handleDismissNotification = () => {
		setSnackbarOpen(false)
	}

	const defaultMessage = t`A new version of ${
		activeAppName || 'this app'
	} is ready. Please press reload to get the new version.`

	const classes = useStyles()

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={
					<span id="message-id">
						{ appDeployTitle || t`A new version of ${activeAppName || 'this app'} is available` }
					</span>
				}
				classes={{ root: classes.snackbarRoot }}
				action={[
					<Button key="update-now" color="inherit" size="small" onClick={reloadApp}>
						{ appDeployButtonText || t`Reload` }
					</Button>,
					<IconButton
						key="close"
						aria-label={t`Close`}
						color="inherit"
						className={classes.closeButton}
						onClick={handleDismissNotification}
						size="small"
					>
						<Icon className="mdi mdi-close" />
					</IconButton>,
				]}
			/>
			<Dialog open={dialogOpen} classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}>
				<div className={classes.dialogHeader}>
					{ appDeployImage ? (
						<img src={appDeployImage} className={classes.image} />
					) : (
						<div className={classes.symbolContainer}>
							<svg
								className={classes.rocket}
								role="img"
								alt="Appfarm rocket logo"
								viewBox="0 0 188 188"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M160.8 100.96C119.39 142.37 64.1 123.57 64.1 123.57C64.1 123.57 60.43 112.76 59.9 97.13C41.81 84.81 21 78.2 0 77.3C3.29 71.86 7.28 66.73 11.98 62.03C29.65 44.36 53.49 36.64 76.55 38.86C79.44 34.78 82.81 30.76 86.7 26.86C128.11 -14.55 183.4 4.25 183.4 4.25C183.4 4.25 202.2 59.55 160.8 100.96Z" />
								<path d="M125.64 175.68C120.94 180.38 115.81 184.37 110.37 187.66C109.74 173 106.33 158.43 100.14 144.82C115.99 141.64 132.93 135.21 149.05 123.34C147.99 142.39 140.19 161.13 125.64 175.68Z" />
								<path d="M66.06 166.18C47.34 184.9 16.79 170.86 16.79 170.86C16.79 170.86 2.75 140.32 21.47 121.59C29.71 113.35 40.23 111.46 49.44 111.92C46.66 112.9 43.97 114.48 41.58 116.87C29.32 129.13 38.52 149.13 38.52 149.13C38.52 149.13 58.52 158.32 70.78 146.07C73.17 143.68 74.75 140.99 75.73 138.21C76.19 147.42 74.29 157.95 66.06 166.18Z" />
							</svg>
						</div>
					) }
					<DialogTitle className={classes.dialogTitle}>
						{ appDeployTitle || t`New version available` }
					</DialogTitle>
				</div>
				<div className={classes.textContentContainer}>
					<Typography variant="body1" align="center" color="textSecondary" className={classes.wrapText}>
						{ appDeployMessage || defaultMessage }
					</Typography>

					{ !deployComment && personalizedDeploy && (
						<div className={classes.standaloneAvatarContainer}>
							<Avatar src={deployedByImageUrl} />
							<Typography variant="caption" color="textSecondary" noWrap>
								{ deployedByName }
							</Typography>
						</div>
					) }

					{ deployComment && (
						<div className={classes.commentContainer}>
							{ personalizedDeploy && (
								<div className={classes.avatarContainer}>
									<Avatar src={deployedByImageUrl} />
									<Typography variant="caption" color="textSecondary" noWrap>
										{ deployedByName }
									</Typography>
								</div>
							) }
							<Typography variant="body1" color="textSecondary" className={classes.wrapText}>
								{ deployComment }
							</Typography>
						</div>
					) }
				</div>
				<div>
					<Button className={classes.button} onClick={reloadApp} variant="contained" color="primary">
						{ appDeployButtonText || t`Reload` }
					</Button>
				</div>
			</Dialog>
		</>
	)
}

export default DeployNotifier
