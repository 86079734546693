import { t } from '@lingui/macro'

import { openSnackbar, closeSnackbar } from '#actions/appStateActions'
import executeBlockInSequence from './helpers/executeBlockInSequence'

const openSnackbarActionNode = ({
	actionNode,
	contextData,
	actionNodeRunner,
	dispatch,
	appController,
	actionNodeLogger,
}) =>
	new Promise((resolve, reject) => {
		const text = appController.getDataFromDataValue(actionNode.text, contextData) || ''
		const timeout = actionNode.timeout ? actionNode.timeout * 1000 : 5000

		let actionButtonText
		if (actionNode.enableAction)
			actionButtonText =
				appController.getDataFromDataValue(actionNode.actionButtonText, contextData) || t`Action`

		if (actionNode.notifyOnly) {
			resolve()
		}

		const onDismissCallback = () => {
			dispatch(closeSnackbar())

			if (actionNode.notifyOnly) {
				actionNodeLogger.debug('Snackbar action called - is Notify Only so just close the snackbar')
				return
			}

			actionNodeLogger.debug('Snackbar dismissed - continue execution')
			resolve()
		}

		const onActionCallback = () => {
			dispatch(closeSnackbar())

			if (actionNode.notifyOnly) {
				actionNodeLogger.debug('Snackbar action called - is Notify Only so just close the snackbar')
				return
			}

			actionNodeLogger.debug('Snackbar action called - run children')

			if (actionNodeRunner.children.length) {
				executeBlockInSequence(actionNodeRunner.children, contextData, actionNodeLogger)
					.then((result) => resolve(result))
					.catch(reject)
			} else {
				// condition not true - Signal to parent to run next
				actionNodeLogger.debug('No children - just continue execution')
				resolve()
			}
		}

		dispatch(
			openSnackbar(
				actionNode.autoHide ? timeout : null,
				text,
				actionButtonText,
				onActionCallback,
				onDismissCallback
			)
		)
	})

export default openSnackbarActionNode
